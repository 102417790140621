/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { showToast, theme } from '@provi/provi-components'
import { uploadImages } from '../../../services/api'

import { URL_EXTERNAL } from '../../../constants'

export const useSchoolRecords = (
  goToNextScreen,
  setDataModal,
  creditPath,
  setIsLoading,
  setIsShowModal,
  match,
  MesadaProviSlug
) => {
  const [preview, setPreview] = useState()
  const [filesNotUpload, setFilesNotUpload] = useState([])
  const [filesUploaded, setFilesUploaded] = useState([])
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [numberOfFiles, setNumberOfFiles] = useState(0)
  const [numberOfFilesInitial, setNumberOfFilesInitial] = useState(0)

  let title = 'Envie o seu histórico acadêmico'

  if (creditPath.partnerId === 1344) {
    title = 'Envie uma foto do verso do seu CRM'
  } else if (match.params.partner === MesadaProviSlug) {
    title = 'Envie o seu histórico acadêmico completo'
  }

  useEffect(() => {
    setDataModal({
      title: 'O que é o histórico acadêmico?',
      texts: [
        'O histórico acadêmico é um documento oficial que registra toda a sua trajetória acadêmica referente ao seu rendimento escolar.',
        'Normalmente, a solicitação desse documento é feita no departamento de Coordenação do Curso ou pelo portal digital da sua universidade.'
      ],
      textButton: 'Entendi',
      buttonFunction: () => setIsShowModal(false),
      textLink: {
        color: theme.colors.blue900,
        text: 'Abrir chat',
        onClick: () => window.open(URL_EXTERNAL, '_blank')
      }
      // buttonFunction: () => setIsShowModal(false),
      // textLink: {
      //   color: theme.colors.blue900,
      //   text: 'Abrir chat',
      //   onClick: () => window.open(URL_EXTERNAL, '_blank')
      // }
    })

    if (creditPath && creditPath.ReadSchoolRecord && creditPath.ReadSchoolRecord.length > 0) {
      setIsButtonDisabled(false)
      setNumberOfFiles(creditPath.ReadSchoolRecord.length)
      setPreview(creditPath.ReadSchoolRecord[0])
      setFilesUploaded(creditPath.ReadSchoolRecord)
      setNumberOfFilesInitial(creditPath.ReadSchoolRecord.length)
    }
  }, [setDataModal, creditPath])

  useEffect(() => {
    if (filesNotUpload.length > 0) {
      setIsButtonDisabled(false)
    }
  }, [filesNotUpload])

  const onSubmit = async e => {
    e.preventDefault()
    setIsLoading(true)
    try {
      const urls = await uploadImages(filesNotUpload)
      goToNextScreen([...urls, ...filesUploaded])
    } catch (e) {
      showToast()
    }
  }

  return [
    onSubmit,
    numberOfFiles,
    preview,
    setPreview,
    setFilesNotUpload,
    filesNotUpload,
    setNumberOfFiles,
    numberOfFilesInitial,
    isButtonDisabled,
    title
  ]
}
