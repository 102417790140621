import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const ContainerOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${theme.variables.spacer * 2}px;
  margin-bottom: 38px;

  ${theme.breakpoints.down('md')} {
    justify-content: space-between;
  }
`
