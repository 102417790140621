/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { showToast, theme } from '@provi/provi-components'
import Fuse from 'fuse.js'
import { addCourse, removeCourse } from '../../../services/api'
import { getSlugPartner } from '../../../utils'
import { LOCALSTORAGE_TOKENS, URL_EXTERNAL } from '../../../constants'

const formatCourses = (available, choosed) => {
  let availableCoursesFormatted = [...available]
  choosed.map(course => {
    const indexOfCourse = availableCoursesFormatted.indexOf(availableCoursesFormatted.find(item => item.id === course.id))
    return availableCoursesFormatted.splice(indexOfCourse, 1)
  })
  return availableCoursesFormatted
}

const OPTIONS = {
  shouldSort: true,
  threshold: 0.3,
  location: 4,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ['name']
}

const INITIAL_NUMBERS_SHOW_COURSES = 6

export const useCourseSelection = (setDataModal, goToNextScreen, creditPath, setIsLoading, setCreditPath, setIsShowModal) => {
  const [options, setOptions] = useState([])
  const [optionsSearch, setOptionsSearch] = useState([])
  const [cursesSelect, setCursesSelect] = useState([])
  const [shouldAddCourseThroughRequest, setShouldAddCourseThroughRequest] = useState(true)

  const [textSearch, setTextSearch] = useState('')
  const [countCourses, setCountCourses] = useState(INITIAL_NUMBERS_SHOW_COURSES)

  useEffect(() => {
    setDataModal({
      title: 'Algo errado?',
      texts: ['Não está encontrando o seu curso ou alguma informação não está correta? Entre em contato com a gente.'],
      textButton: 'Entendi',
      buttonFunction: () => setIsShowModal(false),
      textLink: {
        color: theme.colors.blue900,
        text: 'Abrir chat',
        onClick: () => window.open(URL_EXTERNAL, '_blank')
      }
    })

    if (
      creditPath &&
      creditPath.ReadProductSelection &&
      creditPath.ReadProductSelection.availableCourses &&
      creditPath.ReadProductSelection.availableCourses.length === 1 &&
      creditPath &&
      creditPath.ReadProductSelection &&
      creditPath.ReadProductSelection.availableCourses &&
      creditPath.ReadProductSelection.choosedCourses.length === 1
    ) {
      creditPath.ReadProductSelection.availableCourses = creditPath.ReadProductSelection.choosedCourses
      setShouldAddCourseThroughRequest(false)
    }

    if (creditPath && creditPath.ReadProductSelection && creditPath.ReadProductSelection.availableCourses) {
      const courses = formatCourses(
        creditPath.ReadProductSelection.availableCourses,
        creditPath.ReadProductSelection.choosedCourses
      )
      setOptions(courses)
      setOptionsSearch(courses.slice(0, INITIAL_NUMBERS_SHOW_COURSES))
    }

    if (creditPath && creditPath.ReadProductSelection && creditPath.ReadProductSelection.choosedCourses) {
      setCursesSelect(creditPath.ReadProductSelection.choosedCourses)
    }
  }, [setDataModal, creditPath])

  const getSlice = () => {
    let result = []
    let coursesToShow = []

    if (textSearch !== '') {
      let fuse = new Fuse(options, OPTIONS)
      result = fuse.search(textSearch)
      coursesToShow = result.slice(0, countCourses)
    } else {
      coursesToShow = options.slice(0, countCourses)
    }

    setOptionsSearch(coursesToShow)
  }

  useEffect(() => {
    if (textSearch !== '' || countCourses !== 6) {
      getSlice()
    }
  }, [countCourses, textSearch])

  const postCourse = async (idCourse, stopLoadingAnimation = true) => {
    setIsLoading(true)
    const crid = localStorage.getItem(LOCALSTORAGE_TOKENS.crid)
    const partner = getSlugPartner()
    try {
      const { data } = await addCourse(crid, partner, idCourse)
      const newCreditPath = {
        ...creditPath,
        ReadProductSelection: {
          ...creditPath.ReadProductSelection,
          total: data.total,
          discount: data.discount,
          finalTotal: data.finalTotal
        }
      }

      setCreditPath(newCreditPath)
      return true
    } catch (e) {
      showToast('Erro para adicionar curso')
      return false
    } finally {
      stopLoadingAnimation && setIsLoading(false)
    }
  }

  const deleteCourse = async idCourse => {
    setIsLoading(true)
    const crid = localStorage.getItem(LOCALSTORAGE_TOKENS.crid)
    const partner = getSlugPartner()
    try {
      const { data } = await removeCourse(crid, partner, idCourse)
      const newCreditPath = {
        ...creditPath,
        ReadProductSelection: {
          ...creditPath.ReadProductSelection,
          total: data.total,
          discount: data.discount,
          finalTotal: data.finalTotal
        }
      }

      setCreditPath(newCreditPath)
      return true
    } catch (e) {
      showToast('Erro para remover curso')
      return false
    } finally {
      setIsLoading(false)
    }
  }

  const selectCourse = async (option, stopLoadingAnimation = true, shouldManipulateCourseList = true) => {
    const response = await postCourse(option.id, stopLoadingAnimation)

    if (response && shouldManipulateCourseList) {
      cursesSelect.unshift(option)
      setCursesSelect(cursesSelect)
      const newOptions = options.filter(item => item.id !== option.id)
      setOptions(newOptions)
      setOptionsSearch(newOptions.slice(0, countCourses))
    }

    if (!stopLoadingAnimation) {
      onSubmit(document.createEvent('Event'))
    }
  }

  const removeCurse = async option => {
    const response = await deleteCourse(option.id)
    if (response) {
      options.push(option)
      setOptions(options)
      setOptionsSearch(options.slice(0, countCourses))
      const newCursesSelect = cursesSelect.filter(item => item.id !== option.id)
      setCursesSelect(newCursesSelect)
    }
  }

  const handleChange = value => {
    setTextSearch(value)
  }

  const onSubmit = e => {
    e.preventDefault()
    const data = cursesSelect.map(item => item.id)
    goToNextScreen(data)
  }

  const incrementNumberOfShowCourses = () => {
    setCountCourses(countCourses + INITIAL_NUMBERS_SHOW_COURSES)
  }

  return [
    selectCourse,
    cursesSelect,
    removeCurse,
    onSubmit,
    textSearch,
    handleChange,
    optionsSearch,
    countCourses,
    incrementNumberOfShowCourses,
    options,
    shouldAddCourseThroughRequest
  ]
}
