import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import img_intro_guarantor from '../../../assets/images/img_intro_guarantor.png'

export const ContainerMobile = styled.div`
  padding-left: 22px;
  padding-right: 22px;
  padding-top: 104px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
  background-color: ${theme.colors.white100};
  background-image: url(${img_intro_guarantor});
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 100%;
`

export const Text = styled.p`
  line-height: 24px;
  font-size: 16px;
  color: ${theme.colors.blue900};
`

export const View = styled.div`
  width: 100%;
  height: 100vh;
`

export const WrapperFooter = styled.div`
  margin-bottom: 32px;
`

export const TextModal = styled.p`
  font-size: 15px;
  color: ${theme.colors.blue900};

  ${theme.breakpoints.down('md')} {
    font-size: 13px;
  }
`

export const TitleDesktop = styled.h5`
  font-family: 'CenturyGothic', sans serif;
  font-weight: 300;
  font-size: 30px;
  margin: 0;
  color: #323b69;
  padding-top: 16px;
  width: 562px;
`

export const WrapperTitle = styled.div`
  width: 640px;
`

export const ContainerButton = styled.div`
  width: 256px;
  text-align: center;
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`
