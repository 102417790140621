import React, { useContext } from 'react'
import { Container, Title, SubHeaderDesktop, SubTitle } from '@provi/provi-components'
import { TemplateContext } from '../../../components/template/context'
import { TemplateForm } from '../../../components/template-form'

import { FormFormik } from './form'
import { useGuarantorInvite } from './hooks'

export const GuarantorInvite = props => {
  const { goToNextScreen, setDataModal, creditPath, isMobile, setIsShowModal } = useContext(TemplateContext)
  const [isShowBackButton] = useGuarantorInvite(setDataModal, props.location.pathname)

  return (
    <TemplateForm notShowBackButton={!isShowBackButton}>
      <Container>
        {isMobile ? (
          <Title text="Qual CPF do seu avalista?" />
        ) : (
          <SubHeaderDesktop
            title="Qual CPF do seu avalista?"
            handleModal={() => setIsShowModal(true)}
            isShowButtonHelpModal={true}
          />
        )}
        <SubTitle text={props.text} />

        <FormFormik
          canEdit={!(creditPath && creditPath[props.creditPath])}
          data={creditPath && creditPath[props.creditPath]}
          goToNextScreen={goToNextScreen}
        />
      </Container>
    </TemplateForm>
  )
}
