import styled, { keyframes } from 'styled-components'
import { theme } from '@provi/provi-components'

export const View = styled.div`
  width: 100%;
  height: 100vh;
`

export const TitleCard = styled.div`
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  font-family: 'CenturyGothicBold', sans-serif;
  color: ${theme.colors.pink900};
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const CupomDiscount = styled.div`
  width: 272px;
  height: 45px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 12px;
  box-shadow: 2px 2px 41px -15px rgba(0, 0, 0, 0.75);
  margin-top: 20px;
  border-radius: 30px;
  cursor: pointer;
`

export const ContainerOption = styled.div`
  margin-top: ${theme.variables.spacer * 3}px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  display: grid;
  width: 100%;
  ${theme.breakpoints.down('md')} {
    grid-template-columns: 1fr;
  }
`

const animateFadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const Option = styled.div`
  position: relative;
  animation-name: ${animateFadeIn};
  animation-duration: 1s;
  width: 100%;
  min-height: 140px;
  border-radius: 20px;
  box-sizing: border-box;
  border: 1px solid ${theme.colors.blue900};
  padding: ${theme.variables.spacer * 2}px;
  &:focus {
    outline: none;
  }
`

export const Text = styled.p`
  width: 100%;
  margin: 0;
  font-size: ${({ size }) => (size ? size : '12')}px;
  color: ${({ color }) => (color ? color : theme.colors.blue900)};
  ${({ margin }) => margin && 'margin-left: 8px;'};
  ${({ title }) => title && 'height: 35px'};
  ${({ bold }) => (!bold ? 'font-family: "CenturyGothic", sans-serif;' : 'font-family: CenturyGothicBold,sans-serif;')};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`

export const Grid = styled.div`
  margin-top: ${theme.variables.spacer}px;
  display: flex;
  align-items: center;
`

export const TextModal = styled.div`
  font-size: 16px;
  line-height: 24px;
  margin: 24px 0;
  color: ${theme.colors.blue900};

  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    line-height: 18px;
    margin-top: 20px;
  }
`

export const ValueDiscount = styled.p`
  font-family: CenturyGothicBold, sans-serif;
  margin: 0;
  margin-top: ${({ marginTop }) => (marginTop ? '16px' : '4px')};
  font-size: 16px;
  line-height: 24px;
  color: ${({ color }) => color};
`

export const ContainerDiscount = styled.div`
  margin-top: ${theme.variables.spacer * 2}px;
`
