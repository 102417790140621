import React, { useState } from 'react'
import { Button, UploadBox } from '@provi/provi-components'
import img_document from '../../assets/svg/personal-document.svg'

const TesteUpload = () => {
  const [preview, setPreview] = useState({})
  const [filesNotUpload, setFilesNotUpload] = useState([])
  const [numberOfFiles, setNumberOfFiles] = useState(0)
  const [didFinishTrial, setFinishTrial] = useState(false)
  const numberOfFilesInitial = 0
  const OPTIONS_DROPZONE = 'image/jpeg, image/png, application/pdf'

  if (didFinishTrial) {
    return <div style={{ padding: '2rem' }}>Obrigado por nos ajudar a melhorar o envio de arquivos.</div>
  }

  return (
    <div
      style={{
        padding: '2rem'
      }}
    >
      Ao tocar na sessão abaixo, deve aparecer algumas opções para selecionar arquivos. <br /> <br /> Escolha 2 arquivos para
      concluir o teste.
      <div style={{ margin: '2rem 0' }}>
        <UploadBox
          preview={preview}
          setPreview={setPreview}
          setFilesNotUpload={setFilesNotUpload}
          filesNotUpload={filesNotUpload}
          setNumberOfFiles={setNumberOfFiles}
          numberOfFiles={numberOfFiles}
          image={img_document}
          numberOfFilesInitial={numberOfFilesInitial}
          options={OPTIONS_DROPZONE}
        />
      </div>
      <div>
        {numberOfFiles > 1 ? <>{numberOfFiles} arquivos</> : null}

        {numberOfFiles === 1 ? <>{numberOfFiles} arquivo</> : null}

        {/* {numberOfFiles > 0 ? (
          <>
            <Button
              onClick={() => {
                setFilesNotUpload([])
                setPreview({})
                setNumberOfFiles(undefined)
              }}
              icon={() => null}
              text="Remover Arquivos"
            />
          </>
        ) : null} */}

        {numberOfFiles >= 2 ? (
          <>
            <Button
              onClick={() => {
                setFinishTrial(true)
              }}
              icon={() => null}
              text="Finalizar"
              persistFloatingMode={false}
            />
          </>
        ) : null}
      </div>
      <div>versão 1.1</div>
    </div>
  )
}

export default TesteUpload
