import React from 'react'

export const CalendarIcon = ({ className }) => {
  return (
    <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.1667 0.5V3M3.83333 0.5V3M0.5 6.33333H15.5M1.18182 2.16667H14.8182C15.1947 2.16667 15.5 2.43801 15.5 2.77273V14.8939C15.5 15.2287 15.1947 15.5 14.8182 15.5H1.18182C0.805262 15.5 0.500002 15.2287 0.500002 14.8939V2.77273C0.500002 2.43801 0.805262 2.16667 1.18182 2.16667Z"
        stroke="#000B3C"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
