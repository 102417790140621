import React, { useContext } from 'react'
import { LoadingProvi, theme } from '@provi/provi-components'

import { TemplateContext } from '../../../components/template/context'
import { Container, Wrapper, Text, TextBox } from './style'

export const GraduationDateOutOfRange = () => {
  const { isMobile, isLoading } = useContext(TemplateContext)

  return (
    <>
      {isLoading && <LoadingProvi isLoadingDark />}
      <Container isMobile={isMobile}>
        <Wrapper>
          <TextBox>
            <Text bold color={theme.colors.blue600}>
              Agradecemos o seu interesse! Mas, no momento, nosso produto não é compatível com a sua data de graduação.
            </Text>
            <Text>Em breve enviaremos novidades sobre os produtos para o seu e-mail cadastrado.</Text>
          </TextBox>
        </Wrapper>
      </Container>
    </>
  )
}
