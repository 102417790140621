import * as Sentry from '@sentry/browser'
import { getSlugPartner } from '../utils'

export const logger = props => {
  const crid = localStorage.getItem('crid')
  const dataUser = JSON.parse(localStorage.getItem('dataUser'))
  const email_user = dataUser && dataUser.email
  const partner = getSlugPartner()

  props.error && console.log('ERROR ->>>>>: ', props.error)
  props.info && console.log('INFO EXTRA ->>>>>: ', props.info.componentStack)
  console.log('CRID ->>>>>', crid || 'not found')
  console.log('EMAIL USER ->>>>>: ', email_user || 'not found')
  console.log(props)
  if (props && props.error) {
    setLogger()
    Sentry.withScope(scope => {
      scope.setTag('PARTNER', partner)
      scope.setUser({ email: email_user, id: crid })
      props.info &&
        Object.keys(props.info).forEach(key => {
          scope.setExtra(key, props.info[key])
        })
    })
  }

  Sentry.setUser({
    data: JSONPARSER(crid),
    partner
  })
}

export const setLogger = (screen = window.location.pathname) => {
  const partner = getSlugPartner()
  const crid = localStorage.getItem('crid')
  const dataUser = localStorage.getItem('dataUser')
  const token = localStorage.getItem('token')
  Sentry.setTag('Screen', screen)
  Sentry.setExtra('screen', screen)

  Sentry.setUser({
    data: JSONPARSER(dataUser),
    crid: JSONPARSER(crid),
    partner,
    token
  })
}

export const JSONPARSER = str => {
  try {
    return JSON.parse(str)
  } catch (_) {
    return str
  }
}
