import { useState, useEffect, useMemo } from 'react'

export const useAllowanceSurvey = (creditPath, goToNextScreen) => {
  const [courseSatisfaction, setCourseSatisfaction] = useState()
  const [partnerSatisfaction, setPartnerSatisfaction] = useState()
  const [extracurricularActivities, setExtracurricularActivities] = useState([])
  const [extracurricularActitivy, setExtracurricularActivity] = useState()
  const [customActivity, setCustomActivity] = useState()
  const [incomeValue, setIncomeValue] = useState()
  const [errors, setErrors] = useState({})

  const isButtonDisabled = useMemo(() => {
    if (!extracurricularActitivy) {
      return true
    }

    if (extracurricularActitivy.requiresExplanation && !customActivity) {
      setErrors({ ...errors, customActivity: 'Este campo é obrigatório' })
      return true
    }

    if (extracurricularActitivy.requiresIncomeValue && !incomeValue) {
      setErrors({ ...errors, incomeValue: 'Este campo é obrigatório' })
      return true
    }

    if (isNaN(courseSatisfaction)) {
      return true
    }

    if (isNaN(partnerSatisfaction)) {
      return true
    }

    return false
  }, [extracurricularActitivy, customActivity, incomeValue, courseSatisfaction, partnerSatisfaction])

  useEffect(() => {
    if (!creditPath) {
      return
    }

    creditPath.ReadExtracurricularActivities && setExtracurricularActivities(creditPath.ReadExtracurricularActivities.options)
    creditPath.ReadExtracurricularActivities &&
      creditPath.ReadExtracurricularActivities.explanation &&
      setCustomActivity(creditPath.ReadExtracurricularActivities.explanation)
    creditPath.ReadExtracurricularActivities &&
      creditPath.ReadExtracurricularActivities.incomeValue &&
      setIncomeValue(`R$${creditPath.ReadExtracurricularActivities.incomeValue}`)

    if (creditPath.ReadExtracurricularActivities && creditPath.ReadExtracurricularActivities.choosedId) {
      const selectedItem = creditPath.ReadExtracurricularActivities.options.find(
        item => item.id === creditPath.ReadExtracurricularActivities.choosedId
      )

      if (selectedItem) {
        setExtracurricularActivity(selectedItem)
      }
    }

    if (creditPath.ReadCourseSatisfaction && !courseSatisfaction) setCourseSatisfaction(creditPath.ReadCourseSatisfaction)
    if (creditPath.ReadPartnerSatisfaction && !partnerSatisfaction) setPartnerSatisfaction(creditPath.ReadPartnerSatisfaction)
  }, [
    setExtracurricularActivities,
    setCustomActivity,
    setIncomeValue,
    setCourseSatisfaction,
    setPartnerSatisfaction,
    setExtracurricularActivity,
    creditPath
  ])

  useEffect(() => {
    if (customActivity && customActivity.length > 255) {
      setErrors({ ...errors, customActivity: 'O nome da sua atividade pode ter no máximo 255 caracteres' })
    } else if (customActivity && customActivity.length <= 1) {
      setErrors({ ...errors, customActivity: 'O nome da sua atividade é inválido' })
    } else {
      setErrors({ ...errors, customActivity: null })
    }
  }, [customActivity])

  useEffect(() => {
    if (incomeValue) {
      setErrors({ ...errors, incomeValue: null })
    }
  }, [incomeValue])

  const onSubmit = e => {
    e.preventDefault()

    const body = {
      courseRating: courseSatisfaction,
      partnerRating: partnerSatisfaction,
      ExtracurricularActivityOptionId: extracurricularActitivy.id,
      ...(extracurricularActitivy.requiresExplanation ? { extracurricularActivityCustomActivity: customActivity } : {}),
      ...(extracurricularActitivy.requiresIncomeValue
        ? { extracurricularActitivyIncomeValue: Number(incomeValue.split('R$')[1]) }
        : {})
    }
    goToNextScreen(body, false)
  }

  return {
    onSubmit,
    isButtonDisabled,
    courseSatisfaction,
    setCourseSatisfaction,
    partnerSatisfaction,
    setPartnerSatisfaction,
    extracurricularActivities,
    extracurricularActitivy,
    setExtracurricularActivity,
    customActivity,
    setCustomActivity,
    incomeValue,
    setIncomeValue,
    errors
  }
}
