import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import background_analysis_mobile from '../../../assets/svg/background-sucess-analise.svg'

export const ContainerAnalysis = styled.div`
  box-sizing: border-box;
  padding-left: 22px;
  padding-right: 22px;
  height: 100vh;
  background-color: ${theme.colors.blue900};
  background-image: url(${background_analysis_mobile});
  background-repeat: no-repeat;
  background-position: right bottom;
  width: 100%;
  display: flex;
  align-items: center;

  ${theme.breakpoints.down('md')} {
    display: block;
    background-size: 100%;
  }
`

export const Title = styled.h4`
  font-size: 30px;
  line-height: 36px;
  margin-top: 0;
  color: ${({ color = theme.colors.blue600 }) => color};

  font-family: ${({ bold }) => (bold ? 'CenturyGothicBold' : 'CenturyGothic')};

  ${theme.breakpoints.down('md')} {
    padding-top: 36px;
    font-size: 28px;
    line-height: 32px;
  }
`

export const Bold = styled.span`
  font-family: 'CenturyGothicBold';
`

export const Text = styled.p`
  font-family: 'CenturyGothic';
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.white100};
  ${theme.breakpoints.down('md')} {
    font-size: 16px;
    line-height: 18px;
  }
`

export const Wrapper = styled.div`
  padding-left: 140px;
  width: 640px;

  ${theme.breakpoints.down('md')} {
    padding-left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
`

export const SubTitle = styled.p`
  font-family: CenturyGothicBold, sans-serif;
  color: ${theme.colors.white100};
  font-size: 24px;
  line-height: 30px;
  ${theme.breakpoints.down('md')} {
    font-size: 16px;
  }
`

export const ContainerSocial = styled.div`
  display: flex;
  flex-direction: row;
`

export const Item = styled.a`
  text-decoration: none;
  text-underline: none;
  color: transparent;
  margin-right: 8px;
  cursor: pointer;
`
