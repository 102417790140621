const { REACT_APP_API_ENV } = process.env

export const URLS = {
  production: {
    masterpath: 'https://ms-creditpath.provi.com.br',
    passwordless: 'https://ms-passwordless.provi.com.br',
    workerUpload: 'https://workers.provi.com.br/v1/api/file-extension',
    fe_user: 'https://dash.principia.net'
  },
  staging: {
    masterpath: 'https://ms-creditpath-staging.provi.com.br',
    passwordless: 'https://ms-passwordless-staging.provi.com.br',
    workerUpload: 'https://workersstaging.provi.com.br/v1/api/file-extension',
    fe_user: 'https://dash-staging.principia.net'
  },
  development: {
    masterpath: 'https://ms-creditpath-dev.provi.com.br',
    passwordless: 'https://ms-passwordless-dev.provi.com.br',
    workerUpload: 'https://workersdev.provi.com.br/v1/api/file-extension'
  }
}

export const setRootUrl = environment => {
  switch (environment) {
    case 'production':
      return URLS.production
    case 'staging':
      return URLS.staging
    case 'development':
      return URLS.development
    default:
      return URLS.development
  }
}
let _ROOT_URL = setRootUrl(REACT_APP_API_ENV)

export const TOKEN_FRONT_END =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VySWQiOjEsImVtYWlsIjoiam9hb0Bwcm92aS5jb20uYnIiLCJleHAiOiIzNmQifQ.yBOqt3uFDYs7byxW4ZczfOzxlUPgC8CZcWhlhPKBtn4'

export const URLS_REDIRECT = {
  production: {
    dashboard: 'https://dash.principia.net',
    newSdk: 'https://credito.principia.net',
    passwordless: 'https://login.principia.net'
  },
  staging: {
    dashboard: 'https://dash-staging.principia.net',
    newSdk: 'https://credito-staging.principia.net',
    passwordless: 'https://login-staging.principia.net'
  },
  development: {
    dashboard: 'https://dash-dev.principia.net',
    newSdk: 'https://credito-staging.principia.net',
    passwordless: 'https://login-dev.principia.net'
  }
}
export const setRedirectUrl = environment => {
  switch (environment) {
    case 'production':
      return URLS_REDIRECT.production
    case 'staging':
      return URLS_REDIRECT.staging
    case 'development':
      return URLS_REDIRECT.development
    default:
      return URLS_REDIRECT.development
  }
}
let _REDIRECT_URLS = setRedirectUrl(REACT_APP_API_ENV)

export const CEP_URL = 'https://api.postmon.com.br/v1/cep'
export const ROOT_URL = _ROOT_URL
export const REDIRECT_URLS = _REDIRECT_URLS
export const termsAndConditionsLink = 'https://provi.com.br/terms-and-conditions'
export const privacyPolicyLink = 'https://provi.com.br/privacy-policy'
export const scrText = 'A consulta e compartilhamento de informações do SCR do banco central.'

export const STATUS = {
  incomplete: 'incomplete',
  analysis: 'analysis',
  waiting_contract: 'waiting_contract',
  approved: 'approved',
  waiting_signature: 'waiting_signature',
  signed: 'signed',
  transferred: 'transferred',
  settled: 'settled',
  denied: 'denied',
  canceled: 'canceled'
}

export const ERRORS = {
  default: 'Ops, ocorreu algum erro 😔',
  bankValidationInfo: {
    accountDayLimitReached: 'Limite máximo de 5 tentativas de registro de contas bancárias atingido',
    accountLimitReached: 'Limite de 10 contas bancárias cadastradas já atingido',
    accountExisting: 'Já existe uma conta com esses parâmetros',
    invalidAgency: 'Agência inválida',
    invalidAccount: 'Conta inválida',
    accountTypeInvalid: 'Tipo de conta inválido',
    bankCodeInvalid: 'Código do banco inválido',
    bankCode200NotRegistered: 'O banco com o código 200 não está registrado'
  }
}

export const URL_EXTERNAL = 'https://api.whatsapp.com/send?phone=5511957712414&text=Oi%2C%20Provi%21%20Nao%20recebi%20o%20SMS'

export const LOCALSTORAGE_TOKENS = {
  crid: 'crid',
  token: 'token',
  dataUser: 'dataUser'
}

const getEmbeddedURL = {
  development: 'https://ms-docusign-dev.provi.com.br',
  staging: 'https://ms-docusign-staging.provi.com.br',
  production: 'https://ms-docusign.provi.com.br',
  prod: 'https://ms-docusign.provi.com.br'
}

export const EMBEDDED_BASE_URL = getEmbeddedURL[REACT_APP_API_ENV]

export const STATES = [
  { id: 1, label: 'AC' },
  { id: 2, label: 'AL' },
  { id: 3, label: 'AP' },
  { id: 4, label: 'AM' },
  { id: 5, label: 'BA' },
  { id: 6, label: 'CE' },
  { id: 7, label: 'DF' },
  { id: 8, label: 'ES' },
  { id: 9, label: 'GO' },
  { id: 10, label: 'MA' },
  { id: 11, label: 'MT' },
  { id: 12, label: 'MS' },
  { id: 13, label: 'MG' },
  { id: 14, label: 'PA' },
  { id: 15, label: 'PB' },
  { id: 16, label: 'PR' },
  { id: 17, label: 'PE' },
  { id: 18, label: 'PI' },
  { id: 19, label: 'RJ' },
  { id: 20, label: 'RN' },
  { id: 21, label: 'RS' },
  { id: 22, label: 'RO' },
  { id: 23, label: 'RR' },
  { id: 24, label: 'SC' },
  { id: 25, label: 'SP' },
  { id: 26, label: 'SE' },
  { id: 27, label: 'TO' }
]

const googleAnalyticsTrackingId = {
  local: 'UA-146320363-9',
  production: 'UA-146320363-13',
  staging: 'UA-146320363-9',
  development: 'UA-146320363-9'
}

export const trackingId = googleAnalyticsTrackingId[REACT_APP_API_ENV]

export const googleAnalyticsDimensions = {
  CreditRequestId: 'dimension4',
  userId: 'userId',
  partner: 'dimension5'
}

export const googleRecaptchaTerms = {
  privacy: 'https://policies.google.com/privacy?hl=pt-BR',
  service: 'https://policies.google.com/terms?hl=pt-BR'
}

const PUBLIC_RECAPTCHA_SITE_KEYS = {
  staging: '6LfQkjobAAAAACaUNGqrYITjv8wKr0RnKou5wrfe',
  production: '6LcUuUkbAAAAAMPEZimGgVF87_4VJeCLRoBpS1_L'
}

export const PUBLIC_RECAPTCHA_SITE_KEY = PUBLIC_RECAPTCHA_SITE_KEYS[REACT_APP_API_ENV]

export const MesadaProviSlug = 'impulso-provi'

const proxyURL = {
  production: 'https://api.provi.com.br/proxy',
  staging: 'https://api-staging.provi.com.br/proxy'
}

export const PROXY_BASE_URL = proxyURL[REACT_APP_API_ENV]
