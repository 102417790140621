import React from 'react'
import * as Yup from 'yup'
import { withFormik } from 'formik'
import { Input, TextErrorInput, Button } from '@provi/provi-components'
import { Form } from '../../../components/form'
import { validateCouponCode } from '../../../utils/validators'

export const Formik = props => {
  const { handleChange, values, errors, touched, handleBlur, handleSubmit } = props

  return (
    <Form onSubmit={handleSubmit}>
      <Input
        cy="input-coupon-code"
        autoFocus
        margin="normal"
        id="coupon"
        placeholder="Digite um cupom"
        onChange={handleChange('couponCode')}
        onBlur={handleBlur('couponCode')}
        error={!!errors.couponCode}
        value={values.couponCode}
      />
      {errors.couponCode && touched.couponCode && <TextErrorInput cy="error-coupon-code" text={errors.couponCode} />}

      <Button
        marginHorizontal={0}
        type="submit"
        disabled={!validateCouponCode(values.couponCode)}
        text="Avançar"
        persistFloatingMode={false}
      />
    </Form>
  )
}

export const FormFomik = withFormik({
  validateOnBlur: true,
  validateOnChange: false,
  enableReinitialize: true,
  mapPropsToValues: props => ({ couponCode: props.data || '' }),
  validationSchema: Yup.object().shape({
    couponCode: Yup.string().test({
      name: 'couponCode',
      message: 'Digite um cupom válido',
      test: couponCode => validateCouponCode(couponCode)
    })
  }),
  handleSubmit: async ({ couponCode }, { setSubmitting, props }) => {
    await props.goToNextScreen(couponCode)
    setSubmitting(false)
  }
})(Formik)
