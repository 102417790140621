import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import arrow_discount from '../../../assets/svg/arrow-discount.svg'

export const OptionsList = styled.div`
  display: flex;
  margin-top: ${theme.variables.spacer * 4}px;
  ${theme.breakpoints.down('md')} {
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    display: grid;
  }
`

export const Option = styled.div`
  box-sizing: border-box;
  cursor: pointer;
  ${({ first }) => !first && 'margin-left: 8px'};
  padding: 10px;
  height: ${({ upFront }) => (upFront ? '140px' : '108px')};
  box-shadow: 2px 1px 15px -9px rgba(0, 0, 0, 0.5);
  width: ${({ upFront }) => (upFront ? '140px' : '108px')};
  min-width: 100px;

  ${theme.breakpoints.down('md')} {
    width: 100%;
    margin-left: 0;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  border-radius: 20px;
  background-color: ${({ isSelected }) => (isSelected ? theme.colors.blue900 : theme.colors.white100)};
`

export const OptionText = styled.p`
  font-size: ${({ size }) => (size ? size : 16)}px;
  margin: 0;
  margin-top: 5px;
  ${({ bold }) => bold && 'font-family: CenturyGothicBold'};
  color: ${({ isSelected }) => (isSelected ? theme.colors.white100 : theme.colors.blue900)};
`

export const OptionBold = styled.p`
  font-family: 'CenturyGothicBold';
  font-size: 30px;
  margin: 0;
  color: ${({ isSelected }) => (isSelected ? theme.colors.white100 : theme.colors.blue900)};
`

export const ValueDiscount = styled.p`
  font-family: CenturyGothicBold, sans-serif;
  margin: 0;
  margin-top: ${({ marginTop }) => (marginTop ? '16px' : '4px')};
  font-size: 16px;
  line-height: 24px;
  color: ${({ color }) => color};
`

export const ContainerDiscount = styled.div`
  margin-top: ${theme.variables.spacer * 2}px;
`

export const WrapperError = styled.div`
  margin-top: 20px;
`

export const ValueInputValue = styled.p`
  margin-top: 28px;
  font-family: 'CenturyGothic';
  font-size: 12px;
  line-height: 18px;
  color: ${theme.colors.blue900};
`

export const ContainerInputValue = styled.div`
  background-repeat: no-repeat;
  background-position: center left;
  background-image: url(${arrow_discount});
  padding-left: 25px;
`

export const BlueText = styled.span`
  color: ${theme.colors.blue700};
  font-family: 'CenturyGothicBold';
`

export const PinkText = styled.span`
  color: ${theme.colors.pink900} !important;
  font-family: 'CenturyGothicBold';
`

export const ModalText = styled.span`
  color: ${theme.colors.blue900} !important;
`
