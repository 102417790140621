/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { showToast, theme } from '@provi/provi-components'
import { reesendPhone } from '../../../services/api'
import { URL_EXTERNAL } from '../../../constants'

const CODE_COUNT = 4

export const useCodeValidator = (goToNextScreen, setDataModal, setIsLoading, setIsShowModal) => {
  const inputRefs = []
  const [counter, setCounter] = useState(0)
  const [code, setCode] = useState(new Array(CODE_COUNT).fill(undefined))

  useEffect(() => {
    let interval = null
    if (counter > 0) {
      interval = setInterval(() => {
        setCounter(counter => counter - 1)
      }, 1000)
    } else if (counter === 0) {
      clearInterval(interval)
    }
    return () => clearInterval(interval)
  }, [counter])

  useEffect(() => {
    setCounter(30)
    setDataModal({
      title: 'Não recebeu seu código?',
      texts: [
        'O código de verificação pode demorar até 30 segundos para chegar via SMS no seu celular. Caso não tenha recebido o código de verificação, entre em contato com a gente.'
      ],
      textButton: 'Entendi',
      buttonFunction: () => setIsShowModal(false),
      textLink: {
        color: theme.colors.blue900,
        text: 'Abrir chat',
        onClick: () => window.open(URL_EXTERNAL, '_blank')
      }
    })

    inputRefs[0].focus()
  }, [])

  const setCodeNumber = async (codeNumber, position) => {
    if (position < code.length) {
      const newCode = code

      newCode[position] = codeNumber
      setCode(newCode)
    }

    const checkArr = code.filter(c => c === undefined || c === '')
    if (!(checkArr.length > 0)) {
      setIsLoading(true)
      try {
        const data = code.join('')
        await goToNextScreen(data)
      } catch (error) {
        if (error && error.response && error.response.data.error.message) {
          showToast(error.response.data.error.message)
          return
        }

        showToast('Código de verificação inválido')
      } finally {
        setIsLoading(false)
      }
    }
  }

  const addInput = ref => {
    inputRefs.push(ref)
  }

  const onKeyDown = (e, position) => {
    if (e.keyCode === 8 && inputRefs[position].value === '' && position !== 0) {
      inputRefs[position - 1].focus()
    } else {
      e.target.select()
    }
  }

  const setNextFocus = (codeNumber, position) => {
    if (codeNumber !== '' && position < CODE_COUNT - 1) {
      inputRefs[position + 1].focus()
      inputRefs[position + 1].select()
    }
  }

  const sendVerification = async phone => {
    setIsLoading(true)
    try {
      await reesendPhone(phone)
      setCounter(30)
      showToast('Código reenviado :)')
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        showToast(err.response.data.error.message)
        return false
      }
      showToast()
    } finally {
      setIsLoading(false)
    }
  }

  return [code, setCodeNumber, addInput, setNextFocus, onKeyDown, sendVerification, counter]
}
