import React, { useContext } from 'react'
import { Container, SubTitle, Button, Title, SubHeaderDesktop, UploadBox } from '@provi/provi-components'
import { Form } from '../../../components/form'
import { TemplateContext } from '../../../components/template/context'
import { TemplateForm } from '../../../components/template-form'
import { usePersonalDocument } from './hooks'
import img_document from '../../../assets/svg/personal-document.svg'

const OPTIONS_DROPZONE = 'image/jpeg, image/png, application/pdf'

export const PersonalDocument = () => {
  const { setIsShowModal, isMobile, goToNextScreen, setDataModal, setIsLoading, creditPath } = useContext(TemplateContext)
  const [
    onSubmit,
    isButtonDisabled,
    numberOfFiles,
    setFilesNotUpload,
    filesNotUpload,
    setNumberOfFiles,
    preview,
    setPreview,
    numberOfFilesInitial
  ] = usePersonalDocument(goToNextScreen, setDataModal, creditPath, setIsLoading)

  return (
    <TemplateForm>
      <Container>
        <Form onSubmit={onSubmit}>
          {isMobile ? (
            <Title text="Envie uma foto do seu documento pessoal (RG/CNH)" />
          ) : (
            <SubHeaderDesktop
              title="Envie uma foto do seu documento pessoal (RG/CNH)"
              handleModal={() => setIsShowModal(true)}
              isShowButtonHelpModal
            />
          )}
          <SubTitle text="Se o documento estiver plastificado, envie uma foto da frente e outra do verso." />
          <UploadBox
            preview={preview}
            setPreview={setPreview}
            setFilesNotUpload={setFilesNotUpload}
            filesNotUpload={filesNotUpload}
            setNumberOfFiles={setNumberOfFiles}
            numberOfFiles={numberOfFiles}
            image={img_document}
            numberOfFilesInitial={numberOfFilesInitial}
            options={OPTIONS_DROPZONE}
          />
          <Button marginHorizontal={0} type="submit" disabled={isButtonDisabled} text="Avançar" persistFloatingMode={false} />
        </Form>
      </Container>
    </TemplateForm>
  )
}
