import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Container = styled.div`
  padding-left: 137px;
  padding-right: 22px;
  padding-bottom: 22px;
  height: 100vh;
  width: 100%;
  background-color: ${theme.colors.blue900};
  box-sizing: border-box;
  overflow: auto;
  display: flex;
  align-items: center;
  ${theme.breakpoints.down('md')} {
    display: block;
    padding-left: 22px;
    margin-left: 0;
  }
`

export const Title = styled.h4`
  margin-top: 100px;
  color: ${theme.colors.blue600};
  font-size: 38px;
  line-height: 47px;
  margin-bottom: 0;
  ${theme.breakpoints.down('md')} {
    font-size: 24px;
    line-height: 36px;
  }
`

export const SubTitle = styled.p`
  font-family: CenturyGothicBold, sans-serif;
  color: ${theme.colors.white100};
  font-size: 24px;
  line-height: 30px;
  margin-top: 0;
  ${theme.breakpoints.down('md')} {
    font-size: 16px;
  }
`

export const Text = styled.p`
  font-family: 'CenturyGothic', sans serif;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.white100};
  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    line-height: 18px;
  }
`

export const Wrapper = styled.div`
  width: 640px;
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`
