import React, { useContext } from 'react'
import { Container, TextLink, Title, SubTitle, SubHeaderDesktop } from '@provi/provi-components'
import { TemplateContext } from '../../../components/template/context'
import { useISAContext } from '../../../components/template/ISA/context'
import { TemplateFormIsa } from '../../../components/template-isa'
import { Timer } from '../../../components/timer'
import { formatPhone } from '../../../utils'

import { CodeView, CodeNumber, ContainerComponent } from './style'
import { useCodeValidator } from './hooks'

export const PhoneConfirmation = props => {
  const { setIsShowModal, isMobile, setDataModal, setIsLoading, creditPath } = useContext(TemplateContext)
  const { goToNextScreen } = useISAContext()

  const [code, setCodeNumber, addInput, setNextFocus, onKeyDown, sendVerification, counter] = useCodeValidator(
    goToNextScreen,
    setDataModal,
    setIsLoading,
    setIsShowModal
  )

  return (
    <TemplateFormIsa>
      <Container>
        {isMobile ? (
          <Title text="Verificação de celular" />
        ) : (
          <SubHeaderDesktop title="Verificação de celular" handleModal={() => setIsShowModal(true)} isShowButtonHelpModal />
        )}
        <SubTitle
          text={`Insira abaixo o código que enviamos via SMS para o número ${formatPhone(creditPath && creditPath.ReadPhone)}`}
        />
        <ContainerComponent>
          <CodeView>
            {code.map((value, position) => (
              <CodeNumber
                id={`id-${position}`}
                aria-label={`id-${position}`}
                ref={addInput}
                key={position}
                onKeyDown={e => onKeyDown(e, position)}
                onChange={({ target }) => {
                  setCodeNumber(target.value, position)
                  setNextFocus(target.value, position)
                }}
              />
            ))}
          </CodeView>
          {counter === 0 ? (
            <TextLink text="Reenviar código" onClick={() => sendVerification(creditPath && creditPath.ReadPhone)} />
          ) : (
            <Timer counter={counter} message="Reenviar SMS em" />
          )}{' '}
        </ContainerComponent>
      </Container>
    </TemplateFormIsa>
  )
}
