import React, { useContext } from 'react'
import { TemplateContext } from '../../components/template/context'
import { ContainerBackground, ContainerSuccess, ContainerText, Wrapper, Container, Title, Text } from './style'

export const PartnerBlocked = props => {
  const { isMobile } = useContext(TemplateContext)
  const { partnerName } = props.history.location.state
  return (
    <>
      <Wrapper>
        <ContainerSuccess>
          <Container>
            <ContainerText>
              <Title>Infelizmente, novas solicitações de financiamento de {partnerName} estão fechadas no momento.</Title>
              <Text>Entre em contato com {partnerName} para saber mais informações.</Text>
            </ContainerText>
          </Container>
        </ContainerSuccess>
        {!isMobile && <ContainerBackground />}
      </Wrapper>
    </>
  )
}
