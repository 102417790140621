import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import background from '../../../assets/images/bg-docsign.png'
import backgroundDesktop from '../../../assets/images/bg-docsign-mobile.png'

export const ContainerSuccess = styled.div`
  width: 65%;
  height: 100vh;
  background-color: ${theme.colors.blue900};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${theme.breakpoints.down('md')} {
    background: url(${backgroundDesktop});
    background-size: 90%;
    background-position: bottom right;
    background-repeat: no-repeat;
    width: 100%;
    padding: 20px;
    align-items: 'flex-start';
    justify-content: flex-start;
    box-sizing: border-box;
  }
`

export const Container = styled.div`
  position: relative;
  width: 60%;

  ${theme.breakpoints.down('md')} {
    width: auto;
  }
`

export const ContainerBackground = styled.div`
  width: 35%;
  background: url(${background});
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 100%;

  ${theme.breakpoints.down('md')} {
    width: 100%;
    min-height: 300px;
  }
`

export const ContainerText = styled.div``

export const Text = styled.p`
  color: ${theme.colors.white100};
  font-size: 24px;
  line-height: 30px;

  ${theme.breakpoints.down('md')} {
    margin-bottom: 20px;
    /* font-size: 16px;
    line-height: 24px; */

    font-size: 24px;
    line-height: 30px;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${theme.colors.blue900};
  position: relative;
`

export const Title = styled.h4`
  color: ${theme.colors.pink900};
  font-size: 24px;
  line-height: 30px;
  ${theme.breakpoints.down('md')} {
    font-size: 16px;
    line-height: 24px;
  }
`

export const SubTitle = styled.p`
  font-family: CenturyGothic, sans-serif;
  color: ${theme.colors.white100};
  font-size: 16px;
  line-height: 24px;
  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    line-height: 18px;
  }
`

export const Bold = styled.span`
  font-family: CenturyGothicBold, sans-serif;
  color: ${theme.colors.white100};
`
