import { useEffect } from 'react'
import { showToast } from '@provi/provi-components'
import { history } from '../../../navigation/history'
import { LOCALSTORAGE_TOKENS } from '../../../constants'
import { postCreditPathISA, getCreditPathISA, getCreditPathPreviousISA } from '../../../services/api'
import { getPathname, getSlugPartner } from '../../../utils'

export const useNavigation = (crid, creditPath, setIsLoading, setDataModal, setCreditPath) => {
  useEffect(() => {
    if (crid && crid !== localStorage.getItem(LOCALSTORAGE_TOKENS.crid)) {
      localStorage.setItem(LOCALSTORAGE_TOKENS.crid, crid)
    }
  }, [crid])

  const goToNextScreen = async data => {
    setIsLoading(true)
    const creditRequestId = localStorage.getItem(LOCALSTORAGE_TOKENS.crid) || crid
    try {
      const currentScreen = creditPath && creditPath.progress && creditPath.progress.currentScreenUniqueName

      const { nextScreen } = await postCreditPathISA(creditRequestId, data, currentScreen)

      const responseGet = await getCreditPathISA(creditRequestId)
      setDataModal(null)
      setCreditPath(responseGet)

      const pathname = getPathname(nextScreen)
      history.push(`/${creditRequestId}/isa/${pathname}/${getSlugPartner()}`)
    } catch (error) {
      if (error.response) {
        showToast(error.response.data.error.message)
        return
      }
      showToast()
    } finally {
      setIsLoading(false)
    }
  }

  const goToPrevScreen = async () => {
    setIsLoading(true)

    const pathname = history.location.pathname
    const split = pathname.split('/')
    const isCpfScreen = split.includes('cpf')

    const creditRequestId = localStorage.getItem(LOCALSTORAGE_TOKENS.crid) || crid

    const currentScreen = creditPath && creditPath.progress && creditPath.progress.currentScreenUniqueName
    const previousScreen = creditPath && creditPath.progress && creditPath.progress.previousScreenUniqueName
    if (!previousScreen && (!currentScreen || isCpfScreen)) {
      setIsLoading(false)
      history.push(`/isa/intro/${getSlugPartner()}`)
      return
    } else if (!previousScreen) {
      history.push(`/isa/cpf/${getSlugPartner()}`)
      return
    }

    try {
      const response = await getCreditPathPreviousISA(creditRequestId, previousScreen)
      setDataModal(null)
      setCreditPath(response)
      const pathname = getPathname(previousScreen)
      history.push(`/${creditRequestId}/isa/${pathname}/${getSlugPartner()}`)
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error && error.response.data.error.message) {
        showToast(error.response.data.error.message)
        return false
      }
      showToast()
    } finally {
      setIsLoading(false)
    }
  }

  return [goToNextScreen, goToPrevScreen]
}
