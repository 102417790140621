import React from 'react'
import './style.css'

export const TimerDocSign = ({ counter = 3 }) => {
  return (
    <div className="container-timer">
      <p>Estamos gerando seu contrato</p>
      <div className="timer">
        <span>{counter}</span>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}
