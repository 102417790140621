import React from 'react'

export const ReviewHandIcon = ({ className }) => (
  <svg className={className} width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="13.6667" cy="4.66668" rx="4.33333" ry="4.33333" fill="#000B3C" />
    <path
      d="M13.2445 1.77122V2.35658C12.4837 2.44021 11.9019 3.08829 12.0138 3.82C12.0809 4.5308 12.7746 5.05345 13.5354 5.05345H13.9382C14.2962 5.05345 14.5871 5.34613 14.5424 5.68062C14.4976 5.99421 14.2067 6.20327 13.8934 6.20327H13.3564C13.1326 6.20327 12.976 6.07784 12.9313 5.88968C12.9313 5.84787 12.8641 5.80606 12.8194 5.80606H12.1928C12.1257 5.80606 12.0809 5.86878 12.0809 5.9315C12.1257 6.51686 12.6404 6.95589 13.2669 6.9977V7.56216C13.2669 7.62487 13.3117 7.66669 13.3788 7.66669H13.9382C14.0053 7.66669 14.0501 7.62487 14.0501 7.56216V6.97679C14.7214 6.87226 15.2584 6.39143 15.3255 5.76425C15.415 4.94892 14.7214 4.25902 13.8711 4.25902H13.4012C13.0431 4.25902 12.7522 3.96634 12.797 3.63184C12.8417 3.33916 13.1326 3.1301 13.4459 3.1301H13.983C14.2067 3.1301 14.3634 3.25554 14.4081 3.44369C14.4081 3.4855 14.4752 3.52731 14.52 3.52731H15.1465C15.2137 3.52731 15.2584 3.4646 15.2584 3.40188C15.2137 2.81651 14.699 2.37749 14.0725 2.33568V1.77122C14.0725 1.7085 14.0277 1.66669 13.9606 1.66669H13.3564C13.2893 1.66669 13.2445 1.7085 13.2445 1.77122Z"
      fill="white"
    />
    <path
      d="M20.6494 13.7624C20.0428 13.9979 18.807 14.7046 18.2902 15.0815C17.7734 15.4584 16.9196 15.7175 15.8186 15.9295C15.5714 15.9766 15.2793 16.0002 14.9648 16.0237C14.2008 16.0473 13.3021 15.9531 12.3134 15.8824C12.246 15.8824 12.1786 15.8589 12.1112 15.8589C11.864 15.8353 11.6618 15.6469 11.6394 15.3877C11.6169 15.0579 11.864 14.7988 12.1561 14.8459C12.2235 14.8459 12.3134 14.8695 12.3808 14.8695C13.6616 14.9873 14.8749 15.1051 15.6388 14.9402C17.0544 14.6575 17.4588 13.7624 17.369 13.0086C17.3016 12.3961 16.8522 11.8072 16.1556 11.8543C15.167 11.925 13.9986 11.8543 12.8527 11.7601C11.5495 11.6659 10.2238 11.6187 9.2801 11.7365C8.22406 11.8543 6.0895 12.6788 4.2021 14.0215C3.28087 14.681 2.24729 15.4584 1.61816 15.9531C1.30359 16.1886 1.23619 16.6598 1.48335 16.9896L4.22457 20.7114C4.44926 21.0412 4.89864 21.0883 5.21321 20.8528L6.22431 20.0283C6.47147 19.8398 6.78604 19.7692 7.05567 19.8634C8.89813 20.4759 10.8754 20.8763 12.1561 20.947C14.9648 21.0883 20.3798 17.602 21.9302 16.4713C23.4805 15.3642 22.3571 13.0792 20.6494 13.7624Z"
      stroke="#000B3C"
    />
  </svg>
)
