import React, { useState, useEffect } from 'react'
import { LoadingProvi } from '@provi/provi-components'
import { TemplateContext } from './context'
import { useHelpModal, useScreenMeasure, useSendingInfo, useCreditPath, useWindowPop } from './hooks'

import { ISAProvider } from './ISA'

export const TemplateProvider = props => {
  const [creditPath, setCreditPath] = useState()
  const [isLoadingGlobal, setIsLoadingGlobal] = useState(true)
  const [crid, setCrid] = useState()
  const [selfieProviderId, setSelfieProviderId] = useState(null)
  const [isShowModal, setIsShowModal] = useHelpModal()
  const [isMobile] = useScreenMeasure()
  const [isLoading, setIsLoading, dataModal, setDataModal, goToNextScreen, goToPreviousScreen] = useSendingInfo(
    creditPath,
    setCreditPath,
    crid
  )

  const [numberOfCourses, setNumberOfCourses] = useState(undefined)

  useEffect(() => {
    const length = creditPath?.LiftProductSanar?.availableCourses?.length
    setNumberOfCourses(length)
  }, [creditPath?.LiftProductSanar?.availableCourses?.length])

  useCreditPath(setIsLoadingGlobal, setCreditPath, crid)
  const ISA = ISAProvider(crid, creditPath, setIsLoading, setDataModal, setCreditPath)

  useWindowPop(goToPreviousScreen, ISA.goToPrevScreen)

  const value = {
    isLoading,
    setIsLoading,
    isShowModal,
    setIsShowModal,
    isMobile,
    dataModal,
    setDataModal,
    goToNextScreen,
    goToPreviousScreen,
    creditPath,
    isLoadingGlobal,
    setIsLoadingGlobal,
    setCreditPath,
    crid,
    setCrid,
    ISA,
    numberOfCourses,
    selfieProviderId,
    setSelfieProviderId
  }

  return (
    <TemplateContext.Provider value={value}>{isLoadingGlobal ? <LoadingProvi /> : props.children}</TemplateContext.Provider>
  )
}
