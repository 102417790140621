import { PUBLIC_RECAPTCHA_SITE_KEY } from '../../constants'

export const RecaptchaScriptLoader = () => {
  const loadScriptByURL = (id, url) => {
    const isScriptExist = document.getElementById(id)

    if (!isScriptExist) {
      var script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = url
      script.id = id
      document.body.appendChild(script)
    }
  }

  loadScriptByURL('recaptcha-script', `https://www.google.com/recaptcha/api.js?render=${PUBLIC_RECAPTCHA_SITE_KEY}`)

  return null
}
