import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const ContainerOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${theme.variables.spacer * 2}px;
  margin-bottom: 38px;

  ${theme.breakpoints.down('md')} {
    justify-content: space-between;
  }
`

export const SubTitle = styled.h3`
  font-family: 'CenturyGothicBold', sans serif;
  font-size: 20px;
  line-height: 26px;
  text-align: left;
  color: ${theme.colors.blue900};
  margin-top: 24px;
  margin-bottom: 0;
`
