import React from 'react'

export const Text404 = () => {
  return (
    // <svg width="648" height="301" viewBox="0 0 648 301" fill="none" xmlns="http://www.w3.org/2000/svg">
    <svg viewBox="0 0 653 303" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M123.422 0.46875H178.109V185.43H203.695V236.016H178.109V295H124.594V236.016H-0.796875V185.43L123.422 0.46875ZM124.594 185.43V89.1406L58.9688 185.43H124.594ZM326.352 0.46875C347.315 0.46875 365.349 5.61198 380.453 15.8984C395.688 26.1849 407.406 41.9401 415.609 63.1641C423.943 84.2578 428.109 113.685 428.109 151.445C428.109 189.596 423.878 219.284 415.414 240.508C407.081 261.732 395.818 277.357 381.625 287.383C367.432 297.279 349.529 302.227 327.914 302.227C306.299 302.227 288.135 297.214 273.422 287.188C258.708 277.161 247.185 261.927 238.852 241.484C230.518 221.042 226.352 191.94 226.352 154.18C226.352 101.445 234.49 63.2292 250.766 39.5312C268.865 13.4896 294.06 0.46875 326.352 0.46875ZM327.719 52.4219C318.474 52.4219 310.271 55.4167 303.109 61.4062C295.948 67.2656 290.479 77.4219 286.703 91.875C282.927 106.328 281.039 127.487 281.039 155.352C281.039 191.68 285.336 216.81 293.93 230.742C302.654 244.544 313.917 251.445 327.719 251.445C341 251.445 351.547 244.87 359.359 231.719C368.865 215.833 373.617 189.271 373.617 152.031C373.617 114.271 369.32 88.2292 360.727 73.9062C352.263 59.5833 341.26 52.4219 327.719 52.4219ZM571.859 0.46875H626.547V185.43H652.133V236.016H626.547V295H573.031V236.016H447.641V185.43L571.859 0.46875ZM573.031 185.43V89.1406L507.406 185.43H573.031Z"
        fill="#F6F6F6"
      />
    </svg>
  )
}
