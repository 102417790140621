import { useEffect, useState } from 'react'

import { documentAnalysisPulling } from '../../../services/api'

let countdownInterval
let pullingInterval

export const useWaitingDocumentAnalysis = (goToNextScreen, CreditRequestId, selfieProviderId) => {
  const [countdown, setCountdown] = useState(60)

  useEffect(() => {
    if (countdown >= 1) {
      countdownInterval = setInterval(() => {
        if (countdown > 0) {
          setCountdown(countdown - 1)
        }
      }, 1000)
    } else {
      clearInterval(pullingInterval)
      clearInterval(countdownInterval)
      goToNextScreen()
    }

    return () => clearInterval(countdownInterval)
  }, [countdown])

  useEffect(() => {
    pullingInterval = setInterval(() => {
      async function getData() {
        const response = await documentAnalysisPulling(Number(CreditRequestId), selfieProviderId)

        if (response?.analyzed) {
          clearInterval(pullingInterval)
          clearInterval(countdownInterval)
          goToNextScreen()
        }
      }

      getData()
    }, 20000)

    return () => clearInterval(pullingInterval)
  }, [CreditRequestId])

  return [countdown]
}
