import React, { useContext } from 'react'
import { Button, LoadingProvi } from '@provi/provi-components'
import { TemplateContext } from '../../../components/template/context'
import { Container, Title, SubTitle, Wrapper } from '../../../components/document-without-face'

export const DocumentWithoutFace = () => {
  const { isMobile, goToPreviousScreen, isLoading } = useContext(TemplateContext)

  return (
    <>
      {isLoading && <LoadingProvi isLoadingDark />}
      <Container isMobile={isMobile}>
        <Wrapper>
          <Title>Parece que essa não é a frente do seu documento. Vamos precisar que você envie outra foto.</Title>
          <SubTitle>A frente do seu documento possui o seu rosto.</SubTitle>
          <Button
            marginHorizontal={0}
            type="submit"
            text="Tirar outra foto"
            persistFloatingMode={false}
            onClick={() => goToPreviousScreen()}
          />
        </Wrapper>
      </Container>
    </>
  )
}
