import styled, { css } from 'styled-components'
import { CalendarIcon as CalendarSVG } from '../../../assets/svg/calendar-icon'
import { ReviewHandIcon as ReviewHandIconSVG } from '../../../assets/svg/review-hand-icon'
export const ContentWrapper = styled.div`
  border: 0px solid #000;
  height: 100vh;
  overflow-y: scroll;

  ${({ isMobile }) =>
    !isMobile &&
    css`
      padding-top: 60px;
      max-width: 400px;
      padding-left: 20px;
      padding-right: 20px;
    `}

  /* width */
    ::-webkit-scrollbar {
    width: 0px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: 0;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: #fff;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #fff;
  }
`

export const ReviewHeader = styled.h3`
  ${({ isMobile }) =>
    !isMobile &&
    css`
      font-style: normal;
      font-weight: bold;
      font-size: 25px;
      line-height: 32px;
      letter-spacing: 0.0025em;
    `}
`
export const ReviewSeparator = styled.hr`
  width: 100%;
  border-width: 1px;
  border-color: ${({ color }) => (color ? color : '#CFCFCF')};

  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: ${marginTop}px;
    `}
`

export const CalendarIcon = styled(CalendarSVG)`
  padding: 6px;
  border: 0px solid #000;
`
export const ReviewHandIcon = styled(ReviewHandIconSVG)``

export const ReviewTitle = styled.label`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.005em;
  text-align: left;
  color: #2647d7;
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  ${({ flexDirection }) => (flexDirection ? `flex-direction: ${flexDirection};` : `flex-direction: row;`)}

  ${({ justifyContent }) =>
    justifyContent &&
    css`
      justify-content: ${justifyContent};
    `}

  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: ${marginTop}px;
    `}

  ${({ paddingRight }) =>
    paddingRight &&
    css`
      padding-right: ${paddingRight}px;
    `}
`

export const ChangePayment = styled.label`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #de0c4b;
  margin-top: 28px;
  ${({ isMobile }) => isMobile && `margin-bottom: 40px;`} :hover {
    cursor: pointer;
  }
`

export const LoanLabel = styled.label`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;

  ${({ isBold }) =>
    isBold &&
    css`
      font-weight: bold;
    `}

  ${({ marginLeft }) =>
    marginLeft &&
    css`
      margin-left: ${marginLeft}px;
    `}
`

export const TotalLabel = styled.label`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.005em;
  text-align: left;
`
export const TotalValue = styled.label`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.005em;
  text-align: right;
  color: #2647d7;
  margin-left: 5px;
`
export const CustomButton = styled.button`
  height: 48px;
  width: 295px;
  left: 0px;
  top: 0px;
  border: 0px;
  border-radius: 5px;
  background: #de0c4b;

  label {
    color: #fff;
    font-family: 'CenturyGothicBold', sans serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0.005em;
    text-align: center;
  }

  :hover {
    cursor: pointer;
  }
`
