import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 10px;
    `}

  display: flex;
  flex-direction: column;
`

export const CourseTitle = styled.label`
  color: #2647d7;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.005em;
  text-align: left;
`

export const CourseSubTitle = styled.label`
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.005em;
  text-align: left;
`

export const DiscountContainer = styled.div`
  display: flex;
`

export const CourseOldPrice = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.0075em;
  text-decoration-line: line-through;
  color: #575757;
`
export const CourseDiscountPercents = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.0075em;
  color: #2647d7;
`
export const CoursePrice = styled(CourseSubTitle)`
  font-size: 13.2px;
  letter-spacing: 0.005em;
  color: #000b3c;
`
