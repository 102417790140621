import React from 'react'
import * as Yup from 'yup'
import { withFormik } from 'formik'
import { Input, TextErrorInput, Button } from '@provi/provi-components'
import { Form } from '../../../components/form'

export const Formik = props => {
  const { handleChange, values, errors, touched, handleBlur, handleSubmit } = props

  return (
    <Form onSubmit={handleSubmit}>
      <Input
        autoFocus
        margin="normal"
        id="name"
        placeholder="Escreva aqui"
        onChange={handleChange('decisionMoney2')}
        onBlur={handleBlur('decisionMoney2')}
        error={!!errors.decisionMoney2}
        value={values.decisionMoney2}
      />
      {errors.decisionMoney2 && touched.decisionMoney2 && <TextErrorInput text={errors.decisionMoney2} />}

      <Button
        marginHorizontal={0}
        type="submit"
        disabled={errors.decisionMoney2 || !values.decisionMoney2}
        text="Avançar"
        persistFloatingMode={false}
      />
    </Form>
  )
}

export const FormFomik = withFormik({
  validateOnBlur: false,
  validateOnChange: true,
  enableReinitialize: true,
  mapPropsToValues: props => ({ decisionMoney2: props.data || '' }),
  validationSchema: Yup.object().shape({
    decisionMoney2: Yup.string()
      .min(2, 'A resposta deve conter no mínimo 2 caracteres')
      .max(2048, 'A resposta deve conter no máximo 2048 caracteres')
      .required('Digite um texto válido')
  }),
  handleSubmit: async ({ decisionMoney2 }, { setSubmitting, props }) => {
    props.goToNextScreen(decisionMoney2)
    setSubmitting(false)
  }
})(Formik)
