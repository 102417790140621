import React, { useContext } from 'react'
import { Button, LoadingProvi, theme, TextLink } from '@provi/provi-components'
import { TemplateContext } from '../../../components/template/context'
import { useISAContext } from '../../../components/template/ISA/context'
import TypeFormIntegration from '../../../components/TypeformIntegration'

import { Container, Title, Text, Wrapper, ContainerTitle, WrapperButton, SubTitle } from './styles'
import { useSuccess } from './hooks'

export const SuccessPage = () => {
  const { isMobile, setIsLoading, isLoading, creditPath } = useContext(TemplateContext)
  const { goToNextScreen } = useISAContext()
  const [handleFillForm] = useSuccess(setIsLoading, goToNextScreen)

  return (
    <>
      {isLoading && <LoadingProvi isLoadingDark />}
      <Container isMobile={isMobile}>
        <Wrapper>
          <ContainerTitle>
            <Title>Uhul! Recebemos todas as suas informações</Title>
            <SubTitle>
              Dentro dos próximos dias a escola irá te retornar com uma posição sobre o seu processo seletivo.
            </SubTitle>
          </ContainerTitle>

          <Text>Se você quiser aumentar as suas chances de passar, é só preencher a pesquisa abaixo.</Text>
          <Text>É super rapidinho e é um questionário sobre os seus hábitos financeiros.</Text>

          <WrapperButton>
            {isMobile && (
              <TextLink text="Finalizar sem preencher" onClick={() => handleFillForm(true)} color={theme.colors.grey400} />
            )}

            <Button
              marginHorizontal={0}
              type="submit"
              text="Aumentar chances"
              onClick={() => {
                window.open(creditPath && creditPath.ReadISASurvey, '_blank')

                goToNextScreen()
              }}
              textLink={
                !isMobile && {
                  text: 'Finalizar sem preencher',
                  onClick: () => handleFillForm(true),
                  color: theme.colors.grey400
                }
              }
              persistFloatingMode={false}
            />
          </WrapperButton>
        </Wrapper>
        <TypeFormIntegration id="hR849hRt" />
      </Container>
    </>
  )
}
