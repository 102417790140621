import React from 'react'

export const ArrowDocSign = () => (
  <svg version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 60 300 300">
    <path
      d="M158.6,179.6c-4.2,0.7-3.7,7.3,0.5,6.7c6.4-1,12.1-3.2,17.5-6.7c1.1-0.7,1.9-2.6,1.2-3.8
	c-2.7-5.4-5.3-10.9-8.1-16.3c-1.4-2.8-6.6-2.5-6,1.3c0.7,4,1.7,7.9,3.1,11.7c-25-7.8-37-34-41.8-58.4c-0.3-1.3-2.4-1.2-2.2,0.2
	c3.3,26.2,15.6,55.5,42.6,63.5C163.3,178.6,161,179.2,158.6,179.6z"
    />
  </svg>
)
