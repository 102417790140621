import React, { useContext } from 'react'
import _ from 'lodash'
import { Form, Container, Button, Title, InputMasks, Selector, TextErrorInput } from '@provi/provi-components'
import { TemplateContext } from '../../../components/template/context'
import { TemplateForm } from '../../../components/template-form'
import { Checked } from '../../../assets/svg/checked'
import { Unchecked } from '../../../assets/svg/unchecked'
import {
  SubTitle,
  ContainerOption,
  Option,
  OptionText,
  ItemCheck,
  WrapperIcon,
  ExtraDataText,
  ContainerSubTitle,
  ContainerQuestions
} from './styles'
import { useMedicineSchoolDetails } from './hooks'

export const MedicineSchoolDetails = () => {
  const { setDataModal, creditPath, goToNextScreen } = useContext(TemplateContext)
  const [
    typeUniversity,
    setSelectTypeUniversity,
    error,
    universities,
    setType,
    type,
    functionSelectUniversity,
    selectUniversity,
    notFoundUniversity,
    setNotFoundUniversity,
    onSubmit,
    isButtonDisabled,
    date,
    handleChangeDate,
    errorDate,
    TYPES_UNIVERSITIES,
    TYPES,
    isButtonDisabledDate,
    isButtonDisabledTypeUniversity,
    textError,
    setDate,
    isSanarFinancing
  ] = useMedicineSchoolDetails(setDataModal, creditPath, goToNextScreen)

  return (
    <TemplateForm isMarginDesktop>
      <Container isMarginDesktop>
        <Form onSubmit={onSubmit}>
          <Title text="Confirme suas informações de ensino" />

          <ContainerQuestions>
            <SubTitle>Qual faculdade você cursa?</SubTitle>
            <ContainerOption>
              {TYPES_UNIVERSITIES &&
                TYPES_UNIVERSITIES.length > 0 &&
                TYPES_UNIVERSITIES.map(item => (
                  <Option key={item.id} onClick={() => setSelectTypeUniversity(item)} isSelected={typeUniversity === item.id}>
                    <OptionText isSelected={typeUniversity === item.id}>{item.label}</OptionText>
                  </Option>
                ))}
            </ContainerOption>
            {error && <TextErrorInput text="Esse produto é só para estudantes de medicina" />}
          </ContainerQuestions>

          <ContainerQuestions>
            <ContainerSubTitle>
              <SubTitle>Data prevista de graduação</SubTitle>
            </ContainerSubTitle>

            <InputMasks
              mask="99/9999"
              onChange={e => setDate(e.target.value)}
              onBlur={e => handleChangeDate(e.target.value)}
              margin="normal"
              value={date}
              type="tel"
              placeholder="Ex: 06/2022"
            />
            {errorDate && <TextErrorInput text={textError} />}
          </ContainerQuestions>

          {!isSanarFinancing ? (
            <ContainerQuestions>
              <SubTitle>Etapa da graduação</SubTitle>
              <ContainerOption>
                {TYPES &&
                  TYPES.length > 0 &&
                  TYPES.map(item => (
                    <Option key={item.id} onClick={() => setType(item)} isSelected={type && type.id === item.id}>
                      <OptionText isSelected={type && type.id === item.id}>{item.label}</OptionText>
                    </Option>
                  ))}
              </ContainerOption>
            </ContainerQuestions>
          ) : null}

          <ContainerQuestions>
            <SubTitle>Selecione a sua faculdade</SubTitle>
            <Selector
              placeholder="Escolher"
              options={universities}
              value={selectUniversity}
              onValueChange={functionSelectUniversity}
            />

            <ItemCheck
              onClick={() => {
                functionSelectUniversity({})
                setNotFoundUniversity(!notFoundUniversity)
              }}
            >
              <WrapperIcon>{!notFoundUniversity ? <Unchecked /> : <Checked />}</WrapperIcon>
              <ExtraDataText>Não achei minha faculdade</ExtraDataText>
            </ItemCheck>
          </ContainerQuestions>

          <Button
            marginHorizontal={0}
            disabled={
              isButtonDisabled ||
              isButtonDisabledDate ||
              isButtonDisabledTypeUniversity ||
              (_.isEmpty(selectUniversity) && !notFoundUniversity) ||
              error
            }
            text="Avançar"
            type="submit"
            persistFloatingMode={false}
          />
        </Form>
      </Container>
    </TemplateForm>
  )
}
