import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const SubTitle = styled.div`
  font-family: 'CenturyGothicBold';
  font-size: 16px;
  line-height: 18px !important;
  margin-top: 16px;
  margin-bottom: 8px;
  color: ${theme.colors.blue900};

  ${theme.breakpoints.down('md')} {
    margin-bottom: 10px;
  }
`

export const ContainerQuestions = styled.div`
  margin: 30px 0;
`
