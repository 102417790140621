import React from 'react'
import { Disclaimer } from './styles'
import { googleRecaptchaTerms } from '../../constants'

export const RecaptchaDisclaimer = () => {
  return (
    <Disclaimer>
      Este site é protegido por Google reCaptcha e<br />
      os termos de{' '}
      <a
        style={{ opacity: 0.5, textDecoration: 'none' }}
        href={googleRecaptchaTerms.service}
        target="_blank"
        rel="noopener noreferrer"
      >
        Serviço
      </a>{' '}
      e{' '}
      <a
        style={{ opacity: 0.5, textDecoration: 'none' }}
        href={googleRecaptchaTerms.privacy}
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacidade
      </a>{' '}
      se aplicam.
    </Disclaimer>
  )
}
