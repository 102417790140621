import React, { useContext } from 'react'
import { Container, Title } from '@provi/provi-components'
import { TemplateContext } from '../../../components/template/context'
import { useISAContext } from '../../../components/template/ISA/context'
import { TemplateFormIsa } from '../../../components/template-isa'
import { FormFomik } from './form'

export const PersonalSupportDecision = () => {
  const { creditPath, isMobile } = useContext(TemplateContext)
  const { goToNextScreen } = useISAContext()
  return (
    <TemplateFormIsa>
      <Container>
        <Title text="O quanto a sua rede de apoio e familiares participaram da sua decisão de fazer o curso? O que eles estão achando dessa decisão?" />
        <FormFomik data={creditPath && creditPath.ReadPersonalNetworkParticipation} goToNextScreen={goToNextScreen} />
        {isMobile && <div style={{ height: '27px' }} />}
      </Container>
    </TemplateFormIsa>
  )
}
