import React, { useContext } from 'react'
import { Container, Button, Title, SubHeaderDesktop } from '@provi/provi-components'
import { Form } from '../../../components/form'
import { TemplateContext } from '../../../components/template/context'
import { TemplateForm } from '../../../components/template-form'
import { usePersonalDocumentIntro } from './hooks'

export const PersonalDocumentIntro = () => {
  const { setIsShowModal, isMobile, goToNextScreen, setIsLoading } = useContext(TemplateContext)
  const [onSubmit] = usePersonalDocumentIntro(goToNextScreen, setIsLoading)

  return (
    <TemplateForm>
      <Container>
        <Form onSubmit={onSubmit}>
          {isMobile ? (
            <Title text="Escolha abaixo o documento que você quer enviar" />
          ) : (
            <SubHeaderDesktop
              title="Escolha abaixo o documento que você quer enviar"
              handleModal={() => setIsShowModal(true)}
              isShowButtonHelpModal
            />
          )}
          <Button marginHorizontal={0} type="submit" text="Avançar" persistFloatingMode={false} />
        </Form>
      </Container>
    </TemplateForm>
  )
}
