import { useState, useEffect } from 'react'
import { showToast } from '@provi/provi-components'
import { LOCALSTORAGE_TOKENS, REDIRECT_URLS } from '../../constants'
import { history } from '../../navigation/history'
import { signUp } from '../../services/auth'
import { checkURLisISA } from '../../utils'

export const useInvalidToken = partner => {
  const [email, setEmail] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    const storage = localStorage.getItem(LOCALSTORAGE_TOKENS.dataUser)
    if (storage) {
      const emailStorage = JSON.parse(storage).email
      setEmail(emailStorage)
    }
  }, [])

  const onSubmit = async () => {
    const path = window.location.pathname
    const checkisISA = checkURLisISA(path)
    if (email) {
      try {
        setIsLoading(true)
        await signUp({ email, partner, isISA: checkisISA })
        if (checkisISA) {
          history.push(`/isa/email-enviado/${partner}`)
          return
        }
        history.push(`/email-enviado/${partner}`)
      } catch (err) {
        setIsLoading(false)
        showToast()
      } finally {
        setIsLoading(false)
      }
    } else {
      if (checkisISA) {
        window.open(`${REDIRECT_URLS.passwordless}/isa/${partner}`)
        return
      }
      window.open(`${REDIRECT_URLS.passwordless}/${partner}`)
    }
  }

  return [onSubmit, email, isLoading]
}
