import React, { useContext } from 'react'
import { Button } from '@provi/provi-components'
import { URL_EXTERNAL } from '../../../constants'
import { TemplateContext } from '../../../components/template/context'
import { Container, Text } from './style'

export const GuarantorNegativeFeedback = () => {
  const { isMobile } = useContext(TemplateContext)
  return (
    <Container>
      <Text cy="text-1" bold={!isMobile} size={isMobile ? 16 : 24}>
        Infelizmente, não conseguimos seguir com a sua solicitação com esses avalistas.
      </Text>
      <Text cy="text-2" bold={isMobile} size={isMobile ? 12 : 20}>
        Nem tudo está perdido. Entre em contato com a gente para te ajudarmos a achar um avalista.
      </Text>
      <Button
        marginHorizontal={0}
        text="Abrir chat"
        onClick={() => window.open(URL_EXTERNAL, '_blank')}
        persistFloatingMode={false}
      />
    </Container>
  )
}
