import { useState, useEffect } from 'react'
import { theme } from '@provi/provi-components'
import { URL_EXTERNAL } from '../../../constants'

export const useCourseConfirmation = (setDataModal, setIsShowModal) => {
  const [isShowModalDontConfirm, setIsShowModalDontConfirm] = useState(false)

  useEffect(() => {
    setDataModal({
      title: 'Algo errado?',
      texts: ['Não está encontrando o seu curso ou alguma informação não está correta? Entre em contato com a gente.'],
      textButton: 'Entendi',
      buttonFunction: () => setIsShowModal(false),
      textLink: {
        color: theme.colors.blue900,
        text: 'Abrir chat',
        onClick: () => window.open(URL_EXTERNAL, '_blank')
      }
    })
  }, [setDataModal, setIsShowModal])

  return [isShowModalDontConfirm, setIsShowModalDontConfirm]
}
