import React, { useContext } from 'react'
import { Container, Button, TextErrorInput, Selector } from '@provi/provi-components'
import { Form } from '../../../components/form'
import { TemplateContext } from '../../../components/template/context'
import { useISAContext } from '../../../components/template/ISA/context'
import { TemplateFormIsa } from '../../../components/template-isa'

import { useCiviStateSelect } from './hooks'
import { WrapperError, Wrapper, Title } from './style'

const Error = () => {
  return (
    <WrapperError>
      <TextErrorInput text="Por favor, preencha todos os dados" />
    </WrapperError>
  )
}

export const EnglishLevel = () => {
  const { creditPath } = useContext(TemplateContext)
  const { goToNextScreen } = useISAContext()
  const [onSubmit, isShowError, setSelect, selects, options] = useCiviStateSelect(goToNextScreen, creditPath)
  const isValid = Object.keys(selects).filter(item => selects[item] !== undefined)

  return (
    <TemplateFormIsa>
      <Container>
        <Form onSubmit={onSubmit}>
          <Wrapper>
            <Title>Como você avalia o seu nível de inglês?</Title>
            <Selector
              isSearchable={false}
              name="englishLevel"
              placeholder="Escolher"
              options={options}
              value={selects.englishLevel}
              onValueChange={setSelect}
            />
          </Wrapper>

          {isShowError && <Error />}

          <Button
            marginHorizontal={0}
            type="submit"
            disabled={isValid.length !== 1}
            text="Avançar"
            persistFloatingMode={false}
          />
        </Form>
      </Container>
    </TemplateFormIsa>
  )
}
