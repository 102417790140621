import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import background_analysis_mobile from '../../../assets/svg/bg-background-pre-approved.svg'

export const Wrapper = styled.div`
  padding-left: 140px;
  width: 640px;

  ${theme.breakpoints.down('md')} {
    padding-left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 20px;
  }
`

export const Container = styled.div`
  box-sizing: border-box;
  padding-left: 22px;
  padding-right: 22px;
  height: 100vh;
  background-color: ${theme.colors.blue900};
  background-image: url(${background_analysis_mobile});
  background-repeat: no-repeat;
  background-position: right bottom;
  width: 100%;
  display: flex;
  align-items: center;

  ${theme.breakpoints.down('md')} {
    height: 100%;
    background-image: unset;
    display: block;
    background-size: 100%;
  }
`

export const TitleBlue = styled.h3`
  color: ${theme.colors.blue600};
  font-size: 24px;
  line-height: 30px;
  ${theme.breakpoints.down('sm')} {
    font-size: 20px;
    line-height: 30px;
  }
`

export const OptionsList = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${theme.variables.spacer * 4}px;

  ${theme.breakpoints.down('md')} {
    display: block;
  }
`
export const Option = styled.div`
  margin-top: 20px;
  cursor: pointer;
  height: ${theme.variables.spacer * 8}px;
  box-shadow: 2px 1px 15px -9px rgba(0, 0, 0, 0.5);
  height: 88px;
  box-sizing: border-box;
  width: 272px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 20px;
  background-color: ${({ active }) => (active ? theme.colors.blue900 : theme.colors.white100)};
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`
export const OptionText = styled.p`
  font-family: CenturyGothicBold, sans serif;
  margin: 0;
  font-size: ${({ size }) => (size ? size : 16)}px;
  color: ${({ active }) => (active ? theme.colors.white100 : theme.colors.blue900)};
`

export const WrapperOption = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ isFirst }) => !isFirst && 'margin-left: 16px;'}
`

export const TextOption = styled.div`
  font-family: 'CenturyGothicBold', sans-serif;
  font-size: 16px;
  line-height: 24px;
  ${theme.breakpoints.down('md')} {
    font-size: 16px;
    line-height: 24px;
  }
`

export const WrapperStatus = styled.div`
  width: 183px;
  height: 36px;
  text-align: left;
  background-color: ${props => props.color};
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: white;
  font-weight: bold;
`

export const Main = styled.div`
  text-align: left;
  margin-top: 10px;
  margin-left: 20px;
`

export const Description = styled.p`
  font-size: 1rem;
  line-height: 25px;
  text-align: justify;
`

export const MoreHelpText = styled.p`
  margin-top: 15px;
  opacity: 0.6;
`

export const MoreHelpLink = styled.a`
  color: red;
`

export const ContainerCloseModal = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  justify-content: flex-end;
  z-index: 100;
`

export const ContainerText = styled.div`
  padding: 16px;
  border: 1px solid ${theme.colors.blue900};
  border-radius: 20px;
  margin-top: 20px;
`

export const Text = styled.p`
  font-family: 'CenturyGothic', sans-serif;
  font-size: 12px;
  line-height: 15px;
  color: ${theme.colors.white100};
  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    line-height: 18px;
  }
`

export const TextInfo = styled.p`
  font-family: 'CenturyGothic', sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.white100};
  margin-top: 24px;
  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    line-height: 18px;
  }
`

export const Icon = styled.img`
  margin-left: 16px;
`

export const WrapperIcons = styled.div`
  margin-top: 12px;
  display: flex;
  justify-content: center;
`

export const ButtonTitle = styled.p`
  font-size: 12px;
  margin: 0;
  color: ${theme.colors.white100};
  font-weight: bold;
`

export const ContainerButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${theme.breakpoints.down('md')} {
    flex-direction: column;
  }
`

export const WrapperTextCopy = styled.div`
  border: 1px solid white;
  border-radius: 20px;
  margin-top: 20px;
`

export const SubTitle = styled.p`
  font-family: 'CenturyGothicBold', sans serif;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  color: ${theme.colors.white100};
  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    line-height: 18px;
  }
`

export const TextLink = styled.div`
  font-family: 'CenturyGothicBold', sans serif;
  font-size: 16px;
  line-height: 20px;
  text-decoration: underline;
  cursor: pointer;
  color: ${theme.colors.white100};
  &:hover {
    opacity: 0.6;
  }
  ${theme.breakpoints.down('md')} {
    margin-bottom: 16px;
    font-size: 12px;
    line-height: 20px;
  }
`

export const Status = styled.div`
  ${theme.breakpoints.down('md')} {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
`

export const TextLinkGuarantor = styled.div`
  font-family: 'CenturyGothicBold', sans serif;
  font-size: 16px;
  line-height: 20px;
  color: ${theme.colors.blue600};
  word-break: break-all;
  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    line-height: 20px;
  }
`
