/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import _ from 'lodash'
import { theme } from '@provi/provi-components'
import { ERRORS, URL_EXTERNAL } from '../../../constants'
import masks from '../../../theme/masks'

export const useBank = (goToNextScreen, setDataModal, creditPath, setIsShowModal) => {
  const [values, setValues] = useState({})
  const [banks, setBanks] = useState({})
  const [bank, setBank] = useState()
  const [selectedOption, setSelectedOption] = useState()
  const [accountType, setAccountType] = useState()
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const [agencyError, setAgencyError] = useState('')
  const [accountError, setAccountError] = useState('')
  const [digitError, setDigitError] = useState('')
  const [bankError, setBankError] = useState('')
  const [isShowModalBankAccount, setIsShowModalBankAccount] = useState(false)
  const [isBankDigital, setIsBankDigital] = useState(false)
  const [modalError, setModalError] = useState(false)
  const [isVerifyingAccount, setIsVerifyingAccount] = useState(false)
  const [isBlocked, setIsBlocked] = useState(false)

  const OPTIONS = [
    { id: 1, value: 'CONTA_CORRENTE', label: 'Conta corrente' },
    { id: 2, value: 'CONTA_POUPANCA', label: 'Poupança' }
  ]

  /**
   * digital banks that the agency is 0001 without digit
   * 077 - intermedium
   * 260 - Nu pagamentos
   */
  const DIGITAL_BANKS_WITH_0001_CODES = ['077', '260']

  const validateNumber = text => {
    const REGEX = /^\d+$/
    return REGEX.test(text)
  }

  const validatorForm = (value, option, typeBank) => {
    let enabled = false
    if (_.isEmpty(value)) {
      enabled = false
      return
    }

    if (!typeBank) {
      setBankError('Selecione seu banco!')
      enabled = false
      setIsButtonDisabled(enabled)
      return
    } else {
      setBankError(null)
    }

    if ((value && !value.agency && value.agency.length < 4) || !validateNumber(value && value.agency)) {
      setAgencyError('O campo Agência deve ter 4 dígitos!')
      enabled = false
      setIsButtonDisabled(enabled)
      return
    } else {
      setAgencyError(null)
    }

    if (!value.account || !validateNumber(value.account)) {
      setAccountError('O campo Conta não pode estar vazio!')
      enabled = false
      setIsButtonDisabled(enabled)
      return
    } else {
      setAccountError(null)
    }

    if (!value.accountDigit) {
      setDigitError('Preencha o campo')
      enabled = false
      setIsButtonDisabled(enabled)
      return
    } else {
      setDigitError(null)
    }

    if (!option) {
      enabled = false
      setIsButtonDisabled(enabled)
      return
    }
    setIsButtonDisabled(true)
  }

  useEffect(() => {
    setDataModal({
      title: 'Lembre-se que para não ter problemas com o recebimento do dinheiro, a conta bancária cadastrada NÃO pode ser:',
      texts: ['- Conta no nome de outra pessoa', '- Conta BB Fácil', '- Conta Caixa Fácil', '- Conta salário'],
      textButton: 'Entendi',
      buttonFunction: () => setIsShowModal(false),
      textLink: {
        color: theme.colors.blue900,
        text: 'Abrir chat',
        onClick: () => window.open(URL_EXTERNAL, '_blank')
      },
      color: theme.colors.blue900
    })

    if (creditPath && creditPath.ReadBankAccount) {
      const banksOptions = creditPath.ReadBankAccount.options
      if (creditPath.ReadBankAccount) {
        setBanks(banksOptions)

        if (creditPath.ReadBankAccount && creditPath.ReadBankAccount.current) {
          const findBank = banksOptions.find(item => item.id === creditPath.ReadBankAccount.current.FinancialInstitution.id)
          const findTypeAccount = OPTIONS.find(item => item.value === creditPath.ReadBankAccount.current.accountType)
          setSelect(findBank)
          setValues({
            agency: creditPath.ReadBankAccount.current.agency,
            agencyDigit: creditPath.ReadBankAccount.current.agencyDigit,
            account: creditPath.ReadBankAccount.current.account,
            accountDigit: creditPath.ReadBankAccount.current.accountDigit
          })
          onSelectOption(findTypeAccount)
          setAccountError(false)
          setAgencyError(false)
          setBankError(false)
          setIsButtonDisabled(true)
        }
      }
    }
  }, [])

  const onSelectOption = option => {
    setSelectedOption(option)
    setAccountType(option?.value)
    validatorForm(values, option, bank)
  }

  const onFormChange = (formValue, identifier) => {
    const answer = values
    const unmaskedValue = masks.unmask(formValue)
    answer[identifier] = unmaskedValue

    setValues({ ...answer })
    validatorForm(answer, selectedOption, bank)
  }

  const setSelect = item => {
    if (DIGITAL_BANKS_WITH_0001_CODES.includes(item.code)) {
      onFormChange('0001', 'agency')
      onFormChange('', 'agencyDigit')
      setIsBankDigital(true)
    } else {
      onFormChange('', 'agencyDigit')
      onFormChange('', 'agency')
      setIsBankDigital(false)
    }

    setBank(item)
    validatorForm(values, selectedOption, item)
  }

  const onSubmit = form => {
    form.preventDefault()
    setIsShowModalBankAccount(true)
  }

  const onSubmitModal = async () => {
    setIsShowModalBankAccount(false)
    setIsVerifyingAccount(true)
    const data = { financialInstitutionId: bank.id, accountType, ...values }
    const response = await goToNextScreen(data)

    if (!response?.error?.message) {
      setIsVerifyingAccount(false)
    }

    const shouldBlock = [
      ERRORS.bankValidationInfo.accountDayLimitReached,
      ERRORS.bankValidationInfo.accountLimitReached
    ].includes(response?.error?.message)

    if (shouldBlock) return setIsBlocked(true)
    setModalError(true)

    setIsVerifyingAccount(false)
  }

  return [
    onSubmit,
    setSelect,
    values,
    onSelectOption,
    selectedOption,
    isButtonDisabled,
    onFormChange,
    agencyError,
    accountError,
    bank,
    banks,
    OPTIONS,
    isShowModalBankAccount,
    setIsShowModalBankAccount,
    onSubmitModal,
    digitError,
    bankError,
    isBankDigital,
    modalError,
    setModalError,
    isVerifyingAccount,
    isBlocked
  ]
}
