import React, { useContext } from 'react'
import { Container, Title, SubTitle, SubHeaderDesktop } from '@provi/provi-components'
import { TemplateContext } from '../../../components/template/context'
import { useISAContext } from '../../../components/template/ISA/context'
import { TemplateFormIsa } from '../../../components/template-isa'
import { FormFormik } from './form'
import { usePhone } from './hooks'

export const Phone = () => {
  const { isMobile, setDataModal, creditPath } = useContext(TemplateContext)
  const { goToNextScreen } = useISAContext()
  usePhone(setDataModal)
  return (
    <TemplateFormIsa>
      <Container>
        {isMobile ? <Title text="Qual o número do seu celular?" /> : <SubHeaderDesktop title="Qual o número do seu celular?" />}
        <SubTitle text="Vamos te enviar um código de verificação via SMS" />
        <FormFormik data={creditPath && creditPath.ReadPhone} goToNextScreen={goToNextScreen} />
      </Container>
    </TemplateFormIsa>
  )
}
