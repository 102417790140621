import ReactGA from 'react-ga'
import { trackingId, LOCALSTORAGE_TOKENS, googleAnalyticsDimensions } from '../../constants'
import ttiPolyfill from 'tti-polyfill'

/**
 * @description Initialize the GA, set debug to true in order to keep track
 * of what information is being send to analytics
 * @return {void}
 */
export const initGA = () => {
  ReactGA.initialize(trackingId, { debug: false })
}

/**
 * @description Track page views, and set dimensions for the user and for the session
 * @return {void}
 */
export const trackPageViewAndUserInfo = () => {
  const creditRequestId = localStorage.getItem(LOCALSTORAGE_TOKENS.crid)
  const dataUserStorage = localStorage.getItem(LOCALSTORAGE_TOKENS.dataUser)
  let dataUser = {}

  try {
    dataUser = JSON.parse(dataUserStorage) || {}
  } catch (e) {}

  ReactGA.set({
    [googleAnalyticsDimensions.userId]: dataUser.userId,
    [googleAnalyticsDimensions.CreditRequestId]: creditRequestId,
    [googleAnalyticsDimensions.partner]: dataUser.partnerSlug || 'provi.com.br'
  })

  ReactGA.pageview(window.location.pathname + window.location.search)
}

/**
 * @description Dispatch events for the google analytics, can be used
 * to trigger specific conversion goals
 * @param {Object} data
 * @param {string} data.category
 * @param {string} data.action
 * @param {string} data.label
 * @return {void}
 */
export const trackEvent = ({ category, action, label }) => {
  ReactGA.event({
    category,
    action,
    label
  })
}

/**
 * @description Track app performance
 * @return {void}
 */
export const Performance = list => {
  list.getEntries().forEach(entry => {
    ReactGA.timing({
      category: 'Load Performace',
      variable: 'Server Latency',
      value: entry.responseStart - entry.requestStart
    })

    ReactGA.timing({
      category: 'Load Performace',
      variable: 'App load time',
      value: entry.responseEnd - entry.requestStart
    })

    ReactGA.timing({
      category: 'Load Performace',
      variable: 'Total app load time',
      value: entry.responseEnd - entry.responseStart
    })
  })
}

/**
 * @description Track the time it takes for for the site to be interactive
 * @return {void}
 */
export const firstInteraction = () => {
  ttiPolyfill.getFirstConsistentlyInteractive().then(tti => {
    ReactGA.timing({
      category: 'Load Performace',
      variable: 'Download time',
      value: tti
    })
  })
}

/**
 * @description Use to track different exceptions
 * @param {Object} data
 * @param {string} data.error
 * @param {boolean} data.fatal
 * @return {void}
 */
export const trackExceptions = ({ error, fatal }) => {
  ReactGA.exception({
    description: error,
    fatal
  })
}
