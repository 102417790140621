import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const CentralizedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const CustomSubHeaderDesktop = styled.h1`
  color: ${theme.colors.blue600};
  margin: -24px 0 0 0;
  font-size: ${props => (props.isMobile ? '18px' : '30px')};
  line-height: ${props => (props.isMobile ? '24px' : '36px')};
`
