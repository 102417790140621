import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const ContainerErrorInput = styled.div`
  margin-top: 16px;
`

export const ContainerOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${theme.variables.spacer * 2}px;
  margin-bottom: 38px;
  ${theme.breakpoints.down('md')} {
    justify-content: space-between;
  }
`

export const OptionsList = styled.div`
  display: flex;
  margin-top: ${theme.variables.spacer * 4}px;
  ${theme.breakpoints.down('md')} {
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    display: grid;
  }
`

export const Option = styled.div`
  box-sizing: border-box;
  cursor: pointer;
  padding: 10px;
  height: 108px;
  box-shadow: 2px 1px 15px -9px rgba(0, 0, 0, 0.5);
  width: 108px;
  min-width: 100px;
  margin-right: 8px;

  ${theme.breakpoints.down('md')} {
    width: 100%;
    margin-left: 0;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  border-radius: 20px;
  background-color: ${({ isSelected }) => (isSelected ? theme.colors.blue900 : theme.colors.white100)};
`

export const OptionText = styled.p`
  font-size: ${({ size }) => (size ? size : 16)}px;
  margin: 0;
  margin-top: 5px;
  ${({ bold }) => bold && 'font-family: CenturyGothicBold'};
  color: ${({ isSelected }) => (isSelected ? theme.colors.white100 : theme.colors.blue900)};
`
