import React, { useContext } from 'react'
import { TemplateContext } from '../../../components/template/context'
import { Button, LoadingProvi } from '@provi/provi-components'
import { IconPen } from '../../../assets/svg/icon-pen'
import { trackEvent } from '../../../components/google-analytics'
import { Container, Title, SubTitle, Text, Wrapper, TitleBlue } from './style'

export const CreditApproved = () => {
  const { isLoading, goToNextScreen, setIsLoading } = useContext(TemplateContext)
  return (
    <>
      {isLoading && <LoadingProvi isLoadingDark={true} />}
      <Container>
        <Wrapper>
          <TitleBlue>Parabéns!</TitleBlue>
          <Title>Seu crédito foi aprovado! </Title>
          <SubTitle>Mas lembre-se, você ainda precisa assinar o contrato eletronicamente na próxima tela.</SubTitle>
          <Text>
            Caso tenha algum problema com seu contrato ou seu primeiro boleto, entre em contato com a gente via email
            papo@provi.com.br, ou via WhatsApp (11) 95771-2414.
          </Text>
          <Button
            marginHorizontal={0}
            text="Assinar contrato"
            icon={<IconPen />}
            onClick={() => {
              setIsLoading(true)
              goToNextScreen()
              trackEvent({ category: 'CreditPath', action: 'Embedded Signing', label: 'waitingSignature' })
            }}
          />
        </Wrapper>
      </Container>
    </>
  )
}
