import React, { useContext } from 'react'
import { Container, Button, SubTitle, Title, TextLink } from '@provi/provi-components'
import { Form } from '../../../components/form'
import { TemplateFormIsa } from '../../../components/template-isa'
import { TemplateContext } from '../../../components/template/context'
import { useISAContext } from '../../../components/template/ISA/context'
import { theme } from '@provi/provi-components'
import { SubTitleInternal, Text } from './style'
import { usePreDocument } from './hooks'

export const IntroProofOfAddress = () => {
  const { setDataModal, setIsShowModal, setIsLoading, isMobile } = useContext(TemplateContext)
  const { goToNextScreen } = useISAContext()
  usePreDocument(setDataModal, setIsShowModal, setIsLoading)
  return (
    <TemplateFormIsa>
      <Container>
        <Form
          onSubmit={e => {
            e.preventDefault()
            goToNextScreen()
          }}
        >
          <Title text="Qual comprovante de endereço eu envio?" />
          <SubTitleInternal color={theme.colors.pink900}>Contas de consumo:</SubTitleInternal>
          <SubTitle text="Ex: água, luz, gás, internet, etc." />
          <br />

          <SubTitleInternal color={theme.colors.blue900}>Requisitos:</SubTitleInternal>
          <Text>• Precisa ser o mesmo endereço do seu cadastro</Text>
          <Text>• Dos últimos 90 dias</Text>
          <Text>• Precisa estar aberto (se for impresso)</Text>
          <Text>• Tudo deve estar em seu nome ou de algum familiar que mora com você</Text>

          {isMobile && <TextLink text="Não tenho contas fixas" onClick={() => setIsShowModal(true)} />}

          <Button
            marginHorizontal={0}
            type="submit"
            text="Avançar"
            textLink={!isMobile && { text: 'Não tenho contas fixas', onClick: () => setIsShowModal(true) }}
            persistFloatingMode={false}
          />
        </Form>
      </Container>
    </TemplateFormIsa>
  )
}
