import React, { useContext } from 'react'
import { Container, Title, SubTitle, Selector, TextErrorInput, SubHeaderDesktop } from '@provi/provi-components'
import { TemplateContext } from '../../../components/template/context'
import { TemplateForm } from '../../../components/template-form'

import { useProfissionalSituationSelect } from './hooks'
import { WrapperError, WrapperTitles } from './style'
import { FormFormik } from './form'

export const ProfissionalSituation = () => {
  const { setIsShowModal, isMobile, goToNextScreen, setDataModal, creditPath } = useContext(TemplateContext)
  const [selectedOption, onSelectOption, isShowError, isButtonDisabled, options] = useProfissionalSituationSelect(
    goToNextScreen,
    setDataModal,
    creditPath
  )

  return (
    <TemplateForm>
      <Container>
        {isMobile ? (
          <Title text="Qual a sua situação profissional atualmente?" />
        ) : (
          <SubHeaderDesktop
            title="Qual a sua situação profissional atualmente?"
            handleModal={() => setIsShowModal(true)}
            isShowButtonHelpModal
          />
        )}
        <Selector
          isSearchable={false}
          placeholder="Escolher"
          options={options}
          value={selectedOption}
          onValueChange={onSelectOption}
        />
        {isShowError && (
          <WrapperError>
            <TextErrorInput>Por favor selecione o campo acima</TextErrorInput>
          </WrapperError>
        )}
        <WrapperTitles>
          <Title text="Quanto você ganha por mês?" />
          <SubTitle text="Considere: salário, renda extra, aluguéis, bolsas de estudos, pensão e etc." />
        </WrapperTitles>
        <FormFormik
          goToNextScreen={goToNextScreen}
          data={creditPath && creditPath.ReadProfessionalSituation && creditPath.ReadProfessionalSituation.incomeValue}
          isButtonDisabled={isButtonDisabled}
          selectedOption={selectedOption}
        />
        {isMobile && <div style={{ height: '24px' }} />}
      </Container>
    </TemplateForm>
  )
}
