import React from 'react'
import * as Yup from 'yup'
import moment from 'moment'
import { withFormik } from 'formik'
import { Button, InputMasks, TextErrorInput } from '@provi/provi-components'
import { Form } from '../../../components/form'
import { validateBirthDate, validateBirthDateMinor18 } from '../../../utils/validators'

const Formik = props => {
  const { handleChange, values, errors, handleBlur, handleSubmit } = props
  return (
    <Form onSubmit={handleSubmit}>
      <InputMasks
        cy="input-birth-date"
        inputProps={{ 'aria-label': 'birthDate' }}
        mask="99/99/9999"
        autoFocus={!values.birthDate}
        margin="normal"
        id="birthDate"
        type="tel"
        placeholder="Ex: 11/03/1992"
        onChange={handleChange('birthDate')}
        onBlur={handleBlur('birthDate')}
        error={!!errors.birthDate}
        value={values.birthDate}
      />
      {errors.birthDate && <TextErrorInput text={errors.birthDate} cy="error-birth-date" />}

      <Button
        marginHorizontal={0}
        type="submit"
        disabled={
          !validateBirthDate(values.birthDate) ||
          !validateBirthDateMinor18(values.birthDate) ||
          !moment(values.birthDate, 'DD/MM/YYYY', true).isValid()
        }
        text="Avançar"
        persistFloatingMode={false}
      />
    </Form>
  )
}

export const FormFormik = withFormik({
  validateOnBlur: true,
  enableReinitialize: true,
  validateOnChange: false,
  mapPropsToValues: props => ({ birthDate: !!props.data && props.data }),
  validationSchema: Yup.object().shape({
    birthDate: Yup.string()
      .test({
        name: 'birthDate',
        message: 'Data de nascimento inválida',
        test: birthDate => validateBirthDate(birthDate)
      })
      .test({
        name: 'birthDate 18 minor',
        message: 'Você precisa ser maior de 18 anos',
        test: birthDate => validateBirthDateMinor18(birthDate)
      })
      .test({
        name: 'birthDate required',
        message: 'Por favor, preencha sua data de nascimento',
        test: date => moment(date, 'DD/MM/YYYY', true).isValid()
      })
  }),
  handleSubmit: async ({ birthDate }, { props }) => {
    props.goToNextScreen(birthDate)
  }
})(Formik)
