import React, { useContext } from 'react'
import { Container, SubTitle, Button, Title, UploadBox } from '@provi/provi-components'
import { Form } from '../../../components/form'
import { TemplateContext } from '../../../components/template/context'
import { useISAContext } from '../../../components/template/ISA/context'
import { TemplateFormIsa } from '../../../components/template-isa'
import { useFrontSide } from './hooks'
import img_document from '../../../assets/svg/front-side-rg.svg'

const OPTIONS_DROPZONE = 'image/jpeg, image/png, application/pdf'

export const RGFrontSide = () => {
  const { setIsLoading, creditPath } = useContext(TemplateContext)
  const { goToNextScreen } = useISAContext(TemplateContext)
  const [
    onSubmit,
    isButtonDisabled,
    numberOfFiles,
    preview,
    setPreview,
    setFilesNotUpload,
    filesNotUpload,
    setNumberOfFiles,
    numberOfFilesInitial
  ] = useFrontSide(goToNextScreen, creditPath, setIsLoading)

  return (
    <TemplateFormIsa>
      <Container>
        <Form onSubmit={onSubmit}>
          <Title text="Envie a parte da frente do seu RG" />
          <SubTitle text="As informações devem estar legíveis" />
          <UploadBox
            preview={preview}
            setPreview={setPreview}
            setFilesNotUpload={setFilesNotUpload}
            filesNotUpload={filesNotUpload}
            setNumberOfFiles={setNumberOfFiles}
            numberOfFiles={numberOfFiles}
            numberOfFilesInitial={numberOfFilesInitial}
            image={img_document}
            options={OPTIONS_DROPZONE}
          />
          <Button marginHorizontal={0} type="submit" disabled={isButtonDisabled} text="Avançar" persistFloatingMode={false} />
        </Form>
      </Container>
    </TemplateFormIsa>
  )
}
