import axios from 'axios'
import { showToast } from '@provi/provi-components'
import * as Sentry from '@sentry/browser'
import { history } from '../navigation/history'
import { ROOT_URL, LOCALSTORAGE_TOKENS, EMBEDDED_BASE_URL } from '../constants'
import firebase from '../services/firebase'
import { getSlugPartner, checkURLisISA } from '../utils'
import { logger, setLogger } from '../utils/logger'

export const api = axios.create({
  baseURL: ROOT_URL.masterpath
})

const transactionId = Math.random()
  .toString(36)
  .substr(2, 9)

export const logout = (expired, isIsa = false) => {
  Sentry.configureScope(scope => scope.setUser(null))
  if (expired) {
    const AllStorage = Object.keys(localStorage)
    AllStorage.forEach(item => {
      if (item !== LOCALSTORAGE_TOKENS.dataUser) {
        localStorage.removeItem(item)
      }
    })
    const partner = getSlugPartner()
    if (isIsa) {
      history.push(`/isa/token-invalido/${partner}`)
    } else {
      history.push(`/token-invalido/${partner}`)
    }
  } else {
    localStorage.clear()
    window.location.href = 'http://provi.com.br'
  }
}

api.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token')
    if (token) {
      Sentry.configureScope(function(scope) {
        scope.setTag('x-request-id', transactionId)
        scope.addBreadcrumb({
          category: 'token',
          message: token,
          level: Sentry.Severity.Debug
        })
        Sentry.addBreadcrumb({
          category: 'typeRequest',
          message: `[${config.method}] ${config.url}`,
          level: Sentry.Severity.Info
        })
        Sentry.addBreadcrumb({
          category: 'bodyRequest',
          message: `${JSON.stringify(config.data, null, 2) || 'Não tem body request'}`,
          level: Sentry.Severity.Info
        })
        Sentry.setContext('Request', {
          body: JSON.stringify(config.data, null, 2),
          url: config.url,
          method: config.method
        })
      })
      const partner = getSlugPartner()
      config.headers.Authorization = token
      config.headers['x-request-id'] = transactionId
      if (['sanar', 'sanarup-financiamento'].includes(partner)) config.headers['partner'] = partner
    } else {
      const path = window.location.path
      const isISA = checkURLisISA(path)
      logout(true, isISA)
    }

    return config
  },
  error => {
    setLogger()
    Promise.reject(error)
  }
)

api.interceptors.response.use(
  response => response,
  error => {
    if (error && error.response && error.response.status === 401) {
      const path = window.location.path
      const isISA = checkURLisISA(path)

      logout(true, isISA)
      return false
    }
    setLogger()
    if (error && error.response && error.response.status === 500) {
      return Sentry.captureException(error)
    }
    return Promise.reject(error)
  }
)

export const getProfileData = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.get('/profile')
      resolve(data)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'errorRequest',
        message: 'ERROR INFO ' + err,
        level: Sentry.Severity.Warning
      })
      reject(err)
    }
  })
}

export const getCreditPath = crid => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.get(`/credit-paths/${crid}`)
      resolve(data)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'errorRequest',
        message: 'ERROR INFO ' + err,
        level: Sentry.Severity.Warning
      })
      reject(err)
    }
  })
}

export const getCreditPathPrevious = (crid, uniqueName) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.get(`/credit-paths/${crid}/screen/${uniqueName}`)
      resolve(data)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'errorRequest',
        message: 'ERROR INFO ' + err,
        level: Sentry.Severity.Warning
      })
      reject(err)
    }
  })
}

export const postCreditPath = (crid, data, currentScreen, includeOnDataProperty = true) => {
  return new Promise(async (resolve, reject) => {
    const body = {
      ...(includeOnDataProperty ? { data } : data),
      currentScreen
    }
    try {
      const response = await api.post(`/credit-paths/${crid}`, body)
      resolve(response)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'errorRequest',
        message: 'ERROR INFO ' + err,
        level: Sentry.Severity.Warning
      })
      reject(err)
    }
  })
}

export const uploadImages = async array => {
  let result = []
  await firebase.auth().signInAnonymously()
  const crid = localStorage.getItem(LOCALSTORAGE_TOKENS.crid)
  if (array.length) {
    try {
      for (const item of array) {
        const now = new Date().getTime()

        const storagePath = `/${crid}/${now}`
        const uploadRef = firebase.storage().ref(storagePath)
        await uploadRef.put(item)

        const img = {
          url: await uploadRef.getDownloadURL(),
          typeExtension: item.type
        }
        result.push(img)
      }
    } catch (err) {
      Sentry.captureException(err)

      showToast('Não foi possivel enviar uma de suas imagens')
    }
  }
  return result
}

export const sendSelfieToAnalyzeDoc = async (imageUrl, CreditRequestId, isMobile) => {
  return new Promise(async (resolve, reject) => {
    const body = {
      imageUrl,
      CreditRequestId,
      isMobile
    }
    try {
      const { data } = await api.post(`/credit-requests/send-document-info`, body)
      resolve(data)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'errorRequest',
        message: 'ERROR INFO ' + err,
        level: Sentry.Severity.Warning
      })
      reject(err)
    }
  })
}

export const createCrid = (partner, isISA = false) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await api.post(`/credit-requests/partners/${partner}`, { isISA })
      resolve(response)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'errorRequest',
        message: 'ERROR INFO ' + err,
        level: Sentry.Severity.Warning
      })
      reject(err)
    }
  })
}

export const addCourse = (crid, partnerSlug, courseId) => {
  const data = {
    creditRequestId: crid,
    partnerSlug: partnerSlug,
    courseId: courseId
  }
  return new Promise(async (resolve, reject) => {
    try {
      const response = await api.post(`/add-course`, data)
      resolve(response)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'errorRequest',
        message: 'ERROR INFO ' + err,
        level: Sentry.Severity.Warning
      })
      reject(err)
    }
  })
}

export const getUnicoProviderId = async (urls, isMobile) => {
  try {
    const CreditRequestId = localStorage.getItem(LOCALSTORAGE_TOKENS.crid)
    const imageUrl = urls?.[0]?.url

    const selfieAutoResponse = await sendSelfieToAnalyzeDoc(imageUrl, CreditRequestId, isMobile)

    return { error: selfieAutoResponse.error, ProviderId: selfieAutoResponse.data }
  } catch (err) {
    return { error: true, ProviderId: null, message: err }
  }
}

/**
 * @description Get documents analysis status as a pulling
 * @async
 * @function
 * @name documentAnalysisPulling
 * @param {number} CreditRequestId
 * @param {number} ProviderId
 * @returns {Promise<{ analyzed: boolean }>}
 */
export const documentAnalysisPulling = async (CreditRequestId, ProviderId) => {
  try {
    const body = {
      CreditRequestId,
      ProviderId
    }
    const { data: response } = await api.post(`/credit-requests/documents-analysis-pulling`, body)

    return response
  } catch (error) {
    Sentry.addBreadcrumb({
      category: 'errorRequest',
      message: 'ERROR INFO ' + error,
      level: Sentry.Severity.Warning
    })
  }
}

export const removeCourse = (crid, partnerSlug, courseId) => {
  const data = {
    creditRequestId: crid,
    partnerSlug: partnerSlug,
    courseId: courseId
  }
  return new Promise(async (resolve, reject) => {
    try {
      const response = await api.post(`/remove-course`, data)
      resolve(response)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'errorRequest',
        message: 'ERROR INFO ' + err,
        level: Sentry.Severity.Warning
      })
      reject(err)
    }
  })
}

export const getContract = async crid => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await api.get(`${EMBEDDED_BASE_URL}/v1/provi-sign/contract/${crid}`, {
        headers: { Authorization: localStorage.getItem('token') }
      })
      resolve(data)
    } catch (error) {
      Sentry.addBreadcrumb({
        category: 'errorRequest',
        message: 'ERROR INFO ' + error,
        level: Sentry.Severity.Warning
      })
      reject(error)
    }
  })
}

export const getStatusInstallment = async crid => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await api.get(`/credit-requests/${crid}/first-installment-paid`)
      resolve(data)
    } catch (error) {
      Sentry.addBreadcrumb({
        category: 'errorRequest',
        message: 'ERROR INFO ' + error,
        level: Sentry.Severity.Warning
      })
      reject(error)
    }
  })
}

export const checkIfGuarantorIsAnalyzed = async ({ CreditRequestId, cpf }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.post('/credit-requests/guarantor-analysis', {
        CreditRequestId,
        cpf
      })

      resolve(data)
    } catch (error) {
      Sentry.addBreadcrumb({
        category: 'errorRequest',
        message: 'ERROR INFO ' + error,
        level: Sentry.Severity.Warning
      })
      reject(error)
    }
  })
}

export const reesendPhone = async phone => {
  const crid = localStorage.getItem(LOCALSTORAGE_TOKENS.crid)
  const data = {
    phone,
    creditRequestId: crid
  }
  return new Promise(async (resolve, reject) => {
    try {
      const response = await api.post(`resend-token`, data)
      resolve(response)
    } catch (error) {
      Sentry.addBreadcrumb({
        category: 'errorRequest',
        message: 'ERROR INFO ' + error,
        level: Sentry.Severity.Warning
      })
      reject(error)
    }
  })
}

export const validationCrid = async crid => {
  const data = {
    creditRequestId: crid
  }
  return new Promise(async (resolve, reject) => {
    try {
      const response = await api.post(`/status-reader`, data)
      resolve(response)
    } catch (error) {
      Sentry.addBreadcrumb({
        category: 'errorRequest',
        message: 'ERROR INFO ' + error,
        level: Sentry.Severity.Warning
      })
      reject(error)
    }
  })
}

export const getCreditPathISA = crid => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.get(`/income-statement-agreement-paths/${crid}`)
      resolve(data)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'errorRequest',
        message: 'ERROR INFO ' + err,
        level: Sentry.Severity.Warning
      })
      reject(err)
    }
  })
}

export const postCreditPathISA = (creditRequestId, value) => {
  return new Promise(async (resolve, reject) => {
    try {
      const body = {
        data: value
      }
      const { data } = await api.post(`/income-statement-agreement-paths/${creditRequestId}`, body)
      resolve(data)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'errorRequest',
        message: 'ERROR INFO ' + err,
        level: Sentry.Severity.Warning
      })
      reject(err)
    }
  })
}

export const getCreditPathPreviousISA = (crid, uniqueName) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.get(`/income-statement-agreement-paths/${crid}/screen/${uniqueName}`)
      resolve(data)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'errorRequest',
        message: 'ERROR INFO ' + err,
        level: Sentry.Severity.Warning
      })
      reject(err)
    }
  })
}

export const validateContractSign = async ({ ContractRecipientId, ProviContractId, IP, device, phone, email }) => {
  return await api.post('/signature/signature-approval', {
    ContractRecipientId,
    ProviContractId,
    IP,
    device,
    phone,
    email
  })
}

export const fetchCurrentIP = async () => {
  try {
    return await axios.get('https://api.ipify.org/?format=json')
  } catch (error) {
    logger({ error })
    return null
  }
}

export const submitRecaptchaToken = async token => {
  try {
    const req = { token }
    const { data } = await api.post('/recaptcha', req)
    return data
  } catch (err) {
    Sentry.addBreadcrumb({
      category: 'errorRequest',
      message: 'ERROR INFO ' + err,
      level: Sentry.Severity.Warning
    })
    throw err
  }
}
