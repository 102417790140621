import styled from 'styled-components'
import { Button } from '@material-ui/core'
import { theme } from '@provi/provi-components'

export const ContainerOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${theme.variables.spacer * 2}px;
  margin-bottom: 38px;
  ${theme.breakpoints.down('md')} {
    justify-content: space-between;
  }
`

export const ContainerOption = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;
`

export const Option = styled.div`
  position: relative;
  width: 100%;
  border-radius: 20px;
  box-sizing: border-box;
  margin-top: 16px;
  padding-bottom: 24px;
  margin-right: 50px;
  box-shadow: 0px 0px 17px -5px rgba(0, 0, 0, 0.45);
  ${theme.breakpoints.down('md')} {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
`

export const Line = styled.div`
  height: 4px;
  width: ${({ width }) => width}%;
  background-color: ${theme.colors.blue600};
`

export const Text = styled.p`
  margin: 0;
  font-size: ${({ size }) => (size ? size : '16')}px;
  font-weight: ${({ bold }) => (bold ? 'bold' : '400')};
  line-height: 24px;
  ${({ bold }) => !bold && 'font-family: "CenturyGothic", sans-serif;'}
  color: ${({ color }) => (color ? color : theme.colors.blue900)};
  margin-left: ${theme.variables.spacer}px;
  box-sizing: border-box;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    line-height: 18px;
  }
  
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  ${theme.breakpoints.down('md')} {
    flex-direction: column;
    margin-top: 8px;
  }
`

export const Left = styled.div``

export const Right = styled.div`
  display: flex;
  align-items: center;
  border-left: 1px solid #000000;
  justify-content: center;
`

export const Main = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  padding: ${theme.variables.spacer * 2}px;
`

export const Header = styled.div`
  text-align: center;
  height: 33px;
  display: flex;
  justify-content: center;
  background-color: #efefef;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  align-items: center;
`

export const TextHeader = styled.p`
  margin: 0;
  color: #757272;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    line-height: 18px;
  }
`

export const BallStatus = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${props => props.color};
  margin-right: 8px;
`

export const ContainerButton = styled.div`
  width: 256px;
  text-align: center;
  margin-top: 20px;

  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`

export const WrapperButton = styled(Button)`
  float: none;
  border-radius: ${theme.variables.spacer * 4}px !important;
  background: ${theme.colors.pink900} !important;
  width: 122px;
  height: 28px;
  .MuiButton-label {
    color: white;
    font-weight: bold;
    text-transform: none;
    font-size: 12px;
    line-height: 18px;
    font-family: 'CenturyGothic', sans-serif;
  }
  .MuiButton-contained&:hover {
    background-color: #a50024 !important;
  }
`

export const ContainerButtonNewRequest = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 20px;
  margin-left: -5px;
`

export const WrapperButtonNewRequest = styled(Button)`
  float: none;
  border-radius: ${theme.variables.spacer * 4}px !important;
  background: white !important;
  width: 100%;
  height: 52px;

  .MuiButton-label {
    color: ${theme.colors.blue900};
    font-weight: bold;
    text-transform: none;
    font-size: 16px;
    line-height: 24px;
    font-family: 'CenturyGothic', sans-serif;
  }
`
