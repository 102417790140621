import { useState, useEffect } from 'react'
import { showToast } from '@provi/provi-components'

export const useLoanOptionSelection = (goToNextScreen, setDataModal, creditPath) => {
  const [selectedOption, setSelectedOption] = useState()
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [isShowModal, setIsShowModal] = useState(false)

  useEffect(() => {
    setDataModal({
      title: 'Dúvidas sobre as opções de pagamento ou valor das parcelas?',
      texts: [
        'Se quer entender o motivo da soma das parcelas ser maior que o valor do curso apesar da opção de financiamento ser sem juros, essa diferença acontece por conta do IOF (Imposto sobre Operações Financeiras) que é um imposto obrigatório e cobrado pelo governo sobre todas as operações de financiamento.',
        'Caso você não esteja visualizando opções de pagamentos mais longas (em um maior número de parcelas), provavelmente é porque essas opções possuem valores de parcela abaixo do valor mínimo da Provi (R$30,00 por parcela).'
      ]
    })
    if (creditPath && creditPath.ReadFixedInstallments && creditPath.ReadFixedInstallments.options.length === 0) {
      showToast(
        'aconteceu algum problema ao carregar as opções de pagamento. Entre em contato com a instituição parceira ou com a Provi'
      )
    }

    if (creditPath && creditPath.ReadFixedInstallments && creditPath.ReadFixedInstallments.choosedId) {
      const find =
        creditPath.ReadFixedInstallments &&
        creditPath.ReadFixedInstallments.options.find(
          item => item.partnerConditionId === creditPath.ReadFixedInstallments.choosedId
        )
      onSelectOption(find)
      setIsButtonDisabled(false)
    }
  }, [creditPath, setDataModal])

  const onSelectOption = option => {
    setSelectedOption(option)
    setIsButtonDisabled(false)
  }

  const onSubmit = e => {
    e.preventDefault()

    if (
      creditPath?.ReadFixedInstallments?.values &&
      creditPath?.ReadFixedInstallments?.showPaymentInformationModal &&
      creditPath?.partnerId !== 1344
    ) {
      return handleOpenModal(true)
    }

    goToNextScreen({ partnerConditionId: selectedOption && selectedOption.partnerConditionId })
  }

  const handleOpenModal = bool => {
    setIsShowModal(bool)
  }

  const handleModal = () => {
    goToNextScreen({ partnerConditionId: selectedOption && selectedOption.partnerConditionId })
  }

  return [selectedOption, onSelectOption, onSubmit, isButtonDisabled, isShowModal, handleOpenModal, handleModal]
}
