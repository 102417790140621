import React, { useContext } from 'react'
import { Button, LoadingProvi } from '@provi/provi-components'
import { TemplateContext } from '../../../components/template/context'
import { useISAContext } from '../../../components/template/ISA/context'
import RequiredDocumentsList from '../../../components/RequiredDocumentsList'
import {
  Container,
  Title,
  SubTitle,
  Wrapper,
  ContainerTitle,
  ContainerImages,
  ContainerTextMobile,
  TextMobile,
  Bullet
} from './style'
import { usePreDocument } from './hooks'

export const PreDocument = () => {
  const { isMobile, setDataModal, creditPath, setIsLoading, isLoading } = useContext(TemplateContext)
  const { goToNextScreen } = useISAContext()
  usePreDocument(setDataModal)
  const isAllowance = creditPath && creditPath.ReadPartnerIsUserReceivesMoney
  return (
    <>
      {isLoading && <LoadingProvi isLoadingDark />}
      <Container isMobile={isMobile}>
        <Wrapper>
          <ContainerTitle>
            <Title>Falta pouco!</Title>
          </ContainerTitle>

          <SubTitle>Para concluir a sua solicitação você vai precisar de:</SubTitle>

          {isAllowance ? (
            isMobile ? (
              <ContainerTextMobile>
                <TextMobile>
                  <Bullet>•</Bullet> RG/CNH
                </TextMobile>
                <TextMobile>
                  <Bullet>•</Bullet> Selfie
                </TextMobile>
                <TextMobile>
                  <Bullet>•</Bullet> Comprovante de residência
                </TextMobile>
                <TextMobile>
                  <Bullet>•</Bullet> Comprovante de matrícula
                </TextMobile>
                <TextMobile>
                  <Bullet>•</Bullet> Histórico acadêmico
                </TextMobile>
              </ContainerTextMobile>
            ) : (
              <ContainerImages>
                <RequiredDocumentsList />
              </ContainerImages>
            )
          ) : (
            <ContainerImages isMobile={isMobile}>
              <RequiredDocumentsList isMobile={isMobile} isISA={false} />
            </ContainerImages>
          )}

          <Button
            marginHorizontal={0}
            type="submit"
            text="Avançar"
            onClick={() => {
              setIsLoading(true)
              goToNextScreen()
            }}
          />
        </Wrapper>
      </Container>
    </>
  )
}
