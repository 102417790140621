import React from 'react'
import ReactDOM from 'react-dom'
import { AppContainer } from 'react-hot-loader'

import ErrorBoundary from './components/error-boundary'
import { ModalBlock } from './components/modal-block'
import { TemplateProvider } from './components/template/provider'
import { Navigation } from './navigation'
import { RecaptchaScriptLoader } from './components/recaptchaScriptLoader'
import { testLocalStorage } from './utils'

import './config'
import './style.css'
import '../node_modules/react-input-range/lib/css/index.css'

const render = () => {
  const isShowModalBlock = testLocalStorage()
  ReactDOM.render(
    <AppContainer>
      <ErrorBoundary>
        <TemplateProvider>
          {!isShowModalBlock && <ModalBlock />}
          <RecaptchaScriptLoader />
          <Navigation />
        </TemplateProvider>
      </ErrorBoundary>
    </AppContainer>,
    document.getElementById('provi-root')
  )
}

render()

// Hot reloading
if (module.hot) {
  // Reload components
  module.hot.accept('./navigation', () => {
    render()
  })
}
