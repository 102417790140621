import React, { useContext } from 'react'
import { Button, LoadingProvi } from '@provi/provi-components'

import { IconPreApprovedTitle } from '../../../assets/svg/icon-pre-approved-tittle'
import { TemplateContext } from '../../../components/template/context'
import RequiredDocumentsList from '../../../components/RequiredDocumentsList'
import {
  Container,
  Title,
  SubTitle,
  Text,
  Wrapper,
  ContainerTitle,
  ContainerImages,
  ContainerTextMobile,
  TextMobile,
  Bullet
} from './style'
import { usePreApproved } from './hooks'

export const PreApproved = ({ match }) => {
  const { isMobile, goToNextScreen, setDataModal, creditPath, setIsLoading, isLoading } = useContext(TemplateContext)
  usePreApproved(setDataModal)
  const isAllowance = creditPath && creditPath.ReadPartnerIsUserReceivesMoney
  const isSanarLoan = creditPath && creditPath.partnerId === 1344
  return (
    <>
      {isLoading && <LoadingProvi isLoadingDark />}
      <Container isMobile={isMobile}>
        <Wrapper>
          <ContainerTitle>
            <Title>Uau! Parabéns :)</Title>
            <IconPreApprovedTitle />
          </ContainerTitle>

          <SubTitle>Avaliamos sua solicitação e ela já está pré-aprovada!</SubTitle>
          <Text>Para garantir sua vaga e concluir sua solicitação agora, você precisa enviar:</Text>

          {isMobile ? (
            <ContainerTextMobile>
              <TextMobile>
                <Bullet>•</Bullet> RG/CNH
              </TextMobile>
              <TextMobile>
                <Bullet>•</Bullet> Selfie
              </TextMobile>
              {isAllowance && (
                <>
                  <TextMobile>
                    <Bullet>•</Bullet> Comprovante de matrícula
                  </TextMobile>
                  <TextMobile>
                    <Bullet>•</Bullet> Histórico acadêmico
                  </TextMobile>
                </>
              )}
            </ContainerTextMobile>
          ) : (
            <ContainerImages>
              <RequiredDocumentsList isAllowance={isAllowance} partnerSlug={match.params.partner} isSanarLoan={isSanarLoan} />
            </ContainerImages>
          )}

          <Button
            marginHorizontal={0}
            type="submit"
            text="Avançar"
            onClick={() => {
              setIsLoading(true)
              goToNextScreen()
            }}
          />
        </Wrapper>
      </Container>
    </>
  )
}
