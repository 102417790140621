import React from 'react'
import * as Yup from 'yup'
import { withFormik } from 'formik'
import { Button, InputMasks, TextErrorInput } from '@provi/provi-components'
import { Form } from '../../../components/form'

const validateIncomeValue = value => {
  let result
  if (value === '') {
    result = true
  } else {
    if (value.replace(/\D/g, '') === '') {
      result = true
    } else {
      result = false
    }
  }
  return result
}

const Formik = props => {
  const { handleChange, values, errors, touched, handleBlur, handleSubmit, isButtonDisabled } = props
  return (
    <Form onSubmit={handleSubmit}>
      <InputMasks
        mask="R$99999999"
        margin="normal"
        type="tel"
        placeholder="Ex: R$1.500"
        onChange={handleChange('incomeValue')}
        onBlur={handleBlur('incomeValue')}
        error={!!errors.incomeValue}
        value={values.incomeValue}
      />
      {errors.incomeValue && touched.incomeValue && <TextErrorInput text={errors.incomeValue} />}

      <Button
        marginHorizontal={0}
        type="submit"
        disabled={isButtonDisabled || validateIncomeValue(values.incomeValue)}
        text="Avançar"
        persistFloatingMode={false}
      />
    </Form>
  )
}

export const FormFormik = withFormik({
  validateOnBlur: true,
  validateOnChange: false,
  enableReinitialize: true,
  mapPropsToValues: props => ({ incomeValue: !!props.data ? JSON.stringify(props.data) : '' }),
  validationSchema: Yup.object().shape({
    incomeValue: Yup.string().required('Por favor, preencha o campo acima')
  }),
  handleSubmit: async ({ incomeValue }, { props }) => {
    const value = incomeValue.replace(/\D/g, '')
    const data = {
      professionalSituationOptionId: props.selectedOption.id,
      incomeValue: Number(value)
    }
    props.goToNextScreen(data)
  }
})(Formik)
