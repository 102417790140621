import React, { useContext } from 'react'
import { Container, Title, SubHeaderDesktop, Modal, Button } from '@provi/provi-components'
import { TemplateContext } from '../../components/template/context'
import { Template } from '../../components/template'
import { HeaderIntro } from '../../components/header-intro'
import { logout } from '../../services/api'
import { useCpf } from './hooks'
import { FormFormik } from './form'
import { RecaptchaDisclaimer } from '../../components/recaptchaDisclaimer'

export const Cpf = props => {
  const params = new URLSearchParams(props.history.location.search)
  const campaign = params.get('campanha')

  const { setIsShowModal, isMobile, setDataModal, setIsLoading, setCrid, setCreditPath } = useContext(TemplateContext)
  const [data, isShowModalLogout, setIsShowModalLogout, partnerName, recaptchaResponse, setRecaptchaResponse] = useCpf(
    setDataModal,
    setIsLoading,
    setIsShowModal,
    campaign
  )

  return (
    <Template goToPreviousScreen={() => setIsShowModalLogout(true)}>
      {isShowModalLogout && (
        <Modal
          onDismiss={() => setIsShowModalLogout(false)}
          title="Você deseja fazer logout?"
          footer={<Button marginHorizontal={0} text="Sim" onClick={() => logout()} />}
        />
      )}
      <HeaderIntro partnerName={partnerName} />
      <Container>
        {isMobile ? (
          <Title text="Qual o seu CPF?" marginVertical={12} />
        ) : (
          <SubHeaderDesktop title="Qual o seu CPF?" handleModal={() => setIsShowModal(true)} isShowButtonHelpModal />
        )}
        <FormFormik
          data={data}
          setCrid={setCrid}
          setIsLoading={setIsLoading}
          setDataModal={setDataModal}
          setCreditPath={setCreditPath}
          setIsShowModal={setIsShowModal}
          recaptchaResponse={recaptchaResponse}
          setRecaptchaResponse={setRecaptchaResponse}
          {...props}
        />
        <RecaptchaDisclaimer />
      </Container>
    </Template>
  )
}
