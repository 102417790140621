import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import messages from './messages'

export const useEligibilityResponse = () => {
  const [title, setTitle] = useState('')
  const [subtitle, setSubtitle] = useState('')
  const [showButton, setShowButton] = useState(false)
  const { reason } = useParams()

  useEffect(() => {
    localStorage.clear()

    const messageHandler = {
      'menor-de-idade': {
        title: messages.titles.cantPerform,
        subtitle: messages.subtitles.underAge,
        showButton: false
      },
      'situacao-irregular': {
        title: messages.titles.cantPerform,
        subtitle: messages.subtitles.unqualified,
        showButton: false
      },
      'pagamento-em-atraso': {
        title: messages.titles.latePayment,
        subtitle: messages.subtitles.latePaymentISA,
        showButton: true
      },
      'pagamentos-em-atraso': {
        title: messages.titles.latePaymentMulti,
        subtitle: messages.subtitles.latePayment,
        showButton: true
      }
    }

    setTitle(messageHandler[reason].title)
    setSubtitle(messageHandler[reason].subtitle)
    setShowButton(messageHandler[reason].showButton)
  }, [reason])

  return [title, subtitle, showButton]
}
