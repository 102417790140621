import React, { useContext } from 'react'
import { TemplateContext } from '../../../components/template/context'
import { TemplateForm } from '../../../components/template-form'
import { Button, Container, Modal, Title, SubHeaderDesktop, SubTitle } from '@provi/provi-components'
import { Form } from '../../../components/form'
import { IconCheck } from '../../../assets/svg/icon-check'
import { ContainerOption, Option, Text, Grid, TextContainer, TitleCard, WrapperIconCheck } from './styles'
import { useCourseConfirmation } from './hooks'

export const AllowanceSelection = () => {
  const { setIsShowModal, isMobile, goToNextScreen, setDataModal, creditPath, setIsLoading, numberOfCourses } = useContext(
    TemplateContext
  )
  const [isShowModalDontConfirm, setIsShowModalDontConfirm, postCourse, itemSelect, setItemSelect] = useCourseConfirmation(
    setDataModal,
    setIsLoading,
    goToNextScreen,
    creditPath,
    setIsShowModal
  )

  const pickedCourseId = itemSelect?.id

  const titleText =
    numberOfCourses > 1
      ? 'Selecione abaixo o produto certo para você'
      : 'Confira seu produto e avance para confirmar sua escolha'

  return (
    <TemplateForm>
      <Container isMarginDesktop>
        {isShowModalDontConfirm && (
          <Modal title="Algo errado com suas escolhas?" onDismiss={() => setIsShowModalDontConfirm(false)}>
            <Text>
              Caso alguma informação referente ao seu curso esteja incorreta, retorne ao site da sua escola, e selecione
              novamente os seus cursos.
            </Text>
            <Text>Ou, se preferir, clique no botão abaixo para falar com a gente, que vamos te ajudar a resolver.</Text>
          </Modal>
        )}

        <Form>
          {isMobile ? (
            <Title text={titleText} />
          ) : (
            <SubHeaderDesktop title={titleText} handleModal={() => setIsShowModal(true)} isShowButtonHelpModal />
          )}

          {creditPath?.partnerId === 1344 ? (
            <SubTitle text="Você está visualizando os produtos disponíveis para você." />
          ) : (
            <SubTitle text="Você só está visualizando o(s) produto(s) disponível(veis) de acordo com a sua data de formatura." />
          )}

          <ContainerOption>
            {creditPath &&
              creditPath.LiftProductSanar &&
              creditPath.LiftProductSanar.availableCourses &&
              creditPath.LiftProductSanar.availableCourses.length > 0 &&
              creditPath.LiftProductSanar.availableCourses.map((item, index) => {
                if (item.blocked) {
                  return null
                }

                return (
                  <Option
                    isFirst={index === 0}
                    key={item.id}
                    onClick={() => setItemSelect(item)}
                    itemSelect={item.id === pickedCourseId}
                  >
                    {item.name && (
                      <Grid>
                        <TextContainer>
                          <TitleCard>{item.name ? item.name : '-'}</TitleCard>
                        </TextContainer>
                      </Grid>
                    )}

                    {item.details &&
                      item.details.length > 0 &&
                      item.details.map(product => (
                        <Grid>
                          <TextContainer>
                            <WrapperIconCheck>
                              <IconCheck />
                            </WrapperIconCheck>
                            <Text itemSelect={item.id === pickedCourseId}>{product}</Text>
                          </TextContainer>
                        </Grid>
                      ))}
                  </Option>
                )
              })}
          </ContainerOption>
        </Form>
        {pickedCourseId && (
          <Button
            onClick={() => postCourse(pickedCourseId)}
            text="Avançar"
            marginHorizontal="auto"
            persistFloatingMode={false}
          />
        )}
        {isMobile && <div style={{ height: '24px' }} />}
      </Container>
    </TemplateForm>
  )
}
