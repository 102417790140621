import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import Background from './svg/Background.svg'

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: #fff;
  box-sizing: border-box;
  position: relative;
  justify-content: center;
  align-items: center;

  background: url(${Background});
  background-repeat: no-repeat;
  background-position: bottom right;

  ${theme.breakpoints.down('md')} {
    background-size: 100%;

    width: 100vw;
    height: 100vh;
  }
`

export const Screen = styled.div`
  width: 1280px;
  height: 720px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;

  ${theme.breakpoints.down('lg')} {
    width: 100%;
  }

  ${theme.breakpoints.down('md')} {
    align-items: center;
    justify-content: center;
  }
`

export const Wrapper404 = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  box-sizing: border-box;
  width: 650px;

  ${theme.breakpoints.down('lg')} {
    position: fixed;
  }

  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`

export const WrapperErrorText = styled.div`
  position: absolute;
  z-index: 2;
  top: 175px;
  left: 121px;

  ${theme.breakpoints.down('md')} {
    top: 50px;
    left: 0;
    padding: 0 20px;
  }
`

export const WrapperIcon = styled.div`
  margin-top: 40px;
`

export const BoldText = styled.div`
  margin-top: 40px;
  font-size: 20px;
  line-height: 30px;
  color: ${theme.colors.blue900};
  font-family: 'CenturyGothicBold';
`

export const Text = styled.div`
  font-size: 20px;
  line-height: 30px;
  color: ${theme.colors.pink900};
`
