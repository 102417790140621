import React, { useContext } from 'react'
import { Button } from '@provi/provi-components'
import { TemplateContext } from '../../../components/template/context'
import { Container, Title, SubTitle, Text, Wrapper } from './style'
import { useInvoice } from './hooks'

export const Invoice = () => {
  const { goToNextScreen, creditPath } = useContext(TemplateContext)
  useInvoice(goToNextScreen)
  return (
    <Container>
      <Wrapper>
        <Title cy="title">
          Tudo certo com o seu contrato!{' '}
          <span role="img" aria-label="thumbsup">
            👍
          </span>
        </Title>
        {creditPath && creditPath.ReadPartner && (
          <SubTitle cy="sub-title">
            Para garantir a sua vaga com o parceiro {creditPath.ReadPartner}, você só precisa pagar o primeiro boleto que estará
            disponível na próxima tela.
          </SubTitle>
        )}

        <Text cy="text">
          Para que a reserva seja garantida no momento do pagamento, é importante que essa primeira parcela seja paga com cartão
          de crédito.
        </Text>
        {creditPath && creditPath.ReadFirstInstallment && (
          <Button
            marginHorizontal={0}
            text="Garantir minha vaga"
            onClick={() => window.open(creditPath.ReadFirstInstallment, '_blank')}
            persistFloatingMode={false}
          />
        )}
      </Wrapper>
    </Container>
  )
}
