import React, { useContext } from 'react'
import { Container, Title, SubTitle } from '@provi/provi-components'
import { Form } from '../../../components/form'
import { TemplateContext } from '../../../components/template/context'
import { TemplateForm } from '../../../components/template-form'
import { usePersonalDocumentSelection } from './hooks'
import { OptionsList, Option, OptionText } from './style'

const OPTIONS = ['RG', 'CNH']

export const PersonalDocumentSelection = () => {
  const { goToNextScreen, creditPath } = useContext(TemplateContext)
  const [onSubmit, onSelectOption, selected] = usePersonalDocumentSelection(goToNextScreen, creditPath)

  return (
    <TemplateForm>
      <Container>
        <Form onSubmit={onSubmit}>
          <Title text="Escolha abaixo o documento que você quer enviar" />
          <SubTitle text="Dê preferência para a CNH, se tiver" />

          <OptionsList>
            {OPTIONS.map(option => (
              <Option isSelected={option === selected} key={option} onClick={() => onSelectOption(option)}>
                <OptionText bold isSelected={option === selected}>
                  {option}
                </OptionText>
              </Option>
            ))}
          </OptionsList>
        </Form>
      </Container>
    </TemplateForm>
  )
}
