export const useReviewRequest = ({ goToNextScreen, setIsLoading }) => {
  const handleClick = type => {
    let data =
      type == 'edit'
        ? { currentScreen: 'reviewRequest', action: 'edit' }
        : { currentScreen: 'reviewRequest', action: 'continue' }

    goToNextScreen(data)
  }

  return {
    handleClick
  }
}
