import React from 'react'
import * as S from './style'
import { currencyParser } from '../../../../../utils'

export const CourseContainer = ({ course, isMobile }) => {
  const { name, price } = course

  return (
    <S.Container isMobile={isMobile}>
      <S.CourseTitle>{name}</S.CourseTitle>
      <S.CourseSubTitle>R${currencyParser(price)}</S.CourseSubTitle>
    </S.Container>
  )
}
