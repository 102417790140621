import * as Sentry from '@sentry/browser'
import { api } from '../api'

export const getData = partner => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.get(`/credit-requests/partners/${partner}`)
      resolve(data)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'errorRequestGetCreditsRequests',
        message: 'ERROR INFO ' + err,
        level: Sentry.Severity.Warning
      })
      reject(err)
    }
  })
}
