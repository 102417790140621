import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import backgroundIsa from '../../assets/svg/background-isa.svg'

export const Container = styled.div`
  padding-left: 137px;
  padding-right: 22px;
  padding-bottom: 22px;

  height: 100vh;
  width: 100%;

  background-image: url(${backgroundIsa});
  background-repeat: no-repeat;
  background-position: right bottom;
  background-color: ${theme.colors.blue900};

  box-sizing: border-box;
  overflow: auto;
  display: flex;
  align-items: center;

  ${theme.breakpoints.down('md')} {
    display: block;
    padding-left: 22px;
    margin-left: 0;
    background-image: none;
  }

  ${theme.breakpoints.down('lg')} {
    background-size: 36vw;
  }
`

export const Title = styled.h4`
  font-family: 'CenturyGothic', sans-serif;
  font-size: 30px;
  line-height: 36px;
  font-weight: 700;
  margin: 10px 0px;

  ${theme.breakpoints.down('md')} {
    font-size: 20px;
    line-height: 26px;
  }

  color: #de0c4b;
`

export const Text = styled.p`
  font-family: 'CenturyGothic', sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.white100};
`

export const Wrapper = styled.div`
  width: 500px;

  display: flex;
  flex-direction: column;

  ${theme.breakpoints.down('md')} {
    width: 100%;
    margin-top: 90px;
  }
`

export const ContainerTitle = styled.div`
  display: flex;
`

export const WrapperButton = styled.div`
  margin-top: 10px;

  ${theme.breakpoints.down('md')} {
    position: absolute;
    display: flex;
    align-items: center;

    bottom: 30px;
    right: 0;
    left: 0;
  }
`
