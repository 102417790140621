import axios from 'axios'
import * as Sentry from '@sentry/browser'
import { ROOT_URL } from '../constants'
import { setLogger } from '../utils/logger'

const api = axios.create({
  baseURL: ROOT_URL.passwordless
})

api.interceptors.response.use(
  response => response,
  error => {
    setLogger()
    return Promise.reject(error)
  }
)

export const validateToken = token => {
  return new Promise(async (resolve, reject) => {
    try {
      api.defaults.headers.Authorization = token
      const response = await api.get('/auth')
      resolve(response)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'errorRequestAuth',
        message: 'ERROR INFO ' + err,
        level: Sentry.Severity.Warning
      })
      reject(err)
    }
  })
}

export const signUp = ({ email, partner, isISA = false }) => {
  let data = {
    partnerSlug: partner.toLowerCase(),
    email
  }

  return new Promise(async (resolve, reject) => {
    try {
      const response = await api.post('/sign-up', { ...data, isISA })
      resolve(response)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'errorRequestAuth',
        message: 'ERROR INFO ' + err,
        level: Sentry.Severity.Warning
      })
      reject(err)
    }
  })
}

export const getSlugInfo = async (slug, isISA = false) => {
  return new Promise(async (resolve, reject) => {
    try {
      api.defaults.headers.Authorization = localStorage.getItem('token')
      const response = await api.get(`/partner/${slug}/name?isIsa=${isISA}`)
      resolve(response)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'errorRequestAuth',
        message: 'ERROR INFO ' + err,
        level: Sentry.Severity.Warning
      })
      reject(err)
    }
  })
}
