import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const ItemCheck = styled.div`
  display: flex;
  margin: 15px 0 8px 0;
  width: auto;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
`

export const WrapperIcon = styled.div`
  width: 16px;
`

export const ExtraDataText = styled.p`
  font-size: 12px;
  line-height: 18px;
  margin: 0%;
  margin-left: 8px;
  color: ${theme.colors.blue900};
`

export const TextModal = styled.div`
  font-size: 16px;
  line-height: 24px;
  margin: 24px 0;
  color: ${theme.colors.blue900};

  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    line-height: 18px;
    margin-top: 20px;
  }
`
