import React from 'react'
import { ContainerHeader, Title, SubTitle, WrapperItens } from './style'

export const HeaderIntro = ({ partnerName }) => {
  return (
    <ContainerHeader>
      <WrapperItens>
        <Title>Provi | {partnerName}</Title>
        <SubTitle>Transformando carreiras</SubTitle>
      </WrapperItens>
    </ContainerHeader>
  )
}
