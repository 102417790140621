import React, { useContext } from 'react'
import { Button, Container, Title, SubHeaderDesktop } from '@provi/provi-components'
import { TemplateContext } from '../../../components/template/context'
import { Template } from '../../../components/template'
import { URL_EXTERNAL } from '../../../constants'
import { useCreditRequestError } from './hooks'

export const CreditRequestError = () => {
  const { isMobile, setDataModal, creditPath } = useContext(TemplateContext)
  useCreditRequestError(setDataModal)
  return (
    <Template>
      <Container>
        {isMobile ? (
          <Title
            text={`Não encontramos nenhum pedido do parceiro ${creditPath && creditPath.ReadPartner} vinculado ao seu CPF.`}
          />
        ) : (
          <SubHeaderDesktop
            title={`Não encontramos nenhum pedido do parceiro ${creditPath && creditPath.ReadPartner} vinculado ao seu CPF.`}
          />
        )}
        <p>Clique no botão abaixo para falar com a gente.</p>
        <Button
          marginHorizontal={0}
          type="button"
          text="Abrir chat"
          onClick={() => window.open(URL_EXTERNAL, '_blank')}
          persistFloatingMode={false}
        />
      </Container>
    </Template>
  )
}
