import React from 'react'
import { Button } from '@provi/provi-components'

import * as S from './styles'
import { useEligibilityResponse } from './hooks'

export const EligibilityResponse = () => {
  const [title, subtitle, showButton] = useEligibilityResponse()

  return (
    <S.Container>
      <S.Wrapper>
        <S.Title>{title}</S.Title>
        <S.Subtitle>{subtitle}</S.Subtitle>
      </S.Wrapper>

      {showButton && (
        <S.ButtonWrapper>
          <Button
            icon={() => null}
            text="Entre em contato"
            responsiveWidth
            onClick={() =>
              window
                .open(
                  'https://api.whatsapp.com/send?phone=5511957712414&text=Oi%2C%20Provi%21%20Pode%20me%20ajudar%20?',
                  '_blank'
                )
                .focus()
            }
          />
        </S.ButtonWrapper>
      )}
    </S.Container>
  )
}
