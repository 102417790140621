import { Intro } from '../screens/ISA/Intro'
import { Cpf } from '../screens/ISA/cpf'
import { FullName } from '../screens/ISA/full-name'
import { BirthDate } from '../screens/ISA/birth-date'
import { Address } from '../screens/ISA/address'
import { Phone } from '../screens/ISA/phone'
import { PhoneConfirmation } from '../screens/ISA/confirmation-phone'
import { PersonalSituation } from '../screens/ISA/personal-situation'
import { EnglishLevel } from '../screens/ISA/english-level'
import { CourseSelection } from '../screens/ISA/course-selection'
import { EventLevel } from '../screens/ISA/event-level'
import { CourseObjective } from '../screens/ISA/course-objective'
import { ExperienceLevel } from '../screens/ISA/experience-level'
import { CourseReason } from '../screens/ISA/course-reasons'
import { CourseTime } from '../screens/ISA/course-time'
import { CourseFinantial } from '../screens/ISA/course-finantial'
import { CourseAfter } from '../screens/ISA/course-after'
import { ProfissionalSituation } from '../screens/ISA/professional-situation'
import { PersonalSupport } from '../screens/ISA/personal-support'
import { PersonalSupportDecision } from '../screens/ISA/personal-support-decision'
import { DecisionMoney } from '../screens/ISA/decision-money'
import { DecisionMoney2 } from '../screens/ISA/decision-money-2'

import { StopFinancing } from '../screens/ISA/stop-financing'
import { ReasonsTrust } from '../screens/ISA/reasons-trust'
import { LinkedinProfile } from '../screens/ISA/linkedin-profile'

import { PreDocument } from '../screens/ISA/pre-document'
import { IntroSelfie } from '../screens/form/intro-selfie'
import { Selfie } from '../screens/ISA/selfie'
import { PersonalDocumentSelection } from '../screens/ISA/personal-document-selection'
import { RGBackSide } from '../screens/ISA/personal-document-rg-back-side'
import { RGFrontSide } from '../screens/ISA/personal-document-rg-front-side'
import { PersonalDocumentCnh } from '../screens/ISA/personal-document-cnh'
import { IntroProofOfAddress } from '../screens/ISA/intro-proof-of-address'
import { ProofOfAddress } from '../screens/ISA/proof-of-address'
import { SuccessPage } from '../screens/ISA/final'
import { Analysis } from '../screens/ISA/analysis'
import { DocumentWithoutFace } from '../screens/ISA/document-without-face'
import { RG } from '../screens/ISA/rg'
import { EligibilityResponse } from '../screens/ISA/eligibility-response'

import { ValidateToken } from '../screens/validate-token'

export const ISAroutes = [
  {
    path: '/isa/elegibilidade/:reason',
    component: EligibilityResponse,
    private: false,
    form: false
  },
  {
    path: '/token/:partner/:token/isa',
    component: ValidateToken,
    private: false,
    form: false
  },
  {
    path: '/token/:partner/:token/:crid/isa',
    component: ValidateToken,
    private: false,
    form: false
  },
  {
    path: '/isa/intro/:partner',
    component: Intro,
    private: false,
    form: false
  },
  {
    path: '/isa/cpf/:partner',
    component: Cpf,
    private: false,
    form: false
  },
  {
    path: '/:crid/isa/nome-completo/:partner',
    component: FullName,
    private: false,
    form: false,
    uniqueName: 'isaFullName'
  },
  {
    path: '/:crid/isa/data-nascimento/:partner',
    component: BirthDate,
    private: false,
    form: false,
    uniqueName: 'isaBirthDate'
  },
  {
    path: '/:crid/isa/endereco/:partner',
    component: Address,
    private: false,
    form: false,
    uniqueName: 'isaAddress'
  },
  {
    path: '/:crid/isa/telefone/:partner',
    component: Phone,
    private: false,
    form: false,
    uniqueName: 'isaPhone'
  },
  {
    path: '/:crid/isa/verificacao-telefone/:partner',
    component: PhoneConfirmation,
    private: false,
    form: false,
    uniqueName: 'isaPhoneVerification'
  },
  {
    path: '/:crid/isa/situacao-pessoal/:partner',
    component: PersonalSituation,
    private: false,
    form: false,
    uniqueName: 'isaPersonalSituation'
  },
  {
    path: '/:crid/isa/nivel-ingles/:partner',
    component: EnglishLevel,
    private: false,
    form: false,
    uniqueName: 'isaEnglishLevel'
  },
  {
    path: '/:crid/isa/selecao-curso/:partner',
    component: CourseSelection,
    private: false,
    form: false,
    uniqueName: 'isaProductSelection'
  },
  {
    path: '/:crid/isa/eventos/:partner',
    component: EventLevel,
    private: false,
    form: false,
    uniqueName: 'isaParticipationInEvents'
  },
  {
    path: '/:crid/isa/objetivo/:partner',
    component: CourseObjective,
    private: false,
    form: false,
    uniqueName: 'isaCourseObjective'
  },
  {
    path: '/:crid/isa/experiencia/:partner',
    component: ExperienceLevel,
    private: false,
    form: false,
    uniqueName: 'isaTimeOfExperience'
  },
  {
    path: '/:crid/isa/motivos/:partner',
    component: CourseReason,
    private: false,
    form: false,
    uniqueName: 'isaReasonsToChooseProduct'
  },
  {
    path: '/:crid/isa/gerenciar-tempo/:partner',
    component: CourseTime,
    private: false,
    form: false,
    uniqueName: 'isaExpectedWaysToManageTime'
  },
  {
    path: '/:crid/isa/manter-financeiramente/:partner',
    component: CourseFinantial,
    private: false,
    form: false,
    uniqueName: 'isaExpectedWaysToStayFinancially'
  },
  {
    path: '/:crid/isa/pos-curso/:partner',
    component: CourseAfter,
    private: false,
    form: false,
    uniqueName: 'isaAfterGraduationIntent'
  },
  {
    path: '/:crid/isa/situacao-profissional/:partner',
    component: ProfissionalSituation,
    private: false,
    form: false,
    uniqueName: 'isaProfessionalSituation'
  },
  {
    path: '/:crid/isa/suporte-pessoal/:partner',
    component: PersonalSupport,
    private: false,
    form: false,
    uniqueName: 'isaPersonalSupportNetwork'
  },
  {
    path: '/:crid/isa/suporte-pessoal-decisao/:partner',
    component: PersonalSupportDecision,
    private: false,
    form: false,
    uniqueName: 'isaPersonalNetworkParticipation'
  },
  {
    path: '/:crid/isa/dividir/:partner',
    component: DecisionMoney,
    private: false,
    form: false,
    uniqueName: 'isaShareMoneyWithSomeone'
  },
  {
    path: '/:crid/isa/dividir-contrario/:partner',
    component: DecisionMoney2,
    private: false,
    form: false,
    uniqueName: 'isaMinimalPartOfADivisionWithSomeone'
  },
  {
    path: '/:crid/isa/parar-financiar/:partner',
    component: StopFinancing,
    private: false,
    form: false,
    uniqueName: 'isaExpectedEventToStopReportingIncome'
  },
  {
    path: '/:crid/isa/razoes-confiar/:partner',
    component: ReasonsTrust,
    private: false,
    form: false,
    uniqueName: 'isaReasonsToTrustYouAndWhy'
  },
  {
    path: '/:crid/isa/linkedin/:partner',
    component: LinkedinProfile,
    private: false,
    form: false,
    uniqueName: 'isaLinkedInProfile'
  },
  {
    path: '/:crid/isa/falta-pouco/:partner',
    component: PreDocument,
    private: false,
    form: false,
    uniqueName: 'isaDocumentsIntro'
  },
  {
    path: '/:crid/isa/selfie/:partner',
    component: Selfie,
    private: false,
    form: false,
    uniqueName: 'isaSelfie'
  },
  {
    path: '/:crid/isa/selecao-documento/:partner',
    component: PersonalDocumentSelection,
    private: false,
    form: false,
    uniqueName: 'isaPersonalDocumentSelection'
  },
  {
    path: '/:crid/isa/rg-frente/:partner',
    component: RGFrontSide,
    private: false,
    form: false,
    uniqueName: 'isaRGFrontSide'
  },
  {
    path: '/:crid/isa/rg-costas/:partner',
    component: RGBackSide,
    private: false,
    form: false,
    uniqueName: 'isaRGBackSide'
  },
  {
    path: '/:crid/isa/cnh/:partner',
    component: PersonalDocumentCnh,
    private: false,
    form: false,
    uniqueName: 'isaCNHFrontSide'
  },
  {
    path: '/:crid/isa/endereco-intro/:partner',
    component: IntroProofOfAddress,
    private: false,
    form: false,
    uniqueName: 'isaIntroProofOfAddress'
  },
  {
    path: '/:crid/isa/comprovante-endereco/:partner',
    component: ProofOfAddress,
    private: false,
    form: false,
    uniqueName: 'isaProofOfAddress'
  },
  {
    path: '/:crid/isa/final/:partner',
    component: SuccessPage,
    private: false,
    form: false,
    uniqueName: 'isaSurvey'
  },
  {
    path: '/:crid/isa/analise/:partner',
    component: Analysis,
    private: false,
    form: false,
    uniqueName: 'isaCreditAnalysis'
  },
  {
    path: '/:crid/isa/introducao-selfie/:partner',
    component: IntroSelfie,
    private: false,
    form: false,
    uniqueName: 'isaSelfieIntro'
  },
  {
    path: '/:crid/isa/documento-ilegivel/:partner',
    component: DocumentWithoutFace,
    private: true,
    form: false,
    uniqueName: 'isaDocumentWithoutFace'
  },
  {
    path: '/:crid/isa/rg/:partner',
    private: true,
    component: RG,
    form: true,
    uniqueName: 'rg'
  }
]
