import { useEffect, useState } from 'react'
import { showToast, theme } from '@provi/provi-components'
import { postData, getData } from '../../services/cpf'
import { getSlugInfo } from '../../services/auth'
import { getSlugPartner, processFullName, processBirthDate } from '../../utils'
import { REDIRECT_URLS, URL_EXTERNAL, LOCALSTORAGE_TOKENS } from '../../constants'

export const useCpfValidator = data => {
  const [isShowPersonalInfo, setIsShowPersonalInfo] = useState(false)
  const [validateOnChange, setValidateOnChange] = useState(false)
  const [formState, setFormState] = useState(0)

  useEffect(() => {
    if (data && data.cpf) {
      setFormState(1)
      setValidateOnChange(false)
      setIsShowPersonalInfo(true)
    }
  }, [data])

  const getCpf = async body => {
    const personalInfo = await postData(body)
    if (!personalInfo.data) return

    const fullName = processFullName(personalInfo.data.basicPersonalInfo.fullName) || ''
    const birthDate = processBirthDate(personalInfo.data.basicPersonalInfo.birthDate) || ''
    const numberOfCPFs = personalInfo.data.basicPersonalInfo.numberOfCPFs || null

    return {
      fullName,
      birthDate,
      numberOfCPFs
    }
  }

  return [isShowPersonalInfo, setIsShowPersonalInfo, validateOnChange, setValidateOnChange, getCpf, formState, setFormState]
}

export const resetDataModal = (setDataModal, setIsShowModal) => {
  setDataModal({
    title: 'Por que precisamos do seu CPF?',
    texts: [
      'Precisamos do seu CPF para fazer uma análise mais completa e te oferecer as melhores opções de financiamento.',
      'Não se preocupe! O seu histórico de crédito não é decisivo para nossa análise.',
      'Se você está recebendo a mensagem de que seu CPF já está cadastrado em outra conta e você não lembra como logou aqui na Provi, entre em contato com a gente.'
    ],
    textButton: 'Entendi',
    buttonFunction: () => setIsShowModal(false),
    textLink: {
      color: theme.colors.blue900,
      text: 'Abrir chat',
      onClick: () => window.open(URL_EXTERNAL, '_blank')
    }
  })
}

export const useCpf = (setDataModal, setIsLoading, setIsShowModal, campaign) => {
  const [data, setData] = useState()
  const [partnerName, setPartnerName] = useState(null)
  const [isShowModalLogout, setIsShowModalLogout] = useState(false)
  const [recaptchaResponse, setRecaptchaResponse] = useState({})

  useEffect(() => {
    resetDataModal(setDataModal, setIsShowModal)

    const get = async () => {
      setIsLoading(true)
      const partner = getSlugPartner()

      if (['sanar'].includes(partner)) {
        const token = localStorage.getItem(LOCALSTORAGE_TOKENS.token)

        return (window.location.href = `${REDIRECT_URLS.newSdk}/token/sanar/${token}`)
      }

      if (partner === 'metodo-dna-lucrativo' && !campaign) {
        window.location.href = '/cpf/metodo-dna-lucrativo?campanha=metodo-dna-lucrativo'
      }

      try {
        const path = window.location.pathname
        const isISA = path.includes('/isa/')

        let basicPersonalInfo = await getData()
        const response = await getSlugInfo(partner, isISA)

        setPartnerName(response.data.data.name)
        basicPersonalInfo.fullName = processFullName(basicPersonalInfo.fullName)
        setData(basicPersonalInfo)
      } catch (err) {
        showToast('Erro, por favor tente mais tarde')
      } finally {
        setIsLoading(false)
      }
    }
    get()
  }, [setIsLoading, setDataModal, setIsShowModal, campaign])

  return [data, isShowModalLogout, setIsShowModalLogout, partnerName, recaptchaResponse, setRecaptchaResponse]
}

export const parseInitialValues = data => ({
  cpf: data && data.cpf ? data.cpf : '',
  fullName: data && data.fullName ? data.fullName : '',
  birthDate: data && data.birthDate ? data.birthDate : '',
  phone: data?.phone || ''
})

export const parsePropsToValues = data => ({
  cpf: data && data.cpf ? data.cpf : '',
  city: data && data.fullName ? data.fullName : '',
  state: data && data.birthDate ? data.birthDate : ''
})
