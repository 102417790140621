import { useState, useEffect } from 'react'
import adBlocker from 'just-detect-adblock'
import { showToast } from '@provi/provi-components'
import smartlook from 'smartlook-client'
import { getData } from '../../services/select-crid'
import { getCreditPath, createCrid, validationCrid } from '../../services/api'
import { getSlugInfo } from '../../services/auth'
import { LOCALSTORAGE_TOKENS, REDIRECT_URLS } from '../../constants'
import { history } from '../../navigation/history'
import { getSlugPartner, getPathname, getTranslatedProductType, navigateWithParams } from '../../utils'
import { logger } from '../../utils/logger'

export const setCridLocal = async (crid, setCrid, setIsLoading, setCreditPath) => {
  const partner = getSlugPartner()

  setCrid(crid.id)
  localStorage.setItem(LOCALSTORAGE_TOKENS.crid, crid.id)
  if (crid.situation === 'active') {
    const token = localStorage.getItem(LOCALSTORAGE_TOKENS.token)
    window.location.href = `${REDIRECT_URLS.dashboard}/token/${token}/${crid.id}`
    return false
  }
  const dataUserStorage = localStorage.getItem(LOCALSTORAGE_TOKENS.dataUser)
  const dataUser = JSON.parse(dataUserStorage)
  if (!adBlocker.isDetected()) {
    if (dataUser) {
      try {
        window.smartlook &&
          smartlook.identify(dataUser.userId || 9999, {
            email: (dataUser && dataUser.email) || 'provi.com.br',
            partner: (dataUser && dataUser.partnerSlug) || 'provi',
            crid: crid.id || 99999
          })
      } catch (err) {
        logger(err)
      }
    } else {
      try {
        window.smartlook && smartlook.identify(crid.id || 99999)
      } catch (err) {
        logger(err)
      }
    }
  }

  try {
    setIsLoading(true)
    await validationCrid(crid.id)
    const response = await getCreditPath(crid.id)
    setCreditPath(response)
    const pathname = getPathname(response.progress.currentScreenUniqueName)
    const productType = getTranslatedProductType(response.productType)
    setIsLoading(false)

    navigateWithParams(`/formulario/${crid.id}/${productType}/${pathname}/${getSlugPartner()}`)
  } catch (err) {
    logger(err)
    setIsLoading(false)
    const isIsaFlow = err?.response?.data?.error?.message === 'Você não pode iniciar um fluxo do tipo ISA.'

    if (isIsaFlow) {
      const token = localStorage.getItem(LOCALSTORAGE_TOKENS.token)

      const isaUrl = `/token/${partner}/${token}/isa`

      return history.push(isaUrl)
    }

    if (err.response) {
      showToast(err.response.data.error.message)
      return false
    }
    showToast()
  }
}

export const formCreateCrid = async (setIsLoading, setCrid, setCreditPath) => {
  setIsLoading(true)
  const partner = getSlugPartner()
  try {
    const { data } = await createCrid(partner)
    setCrid(data.id)
    await setCridLocal(data, setCrid, setIsLoading, setCreditPath)
  } catch (err) {
    if (err.response) {
      showToast(err.response.data.error.message)

      if (err.response.data.error.message === 'Por favor, informe o seu CPF para seguir com um financiamento') {
        navigateWithParams(`/cpf/${partner}`)
      }
      return false
    }
    showToast()
  }
}

export const useCridSelection = (setIsLoading, setDataModal, setCrid, setCreditPath) => {
  const [partnerName, setPartnerName] = useState(null)
  const [canCreate, setCanCreate] = useState(false)
  const [data, setData] = useState([])
  const partner = getSlugPartner()

  useEffect(() => {
    setDataModal(null)
    const get = async () => {
      setIsLoading(true)
      try {
        const path = window.location.pathname
        const isISA = path.includes('/isa/')
        const { data, canCreate, canCreateNewCrids, partnerName } = await getData(partner)
        const response = await getSlugInfo(partner, isISA)

        setPartnerName(response.data.data.name)
        if (!canCreateNewCrids && !data.length) {
          history.push(`/bloqueado/${partner}`, { partnerName })
          return false
        }
        setData(data)
        setCanCreate(canCreate)
        if (canCreate && data && data.length === 0) {
          formCreateCrid(setIsLoading, setCrid, setCreditPath)
        }
      } catch (err) {
        setIsLoading(false)
        if (err.response) {
          showToast(err.response.data.error.message)
          return false
        }
        showToast()
      } finally {
        setIsLoading(false)
      }
    }
    if (partner) {
      get()
    }
  }, [partner, setIsLoading, setDataModal, setCreditPath])

  const verifyTextHeader = value => {
    const STATUSES = {
      in_progress: {
        color: '#DE0C4B',
        text: 'Solicitação em andamento'
      },
      in_analysis: {
        color: '#5CB5FE',
        text: 'Solicitação em análise'
      },
      active: {
        color: '#89D747',
        text: 'Financiamento ativo'
      },
      denied: {
        color: '#ffc107',
        text: 'Solicitação negada'
      },
      expired: {
        color: '#8e8e8e',
        text: 'Solicitação expirada'
      }
    }
    return STATUSES[value]
  }

  return [data, canCreate, verifyTextHeader, formCreateCrid, partnerName]
}
