import React from 'react'
import * as Yup from 'yup'
import { withFormik } from 'formik'
import { Input, TextErrorInput, Button } from '@provi/provi-components'
import { Form } from '../../../components/form'

export const Formik = props => {
  const { handleChange, values, errors, touched, handleBlur, handleSubmit } = props

  return (
    <Form onSubmit={handleSubmit}>
      <Input
        autoFocus
        margin="normal"
        id="name"
        placeholder="Escreva aqui"
        onChange={handleChange('courseTime')}
        onBlur={handleBlur('courseTime')}
        error={!!errors.courseTime}
        value={values.courseTime}
      />
      {errors.courseTime && touched.courseTime && <TextErrorInput text={errors.courseTime} />}

      <Button
        marginHorizontal={0}
        type="submit"
        disabled={errors.courseTime || !values.courseTime}
        text="Avançar"
        persistFloatingMode={false}
      />
    </Form>
  )
}

export const FormFomik = withFormik({
  validateOnBlur: false,
  validateOnChange: true,
  enableReinitialize: true,
  mapPropsToValues: props => ({ courseTime: props.data || '' }),
  validationSchema: Yup.object().shape({
    courseTime: Yup.string()
      .min(5, 'A resposta deve conter no mínimo 5 caracteres')
      .max(2048, 'A resposta deve conter no máximo 2048 caracteres')
      .required('Digite um texto válido')
  }),
  handleSubmit: async ({ courseTime }, { setSubmitting, props }) => {
    props.goToNextScreen(courseTime)
    setSubmitting(false)
  }
})(Formik)
