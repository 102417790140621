import { useState, useEffect } from 'react'

export const useProfissionalSituationSelect = (goToNextScreen, setDataModal, creditPath) => {
  const [selectedOption, setSelectedOption] = useState()
  const [isShowError, setIsShowError] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [options, setOptions] = useState({})

  const refactingArray = array =>
    array.map(item => ({
      id: item.id,
      label: item.data
    }))

  useEffect(() => {
    setDataModal({
      title: 'Por que precisamos saber a sua renda mensal?',
      texts: [
        'Precisamos saber a sua renda mensal para não te prejudicarmos financeiramente. Com base nela montaremos a melhor proposta de financiamento para você.',
        'Não se preocupe se não tiver renda ou se sua renda for baixa, isso não é um impeditivo para o financiamento!',
        'Fique tranquilo, as suas informações estão seguras.'
      ]
    })

    if (creditPath && creditPath.ReadProfessionalSituation) {
      const arrayProfessionalSituation = refactingArray(creditPath.ReadProfessionalSituation.options)
      setOptions(arrayProfessionalSituation)

      let professionalSituationId
      if (creditPath.ReadProfessionalSituation.choosedId) {
        professionalSituationId = arrayProfessionalSituation.find(
          item => item.id === creditPath.ReadProfessionalSituation.choosedId
        )
      }
      onSelectOption(professionalSituationId)
    }
  }, [setDataModal, creditPath])

  const onSelectOption = option => {
    setIsShowError(false)
    setSelectedOption(option)
    if (option) {
      setIsButtonDisabled(false)
    }
  }

  return [selectedOption, onSelectOption, isShowError, isButtonDisabled, options]
}
