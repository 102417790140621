import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const WrapperError = styled.div``

export const Wrapper = styled.div`
  margin-top: 32px;
  margin-bottom: 15px;
`

export const Title = styled.h4`
  font-family: 'CenturyGothicBold', sans serif;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  text-align: left;
  color: ${theme.colors.blue800};
  margin: 0;
  margin-bottom: 30px;

  ${theme.breakpoints.down('md')} {
    font-size: 20px;
    line-height: 24px;
  }
`
