import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const SubTitle = styled.h3`
  font-family: 'CenturyGothicBold', 'sans serif';
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: ${theme.colors.blue900};
  margin-top: 24px;
  ${theme.breakpoints.down('md')} {
    font-size: 20px;
    line-height: 24px;
  }
`
