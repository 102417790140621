import React, { useContext } from 'react'
import { Container, Button, Title, SubHeaderDesktop, UploadBox } from '@provi/provi-components'
import { Form } from '../../../components/form'
import { TemplateContext } from '../../../components/template/context'
import { TemplateForm } from '../../../components/template-form'
import { useProofOfEnrollment } from './hooks'
import img_proof_enrollment from '../../../assets/svg/img_proof_enrollment.svg'

const OPTIONS_DROPZONE = 'image/jpeg, image/png, application/pdf'

export const ProofOfEnrollment = () => {
  const { setIsShowModal, isMobile, goToNextScreen, setDataModal, setIsLoading, creditPath } = useContext(TemplateContext)
  const [
    onSubmit,
    isButtonDisabled,
    numberOfFiles,
    preview,
    setPreview,
    setFilesNotUpload,
    filesNotUpload,
    setNumberOfFiles,
    numberOfFilesInitial,
    title
  ] = useProofOfEnrollment(goToNextScreen, setDataModal, creditPath, setIsLoading, setIsShowModal)

  return (
    <TemplateForm>
      <Container>
        <Form onSubmit={onSubmit}>
          {isMobile ? (
            <Title text={title} />
          ) : (
            <SubHeaderDesktop title={title} handleModal={() => setIsShowModal(true)} isShowButtonHelpModal />
          )}
          <UploadBox
            preview={preview}
            setPreview={setPreview}
            setFilesNotUpload={setFilesNotUpload}
            filesNotUpload={filesNotUpload}
            setNumberOfFiles={setNumberOfFiles}
            numberOfFiles={numberOfFiles}
            image={img_proof_enrollment}
            numberOfFilesInitial={numberOfFilesInitial}
            options={OPTIONS_DROPZONE}
          />

          <Button marginHorizontal={0} type="submit" disabled={isButtonDisabled} text="Avançar" persistFloatingMode={false} />
        </Form>
      </Container>
    </TemplateForm>
  )
}
