import smartlook from 'smartlook-client'
import { createBrowserHistory } from 'history'
import { trackPageViewAndUserInfo } from '../components/google-analytics'
import { JSONPARSER, setLogger } from '../utils/logger'
import { LOCALSTORAGE_TOKENS } from '../constants'

export const history = createBrowserHistory()

history.listen(() => {
  const dataUser = JSONPARSER(localStorage.getItem(LOCALSTORAGE_TOKENS.dataUser))
  if (dataUser?.userId) {
    window.smartlook &&
      smartlook.identify(dataUser.userId, {
        email: dataUser && dataUser.email,
        partner: dataUser && dataUser.partnerSlug,
        crid: localStorage.getItem(LOCALSTORAGE_TOKENS.crid)
      })
  }
  setLogger()
  trackPageViewAndUserInfo()
})
