import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const ContainerErrorInput = styled.div`
  margin-top: 16px;
`

export const TextModal = styled.div`
  font-size: 16px;
  line-height: 24px;
  margin: 24px 0;
  color: ${theme.colors.blue900};

  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    line-height: 18px;
    margin-top: 20px;
  }
`
