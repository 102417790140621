import { useState, useEffect, useCallback } from 'react'

export const useIncomeSource = (goToNextScreen, creditPath, setDataModal) => {
  const [value, setValue] = useState()
  const [selectedOption, setSelectedOption] = useState()
  const [isShowErrorText, setIsShowErrorText] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)

  const onSelectOption = option => {
    if (selectedOption && option.id === selectedOption.id) {
      return
    }
    setSelectedOption(option)
    setValue('')
    if (!option.requiresExplanation) {
      setIsShowErrorText(false)
      setIsButtonDisabled(false)
    } else {
      setIsButtonDisabled(true)
    }
  }

  useEffect(() => {
    setDataModal(false)
    if (creditPath && creditPath.ReadIncomeSource && creditPath.ReadIncomeSource.choosedId) {
      const find =
        creditPath.ReadIncomeSource &&
        creditPath.ReadIncomeSource.options.find(item => item.id === creditPath.ReadIncomeSource.choosedId)
      if (find) {
        onSelectOption(find)
        setIsButtonDisabled(false)
        setIsShowErrorText(false)
        find.requiresExplanation && setValue(creditPath.ReadIncomeSource.answer)
      }
    }
  }, [])

  const onSubmit = e => {
    e.preventDefault()
    const data = {
      incomeSourceOptionId: selectedOption.id,
      explanation: value
    }
    if (selectedOption) {
      goToNextScreen(data)
      setIsShowErrorText(false)
    } else {
      setIsShowErrorText(true)
    }
  }

  const onValueChange = which => {
    const text = which.target.value
    setValue(text)
    if (text.length >= 4) {
      setIsButtonDisabled(false)
      setIsShowErrorText(false)
    } else {
      setIsShowErrorText(true)
    }
  }

  return [selectedOption, onSelectOption, onSubmit, onValueChange, value, isShowErrorText, isButtonDisabled]
}
