import React, { useContext } from 'react'
import { Container, Button, Title, Modal } from '@provi/provi-components'
import { Form } from '../../../components/form'
import { TemplateContext } from '../../../components/template/context'
import { TemplateForm } from '../../../components/template-form'
import { OptionsList, Option, OptionText, OptionDay, TextModal, SubTitle, Bold } from './style'
import { usePaymentDateSelect } from './hooks'

export const PaymentDateSelect = () => {
  const { isMobile, goToNextScreen, creditPath } = useContext(TemplateContext)
  const [
    paymentDate,
    onSelectOption,
    onSubmit,
    isButtonDisabled,
    DAY_OPTIONS,
    isShowModal,
    setIsShowModal,
    onSubmitFormFull,
    data
  ] = usePaymentDateSelect(goToNextScreen, creditPath)

  return (
    <TemplateForm>
      {isShowModal && (
        <Modal
          title="Você está avançando de etapa!"
          onDismiss={() => setIsShowModal(false)}
          footer={<Button marginHorizontal={0} type="button" text="Avançar" onClick={() => onSubmitFormFull()} />}
        >
          <TextModal>Lembre-se: ao avançar você não poderá mais alterar as informações que você inseriu até agora.</TextModal>
        </Modal>
      )}
      <Container>
        <Form onSubmit={onSubmit}>
          <Title text="Qual dia você prefere pagar as parcelas?" />
          {data && data.daysToUpfrontPayment && (
            <SubTitle>
              Lembre-se que a{' '}
              {creditPath.ReadPaymentDateSelection && creditPath.ReadPaymentDateSelection.hasUpfrontPayment
                ? 'entrada'
                : 'primeira parcela'}{' '}
              tem que ser paga em até{' '}
              <Bold>
                {data.daysToUpfrontPayment} {data.daysToUpfrontPayment > 1 ? 'dias' : 'dia'}
              </Bold>{' '}
              após a assinatura do contrato, independente da data de pagamento que você escolher abaixo.
            </SubTitle>
          )}

          <OptionsList isMobile={isMobile}>
            {DAY_OPTIONS.map(option => (
              <Option
                key={option.id}
                isSelected={paymentDate && paymentDate.id === option.id}
                onClick={() => onSelectOption(option)}
              >
                <OptionText isSelected={paymentDate && paymentDate.id === option.id}>Todo dia</OptionText>
                <OptionDay isSelected={paymentDate && paymentDate.id === option.id}>{option.value}</OptionDay>
              </Option>
            ))}
          </OptionsList>
          <Button marginHorizontal={0} type="submit" disabled={isButtonDisabled} text="Avançar" persistFloatingMode={false} />
        </Form>
      </Container>
    </TemplateForm>
  )
}
