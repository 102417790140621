import React from 'react'
import { ValidateToken } from '../screens/validate-token'
import { InvalidToken } from '../screens/invalid-token'
import { RedirectToken } from '../screens/redirect-token'
import { Cpf } from '../screens/cpf'
import { CridSelector } from '../screens/crid-selector'
import { CreditRequestError } from '../screens/form/credit-request-error'
import { FullName } from '../screens/form/full-name'
import { CouponCode } from '../screens/form/coupon-code'
import { BirthDate } from '../screens/form/birth-date'
import { Address } from '../screens/form/address'
import { Phone } from '../screens/form/phone'
import { PhoneConfirmation } from '../screens/form/confirmation-phone'
import { PersonalSituation } from '../screens/form/personal-situation'
import { CourseObjective } from '../screens/form/course-objective'
import { ProfissionalSituation } from '../screens/form/professional-situation'
import { IncomeSource } from '../screens/form/income-source'
import { CourseSelection } from '../screens/form/course-selection'
import { CourseConfirmation } from '../screens/form/course-confirmation'
import { PaymentDateSelect } from '../screens/form/payment-date-select'
import { FixedInstallmentSelection } from '../screens/form/fixed-installment-selection'
import { ReviewRequest } from '../screens/form/review-request'
import { IntroSelfie } from '../screens/form/intro-selfie'
import { Selfie } from '../screens/form/selfie'
import { WaitingDocumentAnalysis } from '../screens/form/waiting-document-analysis'
import { PersonalDocument } from '../screens/form/personal-document'
import { PersonalDocumentIntro } from '../screens/form/personal-document-intro'
import { GraduationDateOutOfRange } from '../screens/form/graduation-date-out-of-range'
import { PersonalDocumentSelection } from '../screens/form/personal-document-selection'
import { RGFrontSide } from '../screens/form/personal-document-rg-front-side'
import { RGBackSide } from '../screens/form/personal-document-rg-back-side'
import { PersonalDocumentCnh } from '../screens/form/personal-document-cnh'
import { IntroProofOfAddress } from '../screens/form/intro-proof-of-address'
import { ProofOfAddress } from '../screens/form/proof-of-address'
import { GuarantorIntro } from '../screens/form/guarantor-intro'
import { GuarantorInvite } from '../screens/form/guarantor-invite'
import { GuarantorListen } from '../screens/form/guarantor-listen'
import { GuarantorFirstNegativeFeedback } from '../screens/form/guarantor-first-negative-feedback'
import { GuarantorSecondNegativeFeedback } from '../screens/form/guarantor-second-negative-feedback'
import { GuarantorPositiveFeedback } from '../screens/form/guarantor-positive-feedback'
import { GuarantorNegativeFeedback } from '../screens/form/guarantor-negative-feedback'
import { Analysis } from '../screens/form/analysis'
import { CreditApproved } from '../screens/form/credit-approved'
import { PreApproved } from '../screens/form/pre-approved'
import { PreDocument } from '../screens/form/pre-document'
import { Invoice } from '../screens/form/invoice'
import { Success } from '../screens/form/success'
import { GraduationDate } from '../screens/form/graduation-date'
import { IntendedWayToExpend } from '../screens/form/intended-way-to-expend'
import { IntendedWayToPay } from '../screens/form/intended-way-to-pay'
import { PreferConstantInstallments } from '../screens/form/prefer-constant-installments'
import { ProofOfEnrollment } from '../screens/form/proof-of-enrollment'
import { SchoolRecords } from '../screens/form/school-records'
import { Bank } from '../screens/form/bank'
import { StudentDebt } from '../screens/form/student-debt'
import { ProviSign } from '../screens/form/provisign'
import { VariableInstallmentSelection } from '../screens/form/variable-installment-selection'
import { AllowanceSelection } from '../screens/form/allowance-selection'
import { AwaitingSignContract } from '../screens/form/awaiting-sign-contract'
import { MedicineSchoolDetails } from '../screens/form/medicine-school-details'
import { AllowanceTeachingDetails } from '../screens/form/allowance-teaching-details'
import { AllowanceSurvey } from '../screens/form/allowance-survey'
import { HousingSituation } from '../screens/form/housing-situation'
import { StudentDebtWithIncomeDetails } from '../screens/form/student-debts-with-income-details'
import { WaitingGuarantorSignature } from '../screens/form/waiting-guarantor-signature'
import { GuarantorBackgroundAnalysis } from '../screens/form/guarantor-background-analysis'
import { DocumentWithoutFace } from '../screens/form/document-without-face'
import { RG } from '../screens/form/rg'
import TesteUpload from '../screens/teste-upload'

import { PartnerBlocked } from '../screens/partner-blocked'

import { ISAroutes } from './ISAroutes'
import { AlreadyHasCrid } from '../screens/already-has-crid'

export const ROUTES = [
  ...ISAroutes,
  {
    path: '/teste---upload',
    component: TesteUpload,
    private: false,
    form: false
  },
  {
    path: '/token/:partner/:token/crid/:crid',
    component: ValidateToken,
    private: false,
    form: false
  },
  {
    path: '/token/:partner/:token',
    component: ValidateToken,
    private: false,
    form: false
  },
  {
    path: '/token/:partner/:token/campanha/:campaign',
    component: ValidateToken,
    exact: true,
    private: false,
    form: false
  },
  {
    path: '/token-invalido/:partner',
    component: InvalidToken,
    private: false,
    form: false
  },
  {
    path: '/:productType/token-invalido/:partner',
    component: InvalidToken,
    private: false,
    form: false
  },
  {
    path: '/email-enviado/:partner',
    component: RedirectToken,
    private: false,
    form: false
  },
  {
    path: '/:productType/email-enviado/:partner',
    component: RedirectToken,
    private: false,
    form: false
  },
  {
    path: '/cpf/:partner',
    private: true,
    component: Cpf,
    form: false
  },
  {
    path: '/selecao-financiamento/:partner',
    private: true,
    component: CridSelector,
    form: false
  },
  {
    path: '/formulario/:crid/:productType/financiamento-nao-encontrado/:partner',
    private: true,
    component: CreditRequestError,
    form: true,
    uniqueName: 'noCreditRequestOrCheckoutError'
  },
  {
    path: '/formulario/:crid/:productType/nome-completo/:partner',
    private: true,
    component: FullName,
    form: true,
    uniqueName: 'fullName'
  },
  {
    path: '/formulario/:crid/:productType/rg/:partner',
    private: true,
    component: RG,
    form: true,
    uniqueName: 'rg'
  },
  {
    path: '/formulario/:crid/:productType/aniversario/:partner',
    private: true,
    component: BirthDate,
    form: true,
    uniqueName: 'birthDate'
  },
  {
    path: '/formulario/:crid/:productType/endereco/:partner',
    private: true,
    component: Address,
    form: true,
    uniqueName: 'address'
  },
  {
    path: '/formulario/:crid/:productType/telefone/:partner',
    private: true,
    component: Phone,
    form: true,
    uniqueName: 'phone'
  },
  {
    path: '/formulario/:crid/:productType/telefone-verificacao/:partner',
    private: true,
    component: PhoneConfirmation,
    form: true,
    uniqueName: 'phoneVerification'
  },
  {
    path: '/formulario/:crid/:productType/situacao-pessoal/:partner',
    private: true,
    component: PersonalSituation,
    form: true,
    uniqueName: 'personalSituation'
  },
  {
    path: '/formulario/:crid/:productType/objetivo-curso/:partner',
    private: true,
    component: CourseObjective,
    form: true,
    uniqueName: 'courseObjective'
  },
  {
    path: '/formulario/:crid/:productType/situacao-profissional/:partner',
    private: true,
    component: ProfissionalSituation,
    form: true,
    uniqueName: 'professionalSituation'
  },
  {
    path: '/formulario/:crid/:productType/fonte-renda/:partner',
    private: true,
    component: IncomeSource,
    form: true,
    uniqueName: 'incomeSource'
  },
  {
    path: '/formulario/:crid/:productType/selecao-curso/:partner',
    private: true,
    component: CourseSelection,
    form: true,
    uniqueName: 'productSelection'
  },
  {
    path: '/formulario/:crid/:productType/curso-confirmacao/:partner',
    private: true,
    component: CourseConfirmation,
    form: true,
    uniqueName: 'checkoutConfirmation'
  },
  {
    path: '/formulario/:crid/:productType/dia-pagamento/:partner',
    private: true,
    component: PaymentDateSelect,
    form: true,
    uniqueName: 'paymentDateSelection'
  },
  {
    path: '/formulario/:crid/:productType/opcao-pagamento/:partner',
    private: true,
    component: FixedInstallmentSelection,
    form: true,
    uniqueName: 'fixedInstallmentSelection'
  },
  {
    path: '/formulario/:crid/:productType/revisar-solicitacao/:partner',
    private: true,
    component: ReviewRequest,
    form: true,
    uniqueName: 'reviewRequest'
  },
  {
    path: '/formulario/:crid/:productType/selfie/:partner',
    private: true,
    component: Selfie,
    form: true,
    uniqueName: 'selfie'
  },
  {
    path: '/formulario/:crid/:productType/introducao-selfie/:partner',
    private: true,
    component: IntroSelfie,
    form: true,
    uniqueName: 'introSelfie'
  },
  {
    path: '/formulario/:crid/:productType/analise-documentos/:partner',
    private: true,
    component: WaitingDocumentAnalysis,
    form: true,
    uniqueName: 'waitingDocumentAnalysis'
  },
  {
    path: '/formulario/:crid/:productType/documento-pessoal/:partner',
    private: true,
    component: PersonalDocument,
    form: true,
    uniqueName: 'personalDocument'
  },
  {
    path: '/formulario/:crid/:productType/documento-pessoal-intro/:partner',
    private: true,
    component: PersonalDocumentIntro,
    form: true,
    uniqueName: 'personalDocumentIntro'
  },
  {
    path: '/formulario/:crid/:productType/documento-pessoal-selecao/:partner',
    private: true,
    component: PersonalDocumentSelection,
    form: true,
    uniqueName: 'personalDocumentSelection'
  },
  {
    path: '/formulario/:crid/:productType/documento-pessoal-rg-parte-frente/:partner',
    private: true,
    component: RGFrontSide,
    form: true,
    uniqueName: 'RGFrontSide'
  },
  {
    path: '/formulario/:crid/:productType/documento-pessoal-rg-parte-tras/:partner',
    private: true,
    component: RGBackSide,
    form: true,
    uniqueName: 'RGBackSide'
  },
  {
    path: '/formulario/:crid/:productType/documento-pessoal-cnh/:partner',
    private: true,
    component: PersonalDocumentCnh,
    form: true,
    uniqueName: 'CNHFrontSide'
  },
  {
    path: '/formulario/:crid/:productType/intro-comprovante-endereco/:partner',
    private: true,
    component: IntroProofOfAddress,
    form: true,
    uniqueName: 'introProofOfAddress'
  },
  {
    path: '/formulario/:crid/:productType/comprovante-endereco/:partner',
    private: true,
    component: ProofOfAddress,
    form: true,
    uniqueName: 'proofOfAddress'
  },
  {
    path: '/formulario/:crid/:productType/comprovante-matricula/:partner',
    private: true,
    component: ProofOfEnrollment,
    form: true,
    uniqueName: 'proofOfEnrollment'
  },
  {
    path: '/formulario/:crid/:productType/historico-academico/:partner',
    private: true,
    component: SchoolRecords,
    form: true,
    uniqueName: 'schoolRecord'
  },
  {
    path: '/formulario/:crid/:productType/avalista-introducao/:partner',
    private: true,
    component: GuarantorIntro,
    form: true,
    uniqueName: 'introGuarantor'
  },
  {
    path: '/formulario/:crid/:productType/avalista-convidar/:partner',
    private: true,
    component: props => (
      <GuarantorInvite
        creditPath="ReadFirstGuarantorPersonalId"
        text="Você pode tentar aprovar até 3 CPF’s  diferentes, então lembre-se de colocar alguém próximo com bom histórico de crédito"
        {...props}
      />
    ),
    form: true,
    uniqueName: 'guarantorPersonalId'
  },
  {
    path: '/formulario/:crid/:productType/avalista-primeira-analise/:partner',
    private: true,
    component: props => <GuarantorBackgroundAnalysis eventName="ReadFirstGuarantorPersonalId" {...props} />,
    form: true,
    uniqueName: 'waitingFirstGuarantorAnalysis'
  },
  {
    path: '/formulario/:crid/:productType/avalista-convidar-dois/:partner',
    private: true,
    component: props => (
      <GuarantorInvite creditPath="ReadSecondGuarantorPersonalId" text="Você ainda pode inserir mais 2 CPF’s" {...props} />
    ),
    form: true,
    uniqueName: 'secondGuarantorPersonalId'
  },
  {
    path: '/formulario/:crid/:productType/avalista-segunda-analise/:partner',
    private: true,
    component: props => <GuarantorBackgroundAnalysis eventName="ReadSecondGuarantorPersonalId" {...props} />,
    form: true,
    uniqueName: 'waitingSecondGuarantorAnalysis'
  },
  {
    path: '/formulario/:crid/:productType/avalista-convidar-tres/:partner',
    private: true,
    component: props => (
      <GuarantorInvite
        creditPath="ReadThirdGuarantorPersonalId"
        text="Você ainda pode inserir mais 1 CPF. Fique tranquilo! Se não der certo nós vamos tentar te ajudar."
        {...props}
      />
    ),
    form: true,
    uniqueName: 'thirdGuarantorPersonalId'
  },
  {
    path: '/formulario/:crid/:productType/avalista-terceira-analise/:partner',
    private: true,
    component: props => <GuarantorBackgroundAnalysis eventName="ReadThirdGuarantorPersonalId" {...props} />,
    form: true,
    uniqueName: 'waitingThirdGuarantorAnalysis'
  },
  {
    path: '/formulario/:crid/:productType/avalistas/:partner',
    private: true,
    component: GuarantorListen,
    form: true,
    uniqueName: 'guarantorList'
  },
  {
    path: '/formulario/:crid/:productType/avalista-primeiro-feedback/:partner',
    private: true,
    component: GuarantorFirstNegativeFeedback,
    form: true,
    uniqueName: 'guarantorFirstNegativeFeedback'
  },
  {
    path: '/formulario/:crid/:productType/avalista-segundo-feedback/:partner',
    private: true,
    component: GuarantorSecondNegativeFeedback,
    form: true,
    uniqueName: 'secondGuarantorFirstNegativeFeedback'
  },
  {
    path: '/formulario/:crid/:productType/avalista-feedback-negativo/:partner',
    private: true,
    component: GuarantorNegativeFeedback,
    form: true,
    uniqueName: 'guarantorNegativeFeedback'
  },
  {
    path: '/formulario/:crid/:productType/avalista-feedback-positivo/:partner',
    private: true,
    component: GuarantorPositiveFeedback,
    form: true,
    uniqueName: 'guarantorPositiveFeedback'
  },
  {
    path: '/formulario/:crid/:productType/analise/:partner',
    private: true,
    component: Analysis,
    form: true,
    uniqueName: 'creditAnalysis'
  },
  {
    path: '/formulario/:crid/:productType/credito-aprovado/:partner',
    private: true,
    component: CreditApproved,
    uniqueName: 'creditApproved',
    form: true
  },
  {
    path: '/formulario/:crid/:productType/pre-aprovado/:partner',
    private: true,
    component: PreApproved,
    uniqueName: 'preApproved',
    form: true
  },
  {
    path: '/formulario/:crid/:productType/pre-documentos/:partner',
    private: true,
    component: PreDocument,
    uniqueName: 'preDocument',
    form: true
  },
  {
    path: '/formulario/:crid/:productType/boleto/:partner',
    private: true,
    component: Invoice,
    uniqueName: 'paymentAtCheckout',
    form: true
  },
  {
    path: '/formulario/:crid/:productType/sucesso/:partner',
    private: true,
    component: Success,
    uniqueName: 'success',
    form: true
  },
  {
    path: '/formulario/:crid/:productType/data-conclusao/:partner',
    private: true,
    component: GraduationDate,
    form: true,
    uniqueName: 'expectedGraduationDate'
  },
  {
    path: '/formulario/:crid/:productType/como-gastar/:partner',
    private: true,
    component: IntendedWayToExpend,
    form: true,
    uniqueName: 'resourcesPurpose'
  },
  {
    path: '/formulario/:crid/:productType/debito-estudantil/:partner',
    private: true,
    component: StudentDebt,
    form: true,
    uniqueName: 'studentDebt'
  },
  {
    path: '/formulario/:crid/:productType/depois-de-formar/:partner',
    private: true,
    component: IntendedWayToPay,
    form: true,
    uniqueName: 'afterGraduationIntent'
  },
  {
    path: '/formulario/:crid/:productType/como-prefere-pagar/:partner',
    private: true,
    component: PreferConstantInstallments,
    form: true,
    uniqueName: 'installmentTypeSelection'
  },
  {
    path: '/formulario/:crid/:productType/banco/:partner',
    private: true,
    component: Bank,
    form: true,
    uniqueName: 'bankAccount'
  },
  {
    path: '/formulario/:crid/:productType/provisign/:partner',
    private: true,
    component: ProviSign,
    form: true,
    uniqueName: 'contractSignature'
  },
  {
    path: '/formulario/:crid/:productType/pagamento-personalizado/:partner',
    private: true,
    component: VariableInstallmentSelection,
    form: true,
    uniqueName: 'variableInstallmentSelection'
  },
  {
    path: '/formulario/:crid/:productType/produto-mesada/:partner',
    private: true,
    component: AllowanceSelection,
    form: true,
    uniqueName: 'allowanceSelection'
  },
  {
    path: '/bloqueado/:partner',
    component: PartnerBlocked
  },
  {
    path: '/formulario/:crid/:productType/medicina-detalhes/:partner',
    component: MedicineSchoolDetails,
    uniqueName: 'teachingInformation'
  },
  {
    path: '/formulario/:crid/:productType/informacoes-de-ensino/:partner',
    component: AllowanceTeachingDetails,
    uniqueName: 'proviAllowanceTeachingInformation'
  },
  {
    path: '/formulario/:crid/:productType/pesquisa-satisfacao/:partner',
    component: AllowanceSurvey,
    uniqueName: 'allowanceSurvey'
  },
  {
    path: '/formulario/:crid/:productType/situacao-moradia/:partner',
    component: HousingSituation,
    uniqueName: 'housingSituation'
  },
  {
    path: '/formulario/:crid/:productType/debito-estudantil-com-detalhes-de-renda/:partner',
    component: StudentDebtWithIncomeDetails,
    uniqueName: 'studentDebtWithIncomeDetails'
  },
  {
    path: '/formulario/:crid/:productType/produto-restrito/:partner',
    component: GraduationDateOutOfRange,
    uniqueName: 'graduationDateOutOfRange'
  },
  {
    path: '/formulario/:crid/:productType/assinar-contrato/:partner',
    component: AwaitingSignContract,
    uniqueName: 'signContractByEmail'
  },
  {
    path: '/formulario/:crid/:productType/pos-contrato/:partner',
    component: WaitingGuarantorSignature,
    uniqueName: 'waitingGuarantorSignature'
  },
  {
    path: '/formulario/:crid/:productType/cupom/:partner',
    private: true,
    component: CouponCode,
    form: true,
    uniqueName: 'couponCode'
  },
  {
    path: '/bloqueio-contrato-assinado',
    private: true,
    component: AlreadyHasCrid
  },
  {
    path: '/formulario/:crid/:productType/documento-ilegivel/:partner',
    component: DocumentWithoutFace,
    uniqueName: 'documentWithoutFace',
    private: true,
    form: false
  }
]
