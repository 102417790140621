import { useCallback, useRef, useState } from 'react'
import loadImage from 'blueimp-load-image'

export const useUpload = (filesNotUpload, setIsLoading, setFilesNotUpload, setIsButtonDisabled, setIsWebcam, setPreview) => {
  const [image, setImage] = useState()
  const [status, setStatus] = useState('processing')
  const [isButtonModalDisabled, setIsButtonModalDisabled] = useState(false)
  const [isWebcamModal, setIsWebcamModal] = useState(false)

  const videoConstraints = {
    width: 640,
    height: 360,
    facingMode: 'user'
  }

  const webcamRef = useRef(null)

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot()
    setImage(imageSrc)
  }, [webcamRef])

  const b64toBlob = dataURI => {
    const byteString = atob(dataURI.split(',')[1])
    const ab = new ArrayBuffer(byteString.length)
    const ia = new Uint8Array(ab)

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }
    return new Blob([ab], { type: 'image/jpeg' })
  }

  const onDrop = archive => {
    setIsLoading(true)
    const reader = new FileReader()

    reader.onerror = err => {}

    reader.onload = async () => {
      loadImage(
        reader.result,
        img => {
          if (img && img.toDataURL && typeof img.toDataURL === 'function') {
            setPreview(img.toDataURL('image/jpeg'))
          } else if (img && img.currentSrc) {
            setPreview(img.currentSrc)
          }

          setFilesNotUpload([...filesNotUpload, archive[0]])
        },
        {
          orientation: true,
          contain: true
        }
      )
      setFilesNotUpload([...filesNotUpload, archive[0]])
      setStatus('complete')
      setIsWebcamModal(false)
      setIsButtonModalDisabled(false)
      setIsLoading(false)
      setIsButtonDisabled(false)
      setIsWebcam(true)
    }

    try {
      reader.readAsDataURL(archive[0])
    } catch (err) {
      setIsLoading(false)
    }
  }

  const handleImage = () => {
    if (!image) {
      capture()
    } else {
      const blob = b64toBlob(image)
      setIsButtonModalDisabled(true)
      onDrop([blob])
    }
  }

  const completeClick = () => {
    if (image) {
      setIsButtonModalDisabled(true)
    }

    setIsWebcamModal(true)
  }

  const takeNewPhoto = () => {
    setIsButtonModalDisabled(false)
    setImage(null)
  }

  return [
    handleImage,
    image,
    webcamRef,
    videoConstraints,
    status,
    isButtonModalDisabled,
    completeClick,
    takeNewPhoto,
    isWebcamModal,
    setIsWebcamModal
  ]
}
