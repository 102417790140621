import React, { useContext } from 'react'
import { IconLinkedin, IconInstagram, IconFacebook, IconBlog } from '../../../assets/svg/icons-socials'
import { TemplateContext } from '../../../components/template/context'
import { Container, Title, SubTitle, Text, Wrapper, ContainerSocial, Item } from './style'

export const Success = () => {
  const { isMobile } = useContext(TemplateContext)
  return (
    <Container isMobile={isMobile}>
      <Wrapper>
        <Title cy="title">
          Sucesso!{' '}
          <span role="img" aria-label="birthdate">
            🎉
          </span>
        </Title>
        <SubTitle cy="sub-title">Você finalizou a sua solicitação e a sua vaga já está garantida!</SubTitle>
        <Text cy="text">Você receberá em breve uma cópia do seu contrato via email.</Text>
        <SubTitle cy="sub-title">Gostou da Provi? Então segue a gente!</SubTitle>
        <ContainerSocial>
          <Item href="https://www.linkedin.com/company/provi/">
            <IconLinkedin />
          </Item>
          <Item href="https://www.instagram.com/sejaprovi/">
            <IconInstagram />
          </Item>
          <Item href="https://www.facebook.com/SejaProvi/">
            <IconFacebook />
          </Item>
          <Item href="https://blog.provi.com.br/">
            <IconBlog />
          </Item>
        </ContainerSocial>
      </Wrapper>
    </Container>
  )
}
