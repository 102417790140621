import { useEffect, useState } from 'react'

export const useVariableInstallmentSelection = (setDataModal, creditPath, goToNextScreen) => {
  const [isShowModal, setIsShowModal] = useState(false)
  const [data, setdata] = useState({})

  useEffect(() => {
    setDataModal(null)
  }, [setDataModal])

  const handleOpenModal = (bool, option) => {
    setIsShowModal(bool)
    setdata(option)
  }

  const handleModal = data => {
    goToNextScreen(data)
  }

  const handleOption = option => {
    if (creditPath?.ReadVariableInstallments?.daysToUpfrontPayment && creditPath?.partnerId !== 1344) {
      return handleOpenModal(true, option)
    }

    goToNextScreen(option)
  }

  return [isShowModal, handleOpenModal, handleModal, handleOption, data]
}
