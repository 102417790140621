import React, { useContext } from 'react'
import { Container, Title, SubTitle } from '@provi/provi-components'
import { TemplateContext } from '../../../components/template/context'
import { useISAContext } from '../../../components/template/ISA/context'
import { TemplateFormIsa } from '../../../components/template-isa'
import { FormFomik } from './form'

export const CourseFinantial = () => {
  const { creditPath } = useContext(TemplateContext)
  const { goToNextScreen } = useISAContext()
  return (
    <TemplateFormIsa>
      <Container>
        <Title text="Como você pretende se manter financeiramente durante o curso?" />
        <SubTitle text="E se algo der errado, você tem um plano B? Qual seria?" />
        <FormFomik data={creditPath && creditPath.ReadExpectedWaysToStayFinancially} goToNextScreen={goToNextScreen} />
      </Container>
    </TemplateFormIsa>
  )
}
