import React, { useContext } from 'react'
import { Form, Container } from '@provi/provi-components'

import { TemplateContext } from '../../../components/template/context'
import { useISAContext } from '../../../components/template/ISA/context'
import { TemplateFormIsa } from '../../../components/template-isa'
import { currencyParser } from '../../../utils'
import { CloseSelectedCourse } from '../../../assets/svg/close-selected-course'
import { ContainerSelectedCourse, Left, Right, Header, Bottom } from './style'
// import { SmallText } from '../../../components/SmallText'
import { useCourseSelection } from './hooks'
import CourseConfirmationScreen from './CourseConfirmation'
import CourseSelectionScreen from './CourseSelection'

const SelectedCourse = ({ item, removeCurse }) => (
  <ContainerSelectedCourse>
    <Left>
      <Header>{item.name}</Header>
      <Bottom>
        {item.attendType} | {currencyParser(item.price, 'R$')}
      </Bottom>
    </Left>
    {removeCurse && (
      <Right onClick={() => removeCurse(item)}>
        <CloseSelectedCourse />
      </Right>
    )}
  </ContainerSelectedCourse>
)

export const CourseSelection = () => {
  const { setIsShowModal, isMobile, setDataModal, creditPath, setIsLoading, setCreditPath } = useContext(TemplateContext)
  const { goToNextScreen } = useISAContext()
  const [
    selectCourse,
    cursesSelect,
    removeCurse,
    onSubmit,
    textSearch,
    handleChange,
    optionsSearch,
    countCourses,
    incrementNumberOfShowCourses,
    options
  ] = useCourseSelection(setDataModal, goToNextScreen, creditPath, setIsLoading, setCreditPath, setIsShowModal)

  const props = {
    incrementNumberOfShowCourses,
    SelectedCourse,
    removeCurse,
    optionsSearch,
    selectCourse,
    countCourses,
    isMobile,
    setIsShowModal,
    options,
    textSearch,
    handleChange,
    creditPath,
    cursesSelect
  }

  return (
    <TemplateFormIsa>
      <Container isMarginDesktop>
        <Form onSubmit={onSubmit}>
          {creditPath &&
          creditPath.ReadISAProducts &&
          creditPath.ReadISAProducts.availableCourses &&
          (creditPath.ReadISAProducts.availableCourses.length > 1 ||
            creditPath.ReadISAProducts.availableCourses.length === 0) ? (
            <CourseSelectionScreen {...props} />
          ) : (
            <CourseConfirmationScreen {...props} />
          )}
        </Form>
      </Container>
    </TemplateFormIsa>
  )
}
