import React from 'react'
import * as Yup from 'yup'
import { withFormik } from 'formik'
import { Input, TextErrorInput, Button, Modal } from '@provi/provi-components'
import { Form } from '../../../components/form'
import { Checked } from '../../../assets/svg/checked'
import { Unchecked } from '../../../assets/svg/unchecked'
import { ItemCheck, WrapperIcon, ExtraDataText, TextModal } from './styles'

export const Formik = props => {
  const {
    handleChange,
    values,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    handleOption,
    option,
    setIsShowModal,
    isShowModal
  } = props

  return (
    <Form onSubmit={handleSubmit}>
      {isShowModal && (
        <Modal
          title="Você está avançando de etapa!"
          onDismiss={() => setIsShowModal(false)}
          footer={<Button marginHorizontal={0} type="submit" text="Avançar" />}
        >
          <TextModal>Lembre-se: ao avançar você não poderá mais alterar as informações que você inseriu até agora.</TextModal>
        </Modal>
      )}

      <Input
        margin="normal"
        id="name"
        placeholder="https://www.linkedin.com/in/..."
        onChange={handleChange('linkedin')}
        onBlur={handleBlur('linkedin')}
        error={!!errors.linkedin}
        value={values.linkedin}
        disabled={option}
      />
      {errors.linkedin && touched.linkedin && <TextErrorInput text={errors.linkedin} />}

      <ItemCheck onClick={() => handleOption(!option)}>
        <WrapperIcon>{option ? <Checked /> : <Unchecked />}</WrapperIcon>
        <ExtraDataText>Não tenho LinkedIn</ExtraDataText>
      </ItemCheck>
      <Button
        marginHorizontal={0}
        disabled={!values.linkedin && option === false}
        text="Avançar"
        onClick={() => setIsShowModal(true)}
        persistFloatingMode={false}
      />
    </Form>
  )
}

export const FormFomik = withFormik({
  validateOnBlur: true,
  validateOnChange: false,
  enableReinitialize: true,
  mapPropsToValues: props => ({ linkedin: props.data || '' }),
  validationSchema: Yup.object().shape({
    linkedin: Yup.string()
  }),
  handleSubmit: async ({ linkedin }, { setSubmitting, props }) => {
    props.goToNextScreen(props.option ? null : linkedin)
    setSubmitting(false)
  }
})(Formik)
