import { useEffect } from 'react'
import { LOCALSTORAGE_TOKENS } from '../../../constants'
import { getStatusInstallment } from '../../../services/api'
import { logger } from '../../../utils/logger'

let interval

export const useInvoice = goToNextScreen => {
  useEffect(() => {
    interval = setInterval(async () => {
      try {
        const crid = localStorage.getItem(LOCALSTORAGE_TOKENS.crid)
        const { data } = await getStatusInstallment(crid)
        if (data.paid) {
          clearInterval(interval)
          goToNextScreen()
        }
      } catch (error) {
        logger({ error })
      }
    }, 6000)
  }, [goToNextScreen])
}
