import React, { useContext } from 'react'
import moment from 'moment'
import { theme, TextLink } from '@provi/provi-components'
import { URL_EXTERNAL } from '../../../constants'
import { IconCheck } from '../../../assets/svg/icon-check'
import { TemplateContext } from '../../../components/template/context'
import { TemplateForm } from '../../../components/template-form'
import { Container, Button, Modal, Title, SubHeaderDesktop } from '@provi/provi-components'
import { Form } from '../../../components/form'
import { currencyParser } from '../../../utils'
import { ContainerOption, Option, Text, Grid, TextModal, ContainerDiscount, ValueDiscount } from './style'
import { useCourseConfirmation } from './hooks'
import { SmallText } from '../../../components/SmallText'

export const CourseConfirmation = () => {
  const { setIsShowModal, isMobile, goToNextScreen, setDataModal, creditPath } = useContext(TemplateContext)
  const [isShowModalDontConfirm, setIsShowModalDontConfirm] = useCourseConfirmation(setDataModal, setIsShowModal)

  const title =
    creditPath && creditPath.ReadProductSelection && creditPath.ReadProductSelection.choosedCourses.length > 1
      ? 'Confirme suas escolhas'
      : 'Confirme sua escolha'

  return (
    <TemplateForm>
      <Container>
        {isShowModalDontConfirm && (
          <Modal
            footer={
              <Button
                marginHorizontal={0}
                type="button"
                text="Entendi"
                onClick={() => setIsShowModalDontConfirm(false)}
                textLink={{
                  color: theme.colors.blue900,
                  text: 'Abrir chat',
                  onClick: () => window.open(URL_EXTERNAL, '_blank')
                }}
              />
            }
            title="Algo errado com suas escolhas?"
            onDismiss={() => setIsShowModalDontConfirm(false)}
          >
            <TextModal>
              Caso alguma informação dos seus produtos esteja incorreta entre em contato com a equipe de vendas do parceiro{' '}
              {creditPath && creditPath.ReadPartner} ou chame a Provi no chat que podemos te ajudar.
            </TextModal>
          </Modal>
        )}
        <Form>
          {isMobile ? (
            <Title text={title} />
          ) : (
            <SubHeaderDesktop title={title} handleModal={() => setIsShowModal(true)} isShowButtonHelpModal />
          )}

          <ContainerDiscount>
            <ValueDiscount color={theme.colors.blue900}>
              Valor total:{' '}
              {currencyParser(creditPath && creditPath.ReadProductSelection && creditPath.ReadProductSelection.total, 'R$')}
            </ValueDiscount>
            {creditPath && creditPath.ReadProductSelection && creditPath.ReadProductSelection.discount > 0 && (
              <>
                <ValueDiscount color={theme.colors.pink900}>
                  Desconto: {currencyParser(creditPath.ReadProductSelection.discount, 'R$')}
                </ValueDiscount>
                <ValueDiscount color={theme.colors.blue900}>
                  Valor final: {currencyParser(creditPath.ReadProductSelection.finalTotal, 'R$')}
                </ValueDiscount>
              </>
            )}

            <SmallText>*O valor final pode sofrer alteração por conta do IOF.</SmallText>
          </ContainerDiscount>

          <ContainerOption>
            {creditPath &&
              creditPath.ReadProductSelection &&
              creditPath.ReadProductSelection.choosedCourses.length > 0 &&
              creditPath.ReadProductSelection.choosedCourses.map((item, key) => (
                <Option key={item.id}>
                  {item.name && (
                    <Grid>
                      <Text title size="13" bold color={theme.colors.pink900}>
                        {item.name}
                      </Text>
                    </Grid>
                  )}

                  {item.startDate && (
                    <Grid line="row">
                      <IconCheck />
                      <Text bold margin>
                        {moment(item.startDate).format('DD/MM/YYYY')}
                      </Text>
                    </Grid>
                  )}

                  {item.details &&
                    item.details.map(item => (
                      <Grid line="row">
                        <IconCheck />
                        <Text bold margin>
                          {item}
                        </Text>
                      </Grid>
                    ))}

                  {item.price && (
                    <Grid line="row">
                      <IconCheck />
                      <Text bold margin>
                        {currencyParser(item.price, 'R$')}
                      </Text>
                    </Grid>
                  )}
                </Option>
              ))}
          </ContainerOption>

          {isMobile && <TextLink text="Não confirmo" onClick={() => setIsShowModalDontConfirm(true)} />}

          <Button
            marginHorizontal={0}
            text="Confirmo"
            onClick={() => goToNextScreen()}
            textLink={!isMobile && { text: 'Não confirmo', onClick: () => setIsShowModalDontConfirm(true) }}
            persistFloatingMode={false}
          />
        </Form>
      </Container>
    </TemplateForm>
  )
}
