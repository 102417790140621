import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const View = styled.div`
  width: 100%;
  height: 100vh;
`

export const ContainerOption = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

export const Option = styled.div`
  border-radius: 20px;
  border: 1px solid ${theme.colors.blue900};
  padding: 20px 15px;
  width: 250px;
  box-sizing: border-box;
  margin-right: 8px;
  margin-top: 8px;
  cursor: pointer;
  background-color: ${({ selected }) => (selected ? theme.colors.blue900 : theme.colors.white100)};
  &:hover {
    opacity: 0.6;
  }

  ${theme.breakpoints.down('md')} {
    padding: 15px;
    width: 100%;
    margin-left: 0;
    margin-top: 8px;
  }
`

export const TitleInstallments = styled.p`
  margin: 0;
  font-size: 15px;
  line-height: 24px;
  font-family: 'CenturyGothicBold', sans-serif;
  color: ${({ selected }) => (selected ? theme.colors.white100 : theme.colors.blue900)};
  margin: 5px 0;
`

export const Bold = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  font-family: 'CenturyGothicBold', sans-serif;
  color: ${theme.colors.pink900};
`

export const Grid = styled.div`
  display: flex;
  margin-top: 8px;
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const CupomDiscount = styled.div`
  width: 272px;
  height: 45px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 12px;
  box-shadow: 2px 2px 41px -15px rgba(0, 0, 0, 0.75);
  margin-top: 20px;
  border-radius: 30px;
  cursor: pointer;
`

export const ValueDiscount = styled.p`
  font-family: CenturyGothicBold, sans-serif;
  margin: 0;
  font-size: 12px;
  color: ${({ color }) => (color === 'blue' ? theme.colors.blue900 : '#D31540')};
`

export const ContainerDiscount = styled.div`
  margin-top: ${theme.variables.spacer * 2}px;
`

export const Text = styled.p`
  margin: 0;
  font-size: 12px;
  font-weight: ${({ bold }) => (bold ? 'bold' : '400')};
  ${({ bold }) => !bold && 'font-family: "CenturyGothic", sans-serif;'}
  color: ${({ itemSelect }) => (itemSelect ? theme.colors.white100 : theme.colors.blue900)}; 
  margin-left: 8px;
`

export const WrapperIconCheck = styled.div`
  width: 14px;
  height: 14px;
`
export const BlueText = styled.span`
  color: ${theme.colors.blue700};
  font-family: 'CenturyGothicBold';
`

export const PinkText = styled.span`
  color: ${theme.colors.pink900} !important;
  font-family: 'CenturyGothicBold';
`

export const ModalText = styled.span`
  color: ${theme.colors.blue900} !important;
`
