import { useState, useEffect } from 'react'
import { getSlugPartner } from '../../../utils'
import { showToast } from '@provi/provi-components'
import { getSlugInfo } from '../../../services/auth'
import cep from 'cep-promise'

export const useCepValidator = data => {
  const inputRefs = []
  const [isShowAddress, setIsShowAddress] = useState(false)

  useEffect(() => {
    if (data) {
      setIsShowAddress(true)
    }
  }, [data])

  const addInput = ref => {
    inputRefs.push(ref)
  }

  const getCep = async zipcode => {
    setIsShowAddress(true)
    const data = await cep(zipcode)
    const address = {
      zipcode,
      state: data.state || '',
      city: data.city || '',
      district: data.neighborhood || '',
      street: data.street || '',
      number: '',
      complement: ''
    }

    return address
  }

  return [isShowAddress, getCep, addInput, inputRefs]
}

export const useCridSelection = setIsLoading => {
  const [partnerName, setPartnerName] = useState(null)
  const partner = getSlugPartner()

  useEffect(() => {
    const get = async () => {
      setIsLoading(true)
      try {
        const path = window.location.pathname
        const isISA = path.includes('/isa/')

        const response = await getSlugInfo(partner, isISA)
        setPartnerName(response.data.data.name)
      } catch (err) {
        setIsLoading(false)
        if (err.response) {
          showToast(err.response.data.error.message)
          return false
        }
        showToast()
      } finally {
        setIsLoading(false)
      }
    }
    if (partner) {
      get()
    }
  }, [partner, setIsLoading])

  return partnerName
}
