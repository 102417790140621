import { useEffect } from 'react'
import { theme } from '@provi/provi-components'
import { URL_EXTERNAL } from '../../../constants'

export const useBirthDate = (setDataModal, setIsShowModal) => {
  useEffect(() => {
    setDataModal({
      title: 'Você é menor de idade?',
      texts: ['Se você é menor de idade, entre em contato com a gente para te informarmos como prosseguir.'],
      textButton: 'Entendi',
      buttonFunction: () => setIsShowModal(false),
      textLink: {
        color: theme.colors.blue900,
        text: 'Abrir chat',
        onClick: () => window.open(URL_EXTERNAL, '_blank')
      }
    })
  }, [setDataModal, setIsShowModal])
}
