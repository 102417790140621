import React, { useContext } from 'react'
import { Button, LoadingProvi } from '@provi/provi-components'
import { TemplateContext } from '../../../components/template/context'
import RequiredDocumentsList from '../../../components/RequiredDocumentsList'
import { Container, Title, SubTitle, Wrapper, ContainerTitle, ContainerImages } from './style'
import { usePreDocument } from './hooks'

export const PreDocument = () => {
  const { isMobile, goToNextScreen, setDataModal, creditPath, setIsLoading, isLoading } = useContext(TemplateContext)
  usePreDocument(setDataModal)
  const isAllowance = creditPath && creditPath.ReadPartnerIsUserReceivesMoney
  return (
    <>
      {isLoading && <LoadingProvi isLoadingDark />}
      <Container isMobile={isMobile}>
        <Wrapper>
          <ContainerTitle>
            <Title>Falta pouco!</Title>
          </ContainerTitle>

          <SubTitle>Para concluir a sua solicitação você vai precisar de:</SubTitle>

          <ContainerImages isMobile={isMobile}>
            <RequiredDocumentsList isAllowance={isAllowance} isMobile={isMobile} />
          </ContainerImages>

          <Button
            marginHorizontal={0}
            type="submit"
            text="Avançar"
            onClick={() => {
              setIsLoading(true)
              goToNextScreen()
            }}
          />
        </Wrapper>
      </Container>
    </>
  )
}
