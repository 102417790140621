import { useEffect } from 'react'
import { showToast } from '@provi/provi-components'

export const useSuccess = (setIsLoading, goToNextScreen) => {
  useEffect(() => {
    setIsLoading(false)
  }, [setIsLoading])

  const handleFillForm = () => {
    try {
      goToNextScreen()
    } catch (error) {
      showToast('Ocorreu um erro ao enviar')
    }
  }

  return [handleFillForm]
}
