import moment from 'moment'
import * as Sentry from '@sentry/browser'
import { setupFirebase } from './services/firebase'
import { initGA, Performance, firstInteraction } from './components/google-analytics'
import { initSanarGtm } from './services/initSanarGTM'
import { initProviGtm } from './services/initProviGTM'

const { REACT_APP_API_ENV } = process.env

const ignoreErrors = process.env.REACT_APP_API_ENV !== 'production' ? [/smartlook/i] : []
const isSanar = window.location.pathname.includes('/sanar')

Sentry.init({
  dsn: 'https://da60b5359ede4c96934121b4a5a48912@sentry.io/2717624',
  environment: REACT_APP_API_ENV,
  ignoreErrors
})

setupFirebase(REACT_APP_API_ENV)

moment.updateLocale('pt-br', require('moment/locale/pt-br'))

initGA()

firstInteraction()

if (REACT_APP_API_ENV === 'production' && isSanar) {
  initSanarGtm()
  initProviGtm()
}

try {
  const observer = new PerformanceObserver(Performance)
  observer.observe({ entryTypes: ['navigation'] })
} catch (error) {
  console.error(error)
}
