import React from 'react'
import { Route, Switch, Router } from 'react-router-dom'
import { ToastComponent } from '@provi/provi-components'
import { NotFound } from '../screens/not-found'
import { ROUTES } from './routes'
import { history } from './history'
// TODO BUG IN CSS IN PROVI_COMPONENTS
import 'react-toastify/dist/ReactToastify.css'

export const Navigation = () => {
  return (
    <Router history={history}>
      <Switch>
        {ROUTES.map(item => (
          <Route key={item.path} path={item.path} exact={item.exact} component={item.component} />
        ))}
        <Route path={'/'} component={NotFound} />
      </Switch>

      <ToastComponent />
    </Router>
  )
}
