import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const View = styled.div`
  width: 100%;
  height: 100vh;
`

export const ContainerOption = styled.div`
  width: 100%;
  display: flex;
  margin-top: 10px;
  flex-wrap: wrap;
`

export const Option = styled.div`
  border-radius: 20px;
  border: 1px solid ${theme.colors.blue900};
  padding: 20px 15px;
  width: 256px;
  box-sizing: border-box;
  min-height: 175px;
  margin-top: 8px;
  margin-right: 8px;
  ${({ itemSelect }) =>
    itemSelect
      ? `
    background-color: ${theme.colors.blue900};
    &:hover {
      opacity:1;
    }
  `
      : `
    cursor: pointer;
    &:hover {
      opacity: 0.6;
    }`};

  ${theme.breakpoints.down('md')} {
    padding: 15px;
    width: 100%;
    margin-left: 0;
    margin-top: 8px;
    margin-right: 0;
  }
`

export const OptionBlocked = styled.div`
  border-radius: 20px;
  border: 1px solid ${theme.colors.blue900};
  padding: 20px 15px;
  width: 250px;
  box-sizing: border-box;
  min-height: 175px;
  margin-top: 8px;
  margin-right: 8px;
  background-color: ${theme.colors.grey600};
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`

export const TextWhite = styled.p`
  margin: 0;
  font-size: 12px;
  line-height: 18px;
  font-family: 'CenturyGothic', sans-serif;
  color: ${theme.colors.white100};
`

export const Text = styled.p`
  margin: 0;
  font-size: 12px;
  font-weight: ${({ bold }) => (bold ? 'bold' : '400')};
  ${({ bold }) => !bold && 'font-family: "CenturyGothic", sans-serif;'}
  color: ${({ itemSelect }) => (itemSelect ? theme.colors.white100 : theme.colors.blue900)};
  margin-left: 8px;
`

export const TitleCard = styled.div`
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  font-family: 'CenturyGothicBold', sans-serif;
  color: ${({ blocked }) => (blocked ? theme.colors.white100 : theme.colors.pink900)};
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`

export const Grid = styled.div`
  grid-template-columns: ${({ line }) => (line === 'row' ? '1fr 1fr' : '1fr')};
  grid-gap: 10px;
  display: grid;
  margin-top: 10px;
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  ${({ blocked }) => blocked && 'align-items: center'};
`

export const CupomDiscount = styled.div`
  width: 272px;
  height: 45px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 12px;
  box-shadow: 2px 2px 41px -15px rgba(0, 0, 0, 0.75);
  margin-top: 20px;
  border-radius: 30px;
  cursor: pointer;
`

export const WrapperIconCheck = styled.div`
  width: 14px;
  height: 14px;
`
