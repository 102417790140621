import { useState, useEffect } from 'react'
import { showToast } from '@provi/provi-components'
import { getUnicoProviderId, uploadImages } from '../../../services/api'

export const useUploadSelfie = (goToNextScreen, creditPath, setIsLoading, isMobile) => {
  const [filesNotUpload, setFilesNotUpload] = useState([])
  const [filesUploaded, setFilesUploaded] = useState([])
  const [preview, setPreview] = useState(undefined)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [isShowModal, setIsShowModal] = useState(false)
  const [isWebcam, setIsWebcam] = useState(false)
  const [typeModal, setTypeModal] = useState(0)
  const [numberOfFiles, setNumberOfFiles] = useState(0)
  const [numberOfFilesInitial, setNumberOfFilesInitial] = useState(0)

  useEffect(() => {
    setIsLoading(false)
  }, [setIsLoading])

  useEffect(() => {
    if (creditPath && creditPath.ReadSelfie && creditPath.ReadSelfie.length > 0) {
      setIsButtonDisabled(false)
      setNumberOfFiles(creditPath.ReadSelfie.length)
      setFilesUploaded(creditPath.ReadSelfie)
      setNumberOfFilesInitial(creditPath.ReadSelfie.length)
      setPreview(creditPath.ReadSelfie[0])
    }
  }, [creditPath])

  useEffect(() => {
    if (filesNotUpload.length > 0) {
      setIsButtonDisabled(false)
    }
  }, [filesNotUpload])

  const onSubmit = async e => {
    e.preventDefault()
    setIsLoading(true)
    try {
      const urls = await uploadImages(filesNotUpload)

      await getUnicoProviderId(urls, isMobile)

      goToNextScreen([...urls, ...filesUploaded])
    } catch (e) {
      showToast()
    } finally {
      setIsLoading(true)
    }
  }

  return [
    preview,
    onSubmit,
    isButtonDisabled,
    numberOfFiles,
    isShowModal,
    setIsShowModal,
    typeModal,
    setTypeModal,
    filesNotUpload,
    setFilesNotUpload,
    setIsButtonDisabled,
    setIsWebcam,
    isWebcam,
    setPreview,
    setNumberOfFiles,
    numberOfFilesInitial,
    filesUploaded
  ]
}
