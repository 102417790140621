import React, { useContext } from 'react'
import { Container, Title, Button, Input, InputMasks, Selector } from '@provi/provi-components'
import { Form } from '../../../components/form'
import { TemplateContext } from '../../../components/template/context'
import { TemplateForm } from '../../../components/template-form'
import { SubTitle } from '../../../components/sub-title'
import { SmallText } from '../../../components/SmallText'
import { OptionsList, Option, OptionBold, SubTitleWithoutSpacing } from './styles'
import { useStudentDebtWithIncomeDetails } from './hooks'

export const StudentDebtWithIncomeDetails = () => {
  const { goToNextScreen, creditPath, isMobile } = useContext(TemplateContext)
  const {
    selectedOption,
    onSelectOption,
    onSubmit,
    onValueChange,
    value,
    isButtonDisabled,
    OPTIONS,
    incomeValue,
    setIncomeValue,
    employability,
    employabilityOptions,
    setEmployability
  } = useStudentDebtWithIncomeDetails(goToNextScreen, creditPath)
  return (
    <TemplateForm>
      <Container>
        <Form onSubmit={onSubmit}>
          <Title text="Você possui algum financiamento estudantil?" />
          <OptionsList>
            {OPTIONS.map((item, index) => (
              <Option
                key={item.id}
                first={index === 0}
                isSelected={selectedOption && selectedOption.id === item.id}
                onClick={() => onSelectOption(item)}
              >
                <OptionBold isSelected={selectedOption && selectedOption.id === item.id}>{item.label}</OptionBold>
              </Option>
            ))}
          </OptionsList>

          {selectedOption && selectedOption.value && (
            <>
              <SubTitle>Qual tipo de financiamento?</SubTitle>
              <Input
                autoFocus
                margin="normal"
                onChange={onValueChange}
                value={value}
                placeholder="Ex: FIES, financiamento com a faculdade..."
              />
            </>
          )}

          <SubTitle>Atualmente você está empregado?</SubTitle>
          <Selector
            placeholder="Escolher"
            options={employabilityOptions}
            value={employability}
            onValueChange={setEmployability}
            isSearchable={false}
          />

          <SubTitleWithoutSpacing>Qual sua renda mensal?</SubTitleWithoutSpacing>
          <SmallText>Em caso negativo, preencha com zero.</SmallText>
          <InputMasks
            mask="R$99999999"
            margin="normal"
            type="text"
            placeholder="Ex: R$1.500"
            onChange={e => setIncomeValue(e.target.value)}
            value={incomeValue}
          />

          {isMobile && <div style={{ height: '27px' }} />}

          <Button marginHorizontal={0} type="submit" disabled={isButtonDisabled} text="Avançar" persistFloatingMode={false} />
        </Form>
      </Container>
    </TemplateForm>
  )
}
