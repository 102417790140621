import React from 'react'
import { Button, Modal, SubTitle } from '@provi/provi-components'

export const ModalSelfie = ({ setIsShowModal, setTypeModal, typeModal, onSubmit }) => {
  const modals = [
    {
      title: 'Veja abaixo algumas dicas para tirar a sua foto corretamente',
      texts: [
        '- Tire a foto em um ambiente iluminado',
        '- Certifique-se que o documento está aparecendo inteiro na imagem',
        '- Não cubra o rosto com o documento ou com outros acessórios'
      ],
      textButton: 'Entendi',
      func: () => {
        setIsShowModal(false)
      }
    },
    {
      title: 'Você está avançando de etapa!',
      texts: ['Lembre-se: ao avançar você não poderá mais alterar as informações que você inseriu até agora.'],
      textButton: 'Avançar',
      func: () => {
        setIsShowModal(false)
        onSubmit()
      }
    }
  ]

  return (
    <Modal
      title={modals[typeModal].title}
      onDismiss={() => {
        setTypeModal(0)
        setIsShowModal(false)
      }}
      footer={
        <Button marginHorizontal={0} type="button" text={modals[typeModal].textButton} onClick={modals[typeModal].func} />
      }
    >
      {modals[typeModal].texts.map(text => (
        <SubTitle text={text} />
      ))}
    </Modal>
  )
}
