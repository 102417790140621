import React, { useContext } from 'react'
import { Modal, Container, Button, Title, TextLink } from '@provi/provi-components'
import { Form } from '../../../components/form'
import { TemplateContext } from '../../../components/template/context'
import { TemplateForm } from '../../../components/template-form'
import { OptionsList, Option, OptionText, ContainerOption } from './style'
import { usePreferConstantInstallments } from './hooks'

export const PreferConstantInstallments = () => {
  const { goToNextScreen } = useContext(TemplateContext)
  const [
    selectedOption,
    onSelectOption,
    options,
    onSubmit,
    isButtonDisabled,
    isShowModal,
    setIsShowModal,
    MODALS,
    modalSelected,
    setModalSelected
  ] = usePreferConstantInstallments(goToNextScreen)
  return (
    <TemplateForm>
      <Container>
        <Form onSubmit={onSubmit}>
          <Title text="Como você prefere pagar o seu financiamento?" />
          <OptionsList>
            {options &&
              options.map((option, index) => (
                <ContainerOption>
                  <Option
                    key={option.id}
                    first={index === 0}
                    isSelected={selectedOption && selectedOption.id === option.id}
                    onClick={() => onSelectOption(option)}
                  >
                    <OptionText isSelected={selectedOption && selectedOption.id === option.id}>{option.text}</OptionText>
                  </Option>
                  <TextLink
                    text="Ver exemplo"
                    onClick={() => {
                      setModalSelected(index)
                      setIsShowModal(true)
                    }}
                  />
                </ContainerOption>
              ))}
          </OptionsList>

          <Button marginHorizontal={0} type="submit" disabled={isButtonDisabled} text="Avançar" persistFloatingMode={false} />
        </Form>
      </Container>
      {isShowModal && (
        <Modal title={MODALS[modalSelected].title} onDismiss={() => setIsShowModal(false)}>
          {MODALS[modalSelected].children}
        </Modal>
      )}
    </TemplateForm>
  )
}
