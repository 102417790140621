import { theme } from '@provi/provi-components'
import styled from 'styled-components'

export const Disclaimer = styled.div`
  font-family: 'CenturyGothic', sans serif;
  font-style: normal;
  font-size: 10px;
  line-height: 18px;
  margin: 0;
  margin-bottom: 8px;
  color: ${theme.colors.grey600};
  align-content: center;

  ${theme.breakpoints.down('md')} {
    font-size: 10px;
    line-height: 18px;
    margin-top: 15px;
  }
`
