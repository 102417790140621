import { useEffect, useState, useMemo } from 'react'
import moment from 'moment'

export const useAllowanceTeachingDetails = (creditPath, goToNextScreen) => {
  const [courses, setCourses] = useState([])
  const [course, setCourse] = useState()
  const [courseNotFound, setCourseNotFound] = useState(false)
  const [expectedGraduationDate, setExpectedGraduationDate] = useState()
  const [universities, setUniversities] = useState([])
  const [university, setUniversity] = useState()
  const [universityNotFound, setUniversityNotFound] = useState(false)
  const [willStayInBrazil, setWillStayInBrazil] = useState()
  const [disapprovals, setDisapprovals] = useState([])
  const [disapproval, setDisapproval] = useState()
  const [expectedGraduationDateError, setExpectedGraduationDateError] = useState()
  const [customUniversity, setCustomUniversity] = useState()
  const [customUniversityCourse, setCustomUniversityCourse] = useState()

  const isButtonDisabled = useMemo(() => {
    if (!expectedGraduationDate) {
      return true
    }

    const momentExpectedGraduationDate = moment(expectedGraduationDate, 'MM/YYYY')
    if (!momentExpectedGraduationDate.isValid() || expectedGraduationDate.length < 7) {
      setExpectedGraduationDateError('A data de graduação está inválida.')
      return true
    } else if (momentExpectedGraduationDate.isBefore(moment())) {
      setExpectedGraduationDateError('A data de graduação precisa ser uma data futura.')
      return true
    } else {
      setExpectedGraduationDateError()
    }

    if (!course && courseNotFound === false) {
      return true
    }

    if (!course && !customUniversityCourse) {
      return true
    }

    if (!disapproval) {
      return true
    }

    if (!university && universityNotFound === false) {
      return true
    }

    if (!university && !customUniversity) {
      return true
    }

    if (willStayInBrazil !== 'SIM') {
      return true
    }
  }, [
    course,
    courseNotFound,
    expectedGraduationDate,
    disapproval,
    university,
    universityNotFound,
    willStayInBrazil,
    customUniversityCourse,
    customUniversity
  ])

  useEffect(() => {
    if (creditPath) {
      creditPath.ReadUniversityCourses &&
        setCourses(creditPath.ReadUniversityCourses.options.map(item => ({ ...item, value: item.label })))
      creditPath.ReadExpectedGraduationDate && setExpectedGraduationDate(creditPath.ReadExpectedGraduationDate)
      creditPath.ReadUniversities &&
        setUniversities(creditPath.ReadUniversities.options.map(item => ({ ...item, value: item.label })))
      creditPath.ReadHowManyDisapprovals &&
        setDisapprovals(creditPath.ReadHowManyDisapprovals.options.map(item => ({ ...item, value: item.label })))

      if (creditPath.ReadUniversityCourses && creditPath.ReadUniversityCourses.choosedId) {
        const selectedCourse = creditPath.ReadUniversityCourses.options.find(
          item => item.id === creditPath.ReadUniversityCourses.choosedId
        )

        if (selectedCourse) {
          setCourse(selectedCourse)
        }
      } else if (creditPath.ReadUniversityCourses && creditPath.ReadUniversityCourses.answer) {
        setCustomUniversityCourse(creditPath.ReadUniversityCourses.answer)
        setCourseNotFound(true)
        setCourse(null)
      }

      if (creditPath.ReadHowManyDisapprovals && creditPath.ReadHowManyDisapprovals.choosedId) {
        const selectedDisapproval = creditPath.ReadHowManyDisapprovals.options.find(
          item => item.id === creditPath.ReadHowManyDisapprovals.choosedId
        )

        if (selectedDisapproval) {
          setDisapproval(selectedDisapproval)
        }
      }

      if (creditPath.ReadUniversities && creditPath.ReadUniversities.choosedId) {
        const selectedUniversity = creditPath.ReadUniversities.options.find(
          item => item.id === creditPath.ReadUniversities.choosedId
        )

        if (selectedUniversity) {
          setUniversity(selectedUniversity)
        }
      } else if (creditPath.ReadUniversities && creditPath.ReadUniversities.answer) {
        setCustomUniversity(creditPath.ReadUniversities.answer)
        setUniversityNotFound(true)
        setUniversity(null)
      }

      if (
        creditPath.ReadUniversityCourses &&
        (creditPath.ReadUniversityCourses.choosedId ||
          creditPath.ReadHowManyDisapprovals.choosedId ||
          creditPath.ReadUniversities.choosedId ||
          creditPath.ReadExpectedGraduationDate)
      ) {
        setWillStayInBrazil('SIM')
      }
    }
  }, [])

  const onCourseChange = value => {
    setCourse(value)
    setCourseNotFound(false)
    setCustomUniversityCourse(null)
  }

  const onUniversityChange = value => {
    setUniversity(value)
    setUniversityNotFound(false)
    setCustomUniversity(null)
  }

  const onSubmit = e => {
    e.preventDefault()

    const body = {
      UniversityOptionId: university?.id,
      universityOptionAnswer: customUniversity,
      HowManyDisapprovalsInUniversityOptionId: disapproval?.id,
      expectedGraduationDate,
      UniversityCourseOptionId: course?.id,
      universityCourseOptionAnswer: customUniversityCourse
    }

    goToNextScreen(body, false)
  }

  return {
    onSubmit,
    courses,
    course,
    setCourse,
    onCourseChange,
    courseNotFound,
    setCourseNotFound,
    expectedGraduationDate,
    setExpectedGraduationDate,
    expectedGraduationDateError,
    universities,
    university,
    setUniversity,
    onUniversityChange,
    universityNotFound,
    setUniversityNotFound,
    isButtonDisabled,
    setWillStayInBrazil,
    willStayInBrazil,
    disapprovals,
    disapproval,
    setDisapproval,
    customUniversity,
    setCustomUniversity,
    customUniversityCourse,
    setCustomUniversityCourse
  }
}
