import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const TextMobile = styled.p`
  font-family: 'CenturyGothic', sans serif;
  color: ${theme.colors.white100};
  font-size: 12px;
  line-height: 25px;
  margin: 5px 0;
`

export const TextItem = styled(TextMobile)`
  font-weight: bold;
  line-height: 20px;

  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }
`

export const UppercaseText = styled(TextItem)`
  text-transform: uppercase;
  margin: 0;
`

export const DocumentList = styled.div`
  display: flex;
`

export const DocumentItem = styled.div`
  margin-right: 20px;
  text-align: center;
  width: 90px;
`

export const Bullet = styled.span`
  color: ${theme.colors.blue600};
`
