import { useState, useEffect } from 'react'
import { showToast } from '@provi/provi-components'
import * as Sentry from '@sentry/browser'
import { uploadImages } from '../../../services/api'

export const useRgBackSide = (goToNextScreen, setDataModal, creditPath, setIsLoading) => {
  const [filesNotUpload, setFilesNotUpload] = useState([])
  const [filesUploaded, setFilesUploaded] = useState([])
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [numberOfFiles, setNumberOfFiles] = useState(0)
  const [numberOfFilesInitial, setNumberOfFilesInitial] = useState(0)
  const [preview, setPreview] = useState()

  useEffect(() => {
    setIsLoading(false)
  }, [setIsLoading])

  useEffect(() => {
    if (creditPath && creditPath.ReadRGBackSide && creditPath.ReadRGBackSide.length > 0) {
      setIsButtonDisabled(false)
      setNumberOfFiles(creditPath.ReadRGBackSide.length)
      setFilesUploaded(creditPath.ReadRGBackSide)
      setNumberOfFilesInitial(creditPath.ReadRGBackSide.length)
      setPreview(creditPath.ReadRGBackSide[0])
    }
  }, [setDataModal, creditPath])

  useEffect(() => {
    if (filesNotUpload.length > 0) {
      setIsButtonDisabled(false)
    }
  }, [filesNotUpload])

  const onSubmit = async e => {
    e.preventDefault()
    setIsLoading(true)
    try {
      const urls = await uploadImages(filesNotUpload)
      const files = [...urls, ...filesUploaded]

      if (!files.length) {
        /**
         * An error happened with upload to firebase and returns empty array reseting the page to reset the state
         */
        setIsLoading(false)
        showToast('Ocorreu um erro ao enviar o arquivo. Tente novamente ou entre em contato com a Provi.')
        Sentry.captureException(new Error('onSubmit(RG back side): files is empty'))

        setTimeout(() => {
          window.location.reload()
        }, 3000)

        return
      } else {
        goToNextScreen(files)
      }
    } catch (e) {
      showToast()
    }
  }

  return [
    onSubmit,
    isButtonDisabled,
    numberOfFiles,
    preview,
    setPreview,
    setFilesNotUpload,
    filesNotUpload,
    setNumberOfFiles,
    numberOfFilesInitial
  ]
}
