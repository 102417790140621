import React from 'react'

export const SelfieExample = ({ isMobile = false }) => (
  <svg
    width={isMobile ? '100%' : '272'}
    height={isMobile ? '100%' : '180'}
    viewBox="0 0 272 180"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="272" height="179.98" rx="5" fill="#5CB5FE" />
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="272" height="180">
      <rect width="272" height="179.98" rx="5" fill="#5CB5FE" />
    </mask>
    <g mask="url(#mask0)">
      <path d="M69.0384 76.13L92.1327 92.702V200.02" stroke="#9BD2FF" stroke-miterlimit="10" stroke-linejoin="round" />
      <mask id="path-4-inside-1" fill="white">
        <path d="M193.064 162.19V135.103C193.064 117.16 204.305 100.702 221.779 93.2734L235.953 87.2161V196.362" />
      </mask>
      <path
        d="M221.779 93.2734L222.17 94.1937L222.172 94.193L221.779 93.2734ZM235.953 87.2161H236.953V85.7012L235.56 86.2965L235.953 87.2161ZM194.064 162.19V135.103H192.064V162.19H194.064ZM194.064 135.103C194.064 117.587 205.041 101.476 222.17 94.1937L221.388 92.3531C203.57 99.9284 192.064 116.733 192.064 135.103H194.064ZM222.172 94.193L236.346 88.1356L235.56 86.2965L221.386 92.3538L222.172 94.193ZM234.953 87.2161V196.362H236.953V87.2161H234.953Z"
        fill="#9BD2FF"
        mask="url(#path-4-inside-1)"
      />
      <path d="M205.283 236.706V-9.47266H272V241.164" stroke="#9BD2FF" stroke-miterlimit="10" stroke-linejoin="round" />
      <path d="M193.064 56.2437V-0.443787" stroke="#9BD2FF" stroke-miterlimit="10" stroke-linejoin="round" />
      <path d="M68.5501 57.0437V228.935" stroke="#9BD2FF" stroke-miterlimit="10" stroke-linejoin="round" />
      <mask id="path-9-inside-2" fill="white">
        <path d="M46.6773 38.5288L68.5497 59.558H77.2254L46.7995 27.8999" />
      </mask>
      <path d="M46.6773 38.5288L68.5497 59.558H77.2254L46.7995 27.8999" fill="#82BEF4" />
      <path
        d="M68.5497 59.558L67.8567 60.2789L68.147 60.558H68.5497V59.558ZM77.2254 59.558V60.558H79.5734L77.9464 58.8651L77.2254 59.558ZM45.9842 39.2497L67.8567 60.2789L69.2428 58.8372L47.3704 37.8079L45.9842 39.2497ZM68.5497 60.558H77.2254V58.558H68.5497V60.558ZM77.9464 58.8651L47.5205 27.207L46.0785 28.5928L76.5044 60.251L77.9464 58.8651Z"
        fill="#9BD2FF"
        mask="url(#path-9-inside-2)"
      />
      <path d="M77.2258 59.558V55.5579L46.7999 24.1284" stroke="#9BD2FF" stroke-miterlimit="10" stroke-linejoin="round" />
      <path d="M46.7999 4.35632V236.706" stroke="#9BD2FF" stroke-miterlimit="10" stroke-linejoin="round" />
      <path
        d="M34.8248 117.503L0 124.932V18.2996L34.8248 24.4712V117.503Z"
        stroke="#9BD2FF"
        stroke-miterlimit="10"
        stroke-linejoin="round"
      />
      <path d="M25.0607 117.003V24.9713H34.4471V117.003H25.0607Z" fill="#82BEF4" stroke="#9BD2FF" />
      <path d="M28.9596 116.588L0 120.589" stroke="#9BD2FF" stroke-miterlimit="10" stroke-linejoin="round" />
      <path d="M31.4034 25.3856L0 21.4998" stroke="#9BD2FF" stroke-miterlimit="10" stroke-linejoin="round" />
      <path d="M57.175 74.0965V88.7087L47.2999 89.7465V70.9831L57.175 74.0965Z" fill="#82BEF4" stroke="#9BD2FF" />
      <path d="M57.175 97.2632V113.056L47.2999 117.207V98.4048L57.175 97.2632Z" fill="#82BEF4" stroke="#9BD2FF" />
      <path d="M175.59 -2.15814V177.733" stroke="#9BD2FF" stroke-miterlimit="10" stroke-linejoin="round" />
      <path d="M175.59 61.3867L205.283 52.5864" stroke="#9BD2FF" stroke-miterlimit="10" stroke-linejoin="round" />
      <path d="M175.59 66.1868L205.283 61.9581" stroke="#9BD2FF" stroke-miterlimit="10" stroke-linejoin="round" />
      <path
        d="M175.346 24.5855H129.035C129.035 24.5855 134.167 16.1281 141.377 16.1281C148.586 16.1281 151.763 20.2425 156.284 18.1853C162.76 15.2138 160.683 5.72778 175.468 5.72778"
        stroke="#9BD2FF"
        stroke-miterlimit="10"
        stroke-linejoin="round"
      />
      <path
        d="M58.8969 36.5859H94.2105C94.2105 36.5859 89.8116 31.4429 81.1359 30.5286C73.8044 29.7286 70.8718 33.6144 68.5501 31.4429C66.2285 29.2714 65.1287 15.5567 46.7999 17.4996"
        stroke="#9BD2FF"
        stroke-miterlimit="10"
        stroke-linejoin="round"
      />
    </g>
    <rect x="46.7433" y="98.7965" width="9.47373" height="13.5339" fill="#F886BC" />
    <path
      d="M217.275 115.045C197.912 92.8668 165.965 93.3069 165.965 93.3069H163.413H141.85H139.298C139.298 93.3069 109.463 91.0186 90.1006 113.285C77.1632 128.071 67.0421 150.777 67.0421 150.777L102.334 163.715L98.5495 179.996H209.53L203.633 162.482L231.481 133.985C230.804 132.631 230.212 129.919 217.275 115.045Z"
      fill="#0074E7"
    />
    <path
      d="M80.4195 138.192L83.5878 143.912"
      stroke="#4091F2"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M102.51 134.583L106.91 129.655"
      stroke="#4091F2"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M131.905 139.776L138.33 138.896"
      stroke="#4091F2"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M146.515 125.342L150.475 120.15"
      stroke="#4091F2"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M160.596 163.363L164.469 158.082"
      stroke="#4091F2"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M175.646 174.012L176.086 167.499"
      stroke="#4091F2"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M185.503 154.034L191.4 156.762"
      stroke="#4091F2"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M208.65 155.002L213.842 151.129"
      stroke="#4091F2"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M121.696 117.686L126.625 121.998"
      stroke="#4091F2"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M195.448 119.974L198.88 125.606"
      stroke="#4091F2"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M215.338 130.799L221.323 133.439"
      stroke="#4091F2"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M183.127 104.308L187.879 99.9076"
      stroke="#4091F2"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M176.526 133.087L183.039 132.911"
      stroke="#4091F2"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M112.895 102.108L119.407 101.14"
      stroke="#4091F2"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M164.468 111.789L170.541 114.253"
      stroke="#4091F2"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M201.785 141.272L203.721 135.111"
      stroke="#4091F2"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M136.57 167.059L142.29 164.067"
      stroke="#4091F2"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M118.704 150.689L124.16 154.122"
      stroke="#4091F2"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M156.108 140.392L160.332 145.321"
      stroke="#4091F2"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M93.6207 116.893L96.349 122.79"
      stroke="#4091F2"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M89.5723 139.336C90.2042 139.336 90.7165 138.824 90.7165 138.192C90.7165 137.56 90.2042 137.048 89.5723 137.048C88.9404 137.048 88.4282 137.56 88.4282 138.192C88.4282 138.824 88.9404 139.336 89.5723 139.336Z"
      fill="#4091F2"
    />
    <path
      d="M110.342 111.789C110.974 111.789 111.486 111.277 111.486 110.645C111.486 110.013 110.974 109.501 110.342 109.501C109.71 109.501 109.198 110.013 109.198 110.645C109.198 111.277 109.71 111.789 110.342 111.789Z"
      fill="#4091F2"
    />
    <path
      d="M98.5494 154.21C98.5494 154.826 98.0213 155.354 97.4053 155.354C96.7892 155.354 96.2611 154.826 96.2611 154.21C96.2611 153.594 96.7892 153.065 97.4053 153.065C98.0213 153.065 98.5494 153.594 98.5494 154.21Z"
      fill="#4091F2"
    />
    <path
      d="M122.576 138.896C122.576 139.512 122.048 140.04 121.432 140.04C120.816 140.04 120.288 139.512 120.288 138.896C120.288 138.28 120.816 137.752 121.432 137.752C122.048 137.752 122.576 138.28 122.576 138.896Z"
      fill="#4091F2"
    />
    <path
      d="M189.639 166.707C190.271 166.707 190.783 166.195 190.783 165.563C190.783 164.931 190.271 164.419 189.639 164.419C189.007 164.419 188.495 164.931 188.495 165.563C188.495 166.195 189.007 166.707 189.639 166.707Z"
      fill="#4091F2"
    />
    <path
      d="M112.367 167.851C112.999 167.851 113.511 167.339 113.511 166.707C113.511 166.075 112.999 165.563 112.367 165.563C111.735 165.563 111.223 166.075 111.223 166.707C111.223 167.339 111.735 167.851 112.367 167.851Z"
      fill="#4091F2"
    />
    <path
      d="M162.532 123.934C163.164 123.934 163.676 123.422 163.676 122.79C163.676 122.158 163.164 121.646 162.532 121.646C161.9 121.646 161.388 122.158 161.388 122.79C161.388 123.422 161.9 123.934 162.532 123.934Z"
      fill="#4091F2"
    />
    <path
      d="M183.127 118.566C183.758 118.566 184.271 118.053 184.271 117.422C184.271 116.79 183.758 116.277 183.127 116.277C182.495 116.277 181.982 116.79 181.982 117.422C181.982 118.053 182.495 118.566 183.127 118.566Z"
      fill="#4091F2"
    />
    <path
      d="M138.33 113.461C138.962 113.461 139.474 112.949 139.474 112.317C139.474 111.685 138.962 111.173 138.33 111.173C137.698 111.173 137.186 111.685 137.186 112.317C137.186 112.949 137.698 113.461 138.33 113.461Z"
      fill="#4091F2"
    />
    <path
      d="M201.168 114.605C201.8 114.605 202.313 114.093 202.313 113.461C202.313 112.829 201.8 112.317 201.168 112.317C200.537 112.317 200.024 112.829 200.024 113.461C200.024 114.093 200.537 114.605 201.168 114.605Z"
      fill="#4091F2"
    />
    <path
      d="M144.666 154.21C145.298 154.21 145.81 153.697 145.81 153.066C145.81 152.434 145.298 151.921 144.666 151.921C144.034 151.921 143.522 152.434 143.522 153.066C143.522 153.697 144.034 154.21 144.666 154.21Z"
      fill="#4091F2"
    />
    <path
      d="M174.678 148.401C175.31 148.401 175.822 147.889 175.822 147.257C175.822 146.625 175.31 146.113 174.678 146.113C174.046 146.113 173.534 146.625 173.534 147.257C173.534 147.889 174.046 148.401 174.678 148.401Z"
      fill="#4091F2"
    />
    <path
      d="M164.732 169.787C165.364 169.787 165.877 169.275 165.877 168.643C165.877 168.011 165.364 167.499 164.732 167.499C164.101 167.499 163.588 168.011 163.588 168.643C163.588 169.275 164.101 169.787 164.732 169.787Z"
      fill="#4091F2"
    />
    <path
      d="M216.395 142.856C217.026 142.856 217.539 142.344 217.539 141.712C217.539 141.08 217.026 140.568 216.395 140.568C215.763 140.568 215.25 141.08 215.25 141.712C215.25 142.344 215.763 142.856 216.395 142.856Z"
      fill="#4091F2"
    />
    <path
      d="M130.937 24.6593C134.986 20.6109 140.266 17.8826 146.163 16.9145C156.46 15.2423 168.605 17.9706 174.854 26.9476C178.991 32.8442 179.783 41.1171 185.239 45.7817C187.087 47.3658 189.376 48.422 190.696 50.4462C192.984 53.7905 191.84 58.367 190.344 62.1515C188.848 65.9359 187.087 69.9843 188.232 73.9447C189.024 76.673 191.312 79.1373 190.872 82.0416C190.344 85.9141 185.679 87.1462 181.895 87.4102C178.815 87.6743 175.822 87.7623 172.742 87.5862C165.437 87.0582 159.012 86.8822 151.796 87.5862C144.139 88.3783 136.394 88.7304 128.737 88.7304C124.601 88.7304 120.288 88.5544 116.856 86.3541C114.919 85.122 113.335 83.0978 113.335 80.8095C113.335 78.7853 114.479 76.9371 115.36 75.0889C117.736 70.2483 115.272 68.4882 113.599 64.2637C111.135 58.015 118.176 55.2867 120.728 50.7982C123.368 46.0457 122.752 40.061 124.513 35.0445C125.921 30.908 128.121 27.4756 130.937 24.6593Z"
      fill="#00276F"
    />
    <path
      d="M170.981 38.7409C176.79 62.0634 166.228 79.4014 157.251 80.8095C144.402 82.7457 137.185 76.409 133.401 64.9678C131.729 59.8632 129.264 40.6771 134.633 35.0445C143.522 25.6275 166.052 18.8507 170.981 38.7409Z"
      fill="#F886BC"
    />
    <path
      d="M140.617 69.8963L139.121 93.3949C139.121 93.3949 136.305 102.812 153.907 102.812C171.509 102.812 167.724 93.3949 167.724 93.3949L164.38 67.6081"
      fill="#F886BC"
    />
    <path
      d="M170.277 56.2548C171.334 55.0227 173.71 54.8466 175.118 55.8148C176.35 56.6948 176.614 58.103 176.702 59.4231C176.79 60.6553 176.702 61.9754 176.174 63.1195C175.646 64.2637 174.678 65.4078 173.27 65.9359C171.862 66.4639 170.101 66.2879 169.045 65.4078"
      fill="#F886BC"
    />
    <path
      d="M127.329 51.0622C138.33 48.862 148.363 42.8773 155.052 34.5164C160.86 41.1171 168.517 46.3977 176.966 49.5661C177.67 44.1975 178.286 38.4769 175.822 33.6363C174.062 30.292 171.069 27.6517 168.077 25.1874C165.525 23.0752 162.797 20.9629 159.628 19.8188C153.468 17.6186 146.339 19.5548 140.97 23.0752C133.313 28.0037 128.033 36.1006 126.889 44.7255"
      fill="#00276F"
    />
    <path
      d="M153.908 20.4349C153.292 20.7869 152.587 20.2588 152.147 19.7308C150.387 17.7066 148.539 15.1543 149.331 12.602C149.683 11.4579 150.651 10.4898 151.619 9.78568C153.996 8.1135 157.252 7.58545 159.892 8.72957C162.533 9.8737 164.469 12.866 164.029 15.6823C163.589 18.5867 160.772 20.9629 157.868 20.5229"
      fill="#00276F"
    />
    <path
      d="M141.322 77.8171C141.322 77.8171 145.018 83.0097 151.795 83.5378C160.42 84.1538 164.204 76.233 164.204 76.233"
      stroke="#D34F9B"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linejoin="round"
    />
    <path
      d="M134.281 56.2548C133.225 55.0227 130.849 54.8466 129.441 55.8148C128.208 56.6948 127.944 58.103 127.856 59.4231C127.768 60.6553 127.856 61.9754 128.384 63.1195C128.913 64.2637 129.881 65.4078 131.289 65.9359C132.697 66.4639 134.457 66.2879 135.513 65.4078"
      fill="#F886BC"
    />
    <path
      d="M153.907 66.8159C153.907 66.8159 150.035 67.608 150.035 65.3198C150.035 63.1195 151.267 53.2625 151.267 53.2625"
      stroke="#D34F9B"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linejoin="round"
    />
    <path
      d="M161.476 55.5507C161.476 55.9027 161.476 56.2548 161.476 56.5188C161.476 56.6948 161.476 56.8709 161.476 57.0469C161.476 57.3109 161.476 57.5749 161.564 57.751C161.564 57.927 161.652 58.015 161.74 58.103C161.828 58.191 161.916 58.279 162.004 58.367C162.18 58.455 162.444 58.543 162.708 58.455C162.884 58.455 163.149 58.367 163.325 58.191C163.501 58.015 163.677 57.751 163.589 57.4869C163.589 57.0469 163.501 56.6948 163.413 56.2548C163.325 55.9027 163.325 55.6387 163.237 55.2867C163.149 55.1107 162.972 54.8466 162.796 54.7586C162.62 54.6706 162.444 54.5826 162.18 54.6706C161.828 54.7586 161.476 55.1107 161.476 55.5507Z"
      fill="#00276F"
    />
    <path
      d="M159.1 50.4462C159.1 50.4462 162.092 48.1579 166.933 51.0622"
      stroke="#00276F"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M141.058 55.3747C140.97 55.7267 140.882 55.9908 140.882 56.3428C140.794 56.7828 140.706 57.1349 140.706 57.5749C140.706 57.839 140.794 58.103 140.97 58.279C141.146 58.455 141.322 58.543 141.586 58.543C141.85 58.543 142.026 58.543 142.29 58.455C142.466 58.279 142.73 58.103 142.73 57.839C142.73 57.5749 142.818 57.3109 142.818 57.1349C142.818 56.9589 142.818 56.7829 142.818 56.6068C142.818 56.2548 142.818 55.9027 142.818 55.6387C142.818 55.1987 142.466 54.8466 142.026 54.7586C141.586 54.6706 141.146 54.9346 141.058 55.3747Z"
      fill="#00276F"
    />
    <path
      d="M145.194 50.4462C145.194 50.4462 142.202 48.1579 137.361 51.0622"
      stroke="#00276F"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M146.426 71.1284H159.1C159.1 71.1284 157.34 77.6411 152.675 77.6411C148.099 77.6411 146.426 71.1284 146.426 71.1284Z"
      fill="white"
    />
    <path d="M102.246 163.803L110.871 127.807" stroke="#00276F" stroke-miterlimit="10" />
    <path d="M203.545 162.57L190.872 129.303" stroke="#00276F" stroke-miterlimit="10" />
    <path
      d="M134.457 93.3069C134.457 93.3069 134.017 106.948 153.996 106.948C171.861 106.948 172.742 93.7469 172.742 93.7469"
      stroke="#00276F"
      stroke-miterlimit="10"
    />
    <path
      d="M67.2869 165.789L74.9214 154.286L100.879 163.083L90.9539 180H46.6737L41.3296 113.007L55.8351 110.977L67.2869 165.789Z"
      fill="#F886BC"
    />
    <path d="M67.044 165.113V173.91" stroke="#C24882" />
    <rect x="31.8558" y="109.624" width="60.9026" height="39.2483" transform="rotate(-90 31.8558 109.624)" fill="#03844E" />
    <rect x="33.2094" y="108.27" width="58.1958" height="36.5415" transform="rotate(-90 33.2094 108.27)" fill="white" />
    <rect
      x="42.6831"
      y="105.563"
      width="24.361"
      height="18.9475"
      transform="rotate(-90 42.6831 105.563)"
      fill="#C4C4C4"
      fill-opacity="0.45"
    />
    <rect x="42.6831" y="77.1423" width="24.361" height="18.9475" transform="rotate(-90 42.6831 77.1423)" fill="#5CB5FE" />
    <rect
      x="35.9162"
      y="101.503"
      width="44.6619"
      height="4.06017"
      transform="rotate(-90 35.9162 101.503)"
      fill="#03844E"
      fill-opacity="0.1"
    />
    <rect width="44.6619" height="1.35339" transform="matrix(0 -1 -1 0 67.0441 101.503)" fill="#03844E" />
    <mask id="mask1" mask-type="alpha" maskUnits="userSpaceOnUse" x="42" y="52" width="20" height="26">
      <rect x="42.6831" y="77.1423" width="24.361" height="18.9475" transform="rotate(-90 42.6831 77.1423)" fill="#5CB5FE" />
    </mask>
    <g mask="url(#mask1)">
      <path
        d="M64.612 76.6864C60.9136 71.4472 54.8114 71.5512 54.8114 71.5512H54.3239H50.2053H49.7178C49.7178 71.5512 44.019 71.0106 40.3206 76.2706C37.8495 79.7633 35.9162 85.1272 35.9162 85.1272L42.6573 88.1834L41.9344 92.0296H63.1327L62.0063 87.8924L68.7642 84.6491C68.7474 84.6699 67.0831 80.1999 64.612 76.6864Z"
        fill="#0074E7"
      />
      <path
        d="M51.0959 79.1187L51.8524 77.892"
        stroke="#4091F2"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M46.3553 77.3097L47.2967 78.3284"
        stroke="#4091F2"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M60.4427 77.8511L61.0983 79.1817"
        stroke="#4091F2"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M58.0891 74.15L58.9969 73.1105"
        stroke="#4091F2"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M44.6742 73.6316L45.9182 73.4029"
        stroke="#4091F2"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M54.5252 75.9176L55.6852 76.4997"
        stroke="#4091F2"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M44.1866 75.9179C44.3073 75.9179 44.4052 75.7969 44.4052 75.6476C44.4052 75.4983 44.3073 75.3773 44.1866 75.3773C44.0659 75.3773 43.9681 75.4983 43.9681 75.6476C43.9681 75.7969 44.0659 75.9179 44.1866 75.9179Z"
        fill="#4091F2"
      />
      <path
        d="M58.0891 77.5175C58.2098 77.5175 58.3077 77.3965 58.3077 77.2473C58.3077 77.098 58.2098 76.977 58.0891 76.977C57.9684 76.977 57.8706 77.098 57.8706 77.2473C57.8706 77.3965 57.9684 77.5175 58.0891 77.5175Z"
        fill="#4091F2"
      />
      <path
        d="M49.5327 76.3125C49.6534 76.3125 49.7513 76.1915 49.7513 76.0422C49.7513 75.893 49.6534 75.772 49.5327 75.772C49.412 75.772 49.3142 75.893 49.3142 76.0422C49.3142 76.1915 49.412 76.3125 49.5327 76.3125Z"
        fill="#4091F2"
      />
      <path
        d="M61.5356 76.5827C61.6563 76.5827 61.7541 76.4617 61.7541 76.3124C61.7541 76.1631 61.6563 76.0421 61.5356 76.0421C61.4149 76.0421 61.317 76.1631 61.317 76.3124C61.317 76.4617 61.4149 76.5827 61.5356 76.5827Z"
        fill="#4091F2"
      />
      <path
        d="M48.1206 55.3348C48.8939 54.3785 49.9025 53.734 51.0288 53.5053C52.9957 53.1102 55.3155 53.7547 56.5091 55.8754C57.2992 57.2683 57.4505 59.2226 58.4928 60.3245C58.8458 60.6987 59.2829 60.9482 59.535 61.4264C59.9721 62.2164 59.7535 63.2975 59.4678 64.1915C59.182 65.0855 58.8458 66.0418 59.0643 66.9774C59.2156 67.6219 59.6527 68.204 59.5686 68.8901C59.4678 69.8049 58.5768 70.0959 57.8539 70.1583C57.2656 70.2207 56.694 70.2415 56.1056 70.1999C54.7104 70.0751 53.4832 70.0336 52.1047 70.1999C50.6422 70.387 49.1629 70.4702 47.7003 70.4702C46.9102 70.4702 46.0865 70.4286 45.4309 69.9088C45.0611 69.6178 44.7585 69.1396 44.7585 68.599C44.7585 68.1208 44.977 67.6843 45.1451 67.2477C45.599 66.1042 45.1283 65.6884 44.8089 64.6904C44.3382 63.2143 45.6831 62.5698 46.1706 61.5095C46.6749 60.3869 46.5572 58.9731 46.8934 57.7881C47.1624 56.8109 47.5827 56.0001 48.1206 55.3348Z"
        fill="#00276F"
      />
      <path
        d="M55.7694 58.6613C56.8789 64.1707 54.8616 68.2664 53.147 68.5991C50.6926 69.0565 49.3141 67.5596 48.5913 64.8568C48.2719 63.651 47.8012 59.1187 48.8266 57.7881C50.5245 55.5636 54.828 53.9627 55.7694 58.6613Z"
        fill="#F886BC"
      />
      <path
        d="M49.9695 66.0213L49.6837 71.5723C49.6837 71.5723 49.1457 73.7968 52.5079 73.7968C55.87 73.7968 55.1471 71.5723 55.1471 71.5723L54.5083 65.4807"
        fill="#F886BC"
      />
      <path
        d="M55.635 62.7986C55.8368 62.5075 56.2907 62.466 56.5596 62.6947C56.795 62.9026 56.8454 63.2352 56.8622 63.5471C56.879 63.8381 56.8622 64.15 56.7614 64.4203C56.6605 64.6905 56.4756 64.9608 56.2066 65.0855C55.9376 65.2103 55.6014 65.1687 55.3997 64.9608"
        fill="#F886BC"
      />
      <path
        d="M47.4311 61.5719C49.5324 61.0522 51.4488 59.6384 52.7265 57.6634C53.836 59.2226 55.2985 60.4701 56.9123 61.2185C57.0468 59.9503 57.1645 58.5989 56.6938 57.4555C56.3575 56.6654 55.786 56.0417 55.2144 55.4596C54.7269 54.9606 54.2058 54.4617 53.6006 54.1914C52.4239 53.6716 51.0622 54.129 50.0368 54.9606C48.5742 56.1249 47.5656 58.0376 47.347 60.075"
        fill="#00276F"
      />
      <path
        d="M52.5082 54.3371C52.3906 54.4203 52.2561 54.2955 52.172 54.1708C51.8358 53.6926 51.4828 53.0897 51.6341 52.4868C51.7013 52.2165 51.8862 51.9878 52.0712 51.8215C52.525 51.4265 53.147 51.3017 53.6513 51.572C54.1557 51.8423 54.5255 52.5491 54.4414 53.2144C54.3574 53.9005 53.8195 54.4619 53.2647 54.3579"
        fill="#00276F"
      />
      <path
        d="M50.1042 67.8914C50.1042 67.8914 50.8103 69.118 52.1047 69.2427C53.7521 69.3883 54.475 67.5172 54.475 67.5172"
        stroke="#D34F9B"
        stroke-width="0.3"
        stroke-miterlimit="10"
        stroke-linejoin="round"
      />
      <path
        d="M48.7594 62.7986C48.5576 62.5075 48.1038 62.466 47.8348 62.6947C47.5994 62.9026 47.549 63.2352 47.5322 63.5471C47.5154 63.8381 47.5322 64.15 47.6331 64.4203C47.7339 64.6905 47.9188 64.9608 48.1878 65.0855C48.4568 65.2103 48.793 65.1687 48.9947 64.9608"
        fill="#F886BC"
      />
      <path
        d="M52.5081 65.2936C52.5081 65.2936 51.7685 65.4807 51.7685 64.9402C51.7685 64.4204 52.0038 62.0919 52.0038 62.0919"
        stroke="#D34F9B"
        stroke-width="0.3"
        stroke-miterlimit="10"
        stroke-linejoin="round"
      />
      <path
        d="M53.954 62.6325C53.954 62.7156 53.954 62.7988 53.954 62.8612C53.954 62.9027 53.954 62.9443 53.954 62.9859C53.954 63.0483 53.954 63.1106 53.9708 63.1522C53.9708 63.1938 53.9876 63.2146 54.0044 63.2354C54.0212 63.2562 54.038 63.277 54.0548 63.2978C54.0885 63.3186 54.1389 63.3393 54.1893 63.3185C54.2229 63.3185 54.2734 63.2978 54.307 63.2562C54.3406 63.2146 54.3742 63.1522 54.3574 63.0899C54.3574 62.9859 54.3406 62.9027 54.3238 62.7988C54.307 62.7156 54.307 62.6533 54.2902 62.5701C54.2734 62.5285 54.2398 62.4661 54.2061 62.4454C54.1725 62.4246 54.1389 62.4038 54.0885 62.4246C54.0212 62.4454 53.954 62.5285 53.954 62.6325Z"
        fill="#00276F"
      />
      <path
        d="M53.5001 61.4269C53.5001 61.4269 54.0716 60.8864 54.9962 61.5724"
        stroke="#00276F"
        stroke-width="0.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M50.0538 62.5909C50.0369 62.674 50.0201 62.7364 50.0201 62.8196C50.0033 62.9235 49.9865 63.0067 49.9865 63.1106C49.9865 63.173 50.0033 63.2354 50.0369 63.2769C50.0706 63.3185 50.1042 63.3393 50.1546 63.3393C50.2051 63.3393 50.2387 63.3393 50.2891 63.3185C50.3227 63.2769 50.3732 63.2354 50.3732 63.173C50.3732 63.1106 50.39 63.0482 50.39 63.0067C50.39 62.9651 50.39 62.9235 50.39 62.8819C50.39 62.7988 50.39 62.7156 50.39 62.6532C50.39 62.5493 50.3227 62.4661 50.2387 62.4453C50.1546 62.4245 50.0706 62.4869 50.0538 62.5909Z"
        fill="#00276F"
      />
      <path
        d="M50.8437 61.4269C50.8437 61.4269 50.2721 60.8864 49.3475 61.5724"
        stroke="#00276F"
        stroke-width="0.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M51.0792 66.3122H53.4999C53.4999 66.3122 53.1637 67.8507 52.2727 67.8507C51.3986 67.8507 51.0792 66.3122 51.0792 66.3122Z"
        fill="white"
      />
      <path
        d="M48.7929 71.5516C48.7929 71.5516 48.7089 74.7741 52.5249 74.7741C55.9374 74.7741 56.1055 71.6555 56.1055 71.6555"
        stroke="#00276F"
        stroke-miterlimit="10"
      />
    </g>
    <path
      d="M52.8128 92.4429C52.8128 92.9602 52.5262 93.3656 52.1592 93.3656C51.7922 93.3656 51.5057 92.9602 51.5057 92.4429C51.5057 91.9255 51.7922 91.5201 52.1592 91.5201C52.5237 91.5201 52.8128 91.9255 52.8128 92.4429ZM51.8601 92.4429C51.8601 92.7687 52.0185 93.0124 52.1567 93.0124C52.2975 93.0124 52.4533 92.7687 52.4533 92.4429C52.4533 92.1171 52.2975 91.8733 52.1567 91.8733C52.0159 91.8733 51.8601 92.1171 51.8601 92.4429Z"
      fill="#2D2D2D"
    />
    <path
      d="M53.4816 92.5226C53.4816 93.4602 52.8883 94.2213 52.1568 94.2213C51.4254 94.2213 50.8321 93.4577 50.8321 92.5226C50.8321 91.5849 51.4254 90.8239 52.1568 90.8239C52.8883 90.8239 53.4816 91.5849 53.4816 92.5226ZM51.1891 92.5226C51.1891 93.2637 51.6239 93.8681 52.1568 93.8681C52.6898 93.8681 53.1246 93.2637 53.1246 92.5226C53.1246 91.7814 52.6898 91.177 52.1568 91.177C51.6239 91.177 51.1891 91.7814 51.1891 92.5226Z"
      fill="#2D2D2D"
    />
    <path
      d="M54.1528 92.602C54.1528 93.9675 53.2579 95.0792 52.1569 95.0792C51.0559 95.0792 50.161 93.9675 50.161 92.602C50.161 91.2366 51.0559 90.1248 52.1569 90.1248C53.2579 90.1248 54.1528 91.2366 54.1528 92.602ZM50.518 92.602C50.518 93.7735 51.252 94.726 52.1569 94.726C53.0593 94.726 53.7958 93.7735 53.7958 92.602C53.7958 91.4306 53.0618 90.478 52.1569 90.478C51.2545 90.478 50.518 91.4306 50.518 92.602Z"
      fill="#2D2D2D"
    />
    <path
      d="M54.8238 92.6791C54.8238 94.4724 53.6272 95.9323 52.1567 95.9323C50.6862 95.9323 49.4896 94.4724 49.4896 92.6791C49.4896 90.8859 50.6862 89.426 52.1567 89.426C53.6272 89.426 54.8238 90.8859 54.8238 92.6791ZM49.8491 92.6791C49.8491 94.2784 50.8848 95.5792 52.1592 95.5792C53.4312 95.5792 54.4693 94.2784 54.4693 92.6791C54.4693 91.0799 53.4337 89.7791 52.1592 89.7791C50.8848 89.7791 49.8491 91.0799 49.8491 92.6791Z"
      fill="#2D2D2D"
    />
    <path
      d="M55.4948 92.7589C55.4948 94.9799 53.9991 96.788 52.159 96.788C50.319 96.788 48.8233 94.9799 48.8233 92.7589C48.8233 90.5378 50.319 88.7297 52.159 88.7297C53.9966 88.7297 55.4948 90.5378 55.4948 92.7589ZM49.1778 92.7589C49.1778 94.7859 50.5151 96.4349 52.1565 96.4349C53.8005 96.4349 55.1353 94.7859 55.1353 92.7589C55.1353 90.7318 53.798 89.0829 52.1565 89.0829C50.5151 89.0829 49.1778 90.7318 49.1778 92.7589Z"
      fill="#2D2D2D"
    />
    <path
      d="M56.1635 92.8385C56.1635 95.4898 54.3662 97.6461 52.1566 97.6461C49.9471 97.6461 48.1498 95.4898 48.1498 92.8385C48.1498 90.1872 49.9471 88.0308 52.1566 88.0308C54.3662 88.0308 56.1635 90.1872 56.1635 92.8385ZM48.5067 92.8385C48.5067 95.2933 50.1432 97.293 52.1566 97.293C54.1701 97.293 55.8066 95.2958 55.8066 92.8385C55.8066 90.3837 54.1701 88.384 52.1566 88.384C50.1457 88.384 48.5067 90.3812 48.5067 92.8385Z"
      fill="#2D2D2D"
    />
    <path
      d="M56.8347 92.9154C56.8347 95.9945 54.7358 98.499 52.1567 98.499C49.5776 98.499 47.4787 95.9945 47.4787 92.9154C47.4787 89.8363 49.5776 87.3317 52.1567 87.3317C54.7358 87.3317 56.8347 89.8388 56.8347 92.9154ZM47.8381 92.9154C47.8381 95.8005 49.7762 98.1458 52.1592 98.1458C54.5422 98.1458 56.4803 95.8005 56.4803 92.9154C56.4803 90.0303 54.5422 87.6849 52.1592 87.6849C49.7762 87.6849 47.8381 90.0328 47.8381 92.9154Z"
      fill="#2D2D2D"
    />
    <path
      d="M57.506 92.9952C57.506 96.5021 55.1079 99.3548 52.1568 99.3548C49.2082 99.3548 46.8075 96.5021 46.8075 92.9952C46.8075 89.4883 49.2082 86.6356 52.1568 86.6356C55.1054 86.6356 57.506 89.4883 57.506 92.9952ZM47.167 92.9952C47.167 96.3081 49.4067 99.0016 52.1568 99.0016C54.9093 99.0016 57.1465 96.3056 57.1465 92.9952C57.1465 89.6848 54.9093 86.9887 52.1568 86.9887C49.4067 86.9887 47.167 89.6823 47.167 92.9952Z"
      fill="#2D2D2D"
    />
    <path
      d="M58.1772 93.0745C58.1772 97.0092 55.4774 100.213 52.1593 100.213C48.8412 100.213 46.1415 97.0117 46.1415 93.0745C46.1415 89.1398 48.8412 85.9364 52.1593 85.9364C55.4774 85.9364 58.1772 89.1374 58.1772 93.0745ZM46.4959 93.0745C46.4959 96.8152 49.0348 99.8595 52.1568 99.8595C55.2789 99.8595 57.8202 96.8152 57.8202 93.0745C57.8202 89.3338 55.2814 86.2896 52.1593 86.2896C49.0348 86.2896 46.4959 89.3338 46.4959 93.0745Z"
      fill="#2D2D2D"
    />
    <path
      d="M52.1573 101.068C48.4696 101.068 45.4682 97.516 45.4682 93.1535C45.4682 89.3134 47.7834 86.0353 50.9733 85.3613C51.0688 85.3414 51.1643 85.4011 51.1844 85.4981C51.2046 85.5926 51.1442 85.6871 51.0462 85.707C48.0197 86.3462 45.8227 89.4775 45.8227 93.1535C45.8227 97.322 48.6632 100.714 52.1547 100.714C55.6463 100.714 58.4868 97.322 58.4868 93.1535C58.4868 92.1089 58.3109 91.0991 57.9665 90.1491C57.9338 90.057 57.9816 89.9551 58.0746 89.9227C58.1676 89.8904 58.2706 89.9377 58.3033 90.0297C58.6628 91.0171 58.8438 92.0691 58.8438 93.1535C58.8463 97.516 55.8474 101.068 52.1573 101.068Z"
      fill="#2D2D2D"
    />
    <path
      d="M45.5079 96.4572C45.6009 96.4249 45.704 96.4721 45.7366 96.5641C46.8527 99.6059 49.3715 101.571 52.1567 101.571C54.9419 101.571 57.4607 99.6059 58.5767 96.5666C58.6094 96.4746 58.7125 96.4273 58.8055 96.4597C58.8985 96.492 58.9463 96.594 58.9136 96.686C57.7472 99.8671 55.0952 101.924 52.1592 101.924C49.2207 101.924 46.5687 99.8671 45.4048 96.6835C45.3671 96.5915 45.4149 96.492 45.5079 96.4572Z"
      fill="#2D2D2D"
    />
    <path
      d="M49.9219 102.111C50.6409 102.372 51.395 102.506 52.1566 102.506C54.7659 102.506 57.1992 100.907 58.5088 98.335C58.5541 98.248 58.6597 98.2131 58.7476 98.2554C58.8356 98.3002 58.8708 98.4047 58.8281 98.4917C57.4581 101.185 54.9016 102.857 52.1566 102.857C51.3523 102.857 50.5579 102.717 49.7988 102.441C49.7058 102.406 49.658 102.307 49.6932 102.215C49.7259 102.123 49.8289 102.076 49.9219 102.111Z"
      fill="#2D2D2D"
    />
    <path
      d="M46.4682 99.7252C46.5461 99.6655 46.6567 99.678 46.7195 99.7551C47.4083 100.623 48.2328 101.302 49.1654 101.78C49.2534 101.824 49.2886 101.931 49.2433 102.018C49.2106 102.078 49.1503 102.115 49.085 102.115C49.0573 102.115 49.0297 102.108 49.0045 102.095C48.0242 101.596 47.1619 100.882 46.4405 99.9764C46.3777 99.8968 46.3902 99.7874 46.4682 99.7252Z"
      fill="#2D2D2D"
    />
    <path
      d="M45.5656 98.2553C45.6536 98.2105 45.7617 98.2478 45.8044 98.3349C45.9653 98.6508 46.1463 98.9592 46.3423 99.2477C46.3976 99.3297 46.375 99.4392 46.2921 99.4939C46.2619 99.5138 46.2267 99.5237 46.194 99.5237C46.1362 99.5237 46.0809 99.4964 46.0457 99.4441C45.8421 99.1432 45.6536 98.8224 45.4851 98.4941C45.4424 98.4045 45.4776 98.2976 45.5656 98.2553Z"
      fill="#2D2D2D"
    />
    <path
      d="M57.9257 89.5976C57.9031 89.6051 57.883 89.61 57.8604 89.61C57.79 89.61 57.7221 89.5678 57.6945 89.4981C56.7242 87.0856 54.4141 85.5286 51.9431 85.6182C51.845 85.6231 51.7621 85.546 51.7571 85.449C51.7545 85.352 51.83 85.27 51.928 85.265C53.2427 85.2152 54.5196 85.5908 55.6207 86.3469C56.694 87.0831 57.5261 88.1302 58.0238 89.3688C58.064 89.4583 58.0187 89.5628 57.9257 89.5976Z"
      fill="#2D2D2D"
    />
    <path
      d="M37.2716 101.779C36.27 101.752 35.4572 102.702 35.4561 103.901C35.4549 105.099 36.2659 106.092 37.2674 106.119L37.2716 101.779ZM50.8014 106.483C51.803 106.509 52.6158 105.56 52.6169 104.361C52.6181 103.163 51.8071 102.169 50.8056 102.142L50.8014 106.483ZM37.2674 106.119L50.8014 106.483L50.8056 102.142L37.2716 101.779L37.2674 106.119Z"
      fill="#F886BC"
    />
    <path
      d="M37.2716 97.7191C36.27 97.6922 35.4572 98.642 35.4561 99.8405C35.4549 101.039 36.2659 102.032 37.2674 102.059L37.2716 97.7191ZM50.8014 102.422C51.803 102.449 52.6158 101.499 52.6169 100.301C52.6181 99.1025 51.8071 98.1092 50.8056 98.0823L50.8014 102.422ZM37.2674 102.059L50.8014 102.422L50.8056 98.0823L37.2716 97.7191L37.2674 102.059Z"
      fill="#F886BC"
    />
    <path
      d="M37.2716 105.839C36.27 105.813 35.4572 106.762 35.4561 107.961C35.4549 109.159 36.2659 110.153 37.2674 110.18L37.2716 105.839ZM50.8014 110.543C51.803 110.57 52.6158 109.62 52.6169 108.421C52.6181 107.223 51.8071 106.23 50.8056 106.203L50.8014 110.543ZM37.2674 110.18L50.8014 110.543L50.8056 106.203L37.2716 105.839L37.2674 110.18Z"
      fill="#F886BC"
    />
    <path
      d="M37.2716 109.9C36.27 109.873 35.4572 110.823 35.4561 112.021C35.4549 113.22 36.2659 114.213 37.2674 114.24L37.2716 109.9ZM50.8014 114.603C51.803 114.63 52.6158 113.68 52.6169 112.482C52.6181 111.283 51.8071 110.29 50.8056 110.263L50.8014 114.603ZM37.2674 114.24L50.8014 114.603L50.8056 110.263L37.2716 109.9L37.2674 114.24Z"
      fill="#F886BC"
    />
    <path d="M48.7732 115.037L42.0063 121.804L41.3296 115.037H48.7732Z" fill="#C24882" />
    <path
      d="M235.541 152.255L226.067 139.398L206.443 159.699L222.684 180H242.985L272 135.5V108V48.5L235.541 152.255Z"
      fill="#F886BC"
    />
    <path d="M236.218 152.932V161.729" stroke="#C24882" />
  </svg>
)
