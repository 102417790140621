import React, { useContext } from 'react'
import { Container, Button, Title, Option, Input, TextErrorInput } from '@provi/provi-components'
import { Form } from '../../../components/form'
import { TemplateContext } from '../../../components/template/context'
import { TemplateForm } from '../../../components/template-form'
import { SubTitle } from '../../../components/sub-title'
import { ContainerOptions } from './style'
import { useIncomeSource } from './hooks'

export const IncomeSource = () => {
  const { goToNextScreen, creditPath, setDataModal, isMobile } = useContext(TemplateContext)
  const [selectedOption, onSelectOption, onSubmit, onValueChange, value, isShowErrorText, isButtonDisabled] = useIncomeSource(
    goToNextScreen,
    creditPath,
    setDataModal
  )
  return (
    <TemplateForm>
      <Container>
        <Form onSubmit={onSubmit}>
          <Title text="Como imagina conseguir recursos para pagar as parcelas do financiamento?" />
          <ContainerOptions>
            {creditPath &&
              creditPath.ReadIncomeSource &&
              creditPath.ReadIncomeSource.options.map((item, index) => (
                <Option
                  key={item.id}
                  active={selectedOption && selectedOption.id === item.id}
                  func={() => onSelectOption(item)}
                  text={item.data}
                />
              ))}
          </ContainerOptions>

          {selectedOption && selectedOption.requiresExplanation && (
            <>
              <SubTitle>{selectedOption.question}</SubTitle>
              <Input autoFocus margin="normal" onChange={onValueChange} value={value} placeholder="Escreva aqui..." />

              {isShowErrorText && <TextErrorInput>Por favor, preencha o campo acima</TextErrorInput>}

              {isMobile && <div style={{ height: '27px' }} />}
            </>
          )}

          <Button
            marginHorizontal={0}
            type="submit"
            disabled={isShowErrorText || isButtonDisabled}
            text="Avançar"
            persistFloatingMode={false}
          />
        </Form>
      </Container>
    </TemplateForm>
  )
}
