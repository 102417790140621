import React, { useContext } from 'react'
import { Container, Title, SubTitle, SubHeaderDesktop, Button } from '@provi/provi-components'
import { TemplateContext } from '../../../components/template/context'
import { TemplateForm } from '../../../components/template-form'
import { Timer } from '../../../components/timer'
import { formatPhone } from '../../../utils'

import { CodeView, CodeNumber, ContainerComponent, BackLabel } from './style'
import { useCodeValidator } from './hooks'

export const PhoneConfirmation = props => {
  const { setIsShowModal, isMobile, goToNextScreen, setDataModal, setIsLoading, creditPath } = useContext(TemplateContext)
  const [code, setCodeNumber, addInput, setNextFocus, onKeyDown, sendVerification, counter] = useCodeValidator(
    goToNextScreen,
    setDataModal,
    setIsLoading,
    setIsShowModal,
    creditPath
  )

  return (
    <TemplateForm>
      <Container>
        {isMobile ? (
          <Title text="Verificação de celular" />
        ) : (
          <SubHeaderDesktop title="Verificação de celular" handleModal={() => setIsShowModal(true)} isShowButtonHelpModal />
        )}
        <SubTitle
          text={`Insira abaixo o código que enviamos via SMS para o número ${
            creditPath && creditPath.ReadPhone && !creditPath?.ReadPhone?.isVerified ? formatPhone(creditPath.ReadPhone) : ''
          }`}
        />
        <ContainerComponent>
          <CodeView>
            {code.map((value, position) => (
              <CodeNumber
                id={`id-${position}`}
                aria-label={`id-${position}`}
                cy={`cy-${position}`}
                ref={addInput}
                key={position}
                onKeyDown={e => onKeyDown(e, position)}
                onChange={({ target }) => {
                  setCodeNumber(target.value, position)
                  setNextFocus(target.value, position)
                }}
              />
            ))}
          </CodeView>

          {counter === 0 ? (
            <>
              <Button
                text="Reenviar código"
                icon={<></>}
                onClick={() => sendVerification(creditPath && creditPath.ReadPhone)}
              />
              <BackLabel onClick={() => props.history.goBack()}>Alterar celular</BackLabel>
            </>
          ) : (
            <Timer counter={counter} message="Reenviar SMS em" />
          )}
        </ContainerComponent>
      </Container>
    </TemplateForm>
  )
}
