import moment from 'moment'
import * as Yup from 'yup'
import { STATES } from '../constants'

const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]

function getFirstName(fullName) {
  return fullName ? fullName.split(' ')[0] : ''
}
function getLastName(fullName) {
  if (!fullName) return ''
  return fullName
    .trim()
    .split(' ')
    .filter((_name, index) => index > 0)
}
function hasNumbers(string) {
  return string.split('').filter(item => numbers.includes(parseInt(item))).length
}
function isEmptyString(string) {
  return string.trim() === ''
}
function isEmpty(string) {
  return string.trim().length === 0
}
function isEmptyArray(array) {
  return array.length === 0
}

export const isValidCpf = value => {
  if (!value) return false
  let sum = 0
  let rest
  let cpf = value.replace(/[^\d]+/g, '')
  if (cpf.length !== 11) return false
  if (cpf === '00000000000') return false
  if (cpf === '11111111111') return false
  if (cpf === '22222222222') return false
  if (cpf === '33333333333') return false
  if (cpf === '44444444444') return false
  if (cpf === '55555555555') return false
  if (cpf === '66666666666') return false
  if (cpf === '77777777777') return false
  if (cpf === '88888888888') return false
  if (cpf === '99999999999') return false
  for (let i = 1; i <= 9; i++) {
    sum = sum + parseInt(cpf.substring(i - 1, i), 10) * (11 - i)
  }
  rest = (sum * 10) % 11
  if (rest === 10 || rest === 11) rest = 0
  if (rest !== parseInt(cpf.substring(9, 10), 10)) return false
  sum = 0
  for (let i = 1; i <= 10; i++) {
    sum = sum + parseInt(cpf.substring(i - 1, i), 10) * (12 - i)
  }
  rest = (sum * 10) % 11
  if (rest === 10 || rest === 11) rest = 0
  if (rest !== parseInt(cpf.substring(10, 11), 10)) return false
  return true
}

export const validateBirthDate = value => {
  const date = moment(value, 'DD/MM/YYYY')
  const diff = date.diff(moment(), 'years') * -1
  return diff <= 110
}

export const validateBirthDateMinor18 = value => {
  const date = moment(value, 'DD/MM/YYYY')
  const diff = date.diff(moment(), 'years') * -1
  return diff >= 18
}

export const validateGraduationDate = value => {
  const date = moment(value, 'MM/YYYY')
  if (date.isBefore(moment(), 'month')) {
    return true
  }
  return date.isValid()
}

export const validateRG = rg => {
  if (!rg) {
    return false
  }

  if (typeof rg !== 'string') {
    return false
  }

  if (rg.length < 5) {
    return false
  }

  if (rg.length > 255) {
    return false
  }

  return !rg.includes(' ')
}

export const validateFullName = fullName => {
  let result = true

  /**
   * Regex below validate full name with this pattern,
   * when not match the pattern return error
   */

  const REGEX = /^[A-Za-zçáàâãéèêíïóôõöúüÁÀÂÃÉÈÊÍÏÓÔÕÖÚÜ\-' ]+$/
  if (!REGEX.test(fullName)) {
    result = false
    return result
  }

  const firstName = getFirstName(fullName)
  const lastName = getLastName(fullName)

  if (isEmpty(firstName) || isEmptyString(firstName) || isEmptyArray(lastName)) {
    result = false
    return result
  }

  lastName.forEach(name => {
    if (isEmpty(name) || isEmptyString(name) || hasNumbers(name)) result = false
  })
  return result
}

export const validateCouponCode = couponCode => {
  if (couponCode) {
    if (typeof couponCode !== 'string' || couponCode.length > 255) {
      return false
    }
  }

  return true
}

export const validatePhone = phone => {
  if (!phone) {
    return false
  }
  const _value = Number(phone.replace(/[^\d]+/g, ''))
  const regex = /^(?:(55\d{2})|\d{2})[6-9]\d{8}$/

  if (!regex.test(_value) || typeof _value !== 'number' || String(_value).length !== 11) {
    return false
  } else {
    return true
  }
}

export const validateText = text => {
  if (!text) {
    return false
  }

  const REGEX = /^[A-Za-zçáàâãéèêíïóôõöúüÁÀÂÃÉÈÊÍÏÓÔÕÖÚÜ'ªº\- ]+$/
  if (!REGEX.test(text)) {
    return false
  }

  return true
}

export const validateState = state => {
  if (!state) {
    return false
  }

  if (state.length === 2) {
    const findState = STATES.filter(st => st.label.toUpperCase() === state.toUpperCase())
    return findState.length > 0
  }

  return false
}

export const basicInfoValidationSchema = () =>
  Yup.object().shape({
    cpf: Yup.string()
      .required('Preencha o seu CPF')
      .test({
        name: 'cpf',
        message: 'CPF inválido',
        test: cpf => cpf && isValidCpf(cpf.replace(/[^\d]+/g, ''))
      }),
    fullName: Yup.string().test({
      name: 'fullName',
      message: 'Digite um nome válido',
      test: fullName => validateFullName(fullName) || !fullName
    }),
    birthDate: Yup.string()
      .test({
        name: 'birthDate',
        message: 'Data de nascimento inválida',
        test: birthDate => validateBirthDate(birthDate) || !birthDate
      })
      .test({
        name: 'birthDate 18 minor',
        message: 'Você precisa ser maior de 18 anos',
        test: birthDate => validateBirthDateMinor18(birthDate) || !birthDate
      })
      .test({
        name: 'birthDate required',
        message: 'Por favor, preencha sua data de nascimento',
        test: birthDate => moment(birthDate, 'DD/MM/YYYY', true).isValid() || !birthDate
      })
  })
