import React from 'react'
import * as Yup from 'yup'
import moment from 'moment'
import { withFormik } from 'formik'
import { Button, InputMasks, TextErrorInput } from '@provi/provi-components'
import { Form } from '../../../components/form'
import { validateGraduationDate } from '../../../utils/validators'

export const Formik = props => {
  const { handleChange, values, errors, touched, handleBlur, handleSubmit } = props
  return (
    <Form onSubmit={handleSubmit}>
      <InputMasks
        cy="input-full-name"
        autoFocus={!values.date}
        mask="99/9999"
        margin="normal"
        id="name"
        type="tel"
        placeholder="Ex: 12/2021"
        onChange={handleChange('date')}
        onBlur={handleBlur('date')}
        error={!!errors.date}
        value={values.date}
      />

      {errors.date && touched.date && <TextErrorInput text={errors.date} />}

      <Button
        marginHorizontal={0}
        type="submit"
        disabled={!moment(values.date, 'MM/YYYY', true).isValid()}
        text="Avançar"
        persistFloatingMode={false}
      />
    </Form>
  )
}

export const FormFomik = withFormik({
  validateOnBlur: true,
  validateOnChange: false,
  enableReinitialize: true,
  mapPropsToValues: props => ({
    date:
      !!props.data &&
      moment(props.data)
        .utcOffset(3)
        .format('MM/YYYY')
  }),
  validationSchema: Yup.object().shape({
    date: Yup.string()
      .test({
        name: 'date graduation required',
        message: 'Por favor, preencha sua data de graduação',
        test: date => moment(date, 'MM/YYYY', true).isValid()
      })
      .test({
        name: 'date graduation required',
        message: 'Digite uma data acima do dia de hoje.',
        test: value => validateGraduationDate(value)
      })
  }),
  handleSubmit: async ({ date }, { setSubmitting, props }) => {
    props.goToNextScreen(date)
    setSubmitting(false)
  }
})(Formik)
