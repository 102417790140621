import { useState, useEffect } from 'react'
import { showToast, theme } from '@provi/provi-components'
import * as Sentry from '@sentry/browser'
import { uploadImages } from '../../../services/api'
import { URL_EXTERNAL } from '../../../constants'
import { trackEvent } from '../../../components/google-analytics'

export const useProofOfAddress = (goToNextScreen, setDataModal, creditPath, setIsLoading, setIsShowModal) => {
  const [preview, setPreview] = useState()
  const [filesNotUpload, setFilesNotUpload] = useState([])
  const [filesUploaded, setFilesUploaded] = useState([])
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false)
  const [numberOfFiles, setNumberOfFiles] = useState(0)
  const [numberOfFilesInitial, setNumberOfFilesInitial] = useState(0)

  useEffect(() => {
    setDataModal({
      title: 'O que pode ser utilizado como comprovante de endereço?',
      texts: [
        'Contas de consumo: água, luz, gás, telefone, internet, etc.',
        'Contrato de aluguel assinado pelas duas partes.',
        'Boletos bancários: mensalidade escolar, plano de saúde, condomínio, financiamento, fatura de cartão de crédito, etc.',
        'Se a conta estiver no nome de algum familiar com o mesmo sobrenome, fique tranquilo que vamos aceitar. Se não for o caso, entre em contato com a gente.'
      ],
      textButton: 'Entendi',
      buttonFunction: () => setIsShowModal(false),
      textLink: {
        color: theme.colors.blue900,
        text: 'Abrir chat',
        onClick: () => window.open(URL_EXTERNAL, '_blank')
      },
      color: theme.colors.blue900
    })
    if (creditPath && creditPath.ReadProofOfAddress && creditPath.ReadProofOfAddress.length > 0) {
      setIsButtonDisabled(false)
      setNumberOfFiles(creditPath.ReadProofOfAddress.length)
      setNumberOfFilesInitial(creditPath.ReadProofOfAddress.length)
      setFilesUploaded(creditPath.ReadProofOfAddress)
      setPreview(creditPath.ReadProofOfAddress[0])
    }
  }, [setDataModal, creditPath, setIsShowModal])

  useEffect(() => {
    if (filesNotUpload.length > 0) {
      setIsButtonDisabled(false)
    }
  }, [filesNotUpload])

  useEffect(() => {
    if (filesNotUpload.length > 0) {
      setIsButtonDisabled(false)
    }
  }, [filesNotUpload])

  const onSubmit = async e => {
    e.preventDefault()
    setIsShowModalConfirm(true)
  }

  const onSubmitFormFull = async e => {
    trackEvent({ category: 'CreditPath', action: 'Submit Documents', label: 'documentStatus' })
    setIsLoading(true)
    try {
      const urls = await uploadImages(filesNotUpload)
      const files = [...urls, ...filesUploaded]

      if (!files.length) {
        /**
         * An error happened with upload to firebase and returns empty array reseting the page to reset the state
         */
        setIsLoading(false)
        showToast('Ocorreu um erro ao enviar o arquivo. Tente novamente ou entre em contato com a Provi.')
        Sentry.captureException(new Error('onSubmit(Proof of address): files is empty'))

        setTimeout(() => {
          window.location.reload()
        }, 3000)

        return
      } else {
        goToNextScreen(files)
      }
    } catch (e) {
      showToast()
    }
  }

  return [
    onSubmit,
    isButtonDisabled,
    numberOfFiles,
    isShowModalConfirm,
    setIsShowModalConfirm,
    onSubmitFormFull,
    preview,
    setPreview,
    setFilesNotUpload,
    filesNotUpload,
    setNumberOfFiles,
    numberOfFilesInitial
  ]
}
