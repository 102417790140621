import { useState, useEffect, useMemo } from 'react'

export const useHousingSituation = (creditPath, goToNextScreen) => {
  const [housingSituationOptions, setHousingSituationOptions] = useState([])
  const [housingSituation, setHousingSituation] = useState()
  const [complementOptions, setComplementOptions] = useState([])
  const [complement, setComplement] = useState()

  const requiresExplanationOptions = ['Próprio em financiamento', 'Alugado']
  const requiresExplanation = useMemo(() => {
    if (housingSituation && requiresExplanationOptions.includes(housingSituation.label)) {
      return true
    }

    return false
  }, [housingSituation, requiresExplanationOptions])

  const isButtonDisabled = useMemo(() => {
    if (!housingSituation) {
      return true
    }

    if (requiresExplanation && !complement) {
      return true
    }

    return false
  }, [housingSituation, requiresExplanation, complement])

  useEffect(() => {
    if (!creditPath) {
      return
    }

    if (creditPath.ReadHousingSituation) {
      setHousingSituationOptions(creditPath.ReadHousingSituation.housingSituationOptions)
      setComplementOptions(creditPath.ReadHousingSituation.housingSituationComplementOptions)
    }

    if (creditPath.ReadHousingSituation && creditPath.ReadHousingSituation.HousingSituationOptionId) {
      const selectedItem = creditPath.ReadHousingSituation.housingSituationOptions.find(
        item => item.id === creditPath.ReadHousingSituation.HousingSituationOptionId
      )

      if (selectedItem) {
        setHousingSituation(selectedItem)
      }
    }

    if (creditPath.ReadHousingSituation && creditPath.ReadHousingSituation.HousingSituationComplementOptionId) {
      const selectedItem = creditPath.ReadHousingSituation.housingSituationComplementOptions.find(
        item => item.id === creditPath.ReadHousingSituation.HousingSituationComplementOptionId
      )

      if (selectedItem) {
        setComplement(selectedItem)
      }
    }
  }, [creditPath, setHousingSituationOptions, setComplementOptions])

  const onSubmit = e => {
    e.preventDefault()

    const body = {
      HousingSituationOptionId: housingSituation.id,
      ...(requiresExplanation ? { HousingSituationComplementOptionId: complement.id } : {})
    }
    goToNextScreen(body, false)
  }

  return {
    onSubmit,
    isButtonDisabled,
    housingSituationOptions,
    housingSituation,
    setHousingSituation,
    complementOptions,
    complement,
    setComplement,
    requiresExplanation
  }
}
