import { useState } from 'react'
import { trackEvent } from '../../../components/google-analytics'

const DAY_OPTIONS = [
  { id: 1, value: 10, label: '10' }
  // { id: 2, value: 25, label: '25' }
]

export const usePaymentDateSelect = goToNextScreen => {
  const [paymentDate, setPaymentDate] = useState()
  const [data] = useState()
  const [isShowModal, setIsShowModal] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)

  // useEffect(() => {
  //   if (creditPath && creditPath.ReadPaymentDateSelection && creditPath.ReadPaymentDateSelection.data) {
  //     setData(creditPath.ReadPaymentDateSelection)
  //     onSelectOption(DAY_OPTIONS.find(item => item.value === creditPath.ReadPaymentDateSelection.data))
  //     return
  //   }
  // }, [creditPath])

  const onSelectOption = curse => {
    setPaymentDate(curse)
    setIsButtonDisabled(false)
  }

  const onSubmit = e => {
    e.preventDefault()

    if (paymentDate) {
      setIsShowModal(true)
    }
  }

  const onSubmitFormFull = () => {
    goToNextScreen(paymentDate.value)
    trackEvent({ category: 'CreditPath', action: 'Submit Basic Info', label: 'basicInfoStatus' })
  }

  return [
    paymentDate,
    onSelectOption,
    onSubmit,
    isButtonDisabled,
    DAY_OPTIONS,
    isShowModal,
    setIsShowModal,
    onSubmitFormFull,
    data
  ]
}
