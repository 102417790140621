import { useEffect, useState } from 'react'

export const useGuarantorInvite = (setDataModal, pathname) => {
  const [isShowBackButton, setIsShowBackButton] = useState(false)

  const getPathname = route => {
    const split = route.split('/')
    return split[split.length - 2]
  }

  useEffect(() => {
    setIsShowBackButton(getPathname(pathname) === 'avalista-convidar-dois')
    setDataModal({
      title: 'Quem eu coloco como meu avalista?',
      texts: [
        'Seu avalista precisa:',
        ' - Tem que ter mais de 18 anos e menos de 70',
        ' - Ser alguém de sua confiança, de preferência com o mesmo sobrenome',
        ' - Não ter pendência financeira no nome'
      ]
    })
  }, [setDataModal, pathname])
  return [isShowBackButton]
}
