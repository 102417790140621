import styled, { keyframes } from 'styled-components'
import { theme } from '@provi/provi-components'
import icon_search from '../../../assets/svg/icon-search.svg'

export const ContainerOption = styled.div`
  margin-top: ${theme.variables.spacer * 3}px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  display: grid;
  width: 100%;
  ${theme.breakpoints.down('md')} {
    grid-template-columns: 1fr;
  }
`

const animateFadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const Option = styled.div`
  position: relative;
  cursor: pointer;
  animation-name: ${animateFadeIn};
  animation-duration: 1s;
  width: 100%;
  min-height: 140px;
  border-radius: 20px;
  box-sizing: border-box;
  border: 1px solid ${theme.colors.blue900};
  padding: ${theme.variables.spacer * 2}px;
  &:focus {
    outline: none;
  }
`

export const Text = styled.p`
  width: 100%;
  margin: 0;
  font-size: ${({ size }) => (size ? size : '12')}px;
  color: ${({ color }) => (color ? color : theme.colors.blue900)};
  ${({ margin }) => margin && 'margin-left: 8px;'};
  ${({ title }) => title && 'height: 35px'};
  ${({ bold }) => (!bold ? 'font-family: "CenturyGothic", sans-serif;' : 'font-family: CenturyGothicBold,sans-serif;')};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`

export const Grid = styled.div`
  margin-top: ${theme.variables.spacer}px;
  display: flex;
  align-items: center;
`

export const ContainerDescont = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
`

export const ContainerSelectedCourse = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  background: ${theme.colors.blue600};
  border-radius: 20px;
  padding: 8px 0;
  position: relative;
  animation-name: ${animateFadeIn};
  animation-duration: 1s;
`
export const Left = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: ${theme.colors.white100};
  padding-left: 16px;
`

export const Right = styled.div`
  cursor: pointer;
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${theme.colors.white100};
  position: absolute;
  right: 0;
  top: 0;
  height: 53px;
  &:hover {
    opacity: 0.7;
  }
`

export const Header = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-family: CenturyGothicBold, sans-serif;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`

export const Bottom = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-family: CenturyGothicBold, sans-serif;
`

export const Search = styled.input`
  background: #ffffff;
  border: 1px solid #b9b7b7;
  box-sizing: border-box;
  border-radius: 50px;
  height: 40px;
  width: 500px;
  background-image: url(${icon_search});
  background-repeat: no-repeat;
  background-position: center left 10px;
  padding-left: 40px;
  font-size: 16px;
  outline: none;
  color: ${theme.colors.blue900};
  font-family: CenturyGothic, sans-serif;
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`
export const DownloadMore = styled.div`
  text-align: center;
  margin: 20px 0;
  cursor: pointer;
`

export const TextMore = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 20px;
  color: ${theme.colors.blue900};
  font-family: CenturyGothicBold, sans-serif;
`

export const ValueDiscount = styled.p`
  font-family: CenturyGothicBold, sans-serif;
  margin: 0;
  margin-top: ${({ marginTop }) => (marginTop ? '16px' : '4px')};
  font-size: 16px;
  line-height: 24px;
  color: ${({ color }) => color};
`

export const Title = styled.h1`
  font-family: 'CenturyGothicBold';
  margin-top: 7px;
  color: ${({ color }) => color};
  font-size: 24px;
  line-height: 30px;
  ${theme.breakpoints.down('md')} {
    font-size: 20px;
    line-height: 26px;
  }
`
