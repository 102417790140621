import React, { useContext } from 'react'
import moment from 'moment'
import { Form, Container, Button, Title, InputMasks, Selector, TextErrorInput, Input } from '@provi/provi-components'

import { TemplateContext } from '../../../components/template/context'
import { TemplateForm } from '../../../components/template-form'
import { Checked } from '../../../assets/svg/checked'
import { Unchecked } from '../../../assets/svg/unchecked'
import {
  SubTitle,
  ContainerOption,
  Option,
  OptionText,
  ItemCheck,
  WrapperIcon,
  ExtraDataText,
  ContainerSubTitle,
  ContainerQuestions
} from './styles'
import { useAllowanceTeachingDetails } from './hooks'

export const AllowanceTeachingDetails = () => {
  const { creditPath, goToNextScreen } = useContext(TemplateContext)

  const {
    onSubmit,
    courses,
    course,
    setCourse,
    onCourseChange,
    courseNotFound,
    setCourseNotFound,
    expectedGraduationDate,
    setExpectedGraduationDate,
    expectedGraduationDateError,
    universities,
    university,
    setUniversity,
    onUniversityChange,
    universityNotFound,
    setUniversityNotFound,
    isButtonDisabled,
    setWillStayInBrazil,
    willStayInBrazil,
    disapprovals,
    disapproval,
    setDisapproval,
    customUniversity,
    setCustomUniversity,
    customUniversityCourse,
    setCustomUniversityCourse
  } = useAllowanceTeachingDetails(creditPath, goToNextScreen)

  return (
    <TemplateForm isMarginDesktop>
      <Container isMarginDesktop>
        <Form onSubmit={onSubmit}>
          <Title text="Confirme suas informações de ensino" />

          <ContainerQuestions>
            <SubTitle>Qual curso você está cursando?</SubTitle>
            <Selector placeholder="Digite para buscar" options={courses} value={course} onValueChange={onCourseChange} />

            <ItemCheck
              onClick={() => {
                setCourse(null)
                setCourseNotFound(!courseNotFound)
              }}
            >
              <WrapperIcon>{!courseNotFound ? <Unchecked /> : <Checked />}</WrapperIcon>
              <ExtraDataText>Não encontrei o meu curso</ExtraDataText>
            </ItemCheck>
          </ContainerQuestions>

          {courseNotFound && (
            <ContainerQuestions>
              <SubTitle>Qual o nome do curso que você está cursando?</SubTitle>
              <Input
                margin="normal"
                placeholder="Escreva aqui..."
                value={customUniversityCourse}
                onChange={e => setCustomUniversityCourse(e.target.value)}
              />
            </ContainerQuestions>
          )}

          <ContainerQuestions>
            <ContainerSubTitle>
              <SubTitle>Data prevista de graduação</SubTitle>
            </ContainerSubTitle>

            <InputMasks
              mask="99/9999"
              onChange={e => setExpectedGraduationDate(e.target.value)}
              margin="normal"
              value={expectedGraduationDate}
              type="tel"
              placeholder={`Ex: ${moment()
                .add(12, 'months')
                .format('MM/YYYY')}`}
            />
            {expectedGraduationDateError && <TextErrorInput text={expectedGraduationDateError} />}
          </ContainerQuestions>

          <ContainerQuestions>
            <SubTitle>Neste curso, até hoje, quantas reprovações você teve?</SubTitle>
            <Selector
              placeholder="Escolher"
              options={disapprovals}
              value={disapproval}
              onValueChange={setDisapproval}
              isSearchable={false}
            />
          </ContainerQuestions>

          <ContainerQuestions>
            <SubTitle>Qual o nome da instituição de ensino superior em que você estuda?</SubTitle>
            <Selector
              placeholder="Digite para buscar"
              options={universities}
              value={university}
              onValueChange={onUniversityChange}
            />

            <ItemCheck
              onClick={() => {
                setUniversity(null)
                setUniversityNotFound(!universityNotFound)
              }}
            >
              <WrapperIcon>{!universityNotFound ? <Unchecked /> : <Checked />}</WrapperIcon>
              <ExtraDataText>Não encontrei a minha instituição</ExtraDataText>
            </ItemCheck>
          </ContainerQuestions>

          {universityNotFound && (
            <ContainerQuestions>
              <SubTitle>Qual o nome da instituição de ensino?</SubTitle>
              <Input
                margin="normal"
                placeholder="Escreva aqui..."
                value={customUniversity}
                onChange={e => setCustomUniversity(e.target.value)}
              />
            </ContainerQuestions>
          )}

          <ContainerQuestions>
            <SubTitle>Você nasceu no Brasil e terminará seus estudos no Brasil dentro dos próximos 24 meses?</SubTitle>
            <ContainerOption>
              <Option key={1} onClick={() => setWillStayInBrazil('SIM')} isSelected={willStayInBrazil === 'SIM'}>
                <OptionText isSelected={willStayInBrazil === 'SIM'}>SIM</OptionText>
              </Option>
              <Option key={2} onClick={() => setWillStayInBrazil('NÃO')} isSelected={willStayInBrazil === 'NÃO'}>
                <OptionText isSelected={willStayInBrazil === 'NÃO'}>NÃO</OptionText>
              </Option>
            </ContainerOption>
          </ContainerQuestions>

          <Button marginHorizontal={0} disabled={isButtonDisabled} text="Avançar" type="submit" persistFloatingMode={false} />
        </Form>
      </Container>
    </TemplateForm>
  )
}
