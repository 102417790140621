import { useState, useEffect } from 'react'

export const useProfissionalSituationSelect = (goToNextScreen, setDataModal, creditPath) => {
  const [selectedOption, setSelectedOption] = useState()
  const [isShowError, setIsShowError] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [options, setOptions] = useState([])
  const [valuesOptions, setValuesOptions] = useState([])
  const [selectedValueOption, setSelectedValueOption] = useState()

  const refactingArray = array =>
    array.map(item => ({
      id: item.id,
      label: item.data
    }))

  useEffect(() => {
    setDataModal({
      title: 'Por que precisamos saber a sua renda mensal?',
      texts: [
        'Precisamos saber a sua renda mensal para não te prejudicarmos financeiramente. Com base nela montaremos a melhor proposta de financiamento para você.',
        'Não se preocupe se não tiver renda ou se sua renda for baixa, isso não é um impeditivo para o financiamento!',
        'Fique tranquilo, as suas informações estão seguras.'
      ]
    })

    if (creditPath && creditPath.ReadProfessionalSituationWithOptions) {
      const arrayProfessionalSituation = refactingArray(
        creditPath.ReadProfessionalSituationWithOptions.professionalSituationOptions
      )
      setOptions(arrayProfessionalSituation)

      let professionalSituationId
      if (creditPath.ReadProfessionalSituationWithOptions.ProfessionalSituationOptionId) {
        professionalSituationId = arrayProfessionalSituation.find(
          item => item.id === creditPath.ReadProfessionalSituationWithOptions.ProfessionalSituationOptionId
        )
      }
      onSelectOption(professionalSituationId)
    }

    if (creditPath && creditPath.ReadProfessionalSituationWithOptions) {
      const arrayProfessionalSituationValues = refactingArray(
        creditPath.ReadProfessionalSituationWithOptions.incomeValueOptions
      )
      setValuesOptions(arrayProfessionalSituationValues)

      let incomeValueId
      if (creditPath.ReadProfessionalSituationWithOptions.IncomeValueOptionId) {
        incomeValueId = arrayProfessionalSituationValues.find(
          item => item.id === creditPath.ReadProfessionalSituationWithOptions.IncomeValueOptionId
        )
      }

      onSelectValueOption(incomeValueId)
    }
  }, [setDataModal, creditPath])

  const onSelectOption = option => {
    setIsShowError(false)
    setSelectedOption(option)
    if (option) {
      setIsButtonDisabled(false)
    }
  }

  const onSelectValueOption = option => {
    setIsShowError(false)
    setSelectedValueOption(option)
    if (option) {
      setIsButtonDisabled(false)
    }
  }

  const onSubmit = form => {
    form.preventDefault()
    const notHaveError = selectedOption && selectedValueOption

    const result = {}

    if (selectedOption) result.ProfessionalSituationOptionId = selectedOption.id
    if (selectedValueOption) result.IncomeValueOptionId = selectedValueOption.id

    if (notHaveError) {
      goToNextScreen(result)
    } else {
      setIsShowError(true)
    }
  }

  return [
    selectedOption,
    onSelectOption,
    onSelectValueOption,
    isShowError,
    isButtonDisabled,
    options,
    valuesOptions,
    selectedValueOption,
    onSubmit
  ]
}
