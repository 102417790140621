import React, { useContext } from 'react'
import { TemplateContext } from '../../../components/template/context'
import { TemplateForm } from '../../../components/template-form'
import { Container, Title, SubHeaderDesktop, Form, Modal, Button, SubTitle } from '@provi/provi-components'
import { IconCheck } from '../../../assets/svg/icon-check'
import { currencyParser, getSlugPartner } from '../../../utils'
import {
  ContainerOption,
  Option,
  Bold,
  Grid,
  TextContainer,
  ContainerDiscount,
  ValueDiscount,
  TitleInstallments,
  Text,
  WrapperIconCheck,
  ModalText,
  BlueText,
  PinkText
} from './style'

import { useVariableInstallmentSelection } from './hooks'
import { MesadaProviSlug } from '../../../constants'

export const VariableInstallmentSelection = () => {
  const { setIsShowModal, isMobile, goToNextScreen, setDataModal, creditPath } = useContext(TemplateContext)
  const [isShowModal, handleOpenModal, handleModal, handleOption, data] = useVariableInstallmentSelection(
    setDataModal,
    creditPath,
    goToNextScreen
  )

  const { daysToUpfrontPayment } = (creditPath && creditPath.ReadVariableInstallments) || {}

  const firstInstallmentFromCheckoutSimulation = creditPath?.ReadVariableInstallments?.options[0]?.options[0]?.price
  const firstInstallmentFromUpfrontAmount = creditPath?.ReadVariableInstallments?.upfrontAmount

  const firstInstallmentPrice = firstInstallmentFromCheckoutSimulation || firstInstallmentFromUpfrontAmount

  return (
    <TemplateForm>
      {isShowModal && creditPath?.ReadVariableInstallments?.daysToUpfrontPayment && creditPath?.partnerId !== 1344 && (
        <Modal
          title=""
          onDismiss={() => handleOpenModal(false)}
          footer={<Button marginHorizontal={0} type="button" text="Entendi" onClick={() => handleModal(data)} />}
        >
          <ModalText>
            Lembre-se que o primeiro pagamento no valor de <BlueText>{currencyParser(firstInstallmentPrice, 'R$')}</BlueText>{' '}
            deve ser realizado em até{' '}
            <PinkText>
              {daysToUpfrontPayment} dia{daysToUpfrontPayment > 1 ? `s ` : ' '}após a emissão{' '}
            </PinkText>{' '}
            do contrato com a Provi.
          </ModalText>
        </Modal>
      )}
      <Container isMarginDesktop>
        <Form>
          {isMobile ? (
            <>
              <Title text="Escolha a forma de pagamento que mais se adequa ao seu bolso" />
              {MesadaProviSlug === getSlugPartner() && (
                <SubTitle text="Caso deseje, no momento do pagamento, você poderá optar por pagar em menos parcelas ou à vista."></SubTitle>
              )}
            </>
          ) : (
            <>
              <SubHeaderDesktop
                title="Escolha a forma de pagamento que mais se adequa ao seu bolso"
                handleModal={() => setIsShowModal(true)}
              />
              {MesadaProviSlug === getSlugPartner() && (
                <SubTitle text="Caso deseje, no momento do pagamento, você poderá optar por pagar em menos parcelas ou à vista."></SubTitle>
              )}
            </>
          )}
          {creditPath && creditPath.ReadVariableInstallments && creditPath.ReadVariableInstallments.total > 0 && (
            <ValueDiscount color="blue">
              Valor total:{' '}
              {currencyParser(creditPath.ReadVariableInstallments && creditPath.ReadVariableInstallments.total, 'R$')}
            </ValueDiscount>
          )}
          <ContainerDiscount>
            {creditPath && creditPath.ReadVariableInstallments && creditPath.ReadVariableInstallments.discount > 0 && (
              <>
                <ValueDiscount color="red">
                  Desconto: {currencyParser(creditPath.ReadVariableInstallments.discount, 'R$')}
                </ValueDiscount>
                <ValueDiscount color="blue">
                  Valor final: {currencyParser(creditPath.ReadVariableInstallments.finalTotal, 'R$')}
                </ValueDiscount>
              </>
            )}
          </ContainerDiscount>
          <ContainerOption>
            {creditPath &&
              creditPath.ReadVariableInstallments &&
              creditPath.ReadVariableInstallments.options &&
              creditPath.ReadVariableInstallments.options.length > 0 &&
              creditPath.ReadVariableInstallments.options.map((item, key) => (
                <Option key={key} onClick={() => handleOption({ partnerConditionId: item.partnerConditionId })}>
                  <Grid>
                    <TextContainer>
                      <Bold>Opção {key + 1}</Bold>
                      {item.inconstantFlow ? (
                        <TitleInstallments>Comece pagando menos</TitleInstallments>
                      ) : (
                        <TitleInstallments>
                          {item.lastInstallment} parcelas fixas de {currencyParser(item.price, 'R$')}
                        </TitleInstallments>
                      )}
                      {item.inconstantFlow
                        ? item.options &&
                          item.options.length > 0 &&
                          item.options.map((product, index) => (
                            <Grid key={index}>
                              <WrapperIconCheck>
                                <IconCheck />
                              </WrapperIconCheck>
                              {product.lastInstallment === 1 ? (
                                <Text>Primeira parcela de {currencyParser(product.price, 'R$')}</Text>
                              ) : (
                                <Text>
                                  Parcela de {product.initialInstallment} a {product.lastInstallment} de{' '}
                                  {currencyParser(product.price, 'R$')}
                                </Text>
                              )}
                            </Grid>
                          ))
                        : item.options &&
                          item.options.length > 0 &&
                          item.options.map(product => (
                            <Grid key={product}>
                              <WrapperIconCheck>
                                <IconCheck />
                              </WrapperIconCheck>
                              <Text>{product}</Text>
                            </Grid>
                          ))}
                    </TextContainer>
                  </Grid>
                </Option>
              ))}
          </ContainerOption>
        </Form>
      </Container>
    </TemplateForm>
  )
}
