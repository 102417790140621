import React, { useContext } from 'react'
import { Container, Title } from '@provi/provi-components'
import { TemplateContext } from '../../../components/template/context'
import { Template } from '../../../components/template'
import { HeaderIntro } from '../../../components/header-intro'
import { getSlugPartner, navigateWithParams } from '../../../utils'
import { useCridSelection } from './hooks'
import { FormFormik } from './form'

export const Address = () => {
  const { goToNextScreen, setIsLoading, creditPath, isMobile } = useContext(TemplateContext)
  const partnerName = useCridSelection(setIsLoading)

  return (
    <Template goToPreviousScreen={() => navigateWithParams(`/cpf/${getSlugPartner()}`)}>
      <HeaderIntro partnerName={partnerName} />
      <Container>
        <Title text="Qual o seu CEP?" />
        <FormFormik data={creditPath && creditPath.ReadAddress} goToNextScreen={goToNextScreen} setIsLoading={setIsLoading} />
        {isMobile && <div style={{ height: '27px' }} />}
      </Container>
    </Template>
  )
}
