import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Title = styled.div`
  display: flex;
  text-align: left;
  font-style: normal;
  font-weight: bold;
  color: #fff;
  span {
    font-size: 1.2rem;
    padding: 10px;
    border-right: 2px solid #fff;
  }
  h1 {
    font-size: 1rem;
    padding-left: 10px;
  }
  svg {
    display: none;
  }

  ${theme.breakpoints.up('md')} {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20%;
    height: calc(100% - 78px);
    margin: 0;
    box-sizing: border-box;
    padding: 50px 10px 10px 10px;
    text-align: center;
    font-style: normal;
    font-weight: bold;
    color: #fff;
    span {
      font-size: 1.4rem;
      border-right: none;
    }
    h1 {
      font-size: 1.2rem;
    }
    svg {
      display: flex;
      width: 200px;
      height: 200px;
      fill: #fff;
    }
  }
`

export const DocumentContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: white;

  .react-pdf__Document {
    width: 800px;
    margin: 0 auto;
  }

  .react-pdf__message {
    color: white;
  }

  ${theme.breakpoints.down('md')} {
    height: calc(100% - 45px);
  }

  ${theme.breakpoints.up('md')} {
    height: calc(100% - 78px);
  }
`

export const HackButtonGlobals = styled.span`
  .MuiButton-startIcon.MuiButton-iconSizeMedium svg {
    width: 20px;
    height: 20px;
  }

  ${theme.breakpoints.down('md')} {
    .MuiButton-label svg {
      width: 24px !important;
      height: auto !important;
    }
  }
`

export const Container = styled.div`
  height: 100vh;
  background-color: ${theme.colors.blue900};
`

export const WrapperLoading = styled.div`
  width: 100%;
  height: calc(100% - 78px);
  top: 78px;
  position: fixed;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  ${theme.breakpoints.down('md')} {
    height: calc(100% - 45px);
    top: 45px;
  }
`

export const Header = styled.div`
  background-color: ${theme.colors.blue900};
`

export const CenterHeaderContent = styled.div`
  box-sizing: border-box;
  min-height: 78px;
  width: 100%;
  margin: 0 auto;
  max-width: 800px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${theme.breakpoints.down('md')} {
    min-height: 45px;
  }
`

export const OpacityTrick = styled.div`
  will-change: opacity;
  transition: opacity 250ms ease-in;
  opacity: ${({ hide }) => (hide ? '0' : '1')};
`

export const SignerBottomSheet = styled.div`
  display: flex;
  align-items: center;

  position: fixed;
  bottom: 0;
  left: 0;
  background-color: ${theme.colors.blue900};
  min-height: auto;
  width: 100%;
  will-change: transform;
  transform: translateY(${({ isOpen }) => (isOpen ? '0%' : '100%')});
  transition: transform 400ms ease-in-out;

  ${theme.breakpoints.up('md')} {
    padding: 12px 0;
  }

  ${theme.breakpoints.down('md')} {
    padding-bottom: 16px;
  }
`

export const SignerBottomSheetContainer = styled.div`
  width: 100%;
  max-width: 760px;
  margin: 0 auto;
  padding: 0 18px 100px;
`

export const SignerCallToAction = styled.button`
  width: 100%;
  border: 1px solid white;
  background-color: white;
  flex-grow: 1;
  border-radius: 3px;
  padding: 12px;
  color: ${theme.colors.blue900};
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  margin-right: 6px;
  cursor: pointer;
  outline: none;

  svg {
    width: 32px;
    margin-right: 17px;
  }

  ${({ checked }) => {
    if (checked) {
      return `
        background-color: ${theme.colors.blue600};
        color: white;
        cursor: initial;
      `
    }
  }}

  ${theme.breakpoints.down('md')} {
    margin-bottom: 12px;
    margin-right: 0px;

    svg {
      width: ${({ checked }) => (checked ? '30px' : '40px')};
      margin-right: ${({ checked }) => (checked ? '16px' : '16px')};
    }
  }
`

export const LayoutContent = styled.div`
  display: flex;
  justify-content: space-between;

  ${theme.breakpoints.down('md')} {
    flex-direction: column;
    align-items: center;
  }
`

export const HotClickableDragableArea = styled.button`
  width: 100%;
  text-align: center;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;

  /* kill itself on bigger screens */
  ${theme.breakpoints.up('md')} {
    display: none;
  }

  ${theme.breakpoints.down('md')} {
    padding: 14px;
  }
`

export const LoadingPosition = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 1000;
`

export const TextLoading = styled.p`
  color: ${theme.colors.blue900};
  font-family: 'CenturyGothicBold';
  font-size: 16px;
  line-height: 24px;
  margin-top: 120px;
  ${theme.breakpoints.down('md')} {
    font-size: 12px;
  }
`
