import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const OptionsList = styled.div`
  display: flex;
  margin-top: ${theme.variables.spacer * 4}px;
  ${theme.breakpoints.down('md')} {
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    display: grid;
  }
`

export const Option = styled.div`
  box-sizing: border-box;
  cursor: pointer;
  ${({ first }) => !first && 'margin-left: 8px'};
  padding: 10px;
  height: 108px;
  box-shadow: 2px 1px 15px -9px rgba(0, 0, 0, 0.5);
  width: 108px;

  ${theme.breakpoints.down('md')} {
    width: 100%;
    margin-left: 0;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  border-radius: 20px;
  background-color: ${({ isSelected }) => (isSelected ? theme.colors.blue900 : theme.colors.white100)};
`

export const OptionBold = styled.p`
  font-family: CenturyGothicBold;
  font-size: 20px;
  margin: 0;
  color: ${({ isSelected }) => (isSelected ? theme.colors.white100 : theme.colors.blue900)};
`

export const SubTitle = styled.h3`
  font-family: 'CenturyGothicBold', 'sans serif';
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: ${theme.colors.blue900};
  margin-top: 24px;
`
