import { useState, useEffect } from 'react'

export const usePersonalDocumentSelection = goToNextScreen => {
  const [option, setOption] = useState(false)

  useEffect(() => {
    option && onSubmit()
  }, [option])

  const onSubmit = async () => {
    goToNextScreen(option)
  }

  const onSelectOption = item => {
    setOption(item)
  }

  return [onSubmit, onSelectOption, option]
}
