import { useEffect } from 'react'
import { showToast } from '@provi/provi-components'
import { LOCALSTORAGE_TOKENS } from '../../constants'
import { history } from '../../navigation/history'
import { validateToken } from '../../services/auth'
import { getCreditPath, validationCrid } from '../../services/api'
import { getPathname, getTranslatedProductType, navigateWithParams } from '../../utils'

const setSelectCrid = async (crid, partner, setIsLoading, setCreditPath) => {
  try {
    await validationCrid(crid)
    const response = await getCreditPath(crid)
    setCreditPath(response)
    localStorage.setItem(LOCALSTORAGE_TOKENS.crid, crid)
    const pathname = getPathname(response.progress.currentScreenUniqueName)
    const productType = getTranslatedProductType(response.productType)
    history.push(`/formulario/${crid}/${productType}/${pathname}/${partner}`)
  } catch (error) {
    if (error.response) {
      showToast(error.response.data.error.message)
      return false
    }
    showToast()
  } finally {
    setIsLoading(false)
  }
}

export const useValidateToken = props => {
  const { match, setIsLoading, setCreditPath, history } = props
  const pathName = window.location.pathname

  useEffect(() => {
    const getData = async () => {
      const { token, partner, crid } = match.params
      const params = new URLSearchParams(history.location.search)
      const campaign = params.get('campaignSlug')
      const isIsa = pathName.split('/')?.[4] === 'isa' || pathName.split('/')?.[5] === 'isa'

      try {
        const { data } = await validateToken(token)
        localStorage.setItem(LOCALSTORAGE_TOKENS.dataUser, JSON.stringify(data))
        localStorage.setItem(LOCALSTORAGE_TOKENS.token, token)
        if (isIsa) {
          history.push(`/isa/intro/${partner}`)
          return false
        } else {
          if (crid) {
            setSelectCrid(parseInt(crid), partner, setIsLoading, setCreditPath)
            return false
          }
          if (campaign) {
            history.push(`/cpf/${partner}?campanha=${campaign}`)
            return false
          }
        }
        navigateWithParams(`/cpf/${partner}`)
      } catch (e) {
        if (isIsa) {
          history.push(`/isa/token-invalido/${partner}`)
          return
        }
        history.push(`/token-invalido/${partner}`)
      }
    }
    getData()
  }, [match.setIsLoading, setCreditPath, match.params, setIsLoading, history, pathName])
}
