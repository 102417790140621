import React, { useContext } from 'react'
import { Container, Button, theme } from '@provi/provi-components'
import { useLocation } from 'react-router-dom'

import { SelfieExample } from '../../../assets/svg/selfie-example'
import { Form } from '../../../components/form'
import { TemplateFormIsa } from '../../../components/template-isa'
import { TemplateForm } from '../../../components/template-form'
import { TemplateContext } from '../../../components/template/context'
import { useISAContext } from '../../../components/template/ISA/context'
import { Title, Text } from './style'

export const IntroSelfie = () => {
  const { isMobile, goToNextScreen } = useContext(TemplateContext)
  const { goToNextScreen: goToNextScreenISA } = useISAContext()
  const location = useLocation()

  const isISA = location.pathname.includes('/isa/')
  const CorrectTemplateForm = isISA ? TemplateFormIsa : TemplateForm
  const correctAdvanceScreen = isISA ? goToNextScreenISA : goToNextScreen

  return (
    <CorrectTemplateForm>
      <Container>
        <Form
          onSubmit={e => {
            e.preventDefault()
            correctAdvanceScreen()
          }}
        >
          <Title color={theme.colors.blue600}>Chegou a hora da selfie segurando o documento.</Title>
          <Title color={theme.colors.blue900}>A sua foto tem que ficar parecida com a imagem abaixo!</Title>
          <br />

          <SelfieExample isMobile={isMobile} />

          <Text bold>Pontos importantes:</Text>
          <Text>➤ Não cubra o seu rosto com o documento ou com outros acessórios</Text>
          <Text>➤ Tire a foto em um lugar bem iluminado</Text>

          {isMobile && <div style={{ height: '12px' }} />}

          <Button marginHorizontal={0} type="submit" text="Tirar minha foto agora" persistFloatingMode={false} />
        </Form>
      </Container>
    </CorrectTemplateForm>
  )
}
