import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { SubHeader, ButtonBackDesktop, Modal, Button, LoadingProvi, theme } from '@provi/provi-components'
import { View, Left, Right, TextModal } from './style'
import { URL_EXTERNAL } from '../../constants'
import { TemplateContext } from './context'
import { useTemplate } from './hooks'

export const Template = props => {
  const { isShowModal, setIsShowModal, dataModal, isMobile, isLoading, setCrid } = useContext(TemplateContext)
  const { crid } = useParams()
  useTemplate(setCrid, crid)
  return (
    <>
      {isShowModal && dataModal && (
        <Modal
          title={dataModal.title}
          onDismiss={() => setIsShowModal(false)}
          footer={
            dataModal.textButton && (
              <Button
                persistFloatingMode
                marginHorizontal={0}
                type="button"
                text={dataModal.textButton}
                onClick={() => (dataModal.buttonFunction ? dataModal.buttonFunction() : window.open(URL_EXTERNAL, '_blank'))}
                textLink={{
                  color: theme.colors.blue900,
                  text: dataModal && dataModal.textLink && dataModal.textLink.text,
                  onClick: () => dataModal && dataModal.textLink && dataModal.textLink.onClick && dataModal.textLink.onClick()
                }}
              />
            )
          }
        >
          {dataModal && dataModal.texts.map(item => <TextModal>{item}</TextModal>)}
        </Modal>
      )}

      {isLoading && <LoadingProvi />}

      <View>
        <Left>
          {!isMobile && <ButtonBackDesktop functionBackButton={props.goToPreviousScreen} />}
          {isMobile && (
            <SubHeader
              isShowButtonHelpModal={!!dataModal}
              functionBackButton={props.goToPreviousScreen}
              functionHelpModal={() => setIsShowModal(true)}
            />
          )}
          {props.children}
        </Left>
        {!isMobile && <Right />}
      </View>
    </>
  )
}
