import React from 'react'
import { Overlay, Header, Body, Container, Title, Content, TextModal, Bold } from './style'

export const ModalBlock = () => {
  return (
    <Overlay>
      <Container>
        <Content>
          <Header>
            <Title>Para prosseguir você vai precisar alterar as suas configurações de cookies no seu navegador</Title>
          </Header>
          <Body>
            <TextModal>
              <Bold>Passo 1</Bold>: Abra as configurações do Google Chrome{' '}
            </TextModal>
            <TextModal>
              <Bold>Passo 2</Bold>: Digite a palavra "cookie" na caixa de pesquisa{' '}
            </TextModal>
            <TextModal>
              <Bold>Passo 3</Bold>: Desative a terceira opção "Bloquear cookies de terceiros", ou em inglês "Block third-party
              cookies"
            </TextModal>
            <TextModal>
              <Bold>Passo 4</Bold>: Atualize essa página para prosseguir ;):
            </TextModal>
          </Body>
        </Content>
      </Container>
    </Overlay>
  )
}
