import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const OptionsList = styled.div`
  display: flex;
  margin-top: ${theme.variables.spacer * 4}px;
  ${({ isMobile }) => isMobile && 'justify-content: space-between'};
`

export const Option = styled.div`
  cursor: pointer;
  box-sizing: border-box;
  padding: 10px;
  height: ${theme.variables.spacer * 13.5}px;
  width: ${theme.variables.spacer * 16}px;
  box-shadow: 2px 1px 15px -9px rgba(0, 0, 0, 0.5);
  margin-left: ${theme.variables.spacer}px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  border-radius: 20px;
  background-color: ${({ isSelected }) => (isSelected ? theme.colors.blue900 : theme.colors.white100)};
  ${theme.breakpoints.down('md')} {
    width: 48%;
    margin: 0;
  }
`

export const OptionText = styled.p`
  font-family: 'CenturyGothic', sans-serif;
  font-size: 18px;
  margin: 0;
  color: ${({ isSelected }) => (isSelected ? theme.colors.white100 : theme.colors.blue900)};
`

export const Bold = styled.span`
  font-family: 'CenturyGothicBold', sans-serif;
  font-size: 20px;
  line-height: 30px;
  margin: 0;
  ${theme.breakpoints.down('md')} {
    font-size: 16px;
    line-height: 24px;
  }
`

export const OptionDay = styled.p`
  font-style: normal;
  font-size: 30px;
  margin: 0;
  margin-top: 15px;
  color: ${({ isSelected }) => (isSelected ? theme.colors.white100 : theme.colors.blue900)};
`

export const WrapperError = styled.div`
  margin-top: 20px;
`

export const SubTitle = styled.h5`
  font-family: 'CenturyGothic', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;

  color: ${({ isDark }) => (isDark ? theme.colors.pink900 : theme.colors.blue800)};
  margin: 0;
  margin-bottom: ${theme.variables.spacer * 3}px;
  ${theme.breakpoints.down('md')} {
    font-size: 16px;
    line-height: 20px;
  }
`

export const TextModal = styled.div`
  font-size: 16px;
  line-height: 24px;
  margin: 24px 0;
  color: ${theme.colors.blue900};

  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    line-height: 18px;
    margin-top: 20px;
  }
`
