import React, { useContext } from 'react'
import { Container, Title } from '@provi/provi-components'
import { TemplateContext } from '../../../components/template/context'
import { useISAContext } from '../../../components/template/ISA/context'
import { TemplateFormIsa } from '../../../components/template-isa'
import { FormFomik } from './form'
import { useLinkedin } from './hooks'

export const LinkedinProfile = () => {
  const { creditPath } = useContext(TemplateContext)
  const { goToNextScreen } = useISAContext()
  const [handleOption, option, setIsShowModal, isShowModal] = useLinkedin()
  return (
    <TemplateFormIsa>
      <Container>
        <Title text="Qual o seu perfil no LinkedIn" />
        <FormFomik
          data={creditPath && creditPath.ReadLinkedInProfile}
          goToNextScreen={goToNextScreen}
          handleOption={handleOption}
          option={option}
          setIsShowModal={setIsShowModal}
          isShowModal={isShowModal}
        />
      </Container>
    </TemplateFormIsa>
  )
}
