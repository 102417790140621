import React, { useContext } from 'react'
import { Option, Title, Container, Button, Input, TextErrorInput, Form } from '@provi/provi-components'
import { TemplateContext } from '../../../components/template/context'
import { TemplateForm } from '../../../components/template-form'

import { ContainerOptions, SubTitle } from './style'
import { useCourseObjective } from './hooks'

export const CourseObjective = props => {
  const { goToNextScreen, creditPath, isMobile } = useContext(TemplateContext)
  const [
    selectedOption,
    onSelectOption,
    onSubmit,
    onValueChange,
    value,
    isShowErrorText,
    isButtonDisabled
  ] = useCourseObjective(goToNextScreen, creditPath)

  const options = creditPath && creditPath.ReadCourseObjective && creditPath.ReadCourseObjective.options
  return (
    <TemplateForm>
      <Container>
        <Form onSubmit={onSubmit}>
          <Title text="Qual o seu objetivo com o curso?" />
          <ContainerOptions>
            {options &&
              options.map(item => (
                <Option
                  key={item.id}
                  active={selectedOption && selectedOption.id === item.id}
                  func={() => onSelectOption(item)}
                  text={item.data}
                />
              ))}
          </ContainerOptions>
          {selectedOption && selectedOption.requiresExplanation && (
            <>
              <SubTitle>{selectedOption.question}</SubTitle>
              <Input
                inputProps={{ 'aria-label': { value } }}
                autoFocus
                margin="normal"
                placeholder="Escreva aqui..."
                value={value}
                onChange={onValueChange}
              />
              {isShowErrorText && <TextErrorInput>Por favor, preencha o campo acima</TextErrorInput>}

              {isMobile && <div style={{ height: '27px' }} />}
            </>
          )}

          <Button
            marginHorizontal={0}
            type="submit"
            disabled={isButtonDisabled || isShowErrorText}
            text="Avançar"
            persistFloatingMode={false}
          />
        </Form>
      </Container>
    </TemplateForm>
  )
}
