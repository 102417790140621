import React, { useContext } from 'react'
import _ from 'lodash'
import { Container, Button, Title, SubHeaderDesktop, SubTitle, Modal, UploadBox } from '@provi/provi-components'
import { Form } from '../../../components/form'
import { TemplateContext } from '../../../components/template/context'
import { TemplateForm } from '../../../components/template-form'
import { useProofOfAddress } from './hooks'
import { TextModal } from './style'
import img_proof_address from '../../../assets/svg/preview_proof_of_address.svg'

const OPTIONS_DROPZONE = 'image/jpeg, image/png, application/pdf'

const formatCep = value => {
  const valueInitial = _.isNumber(value) ? JSON.stringify(value) : value
  const initial = valueInitial.slice(0, 5)
  const end = valueInitial.slice(5, 8)
  return `${initial}-${end}`
}

export const ProofOfAddress = () => {
  const { setIsShowModal, isMobile, goToNextScreen, setDataModal, setIsLoading, creditPath } = useContext(TemplateContext)
  const [
    onSubmit,
    isButtonDisabled,
    numberOfFiles,
    isShowModalConfirm,
    setIsShowModalConfirm,
    onSubmitFormFull,
    preview,
    setPreview,
    setFilesNotUpload,
    filesNotUpload,
    setNumberOfFiles,
    numberOfFilesInitial
  ] = useProofOfAddress(goToNextScreen, setDataModal, creditPath, setIsLoading, setIsShowModal)

  return (
    <TemplateForm>
      {isShowModalConfirm && (
        <Modal
          title="Você está avançando de etapa!"
          onDismiss={() => setIsShowModalConfirm(false)}
          footer={<Button marginHorizontal={0} type="button" text="Avançar" onClick={() => onSubmitFormFull()} />}
        >
          <TextModal>Lembre-se: ao avançar você não poderá mais alterar os documentos que você enviou.</TextModal>
        </Modal>
      )}
      <Container>
        <Form onSubmit={onSubmit}>
          {isMobile ? (
            <Title text="Envie o seu comprovante de endereço" />
          ) : (
            <SubHeaderDesktop
              title="Envie o seu comprovante de endereço"
              handleModal={() => setIsShowModal(true)}
              isShowButtonHelpModal
            />
          )}
          {creditPath && creditPath.ReadAddress && (
            <SubTitle
              text={`O comprovante deve ser do endereço: ${creditPath.ReadAddress.street}, n° ${creditPath.ReadAddress.number}, 
              ${creditPath.ReadAddress.complement ? creditPath.ReadAddress.complement + ',' : ''}
              CEP ${formatCep(creditPath.ReadAddress.zipcode)}`}
            />
          )}
          <UploadBox
            preview={preview}
            setPreview={setPreview}
            setFilesNotUpload={setFilesNotUpload}
            filesNotUpload={filesNotUpload}
            setNumberOfFiles={setNumberOfFiles}
            numberOfFiles={numberOfFiles}
            image={img_proof_address}
            numberOfFilesInitial={numberOfFilesInitial}
            options={OPTIONS_DROPZONE}
          />

          <Button marginHorizontal={0} type="submit" disabled={isButtonDisabled} text="Avançar" persistFloatingMode={false} />
        </Form>
      </Container>
    </TemplateForm>
  )
}
