import React, { useState } from 'react'
import { Text } from './style'
import { InstallmentsConstants } from '../../../assets/svg/installments-constants'
import { InstallmentsFixed } from '../../../assets/svg/installments-insconstants'

const OPTIONS = [
  {
    text: 'Parcelas fixas',
    value: 'fixed',
    id: 1
  },
  {
    text: 'Começar pagando menos',
    value: 'variable',
    id: 2
  }
]

const MODALS = [
  {
    title: 'Como funcionam as parcelas fixas?',
    children: (
      <>
        <Text>
          Caso você escolha as parcelas fixas, estará optando por um fluxo de pagamento tradicional onde você pagará sempre o
          mesmo valor na data escolhida. Veja no exemplo abaixo:
        </Text>
        <Text bold>Veja no exemplo abaixo:</Text>
        <InstallmentsConstants />
      </>
    )
  },
  {
    title: 'Como funcionam as parcelas não fixas?',
    children: (
      <>
        <Text>
          Caso você escolhaas parcelas não fixas, estará optando por um fluxo de pagamento onde você não vai pagar o mesmo valor
          todo mês, pois você vai começar pagando menos nos primeiros meses, e no final, quando faltarem menos parcelas, sua
          fatura será mais alta. Veja no exemplo abaixo:
        </Text>
        <Text bold>Veja no exemplo abaixo:</Text>
        <InstallmentsFixed />
      </>
    )
  }
]

export const usePreferConstantInstallments = goToNextScreen => {
  const [selectedOption, setSelectedOption] = useState()
  const [modalSelected, setModalSelected] = useState(0)
  const [isShowModal, setIsShowModal] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)

  const onSelectOption = option => {
    setSelectedOption(option)
    setIsButtonDisabled(false)
  }

  const onSubmit = e => {
    e.preventDefault()
    goToNextScreen(selectedOption.value)
  }

  return [
    selectedOption,
    onSelectOption,
    OPTIONS,
    onSubmit,
    isButtonDisabled,
    isShowModal,
    setIsShowModal,
    MODALS,
    modalSelected,
    setModalSelected
  ]
}
