import { useEffect, useState } from 'react'
import { showToast } from '@provi/provi-components'

import { checkIfGuarantorIsAnalyzed, getCreditPath } from '../../../services/api'
import { LOCALSTORAGE_TOKENS } from '../../../constants'

let interval = null
let firstRequestMade = false

export const useGuarantorBackgroundAnalysis = ({ cpf, goToNextScreen, eventName = 'ReadFirstGuarantorPersonalId' }) => {
  const [countDown, setCountDown] = useState(60)
  const [loadedCPF, setLoadedCPF] = useState(cpf)

  useEffect(() => {
    if (countDown > 0) {
      interval = setInterval(() => setCountDown(countDown => countDown - 1), 1000)
    } else if (countDown === 0) {
      showToast(
        'Ops! Parece que algo está demorando mais que o normal... Aguarde enquanto continuamos a análise do seu avalista...'
      )
      clearInterval(interval)
      firstRequestMade = false
      setCountDown(60)
    }

    return () => clearInterval(interval)
  }, [countDown])

  useEffect(() => {
    const CreditRequestId = localStorage.getItem(LOCALSTORAGE_TOKENS.crid)

    if (loadedCPF) {
      async function fetchData() {
        try {
          const data = await checkIfGuarantorIsAnalyzed({ CreditRequestId, cpf: loadedCPF })

          if (data.analyzed && !firstRequestMade) {
            firstRequestMade = true
            goToNextScreen()
          }
        } catch (error) {
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.error &&
            error.response.data.error.message
          ) {
            showToast(error.response.data.error.message)
          } else {
            console.error(error)
          }
        }
      }
      fetchData()
    } else {
      getCreditPath(CreditRequestId).then(data => setLoadedCPF(data[eventName]))
      firstRequestMade = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countDown, eventName, loadedCPF])

  return [countDown]
}
