import { useState, useEffect } from 'react'

export const useCourseObjective = (goToNextScreen, creditPath) => {
  const [value, setValue] = useState()
  const [selectedOption, setSelectedOption] = useState()
  const [isShowErrorText, setIsShowErrorText] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)

  useEffect(() => {
    if (creditPath) {
      const find =
        creditPath.ReadCourseObjective &&
        creditPath.ReadCourseObjective.options.find(item => item.id === creditPath.ReadCourseObjective.choosedId)
      if (find) {
        onSelectOption(find)
        setIsButtonDisabled(false)
        setIsShowErrorText(false)
        find.requiresExplanation && setValue(creditPath.ReadCourseObjective.answer)
      }
    }
  }, [creditPath])

  const onSelectOption = option => {
    setSelectedOption(option)
    setValue('')
    if (!option.requiresExplanation) {
      setIsButtonDisabled(false)
      setIsShowErrorText(false)
    } else {
      setIsButtonDisabled(true)
    }
  }

  const onSubmit = e => {
    e.preventDefault()
    const data = {
      courseObjectiveOptionId: selectedOption.id,
      explanation: value
    }

    if (selectedOption) {
      goToNextScreen(data)
      setIsShowErrorText(false)
    } else {
      setIsShowErrorText(true)
    }
  }

  const onValueChange = which => {
    const text = which.target.value
    setValue(text)
    if (text.length >= 1) {
      setIsShowErrorText(false)
      setIsButtonDisabled(false)
    } else {
      setIsShowErrorText(true)
    }
  }

  return [selectedOption, onSelectOption, onSubmit, onValueChange, value, isShowErrorText, isButtonDisabled]
}
