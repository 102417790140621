import React, { useContext } from 'react'
import { TemplateContext } from '../../../components/template/context'
import { Container, Title, SubTitle, Text, Wrapper } from './styles'

export const WaitingGuarantorSignature = () => {
  const { isMobile } = useContext(TemplateContext)
  return (
    <Container isMobile={isMobile}>
      <Wrapper>
        <Title>Ótimo!</Title>
        <SubTitle>Agora só falta seu avalista assinar.</SubTitle>
        <Text>Depois que o seu avalista assinar, o seu contrato será finalizado e seu financiamento efetivado ;)</Text>
      </Wrapper>
    </Container>
  )
}
