import React from 'react'

import svgProofEnrollment from '../../assets/svg/documents/proof-of-enrollment.svg'
import svgSchoolRecords from '../../assets/svg/documents/school-records.svg'
import svgSelfie from '../../assets/svg/documents/selfie.svg'
import svgRg from '../../assets/svg/documents/rg.svg'
import svgProofAddress from '../../assets/svg/documents/proof-of-address.svg'

import { DocumentList, DocumentItem, TextItem, UppercaseTextItem } from '../../screens/form/pre-document/style'
import { UppercaseText } from './styles'

const RequiredDocumentsList = ({ isAllowance = false, isMobile = false, isISA = false, isSanarLoan = false }) => {
  return (
    <DocumentList isMobile={isMobile}>
      {isAllowance && !isSanarLoan && (
        <>
          <DocumentItem isMobile={isMobile}>
            <img style={{ height: '100%', marginRight: '12px' }} src={svgProofEnrollment} alt="" />

            <TextItem isMobile={isMobile}>Comprovante de matrícula</TextItem>
          </DocumentItem>

          <DocumentItem isMobile={isMobile}>
            <img style={{ height: '100%', marginRight: '12px' }} src={svgSchoolRecords} alt="" />

            <TextItem isMobile={isMobile}>Histórico acadêmico</TextItem>
          </DocumentItem>
        </>
      )}

      {isSanarLoan && (
        <>
          <DocumentItem isMobile={isMobile}>
            <img style={{ height: '100%', marginRight: '12px' }} src={svgProofEnrollment} alt="" />

            <TextItem isMobile={isMobile}>
              Frente do <UppercaseText>CRM</UppercaseText>
            </TextItem>
          </DocumentItem>

          <DocumentItem isMobile={isMobile}>
            <img style={{ height: '100%', marginRight: '12px' }} src={svgSchoolRecords} alt="" />

            <TextItem isMobile={isMobile}>
              Verso do <UppercaseText>CRM</UppercaseText>
            </TextItem>
          </DocumentItem>
        </>
      )}

      <DocumentItem isMobile={isMobile}>
        <img style={{ height: '100%', marginRight: '12px' }} src={svgRg} alt="" />

        <UppercaseTextItem>RG / CNH</UppercaseTextItem>
      </DocumentItem>

      <DocumentItem isMobile={isMobile}>
        <img style={{ height: '100%', marginRight: '12px' }} src={svgSelfie} alt="" />

        <TextItem isMobile={isMobile}>Selfie com documento</TextItem>
      </DocumentItem>

      {isISA && (
        <DocumentItem isMobile={isMobile}>
          <img style={{ height: '100%', marginRight: '12px' }} src={svgProofAddress} alt="" />

          <TextItem isMobile={isMobile}>Comprovante de residência</TextItem>
        </DocumentItem>
      )}
    </DocumentList>
  )
}

export default RequiredDocumentsList
