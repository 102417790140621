import { useState, useEffect } from 'react'
import cep from 'cep-promise'

export const useCepValidator = (data, setDataModal) => {
  const inputRefs = []
  const [isShowAddress, setIsShowAddress] = useState(false)

  useEffect(() => {
    setDataModal(null)
  }, [setDataModal])

  useEffect(() => {
    if (data) {
      setIsShowAddress(true)
    }
  }, [data])

  const addInput = ref => {
    inputRefs.push(ref)
  }

  const getCep = async zipcode => {
    setIsShowAddress(true)
    const data = await cep(zipcode)
    const address = {
      zipcode,
      state: data.state || '',
      city: data.city || '',
      district: data.neighborhood || '',
      street: data.street || '',
      number: '',
      complement: ''
    }

    return address
  }

  return [isShowAddress, getCep, addInput, inputRefs]
}
