import React, { useContext } from 'react'
import { Form, Container, Button, Selector, Title } from '@provi/provi-components'

import { TemplateContext } from '../../../components/template/context'
import { TemplateForm } from '../../../components/template-form'
import { ContainerQuestions } from './styles'
import { useHousingSituation } from './hooks'

export const HousingSituation = () => {
  const { creditPath, goToNextScreen } = useContext(TemplateContext)

  const {
    onSubmit,
    isButtonDisabled,
    housingSituationOptions,
    housingSituation,
    setHousingSituation,
    complementOptions,
    complement,
    setComplement,
    requiresExplanation
  } = useHousingSituation(creditPath, goToNextScreen)

  return (
    <TemplateForm isMarginDesktop>
      <Container isMarginDesktop>
        <Form onSubmit={onSubmit}>
          <ContainerQuestions>
            <Title text="O lugar onde você mora é:" />
            <Selector
              placeholder="Escolher"
              options={housingSituationOptions}
              value={housingSituation}
              onValueChange={setHousingSituation}
              isSearchable={false}
            />
          </ContainerQuestions>

          {requiresExplanation && (
            <ContainerQuestions>
              <Title text="Quem é responsável pelo aluguel ou financiamento da casa?" />
              <Selector
                placeholder="Escolher"
                options={complementOptions}
                value={complement}
                onValueChange={setComplement}
                isSearchable={false}
              />
            </ContainerQuestions>
          )}

          <Button marginHorizontal={0} disabled={isButtonDisabled} text="Avançar" type="submit" persistFloatingMode={false} />
        </Form>
      </Container>
    </TemplateForm>
  )
}
