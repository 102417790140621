import { useState, useEffect } from 'react'
import { showToast, theme } from '@provi/provi-components'
import { getSlugPartner } from '../../../utils'
import { LOCALSTORAGE_TOKENS } from '../../../constants'
import { addCourse } from '../../../services/api'

import { URL_EXTERNAL } from '../../../constants'

export const useCourseConfirmation = (setDataModal, setIsLoading, goToNextScreen, creditPath, setIsShowModal) => {
  const [isShowModalDontConfirm, setIsShowModalDontConfirm] = useState(false)
  const [itemSelect, setItemSelect] = useState({})

  useEffect(() => {
    if (creditPath && creditPath.ReadProductSelection && creditPath.ReadProductSelection.choosedCourses.length > 0) {
      const find =
        creditPath.ReadProductSelection.availableCourses.find(
          item => item.id === creditPath.ReadProductSelection.choosedCourses[0].id
        ) || {}
      setItemSelect(find)
    }

    if (creditPath?.LiftProductSanar?.availableCourses?.length === 1) {
      const find = creditPath.LiftProductSanar.availableCourses[0]
      setItemSelect(find)
    }

    setDataModal({
      title: 'Algo errado?',
      texts: [
        'Lembre-se que você só está visualizando o(s) produto(s) disponível(veis) de acordo com a sua data da sua formatura.',
        'Se mesmo assim você achar que alguma coisa está incorreta, entre em contato com a gente.'
      ],
      textButton: 'Entendi',
      buttonFunction: () => setIsShowModal(false),
      textLink: {
        color: theme.colors.blue900,
        text: 'Abrir chat',
        onClick: () => window.open(URL_EXTERNAL, '_blank')
      }
    })
  }, [setDataModal, creditPath, setIsShowModal])

  const postCourse = async idCourse => {
    setIsLoading(true)
    const crid = localStorage.getItem(LOCALSTORAGE_TOKENS.crid)
    const partner = getSlugPartner()
    try {
      await addCourse(crid, partner, idCourse)
      goToNextScreen()
    } catch (_) {
      setIsLoading(false)
      showToast()
    }
  }

  return [isShowModalDontConfirm, setIsShowModalDontConfirm, postCourse, itemSelect, setItemSelect]
}
