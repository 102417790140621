import React from 'react'
import { theme } from '@provi/provi-components'

import './style.css'

export const Timer = ({ counter, message, isDark = false }) => {
  return (
    <div className="container-timer-phone">
      {message && <p className="p-phone">{message}</p>}
      <div className="timer-phone">
        <span style={isDark ? { color: theme.colors.white100 } : {}}>{counter || ''}</span>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}
