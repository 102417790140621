import React from 'react'
import * as Yup from 'yup'
import { withFormik } from 'formik'
import { Button, InputMasks, TextErrorInput } from '@provi/provi-components'
import InputAdornment from '@material-ui/core/InputAdornment'
import { IconLock } from '../../../assets/svg/icon-lock'
import { Form } from '../../../components/form'
import { isValidCpf } from '../../../utils/validators'

const Formik = props => {
  const { handleChange, values, errors, touched, handleBlur, handleSubmit, canEdit } = props
  return (
    <Form onSubmit={handleSubmit}>
      <InputMasks
        inputProps={{ cy: 'input-cpf', 'aria-label': 'cpf' }}
        mask="999.999.999-99"
        margin="normal"
        id="cpf"
        type="tel"
        placeholder={'Ex: 111.222.333-44'}
        onChange={handleChange('cpf')}
        onBlur={handleBlur('cpf')}
        error={!!errors.cpf}
        value={values.cpf}
        autoFocus={!values.cpf}
        InputProps={
          !(canEdit && canEdit) && {
            disabled: !(canEdit && canEdit),
            endAdornment: (
              <InputAdornment position="end">
                <IconLock />
              </InputAdornment>
            )
          }
        }
      />

      {errors.cpf && touched.cpf && <TextErrorInput cy="error-cpf" text={errors.cpf} />}

      <Button
        marginHorizontal={0}
        type="submit"
        disabled={!(values.cpf && values.cpf.replace(/[^\d]+/g, '').length === 11)}
        text="Avançar"
        persistFloatingMode={false}
      />
    </Form>
  )
}

export const FormFormik = withFormik({
  validateOnBlur: true,
  validateOnChange: false,
  mapPropsToValues: ({ data }) => ({ cpf: (data && data) || '' }),
  validationSchema: Yup.object().shape({
    cpf: Yup.string()
      .required('Preencha o CPF do seu avalista')
      .test({
        name: 'cpf',
        message: 'CPF inválido',
        test: cpf => cpf && isValidCpf(cpf.replace(/[^\d]+/g, ''))
      })
  }),
  handleSubmit: async ({ cpf }, { setSubmitting, props }) => {
    props.goToNextScreen(cpf)
    setSubmitting(false)
  }
})(Formik)
