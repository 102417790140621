import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const SubTitleInternal = styled.div`
  font-family: 'CenturyGothicBold';
  color: ${({ color }) => color};
  font-size: 20px;
  line-height: 30px;

  ${theme.breakpoints.down('md')} {
    font-size: 16px;
    line-height: 24px;
  }
`

export const Text = styled.div`
  font-family: 'CenturyGothic';
  color: ${theme.colors.blue900};
  margin: 8px 0;
  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    line-height: 15px;
  }
`
