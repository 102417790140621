import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/browser'
import { showToast } from '@provi/provi-components'
import { history } from '../../navigation/history'
import { getCreditPath, postCreditPath, getCreditPathPrevious, getCreditPathISA } from '../../services/api'
import { getSlugPartner, getPathname, getTranslatedProductType, navigateWithParams } from '../../utils'
import { LOCALSTORAGE_TOKENS } from '../../constants'

/* eslint-disable react-hooks/exhaustive-deps */

export const useTemplate = (setCrid, crid) => {
  useEffect(() => {
    if (!crid) {
      const storage_crid = localStorage.getItem(LOCALSTORAGE_TOKENS.crid)
      setCrid(storage_crid)
    } else {
      setCrid(crid)
    }
  }, [])
}

export const useHelpModal = () => {
  const [isShowModal, setIsShowModal] = useState(false)

  return [isShowModal, setIsShowModal]
}

export const useSendingInfo = (creditPath, setCreditPath, crid) => {
  const [dataModal, setDataModal] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [notPreviousScreen_pathname, setNotPreviousScreen_pathname] = useState(false)

  const goToNextScreen = async (data, includeOnDataProperty = true) => {
    setIsLoading(true)
    const creditRequestId = crid || localStorage.getItem(LOCALSTORAGE_TOKENS.crid)
    try {
      const currentScreen = creditPath && creditPath.progress && creditPath.progress.currentScreenUniqueName
      const {
        data: { nextScreen, productType }
      } = await postCreditPath(creditRequestId, data, currentScreen, includeOnDataProperty)
      const response = await getCreditPath(creditRequestId)
      setDataModal(null)
      setCreditPath(response)
      Sentry.addBreadcrumb({
        category: 'goToNextScreen',
        message: 'Next Screen ' + nextScreen,
        level: Sentry.Severity.Info
      })

      const pathname = getPathname(nextScreen)
      const params = `/formulario/${creditRequestId}/${getTranslatedProductType(productType)}/${pathname}/${getSlugPartner()}`
      navigateWithParams(params)
    } catch (error) {
      if (error.response) {
        showToast(error.response.data.error.message)
        return error.response.data
      }
      showToast()
    } finally {
      setIsLoading(false)
    }
  }

  const goToPreviousScreen = async () => {
    setIsLoading(true)

    const creditRequestId = crid || localStorage.getItem(LOCALSTORAGE_TOKENS.crid)

    const currentPathname = history.location.pathname
    const split = currentPathname.split('/')
    const isCpfScreen = split.includes('cpf')
    if (isCpfScreen) {
      setIsLoading(false)
      return navigateWithParams(`/selecao-financiamento/${getSlugPartner()}`)
    }

    const previousScreen = creditPath && creditPath.progress && creditPath.progress.previousScreenUniqueName
    if (!previousScreen) {
      setIsLoading(false)
      return navigateWithParams(`/cpf/${getSlugPartner()}`)
    }

    try {
      const response = await getCreditPathPrevious(creditRequestId, previousScreen)
      setDataModal(null)
      setCreditPath(response)
      Sentry.addBreadcrumb({
        category: 'goToPreviousScreen',
        message: 'Previous Screen ' + previousScreen,
        level: Sentry.Severity.Info
      })
      const previousPathname = getPathname(previousScreen)
      const productType = getTranslatedProductType(response.productType)

      navigateWithParams(`/formulario/${creditRequestId}/${productType}/${previousPathname}/${getSlugPartner()}`)
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        showToast(error.response.data.error.message)
        return false
      }
      showToast()
    } finally {
      setIsLoading(false)
    }
  }

  return [
    isLoading,
    setIsLoading,
    dataModal,
    setDataModal,
    goToNextScreen,
    goToPreviousScreen,
    notPreviousScreen_pathname,
    setNotPreviousScreen_pathname
  ]
}

export const useScreenMeasure = () => {
  const [dimensions, setDimensions] = useState([window.innerWidth, window.innerHeight])

  useEffect(() => {
    window.addEventListener('resize', () => setDimensions([window.innerWidth, window.innerHeight]))
    return () => {
      window.removeEventListener('resize', () => setDimensions([window.innerWidth, window.innerHeight]))
    }
  }, [])

  return [dimensions[0] <= 800]
}

export const useCreditPath = (setIsLoadingGlobal, setCreditPath, crid) => {
  useEffect(() => {
    const pathname = history.location.pathname
    const split = pathname.split('/')

    const isForm = split[1] === 'formulario'
    const isISA = split[2] === 'isa'

    const creditRequestId = crid || localStorage.getItem(LOCALSTORAGE_TOKENS.crid)
    const partner = getSlugPartner()

    const getData = async () => {
      try {
        if (creditRequestId && isForm) {
          const response = await getCreditPath(creditRequestId)
          setCreditPath(response)
          const pathname = getPathname(response.progress.currentScreenUniqueName)
          const productType = getTranslatedProductType(response.productType)

          navigateWithParams(`/formulario/${creditRequestId}/${productType}/${pathname}/${getSlugPartner()}`)
        } else if (creditRequestId && isISA) {
          if (pathname.includes('/isa/cpf')) return

          const response = await getCreditPathISA(creditRequestId)

          setCreditPath(response)
          const actuallyPathname = getPathname(response.progress.currentScreenUniqueName)
          history.push(`/${creditRequestId}/isa/${actuallyPathname}/${partner}`)
        }
      } catch (err) {
        if (isISA) {
          return history.push(`/isa/intro/${partner}`)
        }
        navigateWithParams(`/selecao-financiamento/${partner}`)
      } finally {
        setIsLoadingGlobal(false)
      }
    }
    getData()
  }, [setIsLoadingGlobal, setCreditPath, crid])
}

export const useWindowPop = (goToPreviousScreen, goToPrevScreen) => {
  window.onpopstate = function() {
    window.history.go(1)
    const path = window.location.pathname

    if (path.includes('/isa')) {
      goToPrevScreen()
    } else {
      goToPreviousScreen()
    }
  }
}
