import React, { useContext } from 'react'
import { LoadingProvi } from '@provi/provi-components'
import { TemplateContext } from '../../../components/template/context'

import { Timer } from '../../../components/timer'
import { Container, Wrapper, Text, TextBox } from './style'
import { useWaitingDocumentAnalysis } from './hooks'

export const WaitingDocumentAnalysis = ({ match }) => {
  const { isMobile, goToNextScreen, isLoading, selfieProviderId } = useContext(TemplateContext)
  const [countdown] = useWaitingDocumentAnalysis(goToNextScreen, match.params.crid, selfieProviderId)

  return (
    <>
      {isLoading && <LoadingProvi isLoadingDark />}
      <Container isMobile={isMobile}>
        <Wrapper>
          <Timer counter={countdown} isDark></Timer>

          <TextBox>
            <Text>Aguarde alguns instantes.</Text>
            <Text bold>Estamos analisando os seus documentos...</Text>
          </TextBox>
        </Wrapper>
      </Container>
    </>
  )
}
