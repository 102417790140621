import React, { useContext } from 'react'
import { TemplateContext } from '../../../components/template/context'
import { IconLinkedin, IconInstagram, IconFacebook, IconBlog } from '../../../assets/svg/icons-socials'
import { ContainerAnalysis, Title, Text, Wrapper, SubTitle, ContainerSocial, Item } from './style'

export const Analysis = () => {
  const { isMobile } = useContext(TemplateContext)
  return (
    <ContainerAnalysis isMobile={isMobile}>
      <Wrapper>
        <Title>Sua solicitação entrou em análise!</Title>
        <Text>Dentro dos próximos dias a escola irá te retornar com uma posição sobre o seu processo seletivo.</Text>
        <SubTitle>Gostou da Provi? Então segue a gente!</SubTitle>
        <ContainerSocial>
          <Item href="https://www.linkedin.com/company/provi/">
            <IconLinkedin />
          </Item>
          <Item href="https://www.instagram.com/sejaprovi/">
            <IconInstagram />
          </Item>
          <Item href="https://www.facebook.com/SejaProvi/">
            <IconFacebook />
          </Item>
          <Item href="https://blog.provi.com.br/">
            <IconBlog />
          </Item>
        </ContainerSocial>
      </Wrapper>
    </ContainerAnalysis>
  )
}
