import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { Modal, LoadingProvi, SubHeader, ButtonBackDesktop, Button, Header, theme } from '@provi/provi-components'
import { TemplateContext } from '../../components/template/context'
import { HelmetWrapper } from '../../components/helmet'
import { URL_EXTERNAL } from '../../constants'
import { View, TextModal, Left, Right } from './style'
import { useTemplateForm } from './hooks'

export const TemplateForm = props => {
  const {
    isLoading,
    isShowModal,
    setIsShowModal,
    isMobile,
    dataModal,
    goToPreviousScreen,
    creditPath,
    isLoadingGlobal,
    setCrid
  } = useContext(TemplateContext)
  const { crid } = useParams()
  useTemplateForm(setCrid, crid)
  return (
    <>
      <HelmetWrapper />
      {isLoading && <LoadingProvi isLoadingDark={false} />}
      {isShowModal && dataModal && (
        <Modal
          title={dataModal.title}
          onDismiss={() => setIsShowModal(false)}
          footer={
            dataModal.textButton && (
              <Button
                marginHorizontal={0}
                type="button"
                text={dataModal.textButton}
                onClick={() => (dataModal.buttonFunction ? dataModal.buttonFunction() : window.open(URL_EXTERNAL, '_blank'))}
                textLink={{
                  color: theme.colors.blue900,
                  text: dataModal && dataModal.textLink && dataModal.textLink.text,
                  onClick: () => dataModal && dataModal.textLink && dataModal.textLink.onClick && dataModal.textLink.onClick()
                }}
              />
            )
          }
        >
          {dataModal && dataModal.texts.map(item => <TextModal>{item}</TextModal>)}
        </Modal>
      )}

      {!isLoadingGlobal && (
        <View>
          <Left>
            {creditPath && <Header options={creditPath.menu} current={creditPath.progress.percentageCompleted} />}
            {!isMobile && <ButtonBackDesktop functionBackButton={props.backTo || goToPreviousScreen} />}
            {isMobile && (
              <SubHeader
                isShowButtonHelpModal={!!dataModal}
                functionBackButton={props.backTo || goToPreviousScreen}
                functionHelpModal={() => setIsShowModal(true)}
              />
            )}
            {props.children}
          </Left>
          {!isMobile && <Right />}
        </View>
      )}
    </>
  )
}
