import React from 'react'

export const IconLinkedin = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      d="M16.041 31.7537C7.29621 31.7537 0.175781 24.6391 0.175781 15.8885C0.175781 7.14371 7.29039 0.0232849 16.041 0.0232849C24.7858 0.0232849 31.9062 7.13789 31.9062 15.8885C31.9062 24.6391 24.7916 31.7537 16.041 31.7537ZM16.041 1.73498C8.23357 1.73498 1.88748 8.08689 1.88748 15.8885C1.88748 23.6959 8.23939 30.042 16.041 30.042C23.8484 30.042 30.1945 23.6901 30.1945 15.8885C30.2003 8.08689 23.8484 1.73498 16.041 1.73498Z"
      fill="white"
    />
    <path
      d="M25.0133 17.0995V23.0206C25.0133 23.1662 24.8969 23.2884 24.7455 23.2884H21.6889C21.5433 23.2884 21.4211 23.172 21.4211 23.0206V17.5129C21.4211 16.0632 20.9029 15.0734 19.6046 15.0734C18.6148 15.0734 18.0268 15.7372 17.7648 16.3834C17.6716 16.6163 17.6484 16.9365 17.6484 17.2567V23.0206C17.6484 23.1662 17.5319 23.2884 17.3805 23.2884H14.3239C14.1784 23.2884 14.0561 23.172 14.0561 23.0206C14.0619 21.5534 14.0911 14.4388 14.0619 12.7621C14.0619 12.6165 14.1784 12.4942 14.3298 12.4942H17.3805C17.5261 12.4942 17.6484 12.6107 17.6484 12.7621V14.0255C17.6425 14.0371 17.6309 14.0487 17.6251 14.0604H17.6484V14.0255C18.1258 13.2919 18.9758 12.2439 20.8796 12.2439C23.2434 12.2439 25.0133 13.7868 25.0133 17.0995ZM8.7522 23.2884H11.8088C11.9543 23.2884 12.0766 23.172 12.0766 23.0206V12.7621C12.0766 12.6165 11.9602 12.4942 11.8088 12.4942H8.7522C8.60664 12.4942 8.48438 12.6107 8.48438 12.7621V23.0206C8.4902 23.1662 8.60664 23.2884 8.7522 23.2884Z"
      fill="white"
    />
    <path
      d="M10.1713 11.1261C11.2324 11.1261 12.0926 10.2659 12.0926 9.20477C12.0926 8.14367 11.2324 7.28348 10.1713 7.28348C9.11019 7.28348 8.25 8.14367 8.25 9.20477C8.25 10.2659 9.11019 11.1261 10.1713 11.1261Z"
      fill="white"
    />
  </svg>
)

export const IconInstagram = () => (
  <svg width="33" height="32" viewBox="0 0 33 32" fill="none">
    <path
      d="M16.4653 31.678C7.76131 31.678 0.681641 24.5983 0.681641 15.8943C0.681641 7.19027 7.76131 0.110596 16.4653 0.110596C25.1694 0.110596 32.2491 7.19027 32.2491 15.8943C32.2549 24.5925 25.1694 31.678 16.4653 31.678ZM16.4653 1.60105C8.58805 1.60105 2.17792 8.01118 2.17792 15.8885C2.17792 23.7658 8.58805 30.1759 16.4653 30.1759C24.3426 30.1759 30.7528 23.7658 30.7528 15.8885C30.7528 8.01118 24.3426 1.60105 16.4653 1.60105ZM21.7926 9.48417C21.1463 9.48417 20.6223 10.0082 20.6223 10.6544C20.6223 11.3007 21.1463 11.8247 21.7926 11.8247C22.4388 11.8247 22.9628 11.3007 22.9628 10.6544C22.957 10.0082 22.433 9.48417 21.7926 9.48417ZM16.5993 10.9805C13.892 10.9805 11.6912 13.1812 11.6912 15.8885C11.6912 18.5958 13.892 20.7965 16.5993 20.7965C19.3065 20.7965 21.5073 18.5958 21.5073 15.8885C21.5073 13.1812 19.3007 10.9805 16.5993 10.9805ZM16.5993 19.0324C14.8643 19.0324 13.4553 17.6235 13.4553 15.8885C13.4553 14.1535 14.8643 12.7445 16.5993 12.7445C18.3342 12.7445 19.7432 14.1535 19.7432 15.8885C19.7432 17.6235 18.3284 19.0324 16.5993 19.0324ZM26.4968 11.813C26.4968 8.50606 23.8187 5.8279 20.5117 5.8279H12.6111C9.30417 5.8279 6.626 8.50606 6.626 11.813V19.7136C6.626 23.0206 9.30417 25.6987 12.6111 25.6987H20.5117C23.8187 25.6987 26.4968 23.0206 26.4968 19.7136V11.813ZM24.6221 19.7136C24.6221 21.9842 22.7823 23.824 20.5117 23.824H12.6111C10.3405 23.824 8.50072 21.9842 8.50072 19.7136V11.813C8.50072 9.54239 10.3405 7.70261 12.6111 7.70261H20.5117C22.7823 7.70261 24.6221 9.54239 24.6221 11.813V19.7136Z"
      fill="white"
    />
  </svg>
)

export const IconFacebook = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      d="M15.8885 31.777C7.12625 31.777 0 24.6508 0 15.8885C0 7.12625 7.12625 0 15.8885 0C24.6508 0 31.777 7.12625 31.777 15.8885C31.7828 24.6508 24.6508 31.777 15.8885 31.777ZM15.8885 1.70587C8.06943 1.70587 1.70587 8.06943 1.70587 15.8885C1.70587 23.7076 8.06943 30.0711 15.8885 30.0711C23.7076 30.0711 30.0711 23.7076 30.0711 15.8885C30.0711 8.06943 23.7134 1.70587 15.8885 1.70587Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.3976 15.9642V25.1748C13.3976 25.3087 13.5024 25.4135 13.6363 25.4135H17.0539C17.1878 25.4135 17.2926 25.3087 17.2926 25.1748V15.8128H19.7728C19.8951 15.8128 19.9999 15.7197 20.0115 15.5916L20.2502 12.7737C20.2619 12.634 20.1513 12.5117 20.0115 12.5117H17.2926V10.5089C17.2926 10.0373 17.6711 9.65886 18.1426 9.65886H20.0523C20.1862 9.65886 20.291 9.55407 20.291 9.42016V6.60226C20.291 6.46835 20.1862 6.36356 20.0523 6.36356H16.821C14.9288 6.36356 13.3918 7.90059 13.3918 9.79277V12.5117H11.6801C11.5462 12.5117 11.4414 12.6165 11.4414 12.7504V15.5683C11.4414 15.7022 11.5462 15.807 11.6801 15.807H13.3918V15.9642H13.3976Z"
      fill="white"
    />
  </svg>
)

export const IconBlog = ({ isBackgroundLight = false, isFilled = false, size = 32 }) => {
  const iconColor = isBackgroundLight ? 'black' : 'white'
  return isFilled ? (
    <svg width={size} height={size} viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7254 31.8272C25.5077 31.8272 32.6273 24.7076 32.6273 15.9252C32.6273 7.14285 25.5077 0.0233154 16.7254 0.0233154C7.94296 0.0233154 0.823425 7.14285 0.823425 15.9252C0.823425 24.7076 7.94296 31.8272 16.7254 31.8272ZM8.27343 20.8C9.0401 21.6667 10.0401 22.1 11.2734 22.1C12.3068 22.1 13.1734 21.7667 13.8734 21.1C14.6068 20.4333 14.9734 19.5667 14.9734 18.5C14.9734 17.5 14.6401 16.65 13.9734 15.95C13.3401 15.2167 12.4401 14.7167 11.2734 14.45L10.2234 14.2C10.4568 12.9667 10.8401 11.9333 11.3734 11.1C11.9068 10.2667 12.5234 9.58334 13.2234 9.05001L12.9234 8.45001C11.2901 9.25001 9.92343 10.4667 8.82343 12.1C7.72343 13.7 7.17343 15.4333 7.17343 17.3C7.17343 18.7333 7.5401 19.9 8.27343 20.8ZM18.8234 20.8C19.5901 21.6667 20.5901 22.1 21.8234 22.1C22.8568 22.1 23.7234 21.7667 24.4234 21.1C25.1568 20.4333 25.5234 19.5667 25.5234 18.5C25.5234 17.5 25.1901 16.65 24.5234 15.95C23.8901 15.2167 22.9901 14.7167 21.8234 14.45L20.7734 14.2C21.0068 12.9667 21.3901 11.9333 21.9234 11.1C22.4568 10.2667 23.0734 9.58334 23.7734 9.05001L23.4734 8.45001C21.8401 9.25001 20.4734 10.4667 19.3734 12.1C18.2734 13.7 17.7234 15.4333 17.7234 17.3C17.7234 18.7333 18.0901 19.9 18.8234 20.8Z"
        fill={iconColor}
      />
    </svg>
  ) : (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.8652 31.7538C7.12043 31.7538 0 24.6391 0 15.8885C0 7.14374 7.1146 0.0233154 15.8652 0.0233154C24.61 0.0233154 31.7304 7.13792 31.7304 15.8885C31.7304 24.6391 24.6158 31.7538 15.8652 31.7538ZM15.8652 1.73501C8.05779 1.73501 1.7117 8.08692 1.7117 15.8885C1.7117 23.696 8.06361 30.0421 15.8652 30.0421C23.6727 30.0421 30.0187 23.6901 30.0187 15.8885C30.0246 8.08692 23.6727 1.73501 15.8652 1.73501Z"
        fill={iconColor}
      />
      <path
        d="M10.45 22.1C9.21667 22.1 8.21667 21.6667 7.45001 20.8C6.71667 19.9 6.35001 18.7333 6.35001 17.3C6.35001 15.4333 6.90001 13.7 8.00001 12.1C9.10001 10.4667 10.4667 9.25001 12.1 8.45001L12.4 9.05001C11.7 9.58334 11.0833 10.2667 10.55 11.1C10.0167 11.9333 9.63334 12.9667 9.40001 14.2L10.45 14.45C11.6167 14.7167 12.5167 15.2167 13.15 15.95C13.8167 16.65 14.15 17.5 14.15 18.5C14.15 19.5667 13.7833 20.4333 13.05 21.1C12.35 21.7667 11.4833 22.1 10.45 22.1ZM21 22.1C19.7667 22.1 18.7667 21.6667 18 20.8C17.2667 19.9 16.9 18.7333 16.9 17.3C16.9 15.4333 17.45 13.7 18.55 12.1C19.65 10.4667 21.0167 9.25001 22.65 8.45001L22.95 9.05001C22.25 9.58334 21.6333 10.2667 21.1 11.1C20.5667 11.9333 20.1833 12.9667 19.95 14.2L21 14.45C22.1667 14.7167 23.0667 15.2167 23.7 15.95C24.3667 16.65 24.7 17.5 24.7 18.5C24.7 19.5667 24.3333 20.4333 23.6 21.1C22.9 21.7667 22.0333 22.1 21 22.1Z"
        fill={iconColor}
      />
    </svg>
  )
}
