import React from 'react'
import * as Yup from 'yup'
import { withFormik } from 'formik'
import { Input, TextErrorInput, Button } from '@provi/provi-components'
import { Form } from '../../../components/form'
import { validateRG } from '../../../utils/validators'

export const Formik = props => {
  const { handleChange, values, errors, touched, handleBlur, handleSubmit } = props

  return (
    <Form onSubmit={handleSubmit}>
      <Input
        cy="input-rg"
        autoFocus
        margin="normal"
        id="rg"
        type="tel"
        placeholder="Ex: 00.000.000-0"
        onChange={handleChange('rg')}
        onBlur={handleBlur('rg')}
        error={!!errors.rg}
        value={values.rg}
      />
      {errors.rg && touched.rg && <TextErrorInput cy="error-rg" text={errors.rg} />}

      <Button marginHorizontal={0} type="submit" disabled={!validateRG(values.rg)} text="Avançar" persistFloatingMode={false} />
    </Form>
  )
}

export const FormFomik = withFormik({
  validateOnBlur: true,
  validateOnChange: false,
  enableReinitialize: true,
  mapPropsToValues: props => ({ rg: props.data || '' }),
  validationSchema: Yup.object().shape({
    rg: Yup.string().test({
      name: 'rg',
      message: 'Digite um RG válido',
      test: rg => validateRG(rg)
    })
  }),
  handleSubmit: async ({ rg }, { setSubmitting, props }) => {
    props.goToNextScreen(rg)
    setSubmitting(false)
  }
})(Formik)
