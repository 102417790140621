import { useState, useEffect } from 'react'

export const useExperienceLevel = (goToNextScreen, creditPath) => {
  const [selects, setSelects] = useState({})
  const [options, setOptions] = useState({})
  const [isShowError, setIsShowError] = useState(false)

  const refactingArray = (array, name) =>
    array.map(item => ({
      id: item.id,
      label: item.name,
      name
    }))

  const setSelect = select => {
    setSelects({
      ...selects,
      [select.name]: select
    })
    setIsShowError(false)
  }

  const onSubmit = form => {
    form.preventDefault()
    const notHaveError = Object.keys(selects).length === 1

    const result = {}
    Object.keys(selects).map(key => (result.id = selects[key].id))

    if (notHaveError) {
      goToNextScreen(result)
    } else {
      setIsShowError(true)
    }
  }

  useEffect(() => {
    const experienceOptions =
      creditPath &&
      creditPath.ReadExperienceTime &&
      creditPath.ReadExperienceTime.options &&
      refactingArray(creditPath.ReadExperienceTime.options, 'experienceLevel')

    setOptions(experienceOptions)

    let experienceLevel
    if (creditPath && creditPath.ReadExperienceTime && creditPath.ReadExperienceTime.choosedId) {
      experienceLevel = experienceOptions.find(item => item.id === creditPath.ReadExperienceTime.choosedId)
    }

    setSelects({ experienceLevel })
  }, [creditPath])

  return [onSubmit, isShowError, setSelect, selects, options]
}
