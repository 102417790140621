import React, { useContext } from 'react'
import { theme, showToast, Button } from '@provi/provi-components'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Form } from '../../../components/form'
import { TemplateContext } from '../../../components/template/context'
import { useGuarantorListen } from './hooks'
import {
  Container,
  TitleBlue,
  TextOption,
  WrapperStatus,
  Wrapper,
  ContainerText,
  ContainerButton,
  Text,
  WrapperTextCopy,
  SubTitle,
  TextLink,
  TextInfo,
  Status,
  TextLinkGuarantor
} from './style'

const verifyStatus = status => {
  const options = {
    analysis: 'Em análise',
    incomplete: 'Incompleto',
    approved: 'Aprovado',
    denied: 'Negado',
    review: 'Incompleto - precisa corrigir'
  }
  return `Avalista ${options[status]}`
}

const renderColorStatus = status => {
  const options = {
    analysis: theme.colors.blue600,
    incomplete: theme.colors.pink900,
    approved: theme.colors.green100,
    denied: theme.colors.grey600,
    review: theme.colors.pink900
  }
  return options[status]
}

const buildMessage = text => {
  let message = ''
  text && text.map(item => (message += item + `\n\n`))
  return message
}

const buildLink = text => {
  return text && text[text.length - 1]
}

const copyMessage = () => {
  showToast('Texto copiado. Agora você precisa enviar esse texto para seu avalista.')
}

const copyLink = () => {
  showToast('Link copiado. Agora você precisa enviar esse link para seu avalista.')
}

export const GuarantorListen = () => {
  const { goToNextScreen, setDataModal, creditPath } = useContext(TemplateContext)
  useGuarantorListen(goToNextScreen, setDataModal)
  return (
    <Container>
      <Wrapper>
        <Form>
          <TitleBlue>
            Falta pouco!
            <br />
            Agora só falta seu avalista preencher a parte dele.
          </TitleBlue>
          <SubTitle>
            Fizemos um texto personalizado pra você enviar para ele, mas se preferir, pode enviar somente o link.
          </SubTitle>
          <WrapperTextCopy>
            <ContainerText>
              {creditPath &&
                creditPath.ReadGuarantorCreditRequests &&
                creditPath.ReadGuarantorCreditRequests[0] &&
                creditPath.ReadGuarantorCreditRequests.length > 0 &&
                creditPath.ReadGuarantorCreditRequests[0].screenText.map((item, index) => {
                  if (index === creditPath.ReadGuarantorCreditRequests[0].screenText.length - 1) {
                    return <TextLinkGuarantor key={item}>{item}</TextLinkGuarantor>
                  } else {
                    return <Text key={item}>{item}</Text>
                  }
                })}
            </ContainerText>
            <ContainerButton>
              <CopyToClipboard
                text={buildMessage(
                  creditPath &&
                    creditPath.ReadGuarantorCreditRequests &&
                    creditPath.ReadGuarantorCreditRequests[0] &&
                    creditPath.ReadGuarantorCreditRequests[0].screenText
                )}
                onCopy={() => copyMessage()}
              >
                <Button text="Copiar texto" />
              </CopyToClipboard>

              <CopyToClipboard
                text={buildLink(
                  creditPath &&
                    creditPath.ReadGuarantorCreditRequests &&
                    creditPath.ReadGuarantorCreditRequests[0] &&
                    creditPath.ReadGuarantorCreditRequests[0].screenText
                )}
                onCopy={() => copyLink()}
              >
                <TextLink>Copiar só o link</TextLink>
              </CopyToClipboard>
            </ContainerButton>
          </WrapperTextCopy>

          <Status>
            <TextInfo>Você pode acompanhar o preenchimento dele através do status abaixo:</TextInfo>

            {creditPath &&
              creditPath.ReadGuarantorCreditRequests &&
              creditPath.ReadGuarantorCreditRequests.length > 0 &&
              creditPath.ReadGuarantorCreditRequests.map((item, key) => (
                <WrapperStatus key={key} color={renderColorStatus(item.status)}>
                  <TextOption>{verifyStatus(item.status)}</TextOption>
                </WrapperStatus>
              ))}
          </Status>
        </Form>
      </Wrapper>
    </Container>
  )
}
