import React from 'react'
import * as Yup from 'yup'
import { withFormik } from 'formik'
import { Button, InputMasks, TextErrorInput } from '@provi/provi-components'
import { Form } from '../../../components/form'
import { validatePhone } from '../../../utils/validators'

const Formik = props => {
  const { handleChange, values, errors, touched, handleBlur, handleSubmit } = props
  return (
    <Form onSubmit={handleSubmit}>
      <InputMasks
        autoFocus={!values.phone}
        inputProps={{ cy: 'input-phone', 'aria-label': 'name' }}
        mask="(99) 99999-9999"
        margin="normal"
        id="phone"
        type="tel"
        placeholder={'Ex: (11) 95771-2414'}
        onChange={handleChange('phone')}
        onBlur={handleBlur('phone')}
        error={!!errors.phone}
        value={values.phone}
      />
      {errors.phone && touched.phone && <TextErrorInput text={errors.phone} cy="error-phone" />}
      <Button
        marginHorizontal={0}
        disabled={!(values.phone && validatePhone(values.phone))}
        text="Avançar"
        type="submit"
        persistFloatingMode={false}
      />
    </Form>
  )
}

export const FormFormik = withFormik({
  validateOnBlur: true,
  validateOnChange: false,
  enableReinitialize: true,
  mapPropsToValues: props => ({ phone: (!!props.data && props.data) || '' }),
  validationSchema: Yup.object().shape({
    phone: Yup.string()
      .test({
        name: 'phone',
        message: 'Telefone inválido',
        test: phone => phone && validatePhone(phone)
      })
      .required('Preencha o seu Celular')
  }),
  handleSubmit: async ({ phone }, { props }) => {
    props.goToNextScreen(phone)
  }
})(Formik)
