import { useState, useEffect, useMemo } from 'react'

const OPTIONS = [
  {
    value: true,
    label: 'SIM',
    id: 1
  },
  {
    value: false,
    label: 'NÃO',
    id: 2
  }
]

export const useStudentDebtWithIncomeDetails = (goToNextScreen, creditPath) => {
  const [value, setValue] = useState()
  const [selectedOption, setSelectedOption] = useState()
  const [employabilityOptions, setEmployabilityOptions] = useState([])
  const [employability, setEmployability] = useState()
  const [incomeValue, setIncomeValue] = useState()

  const isButtonDisabled = useMemo(() => {
    if (!selectedOption) {
      return true
    }

    if (selectedOption.value && !value) {
      return true
    }

    if (!employability) {
      return true
    }

    if (!incomeValue) {
      return true
    }

    return false
  }, [selectedOption, value, employability, incomeValue])

  const onSelectOption = option => {
    setSelectedOption(option)
    setValue('')
  }

  useEffect(() => {
    if (!creditPath) {
      return
    }

    if (creditPath.ReadStudentDebt) {
      const find = OPTIONS.find(item => item.value === creditPath.ReadStudentDebt.option)
      if (find) {
        onSelectOption(find)
        setValue(creditPath.ReadStudentDebt.answer)
      }
    }

    if (creditPath.ReadProfessionalSituation) {
      setEmployabilityOptions(creditPath.ReadProfessionalSituation.options)

      if (creditPath.ReadProfessionalSituation.choosedId) {
        const selectedEmployabilityOption = creditPath.ReadProfessionalSituation.options.find(
          item => item.id === creditPath.ReadProfessionalSituation.choosedId
        )

        if (selectedEmployabilityOption) {
          setEmployability(selectedEmployabilityOption)
        }

        if (creditPath.ReadProfessionalSituation.incomeValue !== null) {
          setIncomeValue(`R$${creditPath.ReadProfessionalSituation.incomeValue}`)
        }
      }
    }
  }, [creditPath])

  const onSubmit = e => {
    e.preventDefault()

    const body = {
      option: selectedOption.value,
      answer: value,
      incomeValue: Number(incomeValue.split('R$')[1]),
      professionalSituationOptionId: employability.id
    }
    goToNextScreen(body)
  }

  const onValueChange = event => {
    const text = event.target.value
    setValue(text)
  }

  return {
    selectedOption,
    onSelectOption,
    onSubmit,
    onValueChange,
    value,
    isButtonDisabled,
    OPTIONS,
    incomeValue,
    setIncomeValue,
    employabilityOptions,
    employability,
    setEmployability
  }
}
