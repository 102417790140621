import { useNavigation } from './hooks'

export const ISAProvider = (crid, creditPath, setIsLoading, setDataModal, setCreditPath) => {
  const [goToNextScreen, goToPrevScreen] = useNavigation(crid, creditPath, setIsLoading, setDataModal, setCreditPath)

  return {
    goToNextScreen,
    goToPrevScreen
  }
}
