import { PUBLIC_RECAPTCHA_SITE_KEY } from '../../constants'
import { submitRecaptchaToken } from '../../services/api'
import * as Sentry from '@sentry/browser'

export const activateRecaptcha = setRecaptchaResponse => {
  if (!window?.grecaptcha) {
    Sentry.addBreadcrumb({
      category: 'errorRequest',
      message: 'ERROR INFO: reCAPTCHA script not loaded',
      level: Sentry.Severity.Warning
    })
    return
  }

  window.grecaptcha.ready(() => {
    window.grecaptcha
      .execute(PUBLIC_RECAPTCHA_SITE_KEY, { action: 'submit' })
      .then(token => submitRecaptchaToken(token))
      .then(response => {
        setRecaptchaResponse(response)
      })
      .catch(err => {
        Sentry.addBreadcrumb({
          category: 'errorRequest',
          message: 'ERROR INFO: ' + err,
          level: Sentry.Severity.Warning
        })
      })
  })
}
