import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Wrapper = styled.div`
  width: 640px;
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`

export const Container = styled.div`
  padding-left: 137px;
  padding-right: 22px;
  padding-bottom: 22px;
  height: 100vh;
  background-color: ${theme.colors.blue900};
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  ${theme.breakpoints.down('md')} {
    padding-left: 22px;
    margin-left: 0;
  }
`

export const Title = styled.h4`
  font-size: 38px;
  line-height: 47px;
  color: ${theme.colors.white100};
  ${theme.breakpoints.down('md')} {
    font-size: 24px;
    line-height: 30px;
  }
`

export const SubTitle = styled.p`
  font-family: 'CenturyGothicBold', sans-serif;
  color: ${theme.colors.white100};
  line-height: 30px;
  font-size: 24px;
  ${theme.breakpoints.down('md')} {
    font-size: 16px;
    line-height: 24px;
  }
`

export const Text = styled.p`
  font-family: 'CenturyGothic', sans serif;
  color: ${theme.colors.white100};
  font-size: 16px;
  line-height: 24px;
  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    line-height: 18px;
  }
`
