import { useState, useEffect, useMemo } from 'react'
import { ScholarityLevelOptionSanarFinancing } from './constants'

export const useHousingSituation = (creditPath, goToNextScreen) => {
  const [housingSituationOptions, setHousingSituationOptions] = useState([])
  const [housingSituation, setHousingSituation] = useState()
  const [scholarity, setScholarity] = useState()
  const [scholarityLevelsOptions, setScholarityLevelsOptions] = useState()
  const [complementOptions, setComplementOptions] = useState([])
  const [complement, setComplement] = useState()

  const isSanarFinancing = window.location.pathname.includes('/sanarup-financiamento')

  const requiresExplanationOptions = ['Próprio em financiamento', 'Alugado']
  const requiresExplanation = useMemo(() => {
    if (housingSituation && requiresExplanationOptions.includes(housingSituation.label)) {
      return true
    }

    return false
  }, [housingSituation, requiresExplanationOptions])

  const isButtonDisabled = useMemo(() => {
    if (!scholarity) return true

    if (!housingSituation) {
      return true
    }

    if (requiresExplanation && !complement) {
      return true
    }

    return false
  }, [scholarity, housingSituation, requiresExplanation, complement])

  const options = ScholarityLevelOptionSanarFinancing(
    creditPath?.ReadPersonalSituation?.scholarityLevelsOptions,
    isSanarFinancing
  )

  useEffect(() => {
    if (!creditPath) return

    if (creditPath.ReadPersonalSituation) {
      setHousingSituationOptions(creditPath.ReadPersonalSituation.housingSituationOptions)
      setComplementOptions(creditPath.ReadPersonalSituation.housingSituationComplementOptions)
      setScholarityLevelsOptions(options)
    }

    if (creditPath.ReadPersonalSituation && creditPath.ReadPersonalSituation.ScholarityLevelOptionId) {
      const selectedItem = creditPath.ReadPersonalSituation.scholarityLevelsOptions.find(
        item => item.id === creditPath.ReadPersonalSituation.ScholarityLevelOptionId
      )

      if (selectedItem) {
        setScholarity(selectedItem)
      }
    }

    if (creditPath.ReadPersonalSituation && creditPath.ReadPersonalSituation.HousingSituationOptionId) {
      const selectedItem = creditPath.ReadPersonalSituation.housingSituationOptions.find(
        item => item.id === creditPath.ReadPersonalSituation.HousingSituationOptionId
      )

      if (selectedItem) {
        setHousingSituation(selectedItem)
      }
    }

    if (creditPath.ReadPersonalSituation && creditPath.ReadPersonalSituation.HousingSituationComplementOptionId) {
      const selectedItem = creditPath.ReadPersonalSituation.housingSituationComplementOptions.find(
        item => item.id === creditPath.ReadPersonalSituation.HousingSituationComplementOptionId
      )

      if (selectedItem) {
        setComplement(selectedItem)
      }
    }
  }, [creditPath, setHousingSituationOptions, setComplementOptions])
  const onSubmit = e => {
    e.preventDefault()
    const body = {
      ScholarityLevelOptionId: scholarity.id,
      HousingSituationOptionId: housingSituation.id,
      ...(requiresExplanation ? { HousingSituationComplementOptionId: complement.id } : {})
    }
    goToNextScreen(body, false)
  }

  return {
    onSubmit,
    isButtonDisabled,
    scholarityLevelsOptions,
    scholarity,
    setScholarity,
    housingSituationOptions,
    housingSituation,
    setHousingSituation,
    complementOptions,
    complement,
    setComplement,
    requiresExplanation
  }
}
