import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import backgroundIsa from '../../../assets/svg/background-isa.svg'

export const Container = styled.div`
  padding-left: 137px;
  padding-right: 22px;
  padding-bottom: 22px;

  height: 100vh;
  width: 100%;

  background-image: url(${backgroundIsa});
  background-repeat: no-repeat;
  background-position: right bottom;
  background-color: ${theme.colors.blue900};

  box-sizing: border-box;
  overflow: auto;
  display: flex;
  align-items: center;

  ${theme.breakpoints.down('md')} {
    display: block;
    padding-left: 22px;
    margin-left: 0;
    background-image: none;
  }

  ${theme.breakpoints.down('lg')} {
    background-size: 36vw;
  }
`

export const Title = styled.h4`
  font-family: CenturyGothic, sans-serif;
  color: ${theme.colors.grey400};
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;

  ${theme.breakpoints.down('md')} {
    font-size: 24px;
    line-height: 30px;
  }
`

export const Text = styled.p`
  font-family: 'CenturyGothic', sans serif;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.white100};

  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    line-height: 20px;
  }
`

export const Wrapper = styled.div`
  width: 640px;

  ${theme.breakpoints.down('md')} {
    width: 100%;
    margin-top: 90px;
  }
`

export const ContainerTitle = styled.div`
  display: flex;
`

export const WrapperButton = styled.div`
  display: flex;
  justify-content: center;
`
