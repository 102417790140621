import React, { useContext } from 'react'
import { Container, Title } from '@provi/provi-components'
import { TemplateContext } from '../../../components/template/context'
import { TemplateForm } from '../../../components/template-form'
import { FormFomik } from './form'

export const RG = () => {
  const { goToNextScreen, creditPath } = useContext(TemplateContext)
  return (
    <TemplateForm>
      <Container>
        <Title text="Digite o número do seu RG abaixo" />
        <FormFomik data={creditPath && creditPath.ReadRG} goToNextScreen={goToNextScreen} />
      </Container>
    </TemplateForm>
  )
}
