import React, { useContext } from 'react'
import { LoadingProvi } from '@provi/provi-components'
import { useValidateToken } from './hooks'
import { TemplateContext } from '../../components/template/context'

export const ValidateToken = props => {
  const { setIsLoading, setCreditPath } = useContext(TemplateContext)
  useValidateToken({ ...props, setIsLoading, setCreditPath })
  return <LoadingProvi />
}
