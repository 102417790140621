/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { showToast } from '@provi/provi-components'
import Fuse from 'fuse.js'
import { addCourse, removeCourse } from '../../../services/api'
import { getSlugPartner } from '../../../utils'
import { LOCALSTORAGE_TOKENS, URL_EXTERNAL } from '../../../constants'
import { redirectToContract } from '../../../utils/redirectToContract'

const formatCourses = (available, choosed) => {
  let availableCoursesFormatted = [...available]
  choosed.forEach(course => {
    const indexOfCourse = availableCoursesFormatted.indexOf(availableCoursesFormatted.find(item => item.id === course.id))
    indexOfCourse >= 0 && availableCoursesFormatted.splice(indexOfCourse, 1)
  })
  return availableCoursesFormatted
}

const OPTIONS = {
  shouldSort: true,
  threshold: 0.3,
  location: 4,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ['name']
}

const INITIAL_NUMBERS_SHOW_COURSES = 6

export const useCourseSelection = (setDataModal, goToNextScreen, creditPath, setIsLoading, setCreditPath) => {
  const [options, setOptions] = useState([])
  const [optionsSearch, setOptionsSearch] = useState([])
  const [cursesSelect, setCursesSelect] = useState([])

  const [textSearch, setTextSearch] = useState()
  const [countCourses, setCountCourses] = useState(INITIAL_NUMBERS_SHOW_COURSES)
  const [moveToWaitingSignature, setMoveToWaitingSignature] = useState(false)
  const [token, setToken] = useState('')

  useEffect(() => {
    setIsLoading(true)
  }, [setIsLoading])

  useEffect(() => {
    setDataModal({
      title: 'Algo errado?',
      texts: [
        // 'Por conta da antecipação da volta do IOF (Imposto sobre Operações Financeiras), o valor final do curso deve aumentar em 3,38%.',
        'Você não está encontrando o seu curso ou alguma informação não está batendo? Clique no botão abaixo para falar com a gente.'
      ],
      textButton: 'Entendi',
      buttonFunction: () => window.open(URL_EXTERNAL, '_blank')
    })
    setIsLoading(true)

    const getData = () => {
      if (creditPath && creditPath.ReadISAProducts && creditPath.ReadISAProducts.availableCourses) {
        const courses = formatCourses(creditPath.ReadISAProducts.availableCourses, creditPath.ReadISAProducts.choosedCourses)
        setOptions(courses)
        setOptionsSearch(courses.slice(0, INITIAL_NUMBERS_SHOW_COURSES))
      }

      if (creditPath && creditPath.ReadISAProducts && creditPath.ReadISAProducts.choosedCourses) {
        setCursesSelect(creditPath.ReadISAProducts.choosedCourses)
      }
    }

    getData()
  }, [setDataModal, creditPath])

  useEffect(() => {
    if (options.length || cursesSelect.length) {
      setTimeout(() => {
        setIsLoading(false)
      }, 5000)
    }
  }, [options, cursesSelect])

  useEffect(() => {
    if (!options.length) return

    let result = []
    let coursesToShow = []

    if (textSearch && textSearch !== '') {
      let fuse = new Fuse(options, OPTIONS)
      result = fuse.search(textSearch)
      coursesToShow = result.slice(0, countCourses)
    } else {
      coursesToShow = options.slice(0, countCourses)
    }

    setOptionsSearch(coursesToShow)
  }, [options, countCourses, textSearch])

  const postCourse = async (idCourse, stopLoadingAnimation = true) => {
    setIsLoading(true)
    const crid = localStorage.getItem(LOCALSTORAGE_TOKENS.crid)
    const partner = getSlugPartner()
    try {
      const { data } = await addCourse(crid, partner, idCourse)

      setMoveToWaitingSignature(data?.moveToWaitingSignature)
      setToken(data?.token)

      const newCreditPath = {
        ...creditPath,
        ReadISAProducts: {
          ...creditPath.ReadISAProducts,
          total: data.total,
          discount: data.discount,
          finalTotal: data.finalTotal
        }
      }
      setCreditPath(newCreditPath)
      return true
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error && error.response.data.error.message) {
        showToast(error.response.data.error.message)
        return
      }

      showToast('Erro ao adicionar curso')
      return false
    } finally {
      stopLoadingAnimation && setIsLoading(false)
    }
  }

  const deleteCourse = async idCourse => {
    setIsLoading(true)
    const crid = localStorage.getItem(LOCALSTORAGE_TOKENS.crid)
    const partner = getSlugPartner()
    try {
      const { data } = await removeCourse(crid, partner, idCourse)
      const newCreditPath = {
        ...creditPath,
        ReadISAProducts: {
          ...creditPath.ReadISAProducts,
          total: data.total,
          discount: data.discount,
          finalTotal: data.finalTotal
        }
      }
      setCreditPath(newCreditPath)
      return true
    } catch (e) {
      showToast('Erro para remover curso')
      return false
    } finally {
      setIsLoading(false)
    }
  }

  const selectCourse = async (option, stopLoadingAnimation = true) => {
    if (cursesSelect.length > 0 && cursesSelect[0].id !== option.id) {
      showToast('Você só pode escolher uma opção de curso, caso quiser trocar de curso remova o curso selecionado')
      return
    }
    const response = await postCourse(option.id, stopLoadingAnimation)
    if (response) {
      setCursesSelect([option])

      const newOptions = options.filter(item => item.id !== option.id)
      setOptions(newOptions)
      setOptionsSearch(newOptions.slice(0, countCourses))
    }

    if (!stopLoadingAnimation) {
      onSubmit(document.createEvent('Event'))
    }
  }

  const removeCurse = async option => {
    const response = await deleteCourse(option.id)
    if (response) {
      options.push(option)
      setOptions(options)
      setOptionsSearch(options.slice(0, countCourses))
      const newCursesSelect = cursesSelect.filter(item => item.id !== option.id)
      setCursesSelect(newCursesSelect)
    }
  }

  const handleChange = value => {
    setTextSearch(value)
  }

  const onSubmit = e => {
    if (moveToWaitingSignature) {
      showToast('Você já possui um contrato gerado para este curso. Redirecionando para o contrato.')

      return redirectToContract(token)
    }

    e.preventDefault()
    goToNextScreen()
  }

  const incrementNumberOfShowCourses = () => {
    setCountCourses(countCourses + INITIAL_NUMBERS_SHOW_COURSES)
  }

  return [
    selectCourse,
    cursesSelect,
    removeCurse,
    onSubmit,
    textSearch,
    handleChange,
    optionsSearch,
    countCourses,
    incrementNumberOfShowCourses,
    options
  ]
}
