import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'

export const setupFirebase = enviroment => {
  let config
  if (enviroment === 'production') {
    config = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: 'fe-masterpath.firebaseapp.com',
      databaseURL: 'https://fe-masterpath.firebaseio.com',
      projectId: 'fe-masterpath',
      storageBucket: 'fe-masterpath.appspot.com',
      messagingSenderId: '261186277899',
      appId: '1:261186277899:web:ea9f609e38afd513532d0e',
      measurementId: 'G-X0EH94WFXD'
    }
  } else {
    config = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: 'fe-masterpath-staging.firebaseapp.com',
      databaseURL: 'https://fe-masterpath-staging.firebaseio.com',
      projectId: 'fe-masterpath-staging',
      storageBucket: 'fe-masterpath-staging.appspot.com',
      messagingSenderId: '378747496130',
      appId: '1:378747496130:web:ef97feb80d18e5079bfd5b',
      measurementId: 'G-ZYBR78ZQLY'
    }
  }

  return firebase.initializeApp(config)
}

export default firebase
