import * as Sentry from '@sentry/browser'
import { api } from '../api'

export const getData = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.get('/cpf')
      resolve(data)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'errorRequestGetCPF',
        message: 'ERROR INFO ' + err,
        level: Sentry.Severity.Warning
      })
      reject(err)
    }
  })
}

export const postData = data => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await api.post('/cpf', data)
      resolve(response)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'errorRequestPostCPF',
        message: 'ERROR INFO ' + err,
        level: Sentry.Severity.Warning
      })
      reject(err)
    }
  })
}

export const postBasicInfo = data => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await api.post('/basic-info', data)
      resolve(response)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'errorRequestPostCPF',
        message: 'ERROR INFO ' + err,
        level: Sentry.Severity.Warning
      })
      reject(err)
    }
  })
}
