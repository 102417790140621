import { isString } from 'lodash'
import smartlook from 'smartlook-client'
import { logger } from '../utils/logger'
import { LOCALSTORAGE_TOKENS } from '../constants'
import { ROUTES } from '../navigation/routes'
import { history } from '../navigation/history'
import moment from 'moment'

export const setLocalstorage = (storage, name) => {
  if (storage && name) localStorage.setItem(name, removeSpecialCharacters(storage))
}

export const removeSpecialCharacters = element => {
  if (!isString(element)) return element

  const newElement = element.replace(/[^\d]+/g, '')
  return newElement
}

export const formatMonthlyInterestText = monthlyInterest => {
  return (
    Number(monthlyInterest.toFixed(2)).toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }) + '% a.m.'
  )
}

export function currencyParser(value, prefix = '', isAbsolute) {
  let stringValue = new String(value) // eslint-disable-line
  let integers = []
  let decimals = ''

  const sign = /-.+/.test(stringValue) ? '-' : ''

  stringValue = stringValue
    .replace(/-/g, '')
    .replace(/,/g, '.')
    .replace(prefix, '')

  const splitedValues = stringValue.split('.')

  decimals = splitedValues.length > 1 ? splitedValues.pop() : '00'
  const integerValues = splitedValues.join('')

  integers = placeDots(integerValues)

  decimals = decimals.length < 2 ? `${decimals}0` : decimals.substring(0, 2)

  return `${prefix + sign + integers},${decimals}`
}

export function placeDots(string) {
  let iteration = -2
  const arrayWithDots = []

  for (let i = string.length - 1; i > -1; i -= 1) {
    if (iteration % 3 === 0 && i !== 0) {
      arrayWithDots.push(`.${string[i]}`)
    } else {
      arrayWithDots.push(string[i])
    }

    iteration += 1
  }

  return arrayWithDots.reverse().join('')
}

export const getSlugPartner = () => {
  const pathname = history.location.pathname
  const split = pathname.split('/')

  return split[split.length - 1]
}

export const getPathname = nextScreen => {
  const pathname = ROUTES.find(element => element.uniqueName === nextScreen)
  const pathSplit = pathname.path.split('/')
  return pathSplit[pathSplit.length - 2]
}

export const formatPhone = phone => {
  if (!phone) return

  const ddd = `(${phone.substr(0, 2)})`
  const _phone = `${phone.length === 11 ? phone.substr(2, 5) : phone.substr(2, 4)}-${phone.substr(7)}`

  return `${ddd} ${_phone}`
}

export const setSmartlook = crid => {
  try {
    const storage = localStorage.setItem(LOCALSTORAGE_TOKENS.dataUser)
    const dataUser = JSON.parse(storage)
    window.smartlook &&
      smartlook.identify(dataUser.UserId, {
        email: dataUser.userId,
        partnerSlug: dataUser.partnerSlug,
        crid: crid || 'Nao encontrado ainda'
      })
  } catch (error) {
    console.error('smartlook is not defined')
    logger(error)
  }
}

export const zipcodeFixer = zipcode => {
  const _zipcode = zipcode.replace(/-/g, '')
  const size = _zipcode.length
  if (size === 8) return _zipcode
  if (size < 8) {
    let fixZero = ''
    for (let i = size; i < 8; i++) {
      fixZero += `${0}`
    }
    return `${fixZero}${_zipcode}`
  }
}

export const testLocalStorage = () => {
  var test = 'test'
  try {
    localStorage.setItem(test, test)
    localStorage.removeItem(test)
    return true
  } catch (e) {
    return false
  }
}

export const checkURLisISA = path => {
  const pathname = path || history.location.pathname
  const split = pathname.split('/')

  const isISA = split[2] === 'isa'

  return isISA
}

export const getTranslatedProductType = rawProductType => {
  const options = {
    CourseFinancing: 'credito-educacional',
    PersonalLoan: 'credito-livre',
    Allowance: 'mesada'
  }
  const defaultProductType = options.CourseFinancing

  return options[rawProductType] || defaultProductType
}

export const processFullName = name => {
  try {
    let processedName = ''
    let names = name.toLowerCase().split(' ')
    for (const n in names) {
      processedName += ' ' + names[n].charAt(0).toUpperCase() + names[n].slice(1)
    }
    return processedName.slice(1)
  } catch (err) {
    return ''
  }
}

export const processBirthDate = date => {
  return date
    ? moment(date)
        .add(1, 'days')
        .format('DD/MM/YYYY')
    : ''
}

/**
 * @description Transform any object into query params
 * @function
 * @name transformObjectIntoQueryParams
 * @param {object} data
 * @returns {string}
 */
const transformObjectIntoQueryParams = data => {
  return Object.keys(data)
    .map(key => `${key}=${data[key]}`)
    .join('&')
}

/**
 * @description Navigate to route passed with params or without params
 * @function
 * @name navigateWithParams
 * @param {string} route
 */
export const navigateWithParams = route => {
  const dataUser = JSON.parse(localStorage.getItem(LOCALSTORAGE_TOKENS.dataUser))
  const objQueryParam = dataUser && dataUser.utmSourcesCode ? { ...dataUser.utmSourcesCode } : null

  objQueryParam
    ? history.push({
        pathname: route,
        search: transformObjectIntoQueryParams(objQueryParam)
      })
    : history.push(route)
}
