/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import moment from 'moment'

const TYPES_UNIVERSITIES = [
  {
    label: 'Medicina',
    value: 'medicine',
    id: 1
  },
  {
    label: 'Outra',
    value: 'other',
    id: 2
  }
]

const TYPES = [
  {
    label: 'Residente',
    value: 'resident',
    id: 1
  },
  {
    label: 'Interno',
    value: 'intern',
    id: 2
  },
  {
    label: 'Pré-interno',
    value: 'preIntern',
    id: 3
  }
]

const isSanarFinancing = window.location.pathname.includes('/sanarup-financiamento')

let DATE_MIN = '02/2022'
let DATE_LIMIT = '12/2024'

if (isSanarFinancing) {
  DATE_MIN = '03/2022'
  DATE_LIMIT = '01/2023'
}

export const useMedicineSchoolDetails = (setDataModal, creditPath, goToNextScreen) => {
  const [universities, setUniversities] = useState({})
  const [selectUniversity, setSelectUniversity] = useState()
  const [typeUniversity, setTypeUniversity] = useState()
  const [error, setError] = useState(false)
  const [notFoundUniversity, setNotFoundUniversity] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [isButtonDisabledDate, setIsButtonDisabledDate] = useState(true)
  const [isButtonDisabledTypeUniversity, setIsButtonDisabledTypeUniversity] = useState(true)
  const [date, setDate] = useState(false)
  const [errorDate, setErrorDate] = useState(false)
  const [, setMinDateString] = useState('')
  const [textError, setTextError] = useState('')

  const [type, setType] = useState(
    isSanarFinancing && {
      label: 'Interno',
      value: 'intern',
      id: 2
    }
  )

  const validationForm = (date, type) => {
    let enabled = false

    if (!type) {
      enabled = true
      setIsButtonDisabled(enabled)
      return
    }

    setIsButtonDisabled(enabled)
  }

  useEffect(() => {
    let enabled = true
    if (creditPath && creditPath.ReadTeachingInformation && creditPath.ReadTeachingInformation.universities) {
      setUniversities(creditPath.ReadTeachingInformation.universities.map(item => ({ ...item, value: item.label })))
    }

    if (creditPath && creditPath.ReadTeachingInformation && creditPath.ReadTeachingInformation.choosedUniversityOptionId) {
      if (creditPath.ReadTeachingInformation.choosedUniversityOptionId === 1) {
        setSelectTypeUniversity(TYPES_UNIVERSITIES[0])
        setNotFoundUniversity(true)
      }
      const find =
        creditPath.ReadTeachingInformation &&
        creditPath.ReadTeachingInformation.universities.find(
          item => item.id === creditPath.ReadTeachingInformation.choosedUniversityOptionId
        )

      if (find) {
        functionSelectUniversity(find)
      }

      enabled = false
    }

    if (creditPath && creditPath.ReadTeachingInformation && creditPath.ReadTeachingInformation.studentType) {
      const find = TYPES.find(item => item.value === creditPath.ReadTeachingInformation.studentType)
      setSelectTypeUniversity(TYPES_UNIVERSITIES[0])
      setType(find)
      enabled = false
    }

    if (creditPath && creditPath.ReadTeachingInformation && creditPath.ReadTeachingInformation.expectedGraduationDate) {
      setIsButtonDisabledDate(false)
      setDate(creditPath.ReadTeachingInformation.expectedGraduationDate)
    }

    setIsButtonDisabled(enabled)
    setDataModal(null)
  }, [])

  const handleChangeDate = value => {
    if (value.length <= 4) {
      setErrorDate(false)
      setIsButtonDisabledDate(true)
      return
    }

    if (value.length === 5) {
      setErrorDate(true)
      setIsButtonDisabledDate(true)
      setTextError('Data de formatura inválida')
      return
    }

    const momentCurrentDate = moment()
    const minDate = moment(DATE_MIN, 'MM/YYYY')
    const date = moment(value, 'MM/YYYY')
    const momentLimitDate = moment(DATE_LIMIT, 'MM/YYYY')
    const betweenDateLimits = date.isBetween(momentCurrentDate.clone().subtract(1, 'month'), momentLimitDate.add(1, 'month'))
    const isNotInThePast = date.diff(momentCurrentDate, 'days') >= 0
    const isAfterMinDate = date.diff(minDate, 'days') >= 0
    const isAfterLimitDate = date.diff(momentLimitDate, 'days') >= 0

    if (date.isValid() && betweenDateLimits && isAfterMinDate && isNotInThePast) {
      setErrorDate(false)
      setIsButtonDisabledDate(false)
    } else {
      if (isAfterMinDate && isAfterLimitDate) {
        setMinDateString(DATE_MIN)
      } else if (isAfterMinDate) {
        setMinDateString(momentCurrentDate.format('MM/YYYY'))
      } else {
        setMinDateString(DATE_MIN)
      }
      setErrorDate(true)
      setIsButtonDisabledDate(true)
      setTextError(`Exclusivo para quem vai se formar entre ${DATE_MIN} e ${DATE_LIMIT}`)
    }
  }

  const setSelectTypeUniversity = item => {
    validationForm(date, item)
    if (item.value === 'other') {
      setIsButtonDisabledTypeUniversity(true)
      setError(true)
    } else {
      setIsButtonDisabledTypeUniversity(false)
      setError(false)
    }

    setTypeUniversity(item.id)
  }

  const functionSelectUniversity = item => {
    setNotFoundUniversity(false)
    setSelectUniversity(item)
  }

  const onSubmit = e => {
    e.preventDefault()
    const body = {
      UniversityOptionId: notFoundUniversity ? 1 : selectUniversity && selectUniversity.id,
      studentType: type && type.value,
      expectedGraduationDate: date
    }
    goToNextScreen(body)
  }

  return [
    typeUniversity,
    setSelectTypeUniversity,
    error,
    universities,
    setType,
    type,
    functionSelectUniversity,
    selectUniversity,
    notFoundUniversity,
    setNotFoundUniversity,
    onSubmit,
    isButtonDisabled,
    date,
    handleChangeDate,
    errorDate,
    TYPES_UNIVERSITIES,
    TYPES,
    isButtonDisabledDate,
    isButtonDisabledTypeUniversity,
    textError,
    setDate,
    isSanarFinancing
  ]
}
