import React from 'react'
import { theme, Button, Title, SubHeaderDesktop } from '@provi/provi-components'

import { Title as InformationTitle, ContainerDescont, ValueDiscount, ContainerOption } from './style'
import { currencyParser } from '../../../utils'
import { SmallText } from '../../../components/SmallText'

function CourseConfirmation({
  isMobile,
  setIsShowModal,
  creditPath,
  options,
  SelectedCourse,
  selectCourse,
  cursesSelect,
  shouldAddCourseThroughRequest
}) {
  return (
    <>
      <InformationTitle color={theme.colors.blue600}>Essa é uma tela de confirmação</InformationTitle>

      {isMobile ? (
        <Title text="Avance para confirmar o produto selecionado" />
      ) : (
        <SubHeaderDesktop
          title="Avance para confirmar o produto selecionado"
          handleModal={() => setIsShowModal(true)}
          isShowButtonHelpModal
        />
      )}

      {creditPath && creditPath.ReadProductSelection && creditPath.ReadProductSelection.total !== null && (
        <ContainerDescont>
          <ValueDiscount bold color={theme.colors.blue900}>
            Valor total:{' '}
            {currencyParser(
              creditPath &&
                creditPath.ReadProductSelection &&
                creditPath.ReadProductSelection.availableCourses &&
                creditPath.ReadProductSelection.availableCourses[0] &&
                creditPath.ReadProductSelection.availableCourses[0].price,
              'R$'
            )}
          </ValueDiscount>
          {creditPath && creditPath.ReadProductSelection && creditPath.ReadProductSelection.discount > 0 && (
            <>
              <ValueDiscount color={theme.colors.pink900}>
                Desconto: {currencyParser(creditPath.ReadProductSelection.discount, 'R$')}
              </ValueDiscount>
              <ValueDiscount color={theme.colors.blue900}>
                Valor final: {currencyParser(creditPath.ReadProductSelection.finalTotal, 'R$')}
              </ValueDiscount>
            </>
          )}

          <SmallText>*O valor final pode sofrer alteração por conta do IOF.</SmallText>
        </ContainerDescont>
      )}

      <ContainerOption>
        {creditPath &&
          creditPath.ReadProductSelection &&
          creditPath.ReadProductSelection.availableCourses &&
          creditPath.ReadProductSelection.availableCourses.map((item, key) => <SelectedCourse item={item} key={key} />)}
      </ContainerOption>

      <Button
        marginHorizontal={0}
        disabled={[...options, ...cursesSelect].length <= 0}
        text="Avançar"
        type="button"
        persistFloatingMode={false}
        onClick={async () =>
          await selectCourse(
            creditPath &&
              creditPath.ReadProductSelection &&
              creditPath.ReadProductSelection.availableCourses &&
              creditPath.ReadProductSelection.availableCourses[0],
            false,
            false
          )
        }
      />
    </>
  )
}

export default CourseConfirmation
