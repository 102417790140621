import React, { useContext } from 'react'
import { Container, Title, Button, SubHeaderDesktop, theme, Modal } from '@provi/provi-components'
import { Form } from '../../../components/form'
import { TemplateContext } from '../../../components/template/context'
import { TemplateForm } from '../../../components/template-form'
import { currencyParser } from '../../../utils'
import {
  OptionsList,
  Option,
  OptionText,
  OptionBold,
  ValueDiscount,
  ContainerDiscount,
  ValueInputValue,
  ContainerInputValue,
  BlueText,
  PinkText,
  ModalText
} from './style'
import { useLoanOptionSelection } from './hooks'

export const FixedInstallmentSelection = () => {
  const { goToNextScreen, setDataModal, creditPath, isMobile, setIsShowModal } = useContext(TemplateContext)
  const [
    selectedOption,
    onSelectOption,
    onSubmit,
    isButtonDisabled,
    isShowModal,
    handleOpenModal,
    handleModal
  ] = useLoanOptionSelection(goToNextScreen, setDataModal, creditPath)

  const { daysToUpfrontPayment } = (creditPath && creditPath.ReadFixedInstallments) || {}

  return (
    <TemplateForm>
      {isShowModal &&
        creditPath?.ReadFixedInstallments?.values &&
        creditPath?.ReadFixedInstallments?.showPaymentInformationModal &&
        creditPath?.partnerId !== 1344 && (
          <Modal
            title=""
            onDismiss={() => handleOpenModal(false)}
            footer={<Button marginHorizontal={0} type="button" text="Entendi" onClick={() => handleModal()} />}
          >
            <ModalText>
              {creditPath && creditPath.ReadFixedInstallments && creditPath.ReadFixedInstallments.upfrontAmount ? (
                <>
                  Lembre-se que o pagamento da entrada no valor de{' '}
                  <BlueText>{currencyParser(creditPath.ReadFixedInstallments.upfrontAmount, 'R$')}</BlueText> deve ser realizado
                  em até{' '}
                  <PinkText>
                    {daysToUpfrontPayment} dia{daysToUpfrontPayment > 1 ? `s ` : ' '}após a emissão{' '}
                  </PinkText>{' '}
                  do contrato com a Provi.
                </>
              ) : (
                <>
                  Lembre-se que o pagamento da primeira parcela deve ser realizado em até{' '}
                  <PinkText>
                    {daysToUpfrontPayment} dia{daysToUpfrontPayment > 1 ? `s ` : ' '}após a emissão{' '}
                  </PinkText>{' '}
                  do contrato com a Provi.
                </>
              )}
            </ModalText>
          </Modal>
        )}
      <Container>
        <Form onSubmit={onSubmit}>
          {isMobile ? (
            <Title text="Escolha a forma de pagamento que mais se adequa ao seu bolso" />
          ) : (
            <SubHeaderDesktop
              title="Escolha a forma de pagamento que mais se adequa ao seu bolso"
              handleModal={() => setIsShowModal(true)}
              isShowButtonHelpModal
            />
          )}
          <ContainerDiscount>
            <ValueDiscount color={theme.colors.blue900}>
              Valor total:{' '}
              {currencyParser(
                creditPath &&
                  creditPath.ReadFixedInstallments &&
                  creditPath.ReadFixedInstallments.values &&
                  creditPath.ReadFixedInstallments.values.total,
                'R$'
              )}
            </ValueDiscount>

            {creditPath &&
              creditPath.ReadFixedInstallments &&
              creditPath.ReadFixedInstallments.values &&
              creditPath.ReadFixedInstallments.values.discount > 0 && (
                <>
                  <ValueDiscount color={theme.colors.pink900}>
                    (-) Desconto:{' '}
                    {currencyParser(
                      creditPath &&
                        creditPath.ReadFixedInstallments &&
                        creditPath.ReadFixedInstallments.values &&
                        creditPath.ReadFixedInstallments.values.discount,
                      'R$'
                    )}
                  </ValueDiscount>
                  <ValueDiscount marginTop color={theme.colors.blue900}>
                    Valor da venda:{' '}
                    {currencyParser(
                      creditPath &&
                        creditPath.ReadFixedInstallments &&
                        creditPath.ReadFixedInstallments.values &&
                        creditPath.ReadFixedInstallments.values.finalTotal,
                      'R$'
                    )}
                  </ValueDiscount>
                </>
              )}

            {creditPath &&
              creditPath.ReadFixedInstallments &&
              creditPath.ReadFixedInstallments.values &&
              creditPath.ReadFixedInstallments.values.hasUpfrontPayment && (
                <ContainerInputValue>
                  <ValueDiscount color={theme.colors.blue600}>
                    Entrada*:{' '}
                    {currencyParser(
                      creditPath &&
                        creditPath.ReadFixedInstallments.values &&
                        creditPath.ReadFixedInstallments.values.upfrontAmount,
                      'R$'
                    )}
                  </ValueDiscount>
                  <ValueDiscount color={theme.colors.grey600}>
                    Parcelado:{' '}
                    {currencyParser(
                      creditPath &&
                        creditPath.ReadFixedInstallments.values &&
                        creditPath.ReadFixedInstallments.values.installmentAmount,
                      'R$'
                    )}
                  </ValueDiscount>
                </ContainerInputValue>
              )}
          </ContainerDiscount>

          {creditPath &&
            creditPath.ReadFixedInstallments &&
            creditPath.ReadFixedInstallments.values &&
            creditPath.ReadFixedInstallments.values.hasUpfrontPayment && (
              <ValueInputValue>
                *O pagamento da entrada deve ser realizado em até{' '}
                {creditPath && creditPath.ReadFixedInstallments && creditPath.ReadFixedInstallments.daysToUpfrontPayment}
                {creditPath && creditPath.ReadFixedInstallments && creditPath.ReadFixedInstallments.daysToUpfrontPayment === 1
                  ? ' dia '
                  : ' dias '}
                após a assinatura do contrato com a Provi.
              </ValueInputValue>
            )}

          <OptionsList>
            {creditPath &&
              creditPath.ReadFixedInstallments &&
              creditPath.ReadFixedInstallments.options.length > 0 &&
              creditPath.ReadFixedInstallments.options.map((option, index) => (
                <Option
                  key={option.id}
                  upFront={
                    creditPath &&
                    creditPath.ReadFixedInstallments &&
                    creditPath.ReadFixedInstallments.values &&
                    creditPath.ReadFixedInstallments.values.hasUpfrontPayment
                  }
                  first={index === 0}
                  isSelected={selectedOption && selectedOption.id === option.id}
                  onClick={() => onSelectOption(option)}
                >
                  {creditPath &&
                    creditPath.ReadFixedInstallments &&
                    creditPath.ReadFixedInstallments.values &&
                    creditPath.ReadFixedInstallments.values.hasUpfrontPayment && (
                      <ValueDiscount color={theme.colors.blue600}>Entrada +</ValueDiscount>
                    )}

                  <OptionBold isSelected={selectedOption && selectedOption.id === option.id}>
                    {option.installmentsToApply}x
                  </OptionBold>
                  <OptionText size={13} bold isSelected={selectedOption && selectedOption.id === option.id}>
                    {currencyParser(option.pricePerInstallment, 'R$ ')}
                  </OptionText>

                  <OptionText size={10} isSelected={selectedOption && selectedOption.id === option.id}>
                    {!option.monthlyInterest ? 'sem juros' : `${String(option.monthlyInterest).replace('.', ',')}% a.m.`}
                  </OptionText>
                </Option>
              ))}
          </OptionsList>

          {isMobile && <div style={{ height: '33px' }} />}

          <Button marginHorizontal={0} type="submit" disabled={isButtonDisabled} text="Avançar" persistFloatingMode={false} />
        </Form>
      </Container>
    </TemplateForm>
  )
}
