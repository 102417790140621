import React, { useContext } from 'react'
import { Container, Title } from '@provi/provi-components'
import { TemplateContext } from '../../../components/template/context'
import { useISAContext } from '../../../components/template/ISA/context'
import { TemplateFormIsa } from '../../../components/template-isa'
import { FormFomik } from './form'

export const CourseTime = () => {
  const { creditPath } = useContext(TemplateContext)
  const { goToNextScreen } = useISAContext()
  return (
    <TemplateFormIsa>
      <Container>
        <Title text="Como você pretende gerir o seu tempo durante o curso pensando nas demais coisas que você faz?" />
        <FormFomik data={creditPath && creditPath.ReadExpectedWaysToManageTime} goToNextScreen={goToNextScreen} />
      </Container>
    </TemplateFormIsa>
  )
}
