import React, { useContext } from 'react'
import { Container, Title, SubHeaderDesktop } from '@provi/provi-components'
import { TemplateContext } from '../../../components/template/context'
import { TemplateFormIsa } from '../../../components/template-isa'
import { FormFormik } from '../../cpf/form'
import { useCpf } from '../../cpf/hooks'

export const Cpf = props => {
  const { setIsShowModal, isMobile, setDataModal, setIsLoading, setCreditPath } = useContext(TemplateContext)
  const [data] = useCpf(setDataModal, setIsLoading, setIsShowModal, setCreditPath)

  return (
    <TemplateFormIsa>
      <Container>
        {isMobile ? (
          <Title text="Qual o seu CPF?" marginVertical={12} />
        ) : (
          <SubHeaderDesktop title="Qual o seu CPF?" handleModal={() => setIsShowModal(true)} isShowButtonHelpModal />
        )}
        <FormFormik
          data={data}
          setIsLoading={setIsLoading}
          setDataModal={setDataModal}
          setCreditPath={setCreditPath}
          setIsShowModal={setIsShowModal}
          {...props}
        />
        {isMobile && data && <div style={{ height: '27px' }} />}
      </Container>
    </TemplateFormIsa>
  )
}
