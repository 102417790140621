import React, { useContext, useState } from 'react'
import { LoadingProvi, Button, Container, SubHeaderDesktop, Modal, TextLink } from '@provi/provi-components'
import { Form } from '../../../components/form'
import { TemplateContext } from '../../../components/template/context'
import { TemplateForm } from '../../../components/template-form'

import { ContainerMobile, Text, WrapperFooter, TextModal, TitleDesktop, WrapperTitle, ContainerButton } from './style'

const TITLE = 'Para concluir a sua solicitação, será necessário que você coloque alguém como seu avalista de crédito.'

export const GuarantorIntro = () => {
  const { setIsShowModal, isMobile, goToNextScreen, isLoading } = useContext(TemplateContext)
  const [isShowModalGuarantor, setIsShowModalGuarantor] = useState(false)
  return (
    <>
      {isLoading && <LoadingProvi />}
      {isShowModalGuarantor && (
        <Modal
          onDismiss={() => setIsShowModalGuarantor(false)}
          title="Avalista é aquele que aceita ser responsável pelo pagamento das parcelas de um financiamento ou empréstimo feito por outra pessoa."
          footer={<Button marginHorizontal={0} text="Indicar avalista" onClick={goToNextScreen} />}
        >
          <TextModal>Fique tranquilo, pois vamos te ajudar a falar com o seu futuro avalista! :)</TextModal>
          <TextModal>Requisitos:</TextModal>
          <TextModal>- Tem que ter mais de 18 anos e menos de 70</TextModal>
          <TextModal>- Alguém de sua confiança, de preferência com o mesmo sobrenome</TextModal>
          <TextModal>- Sem pendência financeira no nome</TextModal>
        </Modal>
      )}
      {isMobile ? (
        <ContainerMobile>
          <Text>{TITLE}</Text>
          <WrapperFooter>
            <TextLink text="O que é um avalista?" onClick={() => setIsShowModalGuarantor(true)} />

            <Button marginHorizontal={0} text="Avançar" onClick={() => goToNextScreen()} persistFloatingMode={false} />
          </WrapperFooter>
        </ContainerMobile>
      ) : (
        <TemplateForm>
          <Container>
            <Form
              onSubmit={e => {
                e.preventDefault()
                goToNextScreen()
              }}
            >
              <WrapperTitle>
                <SubHeaderDesktop title={TITLE} handleModal={() => setIsShowModal(true)} />
              </WrapperTitle>
              <ContainerButton>
                <Button
                  marginHorizontal={0}
                  type="submit"
                  text="Avançar"
                  textLink={{ text: 'O que é um avalista?', onClick: () => setIsShowModalGuarantor(true) }}
                />
              </ContainerButton>
            </Form>
          </Container>
        </TemplateForm>
      )}
    </>
  )
}
