import React, { useContext } from 'react'
import { Container, Button, Title, SubTitle, SubHeaderDesktop, UploadBox } from '@provi/provi-components'

import { Form } from '../../../components/form'
import { TemplateContext } from '../../../components/template/context'
import { TemplateForm } from '../../../components/template-form'
import { useSchoolRecords } from './hooks'
import img_history_school from '../../../assets/svg/img_history_school.svg'
import { MesadaProviSlug } from '../../../constants'

const OPTIONS_DROPZONE = 'image/jpeg, image/png, application/pdf'

export const SchoolRecords = ({ match }) => {
  const { setIsShowModal, isMobile, goToNextScreen, setDataModal, setIsLoading, creditPath } = useContext(TemplateContext)
  const [
    onSubmit,
    numberOfFiles,
    preview,
    setPreview,
    setFilesNotUpload,
    filesNotUpload,
    setNumberOfFiles,
    numberOfFilesInitial,
    isButtonDisabled,
    title
  ] = useSchoolRecords(goToNextScreen, setDataModal, creditPath, setIsLoading, setIsShowModal, match, MesadaProviSlug)

  return (
    <TemplateForm>
      <Container>
        <Form onSubmit={onSubmit}>
          {isMobile ? (
            <Title text={title} />
          ) : (
            <SubHeaderDesktop title={title} handleModal={() => setIsShowModal(true)} isShowButtonHelpModal />
          )}
          {match.params.partner === MesadaProviSlug && (
            <SubTitle text="É importante que o histórico inclua reprovações (caso tenha reprovado alguma disciplina) e data prevista de formatura."></SubTitle>
          )}
          <UploadBox
            preview={preview}
            setPreview={setPreview}
            setFilesNotUpload={setFilesNotUpload}
            filesNotUpload={filesNotUpload}
            setNumberOfFiles={setNumberOfFiles}
            numberOfFiles={numberOfFiles}
            image={img_history_school}
            numberOfFilesInitial={numberOfFilesInitial}
            options={OPTIONS_DROPZONE}
          />
          <Button marginHorizontal={0} type="submit" disabled={isButtonDisabled} text="Avançar" persistFloatingMode={false} />
        </Form>
      </Container>
    </TemplateForm>
  )
}
