import React from 'react'
import { Button, Modal, SubTitle } from '@provi/provi-components'
import modalSelfie1 from '../../../../assets/images/modal-selfie-1.png'
import modalSelfie1Desktop from '../../../../assets/images/modal-selfie-1-desktop.png'
import modalSelfie2 from '../../../../assets/images/modal-selfie-2.png'
import modalSelfie2Desktop from '../../../../assets/images/modal-selfie-2-desktop.png'
import { Image, ContainerModal } from '../style'

export const ModalSelfie = ({ setIsShowModal, setTypeModal, typeModal, isMobile }) => {
  const modals = [
    {
      title: 'Correto',
      subTitle: `Estar bem iluminada e nítida. Sem filtros!`,
      textButton: 'Avançar',
      func: () => setTypeModal(1),
      image: isMobile ? modalSelfie1 : modalSelfie1Desktop
    },
    {
      title: 'Errado',
      subTitle: 'Não use acessórios que escondam o rosto!',
      textButton: 'Tirar foto',
      func: () => {
        setIsShowModal(false)
        setTypeModal(0)
      },
      image: isMobile ? modalSelfie2 : modalSelfie2Desktop
    }
  ]

  return (
    <Modal
      title={modals[typeModal].title}
      onDismiss={() => {
        setTypeModal(0)
        setIsShowModal(false)
      }}
      footer={
        <Button marginHorizontal={0} type="button" text={modals[typeModal].textButton} onClick={modals[typeModal].func} />
      }
    >
      <SubTitle text={modals[typeModal].subTitle} />
      <ContainerModal>
        <Image src={modals[typeModal].image} />
      </ContainerModal>
    </Modal>
  )
}
