import React, { useContext } from 'react'
import { Button, Modal, LoadingProvi, TextLink } from '@provi/provi-components'
import { TemplateContext } from '../../../components/template/context'
import { URL_EXTERNAL } from '../../../constants'
import { Container, SubTitle, Text, Wrapper, TextModal, ContainerButton, ContainerButtonModal } from './style'
import { useGuarantorSecondNegativeFeedback } from './hooks'

export const GuarantorSecondNegativeFeedback = () => {
  const { goToNextScreen, isLoading, isMobile } = useContext(TemplateContext)
  const [isShowModal, setIsShowModal] = useGuarantorSecondNegativeFeedback()
  return (
    <>
      {isLoading && <LoadingProvi isLoadingDark />}
      <Container>
        {isShowModal && (
          <Modal onDismiss={() => setIsShowModal(false)} title="Por que você precisa de adicionar outro avalista?">
            <TextModal>Por que preciso adicionar outro avalista?</TextModal>

            <TextModal>O CPF que você passou não foi aprovado pela nossa análise.</TextModal>
            <TextModal>
              A gente não olha apenas se o nome está limpo. Muitas variáveis são levadas em conta, como o comportamento
              financeiro recente, histórico de pagamentos, quanto já tem de crédito em outros lugares, etc.
            </TextModal>
            <TextModal>Caso você não saiba quem colocar como avalista, entre em contato com a gente.</TextModal>
            <ContainerButtonModal>
              <Button
                marginHorizontal={0}
                text="Entendi"
                onClick={() => setIsShowModal(false)}
                textLink={{ text: 'Abrir chat', onClick: () => window.open(URL_EXTERNAL, '_blank') }}
              />
            </ContainerButtonModal>
          </Modal>
        )}
        <Wrapper>
          <SubTitle>Infelizmente, você não pode seguir com esse avalista.</SubTitle>
          <Text>
            Você ainda pode inserir mais 1 CPF.
            <br />
            Fique tranquilo! Se não der certo nós vamos tentar de ajudar.
          </Text>
          <ContainerButton>
            {isMobile && (
              <TextLink text="Por que preciso de outro avalista?" onClick={() => setIsShowModal(true)} color="white" />
            )}

            <Button
              marginHorizontal={0}
              text="Adicionar outro"
              onClick={() => goToNextScreen()}
              textLink={
                !isMobile && { text: 'Por que preciso de outro avalista?', onClick: () => setIsShowModal(true), color: 'white' }
              }
              persistFloatingMode={false}
            />
          </ContainerButton>
        </Wrapper>
      </Container>
    </>
  )
}
