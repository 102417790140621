import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import background_right_desktop from '../../assets/svg/background-right-desktop.svg'

export const View = styled.div`
  width: 100%;
  min-height: 100vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: row;
`

export const TitleContainer = styled.div`
  display: flex;
  width: 60%;

  ${theme.breakpoints.down('lg')} {
    width: 90%;
  }
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`

export const TextModal = styled.div`
  font-size: 16px;
  line-height: 24px;
  margin: 24px 0;
  color: ${theme.colors.blue900};

  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    line-height: 18px;
    margin-top: 20px;
  }
`

export const Left = styled.div`
  width: 65%;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;

  ${theme.breakpoints.down('md')} {
    position: relative;
    left: 0;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
  }
`

export const Right = styled.div`
  width: 35%;
  height: 100vh;
  background-color: ${theme.colors.white100} !important;
  background: url(${background_right_desktop});
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 100%;
  transform: translate(1px, 1px);
  position: fixed;
  right: 0;
`
