import React, { useContext } from 'react'
import { Container, Title, SubTitle } from '@provi/provi-components'
import { TemplateContext } from '../../../components/template/context'
import { TemplateForm } from '../../../components/template-form'
import { FormFomik } from './form'

export const CouponCode = () => {
  const { goToNextScreen } = useContext(TemplateContext)
  return (
    <TemplateForm>
      <Container>
        <Title text="Possui algum cupom de desconto? Se sim, digite abaixo" />
        <SubTitle text="Caso não tenha, avance sem preencher" />
        <FormFomik goToNextScreen={goToNextScreen} />
      </Container>
    </TemplateForm>
  )
}
