import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Title = styled.h1`
  font-family: 'CenturyGothicBold';
  margin-top: 7px;
  color: ${({ color }) => color};
  font-size: 24px;
  line-height: 30px;
  ${theme.breakpoints.down('md')} {
    font-size: 20px;
    line-height: 26px;
  }
`

export const Text = styled.p`
  font-family: ${({ bold }) => (bold ? 'CenturyGothicBold' : 'CenturyGothic')};
  color: ${theme.colors.blue900};
  margin: 8px 0;
  ${theme.breakpoints.down('md')} {
    font-size: 16px;
    line-height: 20px;
  }
`
