import React, { useContext } from 'react'
import { Container, Title } from '@provi/provi-components'
import { TemplateContext } from '../../../components/template/context'
import { useISAContext } from '../../../components/template/ISA/context'
import { TemplateFormIsa } from '../../../components/template-isa'
import { BlueSubTitle } from '../../../components/BlueText'
import { FormFomik } from './form'

export const DecisionMoney2 = () => {
  const { creditPath, isMobile } = useContext(TemplateContext)
  const { goToNextScreen } = useISAContext()
  return (
    <TemplateFormIsa>
      <Container>
        <Title text="E se a situação fosse ao contrário? Qual seria o mínimo que você aceitaria receber na divisão?" />
        <BlueSubTitle>Nesse caso, você é pessoa desconhecida que receberá uma parte da divisão de R$ 100.</BlueSubTitle>
        <FormFomik data={creditPath && creditPath.ReadMinimalPartOfADivisionWithSomeone} goToNextScreen={goToNextScreen} />
        {isMobile && <div style={{ height: '24px' }} />}
      </Container>
    </TemplateFormIsa>
  )
}
