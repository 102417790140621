import { useState, useEffect } from 'react'

export const useCiviStateSelect = (goToNextScreen, creditPath) => {
  const [options, setOptions] = useState({})
  const [selects, setSelects] = useState({})
  const [isShowError, setIsShowError] = useState(false)

  const refactingArray = (array, name) =>
    array.map(item => ({
      id: item.id,
      label: item.name,
      name
    }))

  const setSelect = select => {
    setSelects({
      ...selects,
      [select.name]: select
    })
    setIsShowError(false)
  }

  const onSubmit = event => {
    event.preventDefault()
    const notHaveError = Object.keys(selects).length === 1

    const result = {}
    Object.keys(selects).map(key => (result.id = selects[key].id))
    if (notHaveError) {
      goToNextScreen(result)
    } else {
      setIsShowError(true)
    }
  }

  useEffect(() => {
    const englishOptions =
      creditPath &&
      creditPath.ReadEnglishLevel &&
      creditPath.ReadEnglishLevel.options &&
      refactingArray(creditPath.ReadEnglishLevel.options, 'englishLevel')

    setOptions(englishOptions)

    let englishLevel
    if (creditPath && creditPath.ReadEnglishLevel && creditPath.ReadEnglishLevel.choosedId) {
      englishLevel = englishOptions.find(item => item.id === creditPath.ReadEnglishLevel.choosedId)
    }

    setSelects({ englishLevel })
  }, [creditPath])

  return [onSubmit, isShowError, setSelect, selects, options]
}
