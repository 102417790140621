import React, { useContext } from 'react'
import { LoadingProvi } from '@provi/provi-components'
import { TemplateContext } from '../../../components/template/context'
import { ContainerBackground, ContainerSuccess, ContainerText, Wrapper, Container, Title, SubTitle, Bold } from './style'

export const AwaitingSignContract = () => {
  const { isMobile, isLoading } = useContext(TemplateContext)

  return (
    <>
      <Wrapper>
        <ContainerSuccess>
          {isLoading && <LoadingProvi isLoadingDark />}
          <Container>
            <ContainerText>
              <Title>Você só precisa assinar o contrato que te enviamos por email!</Title>
              <SubTitle>
                Procure na sua caixa de entrada por um email com o assunto <Bold>“Contrato de financiamento”</Bold> enviado pelo
                remetente <Bold>DocuSign.</Bold>
              </SubTitle>
              <SubTitle>
                <Bold>Assim que assinar, sua solicitação estará garantida!</Bold>
              </SubTitle>
            </ContainerText>
          </Container>
        </ContainerSuccess>
        {!isMobile && <ContainerBackground />}
      </Wrapper>
    </>
  )
}
