import React, { useContext } from 'react'
import { SubTitle, Container, Button, Title, UploadBox, TextLink } from '@provi/provi-components'
import { Form } from '../../../components/form'
import { TemplateContext } from '../../../components/template/context'
import { useISAContext } from '../../../components/template/ISA/context'
import { TemplateFormIsa } from '../../../components/template-isa'
import { WebcamWrapper } from '../../../components/webcam'
import img_selfie from '../../../assets/svg/img_selfie.svg'
import img_selfie_disabled from '../../../assets/svg/img_selfie_disabled.svg'
import { WrapperFiles, Text } from './style'
import { useUploadSelfie } from './hooks'
import { ModalSelfie } from './modal'

const OPTIONS_DROPZONE = 'image/jpeg, image/png, application/pdf'

export const Selfie = () => {
  const { creditPath, setIsLoading, isMobile } = useContext(TemplateContext)
  const { goToNextScreen } = useISAContext()
  const [
    preview,
    onSubmit,
    isButtonDisabled,
    numberOfFiles,
    isShowModal,
    setIsShowModal,
    typeModal,
    setTypeModal,
    filesNotUpload,
    setFilesNotUpload,
    setIsButtonDisabled,
    setIsWebcam,
    isWebcam,
    setPreview,
    setNumberOfFiles,
    numberOfFilesInitial
  ] = useUploadSelfie(goToNextScreen, creditPath, setIsLoading, isMobile)
  return (
    <TemplateFormIsa>
      {isShowModal && (
        <ModalSelfie isMobile={isMobile} setIsShowModal={setIsShowModal} setTypeModal={setTypeModal} typeModal={typeModal} />
      )}
      <Container>
        <Form onSubmit={onSubmit}>
          <Title text="Envie uma selfie" />
          <SubTitle text="Envie uma foto nítida, bem iluminada e sem acessórios que cubram o seu rosto!" />
          {!isMobile && (
            <WrapperFiles>
              <UploadBox
                width={216}
                preview={preview}
                setPreview={setPreview}
                setFilesNotUpload={setFilesNotUpload}
                filesNotUpload={filesNotUpload}
                setNumberOfFiles={setNumberOfFiles}
                numberOfFiles={numberOfFiles}
                numberOfFilesInitial={numberOfFilesInitial}
                isWebcam={isWebcam}
                image={isWebcam ? img_selfie_disabled : img_selfie}
                options={OPTIONS_DROPZONE}
              />
              <Text>ou</Text>
              <WebcamWrapper
                file={preview}
                setPreview={setPreview}
                setIsButtonDisabled={setIsButtonDisabled}
                filesNotUpload={filesNotUpload}
                setFilesNotUpload={setFilesNotUpload}
                disabled={numberOfFiles > 0}
                img={numberOfFiles > 0 ? img_selfie_disabled : img_selfie}
                setIsWebcam={setIsWebcam}
              />
            </WrapperFiles>
          )}

          {isMobile && (
            <UploadBox
              origin={'selfie'}
              preview={preview}
              setPreview={setPreview}
              setFilesNotUpload={setFilesNotUpload}
              filesNotUpload={filesNotUpload}
              setNumberOfFiles={setNumberOfFiles}
              numberOfFiles={numberOfFiles}
              numberOfFilesInitial={numberOfFilesInitial}
              isWebcam={isWebcam}
              image={isWebcam ? img_selfie_disabled : img_selfie}
              options={OPTIONS_DROPZONE}
            />
          )}

          {isMobile && <TextLink text="Como tirar a minha selfie?" onClick={() => setIsShowModal(true)} />}

          <Button
            marginHorizontal={0}
            type="submit"
            text="Avançar"
            disabled={isButtonDisabled}
            textLink={!isMobile && { text: 'Como tirar a minha selfie?', onClick: () => setIsShowModal(true) }}
            persistFloatingMode={false}
          />
        </Form>
      </Container>
    </TemplateFormIsa>
  )
}
