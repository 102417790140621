import React from 'react'
import * as Yup from 'yup'
import { withFormik } from 'formik'
import { Input, TextErrorInput, Button } from '@provi/provi-components'
import { Form } from '../../../components/form'

export const Formik = props => {
  const { handleChange, values, errors, touched, handleBlur, handleSubmit } = props

  return (
    <Form onSubmit={handleSubmit}>
      <Input
        cy="input-full-name"
        autoFocus
        margin="normal"
        id="name"
        placeholder="Digite aqui..."
        onChange={handleChange('value')}
        onBlur={handleBlur('value')}
        error={!!errors.value}
        value={values.value}
        inputProps={{
          maxLength: 1024
        }}
      />
      {errors.value && touched.value && <TextErrorInput text={errors.value} />}

      <Button
        marginHorizontal={0}
        type="submit"
        disabled={!(values.value && values.value.length > 4)}
        text="Avançar"
        persistFloatingMode={false}
      />
    </Form>
  )
}

export const FormFomik = withFormik({
  validateOnBlur: true,
  validateOnChange: false,
  enableReinitialize: true,
  mapPropsToValues: props => ({ value: !!props.data ? props.data : '' }),
  validationSchema: Yup.object().shape({
    value: Yup.string()
      .required('Por favor preencha o campo acima')
      .min(4, 'Por favor preencha mais que 4 caracteres')
  }),
  handleSubmit: async ({ value }, { setSubmitting, props }) => {
    props.goToNextScreen(value)
    setSubmitting(false)
  }
})(Formik)
