import { useState, useEffect } from 'react'

export const useCourseReason = creditPath => {
  const [courseName, setCourseName] = useState('')
  const [partnerName, setPartnerName] = useState('')

  useEffect(() => {
    setCourseName(creditPath && creditPath.ReadChoosedISAProduct)
    setPartnerName(creditPath && creditPath.ReadPartner)
  }, [creditPath])

  return [courseName, partnerName]
}
