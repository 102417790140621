import React, { useContext } from 'react'
import moment from 'moment'
import ArrowForward from '@material-ui/icons/ArrowForward'
import { makeStyles } from '@material-ui/core/styles'
import { theme, Container, Title, SubHeaderDesktop } from '@provi/provi-components'
import { TemplateContext } from '../../components/template/context'
import { Template } from '../../components/template'
import { HeaderIntro } from '../../components/header-intro'
import { currencyParser, getSlugPartner, navigateWithParams } from '../../utils'

import { setCridLocal, useCridSelection } from './hooks'
import {
  ContainerOption,
  Option,
  Row,
  Text,
  Line,
  Header,
  Main,
  TextHeader,
  BallStatus,
  ContainerButton,
  WrapperButton,
  ContainerButtonNewRequest,
  WrapperButtonNewRequest
} from './style'

const useStyles = makeStyles({
  icon: {
    fontSize: theme.variables.spacer * 2,
    marginLeft: 10
  }
})

export const CridSelector = () => {
  const { setIsShowModal, isMobile, setIsLoading, setDataModal, setCrid, setCreditPath } = useContext(TemplateContext)
  const [data, canCreate, verifyTextHeader, formCreateCrid, partnerName] = useCridSelection(
    setIsLoading,
    setDataModal,
    setCreditPath
  )
  const classes = useStyles()
  return (
    <Template
      goToPreviousScreen={() => {
        setIsLoading(true)

        navigateWithParams(`/cpf/${getSlugPartner()}`)
      }}
    >
      <HeaderIntro partnerName={partnerName} />
      <Container isMarginDesktop>
        {isMobile ? (
          <Title text="Selecione a solicitação de financiamento que você deseja dar continuidade:" />
        ) : (
          <SubHeaderDesktop
            title="Selecione a solicitação de financiamento que você deseja dar continuidade:"
            handleModal={() => setIsShowModal(true)}
          />
        )}

        {canCreate && (
          <ContainerButtonNewRequest>
            <WrapperButtonNewRequest
              variant="contained"
              onClick={() => formCreateCrid(setIsLoading, setCrid, setCreditPath)}
              type="button"
            >
              {data && data.length > 0 ? 'Começar nova solicitação' : 'Começar solicitação'}
            </WrapperButtonNewRequest>
          </ContainerButtonNewRequest>
        )}

        <ContainerOption>
          {data.map(item => (
            <Option key={item.id}>
              <Header>
                <BallStatus color={verifyTextHeader(item.situation).color} />
                <TextHeader>
                  {verifyTextHeader(item.situation).text}{' '}
                  {item.progress.percentageCompleted > 0 &&
                    `(${item.progress && item.progress.percentageCompleted && item.progress.percentageCompleted.toFixed()}% ${
                      !isMobile ? ' completa' : ''
                    })`}
                </TextHeader>
              </Header>
              <Line width={item.progress && item.progress.percentageCompleted && item.progress.percentageCompleted.toFixed()} />
              <Main>
                <Row>
                  <Text>Data de início da solicitação: </Text>
                  <Text bold>{item.startDate ? moment(item.startDate).format('DD/MM/YYYY') : '-'} </Text>
                </Row>

                {item.products && item.products.length > 0 ? (
                  item.products.map((item, index) => (
                    <Row key={index}>
                      <Text>Produto {index + 1}:</Text>
                      <Text bold>{item}</Text>
                    </Row>
                  ))
                ) : (
                  <Row>
                    <Text>Produto:</Text>
                    <Text bold>A escolher</Text>
                  </Row>
                )}

                {item.price > 0 ? (
                  <Row>
                    <Text>Valor:</Text>
                    <Text bold>{currencyParser(item.price, 'R$')}</Text>
                  </Row>
                ) : (
                  <Row>
                    <Text>Valor:</Text>
                    <Text bold>-</Text>
                  </Row>
                )}
              </Main>
              {item.situation !== 'denied' && item.situation !== 'expired' && (
                <ContainerButton>
                  <WrapperButton
                    variant="contained"
                    onClick={() => setCridLocal(item, setCrid, setIsLoading, setCreditPath)}
                    type="button"
                  >
                    Continuar
                    <ArrowForward className={classes.icon} />
                  </WrapperButton>
                </ContainerButton>
              )}
            </Option>
          ))}
        </ContainerOption>
      </Container>
    </Template>
  )
}
