import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import bg_background_pre_approved from '../../../assets/svg/bg-background-pre-approved.svg'

export const Container = styled.div`
  padding-left: 137px;
  padding-right: 22px;
  padding-bottom: 22px;
  height: 100vh;
  width: 100%;
  background-image: url(${bg_background_pre_approved});
  background-repeat: no-repeat;
  background-position: right bottom;
  background-color: ${theme.colors.blue900};
  box-sizing: border-box;
  overflow: auto;
  display: flex;
  align-items: center;
  ${theme.breakpoints.down('md')} {
    display: block;
    padding-left: 22px;
    margin-left: 0;
    background-image: none;
  }
  ${theme.breakpoints.down('lg')} {
    background-size: 36vw;
  }
`

export const Title = styled.h4`
  margin-top: 40px;
  font-family: CenturyGothicBold, sans-serif;
  color: ${theme.colors.blue600};
  font-size: 38px;
  line-height: 47px;
  ${theme.breakpoints.down('md')} {
    font-size: 24px;
    line-height: 30px;
  }
`

export const SubTitle = styled.p`
  font-family: CenturyGothicBold, sans-serif;
  color: ${theme.colors.white100};
  font-size: 24px;
  line-height: 30px;
  ${theme.breakpoints.down('md')} {
    font-size: 16px;
    line-height: 24px;
  }
`

export const Text = styled.p`
  font-family: 'CenturyGothic', sans serif;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.white100};
  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    line-height: 20px;
  }
`

export const Wrapper = styled.div`
  width: 640px;
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`

export const ContainerSocial = styled.div`
  display: flex;
  flex-direction: row;
`

export const Item = styled.a`
  text-decoration: none;
  color: transparent;
  margin-right: 8px;
  cursor: pointer;
`

export const ContainerTitle = styled.div`
  display: flex;
  margin-top: 100px;
  ${theme.breakpoints.down('md')} {
    margin-top: 50px;
  }
`

export const ContainerImages = styled.div`
  display: flex;
  justify-content: ${({ isMobile }) => (isMobile ? 'center' : '')};
  margin-bottom: ${({ isMobile }) => (isMobile ? '30px' : '42px')};
  margin-top: ${({ isMobile }) => (isMobile ? '24px' : '36px')};
  ${theme.breakpoints.down('md')} {
    svg {
      width: 100%;
    }
  }
`

export const ContainerTextMobile = styled.div``
export const TextMobile = styled.p`
  font-family: 'CenturyGothic', sans serif;
  color: ${theme.colors.white100};
  font-size: 13px;
  line-height: 25px;
  margin: 5px 0;
`

export const TextItem = styled(TextMobile)`
  font-weight: bold;
  line-height: 20px;
  text-align: ${({ isMobile }) => (isMobile ? 'left' : '')};

  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }
`

export const UppercaseTextItem = styled(TextItem)`
  text-transform: uppercase;
`

export const DocumentList = styled.div`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
`

export const DocumentItem = styled.div`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'row' : 'column')};
  margin-right: 20px;
  height: ${({ isMobile }) => (isMobile ? '66px' : '')};
  padding: ${({ isMobile }) => (isMobile ? '6px' : '')};
  align-items: ${({ isMobile }) => (isMobile ? 'center' : '')};
  transform: ${({ isMobile }) => (isMobile ? 'translateX(-60%)' : '')};
  text-align: center;
  width: 90px;
`

export const Bullet = styled.span`
  color: ${theme.colors.blue600};
`
