import React, { useContext } from 'react'
import { TemplateContext } from '../../../components/template/context'
import { Button, LoadingProvi } from '@provi/provi-components'
import { Container, SubTitle, Text, Wrapper, TitleBlue, SubTitle2 } from './style'

export const GuarantorPositiveFeedback = () => {
  const { goToNextScreen, setIsLoading, isLoading, creditPath } = useContext(TemplateContext)
  return (
    <>
      {isLoading &&
        creditPath &&
        creditPath.ReadActiveGuarantorName &&
        creditPath.ReadActiveGuarantorName.guarantorPersonalId && <LoadingProvi isLoadingDark />}
      <Container>
        <Wrapper>
          <TitleBlue>Que ótimo!</TitleBlue>
          <SubTitle>Seu avalista foi aprovado.</SubTitle>
          <SubTitle2>A sua vaga já está garantida.</SubTitle2>
          <Text>O seu avalista só precisa preencher os dados dele no link que está disponível na próxima tela.</Text>
          <Button
            marginHorizontal={0}
            text="Avançar"
            onClick={() => {
              setIsLoading(true)
              goToNextScreen()
            }}
            persistFloatingMode={false}
          />
        </Wrapper>
      </Container>
    </>
  )
}
