import { theme } from '@provi/provi-components'
import styled from 'styled-components'

export const BlueSubTitle = styled.div`
  font-family: 'CenturyGothicBold', sans serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  margin: 0;
  margin-bottom: 8px;
  color: ${theme.colors.blue600};

  ${theme.breakpoints.down('md')} {
    font-size: 18px;
    line-height: 24px;
  }
`
