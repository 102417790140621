import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  background-color: ${theme.colors.blue900};
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Wrapper = styled.div`
  width: 640px;
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`

export const Text = styled.p`
  ${({ bold }) => `font-family: ${bold ? 'CenturyGothicBold' : 'CenturyGothic'} , sans-serif;`};
  color: ${theme.colors.white100};
  font-size: 24px;
  line-height: 30px;
  margin: 0;
  text-align: center;
`

export const TextBox = styled.div`
  margin-top: 30px;
`
