import React, { useContext } from 'react'
import { Container, Title, SubTitle } from '@provi/provi-components'
import { TemplateContext } from '../../../components/template/context'
import { useISAContext } from '../../../components/template/ISA/context'
import { TemplateFormIsa } from '../../../components/template-isa'

import { FormFormik } from './form'

export const Address = () => {
  const { setIsLoading, creditPath, setDataModal, isMobile } = useContext(TemplateContext)
  const { goToNextScreen } = useISAContext()
  return (
    <TemplateFormIsa>
      <Container>
        <Title text="Qual o seu CEP?" />
        <SubTitle text="Lembre-se: você precisará enviar um comprovante desse endereço na próxima etapa" />
        <FormFormik
          data={creditPath && creditPath.ReadAddress}
          setDataModal={setDataModal}
          goToNextScreen={goToNextScreen}
          setIsLoading={setIsLoading}
        />
        {isMobile && <div style={{ height: '27px' }} />}
      </Container>
    </TemplateFormIsa>
  )
}
