import { showToast } from '@provi/provi-components'
import { ROOT_URL } from '../constants'

export const redirectToContract = token => {
  showToast('Você já possui um contrato gerado para este curso. Redirecionando para o contrato.')

  return setTimeout(() => {
    window.location.href = `${ROOT_URL.fe_user}/provi-sign/carregando/${token}`

    return localStorage.clear()
  }, 4000)
}
