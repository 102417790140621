import React, { useContext } from 'react'
import { Container, SubHeaderDesktop } from '@provi/provi-components'
import { TemplateContext } from '../../../components/template/context'
import { TemplateForm } from '../../../components/template-form'
import { Timer } from '../../../components/timer'

import { useGuarantorBackgroundAnalysis } from './hooks'
import { CentralizedContainer, CustomSubHeaderDesktop } from './style'

export const GuarantorBackgroundAnalysis = props => {
  const { goToNextScreen, creditPath, isMobile } = useContext(TemplateContext)
  const [countDown] = useGuarantorBackgroundAnalysis({
    cpf: creditPath.ReadFirstGuarantorPersonalId,
    goToNextScreen,
    eventName: props.eventName
  })

  return (
    <TemplateForm notShowBackButton={false}>
      <Container>
        <CentralizedContainer>
          <Timer counter={countDown} />
          <SubHeaderDesktop title="Estamos analisando seu avalista :)" />
          <CustomSubHeaderDesktop isMobile={isMobile}>Aguarde...</CustomSubHeaderDesktop>
        </CentralizedContainer>
      </Container>
    </TemplateForm>
  )
}
