import React, { useContext } from 'react'
import { Container, Button } from '@provi/provi-components'
import { TemplateContext } from '../../../components/template/context'
import { TemplateForm } from '../../../components/template-form'
import * as S from './style'
import { CourseContainer } from './components/CourseContainer'
import { currencyParser, formatMonthlyInterestText } from '../../../utils'
import { useReviewRequest } from './hooks'

export const ReviewRequest = () => {
  const { goToNextScreen, creditPath, isMobile, setIsLoading } = useContext(TemplateContext)
  const { handleClick } = useReviewRequest({ goToNextScreen, setIsLoading })

  return (
    <TemplateForm>
      <Container>
        {creditPath.ReadReviewInformation && (
          <S.ContentWrapper isMobile={isMobile}>
            <S.ReviewHeader isMobile={isMobile}>Revisão do produto</S.ReviewHeader>
            {isMobile && <S.ReviewSeparator />}
            {creditPath.ReadReviewInformation.courses.map(course => (
              <CourseContainer isMobile={isMobile} course={course} />
            ))}
            <S.ReviewSeparator marginTop={36} />
            <S.Row>
              <S.ReviewHandIcon />
              <S.LoanLabel marginLeft={8}>Financiamento</S.LoanLabel>
            </S.Row>
            {!creditPath.ReadReviewInformation.inconstantFlow ? (
              <>
                <S.Row justifyContent="space-between" marginTop={15}>
                  <S.LoanLabel>Entrada</S.LoanLabel>
                  <S.LoanLabel>R$ {currencyParser(creditPath.ReadReviewInformation.upfrontAmount)}</S.LoanLabel>
                </S.Row>
                <S.Row justifyContent="space-between" marginTop={5}>
                  <S.LoanLabel>
                    {creditPath.ReadReviewInformation.installmentsToApply > 1
                      ? `${creditPath.ReadReviewInformation.installmentsToApply} parcelas`
                      : `${creditPath.ReadReviewInformation.installmentsToApply} parcela`}
                  </S.LoanLabel>
                  <S.LoanLabel>R$ {currencyParser(creditPath.ReadReviewInformation.pricePerInstallment)}</S.LoanLabel>
                </S.Row>
                <S.Row justifyContent="space-between" marginTop={5}>
                  <S.LoanLabel>Taxa de juros</S.LoanLabel>
                  <S.LoanLabel>{formatMonthlyInterestText(creditPath.ReadReviewInformation.monthlyInterest)}</S.LoanLabel>
                </S.Row>
              </>
            ) : (
              creditPath.ReadReviewInformation.installments.map((element, index) => {
                const { initialInstallment, lastInstallment, price } = element
                let label

                if (initialInstallment === lastInstallment && index === 0) {
                  label = 'Entrada'
                } else if (initialInstallment !== lastInstallment && lastInstallment > initialInstallment) {
                  label = `Parcelas ${initialInstallment} a ${lastInstallment}`
                } else if (initialInstallment === lastInstallment && index !== 0) {
                  label = `Parcela ${initialInstallment} a ${lastInstallment}`
                }

                return (
                  <S.Row justifyContent="space-between" marginTop={15}>
                    <S.LoanLabel>{label}</S.LoanLabel>
                    <S.LoanLabel>R$ {currencyParser(price)}</S.LoanLabel>
                  </S.Row>
                )
              })
            )}

            <S.ReviewSeparator color="#2647D7" marginTop={15} />
            <S.Row justifyContent="flex-end" marginTop={5}>
              <S.TotalLabel>Total: </S.TotalLabel>
              <S.TotalValue>R${currencyParser(creditPath.ReadReviewInformation.finalTotal)}</S.TotalValue>
            </S.Row>
            <S.ReviewSeparator marginTop={25} color="#D4DAF7" />
            <S.Row>
              <S.CalendarIcon />
              <S.LoanLabel marginLeft={8}>Datas de vencimento</S.LoanLabel>
            </S.Row>
            <S.Row justifyContent="space-between" marginTop={5}>
              <S.LoanLabel>Entrada</S.LoanLabel>
              <S.LoanLabel>
                {creditPath.ReadReviewInformation.daysToUpfrontPayment > 1
                  ? `${creditPath.ReadReviewInformation.daysToUpfrontPayment} dias após a emissão do contrato`
                  : `${creditPath.ReadReviewInformation.daysToUpfrontPayment} dia após a emissão do contrato`}
              </S.LoanLabel>
            </S.Row>
            <S.Row justifyContent="space-between" marginTop={20}>
              <S.LoanLabel isBold={true}>O pagamento das parcelas deve ser efetuado no dia 10 de cada mês.</S.LoanLabel>
            </S.Row>
            <S.Row marginTop={20} paddingRight={5}>
              <S.LoanLabel>Após avançar você não vai mais poder alterar as informações anteriores.</S.LoanLabel>
            </S.Row>

            <S.Row justifyContent="center" flexDirection={'column'}>
              <S.ChangePayment isMobile={isMobile} onClick={() => handleClick('edit')}>
                Alterar parcelamento
              </S.ChangePayment>
              <Button
                marginHorizontal={0}
                onClick={() => handleClick('continue')}
                type="button"
                responsiveWidth
                text="Enviar documentos"
                persistFloatingMode={false}
              />
            </S.Row>
          </S.ContentWrapper>
        )}
      </Container>
    </TemplateForm>
  )
}
