/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { showToast, theme } from '@provi/provi-components'
import { uploadImages } from '../../../services/api'
import { URL_EXTERNAL } from '../../../constants'

export const useProofOfEnrollment = (goToNextScreen, setDataModal, creditPath, setIsLoading, setIsShowModal) => {
  const [filesNotUpload, setFilesNotUpload] = useState([])
  const [filesUploaded, setFilesUploaded] = useState([])
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [numberOfFiles, setNumberOfFiles] = useState(0)
  const [numberOfFilesInitial, setNumberOfFilesInitial] = useState(0)
  const [preview, setPreview] = useState()

  const title = creditPath.partnerId === 1344 ? 'Envie uma foto da frente do seu CRM' : 'Envie o seu comprovante de matrícula'

  useEffect(() => {
    setDataModal({
      title: 'O que é o comprovante de matrícula?',
      texts: [
        'O comprovante de matrícula é o documento que indica as disciplinas nas quais você está matriculado, contendo todo o registro das suas atividades acadêmicas.',
        'Normalmente, a solicitação desse documento é feita no departamento de Coordenação do Curso ou pelo portal digital da sua universidade.'
      ],
      textButton: 'Entendi',
      buttonFunction: () => setIsShowModal(false),
      textLink: {
        color: theme.colors.blue900,
        text: 'Abrir chat',
        onClick: () => window.open(URL_EXTERNAL, '_blank')
      }
    })
    if (creditPath && creditPath.ReadProofOfEnrollment && creditPath.ReadProofOfEnrollment.length > 0) {
      setIsButtonDisabled(false)
      setNumberOfFiles(creditPath.ReadProofOfEnrollment.length)
      setNumberOfFilesInitial(creditPath.ReadProofOfEnrollment.length)
      setFilesUploaded(creditPath.ReadProofOfEnrollment)
      setPreview(creditPath.ReadProofOfEnrollment[0])
    }
  }, [setDataModal, creditPath])

  useEffect(() => {
    if (filesNotUpload.length > 0) {
      setIsButtonDisabled(false)
    }
  }, [filesNotUpload])

  const onSubmit = async e => {
    e.preventDefault()
    setIsLoading(true)
    try {
      const urls = await uploadImages(filesNotUpload)
      goToNextScreen([...urls, ...filesUploaded])
    } catch (e) {
      showToast()
    }
  }

  return [
    onSubmit,
    isButtonDisabled,
    numberOfFiles,
    preview,
    setPreview,
    setFilesNotUpload,
    filesNotUpload,
    setNumberOfFiles,
    numberOfFilesInitial,
    title
  ]
}
