import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import bg_background_pre_approved from '../../assets/svg/background_analysis.svg'

export const Container = styled.div`
  padding-left: 137px;
  padding-right: 22px;
  padding-bottom: 22px;
  height: 100vh;
  width: 100%;
  background-image: url(${bg_background_pre_approved});
  background-repeat: no-repeat;
  background-position: right bottom;
  background-color: ${theme.colors.blue900};
  box-sizing: border-box;
  overflow: auto;
  display: flex;
  align-items: center;
  ${theme.breakpoints.down('md')} {
    display: block;
    padding-left: 22px;
    margin-left: 0;
    background-image: none;
  }
  ${theme.breakpoints.down('lg')} {
    background-size: 36vw;
  }
`

export const Title = styled.h4`
  margin-top: 21vh;
  font-family: CenturyGothicBold, sans-serif;
  color: ${theme.colors.pink900};
  font-size: 24px;
  line-height: 30px;
  ${theme.breakpoints.down('md')} {
    font-size: 19px;
    line-height: 28px;
  }
`

export const SubTitle = styled.p`
  margin-bottom: 4vh;
  font-family: CenturyGothicBold, sans-serif;
  color: ${theme.colors.white100};
  font-size: 24px;
  line-height: 30px;
  ${theme.breakpoints.down('md')} {
    font-size: 19px;
    line-height: 28px;
  }
`

export const Wrapper = styled.div`
  width: 640px;
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`
