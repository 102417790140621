import { useState, useEffect } from 'react'
import { redirectToContract } from '../../../utils/redirectToContract'

export const useEventLevel = (goToNextScreen, creditPath, setDataModal) => {
  const [options, setOptions] = useState({})
  const [selects, setSelects] = useState({})
  const [isShowError, setIsShowError] = useState(false)
  const [disabledButton, setDisabledButton] = useState(false)

  const refactingArray = (array, name) =>
    array.map(item => ({
      id: item.id,
      label: item.name,
      name
    }))

  const setSelect = select => {
    setSelects({
      ...selects,
      [select.name]: select
    })
    setIsShowError(false)
  }

  const onSubmit = form => {
    form.preventDefault()
    const notHaveError = Object.keys(selects).length === 1

    const result = {}
    Object.keys(selects).map(key => (result.id = selects[key].id))

    if (notHaveError) {
      goToNextScreen(result)
    } else {
      setIsShowError(true)
    }
  }

  useEffect(() => {
    setDataModal(null)

    if (creditPath?.ReadEventParticipation?.moveToWaitingSignature) {
      setDisabledButton(true)

      redirectToContract(creditPath.ReadEventParticipation.token)
    }

    const eventOptions =
      creditPath?.ReadEventParticipation?.options && refactingArray(creditPath.ReadEventParticipation.options, 'eventLevel')

    setOptions(eventOptions)

    let eventLevel
    if (creditPath && creditPath.ReadEventParticipation && creditPath.ReadEventParticipation.choosedId) {
      eventLevel = eventOptions.find(item => item.id === creditPath.ReadEventParticipation.choosedId)
    }

    setSelects({ eventLevel })
  }, [creditPath, setDataModal])

  return [onSubmit, isShowError, setSelect, selects, options, disabledButton]
}
