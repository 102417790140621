import React, { useContext } from 'react'
import { Form, Container, Button, Selector, Title } from '@provi/provi-components'

import { TemplateContext } from '../../../components/template/context'
import { TemplateForm } from '../../../components/template-form'
import { ContainerQuestions } from './style'
import { useHousingSituation } from './hooks'

export const PersonalSituation = () => {
  const { creditPath, goToNextScreen } = useContext(TemplateContext)

  const {
    onSubmit,
    isButtonDisabled,
    scholarityLevelsOptions,
    scholarity,
    setScholarity,
    housingSituationOptions,
    housingSituation,
    setHousingSituation,
    complementOptions,
    complement,
    setComplement,
    requiresExplanation
  } = useHousingSituation(creditPath, goToNextScreen)

  return (
    <TemplateForm>
      <Container>
        <Form onSubmit={onSubmit}>
          <ContainerQuestions>
            <Title text="Qual o seu grau de escolaridade?" />
            <Selector
              isSearchable={false}
              name="educationLevel"
              placeholder="Escolher"
              options={scholarityLevelsOptions}
              value={scholarity}
              onValueChange={setScholarity}
            />
          </ContainerQuestions>

          <ContainerQuestions>
            <Title text="Selecione abaixo a opção que reflete a sua situação de moradia hoje" />
            <Selector
              isSearchable={false}
              name="houseSituation"
              placeholder="Escolher"
              options={housingSituationOptions}
              value={housingSituation}
              onValueChange={setHousingSituation}
            />
          </ContainerQuestions>

          {requiresExplanation && (
            <ContainerQuestions>
              <Title text="Quem é responsável pelo aluguel ou financiamento da casa?" />
              <Selector
                isSearchable={false}
                placeholder="Escolher"
                options={complementOptions}
                value={complement}
                onValueChange={setComplement}
              />
            </ContainerQuestions>
          )}

          <Button marginHorizontal={0} disabled={isButtonDisabled} text="Avançar" type="submit" persistFloatingMode={false} />
        </Form>
      </Container>
    </TemplateForm>
  )
}
