import React from 'react'
import * as Yup from 'yup'
import { withFormik } from 'formik'
import { Button, Input, InputMasks, TextErrorInput, showToast } from '@provi/provi-components'
import { validateText, validateState } from '../../../utils/validators'
import { Form } from '../../../components/form'
import { useCepValidator } from './hooks'

export const Formik = props => {
  const { handleChange, values, errors, touched, handleSubmit, handleBlur, setFieldValue, setIsLoading } = props
  const [isShowAddress, getCep, addInput, inputRefs] = useCepValidator(props.data)
  return (
    <Form onSubmit={handleSubmit}>
      <InputMasks
        id="zipcode"
        InputProps={{ 'aria-label': 'zipcode', cy: 'input-zipcode' }}
        mask="99999-999"
        placeholder="Ex: 04548-004"
        type="tel"
        autoFocus={!values.zipcode}
        value={values.zipcode}
        onChange={async e => {
          const { value } = e.target
          const _zipcode = value && value.replace(/[^\d]+/g, '')
          setFieldValue('zipcode', _zipcode)

          if (_zipcode && _zipcode.length === 8) {
            setIsLoading(true)
            try {
              const address = await getCep(_zipcode)
              setFieldValue('zipcode', address.zipcode)
              setFieldValue('city', address.city)
              setFieldValue('state', address.state)
              setFieldValue('district', address.district)
              setFieldValue('street', address.street)
              inputRefs[0].focus()
              return true
            } catch (err) {
              return false
            } finally {
              setIsLoading(false)
            }
          }
          return false
        }}
      />
      {errors.zipcode && touched.zipcode && <TextErrorInput text={errors.zipcode} />}

      {isShowAddress && (
        <>
          <Input
            id="state"
            inputProps={{ cy: 'input-state', 'aria-label': 'uf', maxLength: 2 }}
            value={values.state}
            placeholder="UF"
            onBlur={handleBlur('state')}
            onChange={handleChange('state')}
            style={{ textTransform: 'uppercase' }}
          />
          {errors.state && <TextErrorInput text={errors.state} />}

          <Input
            id="city"
            inputProps={{ 'aria-label': 'city', cy: 'input-city' }}
            onBlur={handleBlur('city')}
            onChange={handleChange('city')}
            placeholder="Cidade"
            value={values.city}
          />
          {errors.city && touched.city && <TextErrorInput text={errors.city} />}

          <Input
            id="district"
            inputProps={{ 'aria-label': 'district', cy: 'input-district' }}
            placeholder="Bairro"
            onBlur={handleBlur('district')}
            onChange={handleChange('district')}
            value={values.district}
          />
          {errors.district && touched.district && <TextErrorInput text={errors.district} />}

          <Input
            id="street"
            inputProps={{ 'aria-label': 'street', cy: 'input-street' }}
            placeholder="Endereço"
            onBlur={handleBlur('street')}
            onChange={handleChange('street')}
            value={values.street}
          />
          {errors.street && touched.street && <TextErrorInput text={errors.street} />}

          <Input
            id="number"
            inputProps={{ 'aria-label': 'number', cy: 'input-number', ref: addInput }}
            onBlur={handleBlur('number')}
            onChange={handleChange('number')}
            placeholder="Número"
            value={values.number}
          />
          {errors.number && touched.number && <TextErrorInput text={errors.number} />}

          <Input
            id="complement"
            inputProps={{ 'aria-label': 'complement', cy: 'input-complement' }}
            placeholder="Complemento (opcional)"
            onBlur={handleBlur('complement')}
            onChange={handleChange('complement')}
            value={values.complement}
          />
        </>
      )}

      <Button
        marginHorizontal={0}
        marginVertical={24}
        cy="button"
        type="submit"
        disabled={
          !(values.zipcode && values.city && values.state && values.district && values.street && values.number) ||
          errors.zipcode ||
          errors.city ||
          errors.state ||
          errors.district ||
          errors.street ||
          errors.number
        }
        text="Avançar"
        persistFloatingMode={false}
      />
    </Form>
  )
}

export const FormFormik = withFormik({
  mapPropsToValues: props => {
    const { data } = props
    return {
      zipcode: data && data.zipcode ? data.zipcode : '',
      city: data && data.city ? data.city : '',
      state: data && data.state ? data.state : '',
      district: data && data.district ? data.district : '',
      street: data && data.street ? data.street : '',
      number: data && data.number ? data.number : '',
      complement: data && data.complement ? data.complement : ''
    }
  },
  validationSchema: () =>
    Yup.object().shape({
      city: Yup.string()
        .required('Digite uma cidade')
        .test({
          name: 'city',
          message: 'Cidade invalida',
          test: city => city && validateText(city)
        }),
      state: Yup.string()
        .required('Digite um estado')
        .test({
          name: 'state',
          message: 'Estado invalida',
          test: state => state && validateState(state)
        }),
      district: Yup.string().required('Digite um bairro'),
      street: Yup.string().required('Digite um endereço'),
      number: Yup.string().required('Digite um número')
    }),
  handleSubmit: async (values, { props }) => {
    const { setIsLoading, goToNextScreen } = props

    try {
      setIsLoading(true)
      goToNextScreen(values)
    } catch (error) {
      showToast()
      setIsLoading(false)
    }
  }
})(Formik)
