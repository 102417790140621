import React, { useContext } from 'react'
import { Container, Button, TextErrorInput, Selector } from '@provi/provi-components'
import { Form } from '../../../components/form'
import { TemplateContext } from '../../../components/template/context'
import { useISAContext } from '../../../components/template/ISA/context'
import { TemplateFormIsa } from '../../../components/template-isa'

import { useEventLevel } from './hooks'
import { WrapperError, Wrapper, Title } from './style'

const Error = () => {
  return (
    <WrapperError>
      <TextErrorInput text="Por favor, preencha os dados" />
    </WrapperError>
  )
}

export const EventLevel = () => {
  const { creditPath, setDataModal } = useContext(TemplateContext)
  const { goToNextScreen } = useISAContext()
  const [onSubmit, isShowError, setSelect, selects, options, disabledButton] = useEventLevel(
    goToNextScreen,
    creditPath,
    setDataModal
  )

  const isValid = Object.keys(selects).filter(item => selects[item] !== undefined)

  return (
    <TemplateFormIsa>
      <Container>
        <Form onSubmit={onSubmit}>
          <Wrapper>
            <Title>Você já participou de outros eventos ou cursos relacionados ao curso que você quer fazer?</Title>
            <Selector
              isSearchable={false}
              name="eventLevel"
              placeholder="Escolher"
              options={options}
              value={selects.eventLevel}
              onValueChange={setSelect}
            />
          </Wrapper>
          {isShowError && <Error />}

          <Button
            marginHorizontal={0}
            type="submit"
            disabled={disabledButton || isValid.length !== 1}
            text="Avançar"
            persistFloatingMode={false}
          />
        </Form>
      </Container>
    </TemplateFormIsa>
  )
}
