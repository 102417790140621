import { useEffect } from 'react'

export const useGuarantorListen = (goToNextScreen, setDataModal) => {
  useEffect(() => {
    setDataModal({
      title: 'Essa é a última etapa da sua solicitação.',
      texts: [
        'Você deve enviar o link de acesso para a pessoa dona do CPF que você colocou na tela anterior.',
        'Para sua solicitação entrar em análise, é necessário que a pessoa indicada como avalista acesse o link e preencha o formulário.',
        'Você deve enviar o link o quanto antes para a pessoa indicada, e avisaremos você por email sobre o status de preenchimento.',
        'Quanto antes as informações forem preenchidas, antes você terá um retorno sobre o seu financiamento.'
      ]
    })
  }, [setDataModal])
}
