import styled from 'styled-components'
import background_analysis_mobile from '../../../assets/svg/bg-background-pre-approved.svg'
import { theme } from '@provi/provi-components'

export const Wrapper = styled.div`
  padding-left: 140px;
  width: 640px;

  ${theme.breakpoints.down('md')} {
    padding-left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

export const Container = styled.div`
  box-sizing: border-box;
  padding-left: 22px;
  padding-right: 22px;
  height: 100vh;
  background-color: ${theme.colors.blue900};
  background-image: url(${background_analysis_mobile});
  background-repeat: no-repeat;
  background-position: right bottom;
  width: 100%;
  display: flex;
  align-items: center;

  ${theme.breakpoints.down('md')} {
    background-image: unset;
    display: block;
    background-size: 100%;
  }
`

export const SubTitle = styled.p`
  font-family: 'CenturyGothicBold', sans serif;
  font-size: 30px;
  line-height: 36px;
  margin: 0;
  color: ${theme.colors.white100};
  ${theme.breakpoints.down('md')} {
    font-size: 20px;
    line-height: 30px;
  }
`

export const SubTitle2 = styled.p`
  font-family: 'CenturyGothicBold', sans serif;
  font-size: 24px;
  line-height: 30px;
  color: ${theme.colors.white100};
  ${theme.breakpoints.down('md')} {
    font-size: 16px;
    line-height: 24px;
  }
`

export const Text = styled.p`
  font-family: 'CenturyGothic', sans serif;
  line-height: 30px;
  font-size: 16px;
  color: ${theme.colors.white100};
  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    line-height: 18px;
  }
`

export const TitleBlue = styled.h2`
  font-size: 38px;
  line-height: 47px;
  margin: 0;
  color: ${theme.colors.blue600};
  ${theme.breakpoints.down('md')} {
    font-size: 24px;
    line-height: 30px;
  }
`
