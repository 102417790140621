import { useState, useEffect } from 'react'

const OPTIONS = [
  {
    value: true,
    label: 'SIM',
    id: 1
  },
  {
    value: false,
    label: 'NÃO',
    id: 2
  }
]

export const useStudentDebt = (goToNextScreen, creditPath) => {
  const [value, setValue] = useState()
  const [selectedOption, setSelectedOption] = useState()
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)

  useEffect(() => {
    if (creditPath && creditPath.ReadStudentDebt) {
      const find = OPTIONS.find(item => item.value === creditPath.ReadStudentDebt.option)
      if (find) {
        onSelectOption(find)
        setIsButtonDisabled(false)
        setValue(creditPath.ReadStudentDebt.answer)
      }
    }
  }, [creditPath])

  const onSelectOption = option => {
    setSelectedOption(option)
    setValue('')
    if (option.value) {
      setIsButtonDisabled(true)
    } else {
      setIsButtonDisabled(false)
    }
  }

  const onSubmit = e => {
    e.preventDefault()
    const data = {
      option: selectedOption.value,
      answer: value
    }
    goToNextScreen(data)
  }

  const onValueChange = which => {
    const text = which.target.value
    setValue(text)
    if (text.length >= 4) {
      setIsButtonDisabled(false)
    } else {
      setIsButtonDisabled(true)
    }
  }

  return [selectedOption, onSelectOption, onSubmit, onValueChange, value, isButtonDisabled, OPTIONS]
}
