import React, { useContext } from 'react'
import { Form, Container, Button, Selector, Rating, TextErrorInput, Input, InputMasks } from '@provi/provi-components'

import { TemplateContext } from '../../../components/template/context'
import { TemplateForm } from '../../../components/template-form'
import { SubTitle, ContainerQuestions } from './styles'
import { useAllowanceSurvey } from './hooks'

export const AllowanceSurvey = () => {
  const { creditPath, goToNextScreen } = useContext(TemplateContext)

  const {
    onSubmit,
    isButtonDisabled,
    courseSatisfaction,
    setCourseSatisfaction,
    partnerSatisfaction,
    setPartnerSatisfaction,
    extracurricularActivities,
    extracurricularActitivy,
    setExtracurricularActivity,
    customActivity,
    setCustomActivity,
    incomeValue,
    setIncomeValue,
    errors
  } = useAllowanceSurvey(creditPath, goToNextScreen)

  return (
    <TemplateForm isMarginDesktop>
      <Container isMarginDesktop>
        <Form onSubmit={onSubmit}>
          <ContainerQuestions>
            <SubTitle>De zero a dez, qual o seu nível de satisfação com o curso?</SubTitle>
            <Rating onChange={setCourseSatisfaction} value={courseSatisfaction} />
          </ContainerQuestions>

          <ContainerQuestions>
            <SubTitle>De zero a dez, qual o seu nível de satisfação com a instituição de ensino?</SubTitle>
            <Rating onChange={setPartnerSatisfaction} value={partnerSatisfaction} />
          </ContainerQuestions>

          <ContainerQuestions>
            <SubTitle>Você participa de atividades extracurriculares?</SubTitle>
            <Selector
              placeholder="Escolher"
              options={extracurricularActivities}
              value={extracurricularActitivy}
              onValueChange={setExtracurricularActivity}
              isSearchable={false}
            />
          </ContainerQuestions>

          {extracurricularActitivy && extracurricularActitivy.requiresExplanation && (
            <ContainerQuestions>
              <SubTitle>Qual atividade que você participa?</SubTitle>
              <Input
                margin="normal"
                placeholder="Digite o nome da atividade aqui"
                onChange={e => setCustomActivity(e.target.value)}
                error={!!errors.customActivity}
                value={customActivity}
              />
              {errors.customActivity && <TextErrorInput text={errors.customActivity} />}
            </ContainerQuestions>
          )}

          {extracurricularActitivy && extracurricularActitivy.requiresIncomeValue && (
            <ContainerQuestions>
              <SubTitle>Quanto você recebe por mês nesse programa?</SubTitle>
              <InputMasks
                mask="R$99999999"
                margin="normal"
                type="text"
                placeholder="Ex: R$1.500"
                onChange={e => setIncomeValue(e.target.value)}
                error={!!errors.incomeValue}
                value={incomeValue}
              />
              {errors.incomeValue && <TextErrorInput text={errors.incomeValue} />}
            </ContainerQuestions>
          )}

          <Button marginHorizontal={0} disabled={isButtonDisabled} text="Avançar" type="submit" persistFloatingMode={false} />
        </Form>
      </Container>
    </TemplateForm>
  )
}
