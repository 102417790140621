import React, { useContext } from 'react'
import { theme } from '@provi/provi-components'

import { TemplateContext } from '../../../components/template/context'
import { IconLinkedin, IconInstagram, IconFacebook, IconBlog } from '../../../assets/svg/icons-socials'
import { ContainerAnalysis, Title, Text, Wrapper, SubTitle, ContainerSocial, Item, Bold } from './style'
import { MesadaProviSlug } from '../../../constants'

export const Analysis = ({ match }) => {
  const { isMobile } = useContext(TemplateContext)

  const isProviAllowance = match.params.partner === MesadaProviSlug

  return (
    <ContainerAnalysis isMobile={isMobile}>
      <Wrapper>
        <Title color={isProviAllowance ? theme.colors.white100 : theme.colors.blue600} bold={isProviAllowance}>
          {isProviAllowance ? 'Recebemos sua solicitação!' : 'Sua solicitação entrou em análise!'}{' '}
          <span role="img" aria-label="thumbsup">
            🙌
          </span>
        </Title>
        <Text>
          {isProviAllowance ? (
            <>
              Nossa equipe de robôs já está trabalhando com muito carinho na sua proposta (sim, eles têm sentimentos). <br />
              <br />
              Você vai receber uma mensagem no WhatsApp sobre sua solicitação em até 24 horas.
            </>
          ) : (
            <>
              <Bold>Você vai receber um email sobre o resultado da análise referente à sua solicitação em até 24h</Bold> (essa
              estimativa de tempo é válida somente de segunda a sexta-feira, em finais de semana o tempo pode ser mais longo).
            </>
          )}
        </Text>

        {!isProviAllowance && (
          <>
            <SubTitle>Gostou da Provi? Então segue a gente!</SubTitle>
            <ContainerSocial>
              <Item href="https://www.linkedin.com/company/provi/">
                <IconLinkedin />
              </Item>
              <Item href="https://www.instagram.com/sejaprovi/">
                <IconInstagram />
              </Item>
              <Item href="https://www.facebook.com/SejaProvi/">
                <IconFacebook />
              </Item>
              <Item href="https://blog.provi.com.br/">
                <IconBlog />
              </Item>
            </ContainerSocial>
          </>
        )}
      </Wrapper>
    </ContainerAnalysis>
  )
}
