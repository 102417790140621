import { TextField } from '@material-ui/core'
import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const WrapperError = styled.div`
  padding-top: 10px;
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 500px;
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
  .MuiFormControl-root {
    margin-top: 16px;
  }
`

export const WrapperInputs = styled.div`
  ${({ width }) => width && `width:${width}%`};
`

export const Label = styled.div`
  display: block;
  font-family: 'CenturyGothicBold', sans serif;
  font-size: 20px;
  line-height: 30px;
  text-align: left;
  color: ${theme.colors.blue900};
  margin-top: 16px;
  ${theme.breakpoints.down('md')} {
    font-size: 16px;
    line-height: 24px;
    display: block;
  }
`

export const OptionsList = styled.div`
  display: flex;
  margin-top: ${theme.variables.spacer * 4}px;
  ${({ isMobile }) => isMobile && 'justify-content: space-between'};
`

export const Option = styled.div`
  cursor: pointer;
  box-sizing: border-box;
  padding: 10px;
  height: ${theme.variables.spacer * 13.5}px;
  width: ${theme.variables.spacer * 16}px;
  box-shadow: 2px 1px 15px -9px rgba(0, 0, 0, 0.5);
  margin-right: ${theme.variables.spacer}px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  border-radius: 20px;
  background-color: ${({ isSelected }) => (isSelected ? theme.colors.blue900 : theme.colors.white100)};
  ${theme.breakpoints.down('md')} {
    width: 48%;
    margin: 0;
  }
`

export const OptionText = styled.p`
  font-family: 'CenturyGothicBold', sans-serif;
  font-size: 18px;
  margin: 0;
  color: ${({ isSelected }) => (isSelected ? theme.colors.white100 : theme.colors.blue900)};
`

export const Field = styled(TextField)``

export const LabelSelect = styled.div`
  display: block;
  font-family: 'CenturyGothicBold', sans serif;
  font-size: 20px;
  line-height: 30px;
  text-align: left;
  color: ${theme.colors.blue900};
  margin-top: 16px;
  margin-bottom: 8px;
  ${theme.breakpoints.down('md')} {
    font-size: 16px;
    line-height: 24px;
    display: block;
  }
`

export const TextModal = styled.div`
  font-size: 16px;
  line-height: 24px;
  margin: 24px 0;
  color: ${theme.colors.blue900};

  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    line-height: 18px;
    margin-top: 20px;
  }
`
export const TitleModal = styled.h3`
  margin: 0;
  color: #de0c4b;
  font-weight: 700;
  font-size: 16px;
`

export const CenterButton = styled.div`
  ${theme.breakpoints.down('sm')} {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const ButtonCloseModal = styled.button`
  background-color: #de0c4b;
  border: none;
  font-family: 'Montserrat', sans-serif !important;
  color: #fff;
  font-weight: bold;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;

  &:hover {
    background-color: #c20a41;
  }
`

export const ContainerTimer = styled.div`
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background-color: #01163e;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  h4 {
    color: #fff;
    font-size: 16px;
  }
`

export const ContainerUserBlocked = styled.div`
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background-color: #01163e;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  h3 {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    margin: 30px 20px;
  }

  h4 {
    color: #fff;
    font-size: 16px;
    margin: 30px 20px;
  }

  h1 {
    color: #fff;
    font-size: 24px;
    margin: 30px 20px;
  }

  ${theme.breakpoints.down('sm')} {
    justify-content: flex-start;
    text-align: left;

    h1 {
      margin-top: 100px;
    }
  }
`
export const ButtonWhatsApp = styled.button`
  background-color: #de0c4b;
  border: none;
  font-family: 'Montserrat', sans-serif !important;
  color: #fff;
  font-weight: bold;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
  width: 200px;

  &:hover {
    background-color: #c20a41;
  }
`
