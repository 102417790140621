import { useEffect } from 'react'
import { URL_EXTERNAL } from '../../../constants'
import { theme } from '@provi/provi-components'

export const usePreDocument = (setDataModal, setIsShowModal) => {
  useEffect(() => {
    setDataModal({
      title: 'O que pode ser utilizado como comprovante de endereço?',
      texts: [
        'Contas de consumo: água, luz, gás, telefone, internet, etc.',
        'Contrato de aluguel assinado pelas duas partes.',
        'Boletos bancários: mensalidade escolar, plano de saúde, condomínio, financiamento, fatura de cartão de crédito, etc.',
        'Se a conta estiver no nome de algum familiar com o mesmo sobrenome, fique tranquilo que vamos aceitar. Se não for o caso, entre em contato com a gente.'
      ],
      textButton: 'Entendi',
      buttonFunction: () => setIsShowModal(false),
      textLink: {
        color: theme.colors.blue900,
        text: 'Abrir chat',
        onClick: () => window.open(URL_EXTERNAL, '_blank')
      },
      color: theme.colors.blue900
    })
  }, [setDataModal, setIsShowModal])
}
