import React, { useContext } from 'react'
import { Container, Title, Button, Input } from '@provi/provi-components'
import { Form } from '../../../components/form'
import { TemplateContext } from '../../../components/template/context'
import { TemplateForm } from '../../../components/template-form'
import { SubTitle } from '../../../components/sub-title'
import { OptionsList, Option, OptionBold } from './style'
import { useStudentDebt } from './hooks'

export const StudentDebt = () => {
  const { goToNextScreen, creditPath } = useContext(TemplateContext)
  const [selectedOption, onSelectOption, onSubmit, onValueChange, value, isButtonDisabled, OPTIONS] = useStudentDebt(
    goToNextScreen,
    creditPath
  )
  return (
    <TemplateForm>
      <Container>
        <Form onSubmit={onSubmit}>
          <Title text="Você possui algum financiamento estudantil?" />
          <OptionsList>
            {OPTIONS.map((item, index) => (
              <Option
                key={item.id}
                first={index === 0}
                isSelected={selectedOption && selectedOption.id === item.id}
                onClick={() => onSelectOption(item)}
              >
                <OptionBold isSelected={selectedOption && selectedOption.id === item.id}>{item.label}</OptionBold>
              </Option>
            ))}
          </OptionsList>

          {selectedOption && selectedOption.value && (
            <>
              <SubTitle>Qual tipo de financiamento?</SubTitle>
              <Input
                autoFocus
                margin="normal"
                onChange={onValueChange}
                value={value}
                placeholder="Ex: FIES, financiamento com a faculdade..."
              />
            </>
          )}

          <Button marginHorizontal={0} type="submit" disabled={isButtonDisabled} text="Avançar" persistFloatingMode={false} />
        </Form>
      </Container>
    </TemplateForm>
  )
}
