import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

export const getHelmetPartner = partner => {
  switch (partner) {
    case 'sanar':
      return 'Provi <> Sanar'
    default:
      return 'Provi'
  }
}

export const HelmetWrapper = partner => {
  return <Helmet>{/* <title>{getHelmetPartner(partner)}</title> */}</Helmet>
}

HelmetWrapper.propTypes = {
  partner: PropTypes.string
}
