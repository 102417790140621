import React from 'react'
import { Button, LoadingProvi } from '@provi/provi-components'
import { Wrapper, ContainerLogin, Container, Title, SubTitle } from './styles'
import { useInvalidToken } from './hooks'

export const InvalidToken = props => {
  const [onSubmit, email, isLoading] = useInvalidToken(props.match.params.partner)
  return (
    <Wrapper>
      {isLoading && <LoadingProvi isLoadingDark />}
      <ContainerLogin>
        <Container>
          <Title>Seu link expirou!</Title>
          <SubTitle>
            {email
              ? 'Clique no botão abaixo para receber outro link de acesso e iniciar uma nova sessão.'
              : 'Clique no botão abaixo para digitar seu email de cadastro e te enviarmos um novo link de acesso.'}
          </SubTitle>
          <Button marginHorizontal={0} type="button" text="Avançar" onClick={onSubmit} persistFloatingMode={false} />
        </Container>
      </ContainerLogin>
    </Wrapper>
  )
}
