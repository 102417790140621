import styled, { keyframes } from 'styled-components'
import { theme } from '@provi/provi-components'

export const Container = styled.div`
  padding-left: 137px;
  padding-right: 22px;
  padding-bottom: 22px;
  height: 100vh;
  background-color: ${theme.colors.blue900};
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${theme.breakpoints.down('md')} {
    padding-left: 22px;
    margin-left: 0;
  }
`

export const Text = styled.p`
  font-family: ${props => (props.bold ? 'CenturyGothicBold' : 'CenturyGothic')}, sans serif;
  font-size: ${({ size }) => size}px;
  color: ${theme.colors.white100};
`

export const Title = styled.p`
  padding-top: 58px;
  font-size: 16px;
  color: ${theme.colors.white100};
  line-height: 24px;
`
export const animationImage = keyframes`
  0% {
    left: -240px;
  }
  75% {
    left: -30px;
  }
  85% {
    left: -15px;
  }
  100% {
    left: 0;
  }
`

export const Image = styled.img`
  width: 250px;
  padding-top: 33px;
  padding-bottom: 43px;
  position: relative;
  animation-name: ${animationImage};
  animation-duration: 1.5s;
  animation-timing-function: ease;
`
