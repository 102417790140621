import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const ContainerOption = styled.div`
  margin-top: ${theme.variables.spacer * 1}px;
  display: flex;
  width: 100%;
  margin-bottom: ${theme.variables.spacer * 1}px;

  ${theme.breakpoints.down('md')} {
    justify-content: center;
  }
`

export const SubTitle = styled.div`
  font-family: 'CenturyGothicBold';
  font-size: 16px;
  line-height: 18px !important;
  margin-top: 16px;
  margin-bottom: 8px;
  color: ${theme.colors.blue900};

  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    line-height: 24px;
  }
`

export const Option = styled.div`
  cursor: pointer;
  box-sizing: border-box;
  padding: 10px;
  height: 49px;
  width: 200px;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  background-color: ${({ isSelected }) => (isSelected ? theme.colors.blue900 : theme.colors.white100)};

  ${theme.breakpoints.down('md')} {
    height: 50px;
    width: 50%;
  }
`

export const OptionText = styled.p`
  font-family: 'CenturyGothicBold', sans-serif;
  font-size: 18px;
  margin: 0;
  color: ${({ isSelected }) => (isSelected ? theme.colors.white100 : theme.colors.blue900)};

  ${theme.breakpoints.down('md')} {
    font-size: 14px;
    line-height: 18px;
  }
`

export const ItemCheck = styled.div`
  display: flex;
  margin: 16px 0;
  width: auto;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
`

export const WrapperIcon = styled.div`
  width: 16px;
`

export const ExtraDataText = styled.p`
  font-size: 12px;
  line-height: 18px;
  margin: 0%;
  margin-left: 8px;
  font-family: CenturyGothic;
  color: ${theme.colors.blue900};
`

export const ContainerSubTitle = styled.div`
  margin-bottom: -16px;
`

export const ContainerQuestions = styled.div`
  margin: 30px 0;
`
