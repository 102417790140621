import React from 'react'
import { Formik } from 'formik'
import InputAdornment from '@material-ui/core/InputAdornment'
import { IconLock } from '../../assets/svg/icon-lock'
import { showToast } from '@provi/provi-components'
import { useCpfValidator, parseInitialValues, parsePropsToValues } from './hooks'
import { isValidCpf, basicInfoValidationSchema } from '../../utils/validators'
import { getSlugPartner, getPathname, navigateWithParams } from '../../utils'
import { postBasicInfo } from '../../services/cpf'
import { activateRecaptcha } from '../../services/recaptcha'
import { createCrid, getCreditPathISA } from '../../services/api'
import { getData } from '../../services/select-crid'
import { formCreateCrid } from '../crid-selector/hooks'
import { FormCPF } from './formCPF'
import { LOCALSTORAGE_TOKENS } from '../../constants'

export const FormFormik = props => {
  const { setIsLoading, setCrid, setCreditPath, history, data, recaptchaResponse, setRecaptchaResponse } = props
  const [
    isShowPersonalInfo,
    setIsShowPersonalInfo,
    validateOnChange,
    setValidateOnChange,
    getCpf,
    formState,
    setFormState
  ] = useCpfValidator(props.data)

  const initialValues = parseInitialValues(data)
  const mapPropsToValues = parsePropsToValues(data)
  const validationSchema = basicInfoValidationSchema()
  const partner = getSlugPartner()

  const pathname = history.location.pathname
  const split = pathname.split('/')
  const isISA = split[1] === 'isa'

  return (
    <Formik
      validateOnBlur={true}
      validateOnChange={validateOnChange}
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      mapPropsToValues={mapPropsToValues}
    >
      {properties =>
        renderFormCPF(properties, {
          setValidateOnChange,
          history,
          getCpf,
          setIsShowPersonalInfo,
          setIsLoading,
          data,
          partner,
          setCrid,
          setCreditPath,
          isShowPersonalInfo,
          isISA,
          formState,
          setFormState,
          recaptchaResponse,
          setRecaptchaResponse
        })
      }
    </Formik>
  )
}

const onChange = async (e, { setFieldValue, setValidateOnChange }) => {
  const { value } = e.target
  const _cpf = value && value.replace(/[^\d]+/g, '')
  setFieldValue('cpf', _cpf)

  if (_cpf && _cpf.length >= 10) setValidateOnChange(true)
  else setValidateOnChange(false)
}

const onChangeBirthDate = async (e, { handleChange, setValidateOnChange, setFieldValue }) => {
  handleChange('birthDate')
  const { value } = e.target
  const _birthDate = value
  setFieldValue('birthDate', _birthDate)

  if (_birthDate && _birthDate.length >= 9) setValidateOnChange(true)
  else setValidateOnChange(false)
}

const renderFormCPF = (
  properties,
  {
    setValidateOnChange,
    history,
    getCpf,
    setIsShowPersonalInfo,
    setIsLoading,
    setCrid,
    setCreditPath,
    data,
    partner,
    isShowPersonalInfo,
    isISA,
    formState,
    setFormState,
    recaptchaResponse,
    setRecaptchaResponse
  }
) => {
  const { handleChange, values, errors, touched, handleBlur, setFieldValue } = properties

  const InputProps =
    (data && data.cpf) || formState
      ? {
          disabled: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconLock />
            </InputAdornment>
          )
        }
      : {}

  const handleSubmitClick = e => {
    e.preventDefault()

    const _cpf = values.cpf
    if (!isValidCpf(_cpf)) return

    if (formState) {
      isISA
        ? handleSubmitISA(e, { setIsLoading, partner, values, setCreditPath, history })
        : handleSubmit(e, { setIsLoading, values, setCrid, setCreditPath, history, partner })
      return
    }

    sendCPFAndFetchData({
      setIsLoading,
      _cpf,
      getCpf,
      setFieldValue,
      setIsShowPersonalInfo,
      setFormState,
      setRecaptchaResponse
    })
  }

  return (
    <FormCPF
      handleSubmit={e => handleSubmitClick(e)}
      onChange={onChange}
      onChangeBirthDate={onChangeBirthDate}
      values={values}
      errors={errors}
      handleBlur={handleBlur}
      handleChange={handleChange}
      setFieldValue={setFieldValue}
      setValidateOnChange={setValidateOnChange}
      isShowPersonalInfo={isShowPersonalInfo}
      InputProps={InputProps}
      touched={touched}
      formState={formState}
      isSanar={data && data['isSanar']}
    />
  )
}

const sendCPFAndFetchData = async ({
  setIsLoading,
  _cpf,
  getCpf,
  setFieldValue,
  setIsShowPersonalInfo,
  setFormState,
  setRecaptchaResponse
}) => {
  setIsLoading(true)
  activateRecaptcha(setRecaptchaResponse)
  try {
    const body = { data: _cpf }
    const personalInfo = await getCpf(body)
    if (personalInfo) {
      personalInfo.fullName && setFieldValue('fullName', personalInfo.fullName)
      personalInfo.birthDate && setFieldValue('birthDate', personalInfo.birthDate)
    }
    setIsShowPersonalInfo(true)
    setFormState(1)
    return
  } catch (err) {
    showToast(err?.response?.data?.error?.message)
  } finally {
    setIsLoading(false)
  }
}

const handleSubmit = async (e, { setIsLoading, values, setCrid, setCreditPath, history, partner }) => {
  e.preventDefault()
  setIsLoading(true)
  try {
    const body = {
      ...values
    }
    const params = new URLSearchParams(history.location.search)

    const campaign = params.get('campanha')
    if (campaign) {
      body.campaignSlug = campaign
      body.partnerSlug = partner
    }
    await postBasicInfo(body)
    const { data: availableCreditRequests, canCreate } = await getData(partner)
    if (canCreate && availableCreditRequests && availableCreditRequests.length === 0) {
      await formCreateCrid(setIsLoading, setCrid, setCreditPath)
    } else {
      navigateWithParams(`/selecao-financiamento/${partner}`)
    }
  } catch (err) {
    setIsLoading(false)
    if (err.response) {
      showToast(err?.response?.data?.error?.message)
      return false
    }
    showToast()
  }
}

const handleSubmitISA = async (e, { setIsLoading, partner, values, setCreditPath, history }) => {
  e.preventDefault()
  setIsLoading(true)
  try {
    const { data: availableCreditRequests, canCreateNewCridsISA } = await getData(partner)
    const checkIfCantCreateCrid = availableCreditRequests.find(
      crid => crid.ProductType === 'ISA' && !['active', 'denied', 'expired', 'settled'].includes(crid.status)
    )
    const body = {
      fullName: values.fullName,
      birthDate: values.birthDate
    }
    await postBasicInfo(body)
    let creditRequestId = ''
    if (!checkIfCantCreateCrid && canCreateNewCridsISA) {
      const { data: createCridData } = await createCrid(partner, true)

      if (createCridData?.blockedContractIsa) {
        setIsLoading(false)
        return history.push('/bloqueio-contrato-assinado')
      }

      if (createCridData?.userCanContinue === false) {
        setIsLoading(false)
        const reasonsTransform = {
          under_age: 'menor-de-idade',
          irregular_situation: 'situacao-irregular',
          pending_payment: 'pagamento-em-atraso',
          is_defaulting: 'pagamentos-em-atraso'
        }

        return history.push(`/isa/elegibilidade/${reasonsTransform[createCridData?.reason]}`)
      }

      creditRequestId = createCridData.id
    } else if (canCreateNewCridsISA) {
      creditRequestId = checkIfCantCreateCrid.id
    } else {
      showToast('Você não pode iniciar uma solicitação ISA')
    }
    if (creditRequestId) {
      localStorage.setItem(LOCALSTORAGE_TOKENS.crid, creditRequestId)
      const response = await getCreditPathISA(creditRequestId)
      setCreditPath(response)

      const pathname = getPathname(response.progress.currentScreenUniqueName)
      setIsLoading(false)
      history.push(`/${creditRequestId}/isa/${pathname}/${partner}`)
    }
  } catch (err) {
    setIsLoading(false)
    if (err.response) {
      showToast(err?.response?.data?.error?.message)
      return false
    }
    showToast()
  }
}
