import React, { useContext } from 'react'
import { Container, Title, SubTitle } from '@provi/provi-components'
import { TemplateContext } from '../../../components/template/context'
import { useISAContext } from '../../../components/template/ISA/context'
import { TemplateFormIsa } from '../../../components/template-isa'
import { FormFomik } from './form'
import { useFullName } from './hooks'

export const FullName = () => {
  const { creditPath, crid, setDataModal } = useContext(TemplateContext)
  const { goToNextScreen } = useISAContext()
  useFullName(setDataModal)

  return (
    <TemplateFormIsa>
      <Container>
        <Title text="Insira o seu nome completo" />
        <SubTitle text="Igual consta no seu documento" />
        <FormFomik data={creditPath && creditPath.ReadFullName} crid={crid} goToNextScreen={goToNextScreen} />
      </Container>
    </TemplateFormIsa>
  )
}
