import React, { useContext } from 'react'
import { Container, Title, SubTitle } from '@provi/provi-components'
import { TemplateContext } from '../../../components/template/context'
import { TemplateForm } from '../../../components/template-form'
import { FormFomik } from './form'

export const FullName = () => {
  const { goToNextScreen, creditPath } = useContext(TemplateContext)
  return (
    <TemplateForm>
      <Container>
        <Title text="Insira o seu nome completo" />
        <SubTitle text="Igual consta no seu documento" />
        <FormFomik data={creditPath && creditPath.ReadFullName} goToNextScreen={goToNextScreen} />
      </Container>
    </TemplateForm>
  )
}
