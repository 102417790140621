import React, { useContext } from 'react'
import { TemplateContext } from '../../../components/template/context'
import { TemplateForm } from '../../../components/template-form'
import { Container, SubHeaderDesktop, Title } from '@provi/provi-components'
import { FormFormik } from './form'
import { useBirthDate } from './hooks'

export const BirthDate = () => {
  const { setIsShowModal, isMobile, goToNextScreen, setDataModal, creditPath } = useContext(TemplateContext)
  useBirthDate(setDataModal, setIsShowModal)
  return (
    <TemplateForm>
      <Container>
        {isMobile ? (
          <Title text="Qual a sua data de nascimento?" />
        ) : (
          <SubHeaderDesktop
            title="Qual a sua data de nascimento?"
            handleModal={() => setIsShowModal(true)}
            isShowButtonHelpModal
          />
        )}
        <FormFormik data={creditPath && creditPath.ReadBirthDate} goToNextScreen={goToNextScreen} />
      </Container>
    </TemplateForm>
  )
}
