import React, { useContext } from 'react'
import { Container, Title } from '@provi/provi-components'
import { TemplateContext } from '../../../components/template/context'
import { useISAContext } from '../../../components/template/ISA/context'
import { TemplateFormIsa } from '../../../components/template-isa'
import { FormFomik } from '../../form/rg/form'

export const RG = () => {
  const { creditPath } = useContext(TemplateContext)
  const { goToNextScreen } = useISAContext()

  return (
    <TemplateFormIsa>
      <Container>
        <Title text="Digite o número do seu RG abaixo" />
        <FormFomik data={creditPath && creditPath.ReadRG} goToNextScreen={goToNextScreen} />
      </Container>
    </TemplateFormIsa>
  )
}
