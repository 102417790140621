import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const ContainerErrorInput = styled.div`
  margin-top: 16px;
`

export const WrapperFiles = styled.div`
  display: flex;
  align-items: center;
`

export const Text = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.blue900};
  margin: 0 20px;
  font-family: 'CenturyGothicBold', sans serif;

  ${theme.breakpoints.down('md')} {
    font-size: 12px;
  }
`
