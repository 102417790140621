import React, { useContext } from 'react'
import { Container, Title, SubTitle, Selector, TextErrorInput, SubHeaderDesktop, Form, Button } from '@provi/provi-components'
import { TemplateContext } from '../../../components/template/context'
import { useISAContext } from '../../../components/template/ISA/context'
import { TemplateFormIsa } from '../../../components/template-isa'

import { useProfissionalSituationSelect } from './hooks'
import { WrapperError, WrapperTitles } from './style'

export const ProfissionalSituation = () => {
  const { setIsShowModal, isMobile, setDataModal, creditPath } = useContext(TemplateContext)
  const { goToNextScreen } = useISAContext()
  const [
    selectedOption,
    onSelectOption,
    onSelectValueOption,
    isShowError,
    isButtonDisabled,
    options,
    valuesOptions,
    selectedValueOption,
    onSubmit
  ] = useProfissionalSituationSelect(goToNextScreen, setDataModal, creditPath)

  const isValid = selectedOption && selectedValueOption

  return (
    <TemplateFormIsa>
      <Form onSubmit={onSubmit}>
        <Container>
          {isMobile ? (
            <Title text="Qual a sua situação profissional atualmente?" />
          ) : (
            <SubHeaderDesktop
              title="Qual a sua situação profissional atualmente?"
              handleModal={() => setIsShowModal(true)}
              isShowButtonHelpModal
            />
          )}
          <Selector
            isSearchable={false}
            placeholder="Escolher"
            options={options}
            value={selectedOption}
            onValueChange={onSelectOption}
          />
          {isShowError && (
            <WrapperError>
              <TextErrorInput text="Por favor selecione o campo acima" />
            </WrapperError>
          )}
          <WrapperTitles>
            <Title text="Quanto você ganha por mês?" />
            <SubTitle text="Considere: salário, renda extra, aluguéis, bolsas de estudos, pensão e etc." />
          </WrapperTitles>

          <Selector
            isSearchable={false}
            placeholder="Escolher"
            options={valuesOptions}
            value={selectedValueOption}
            onValueChange={onSelectValueOption}
          />

          <Button
            marginHorizontal={0}
            type="submit"
            disabled={!isValid || isButtonDisabled}
            text="Avançar"
            persistFloatingMode={false}
          />
          {isMobile && <div style={{ height: '36px' }} />}
        </Container>
      </Form>
    </TemplateFormIsa>
  )
}
