const messages = {
  titles: {
    cantPerform: 'Poxa, não podemos completar o seu pedido',
    latePayment: 'Você tem um pagamento em atraso',
    latePaymentMulti: 'Você têm pagamentos em atraso'
  },

  subtitles: {
    underAge: 'O ISA só está disponível para pessoas maiores de 18 anos.',
    unqualified: 'O ISA não está disponível para você. Sentimos muito por não poder te ajudar nesse momento.',
    latePaymentISA:
      'Só é possível solicitar o ISA depois de pagar o valor em aberto. Entre em contato pelo WhatsApp (11) 95771-2414 para regularizar a sua situação.',
    latePayment:
      'Você precisa pagar o valor em aberto. Para regularizar a sua situação, entre em contato pelo WhatsApp (11) 95771-2414.'
  }
}

export default messages
