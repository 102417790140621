import React, { useContext } from 'react'
import { Container, SubTitle, Button, Title, UploadBox } from '@provi/provi-components'
import { Form } from '../../../components/form'
import { TemplateContext } from '../../../components/template/context'
import { TemplateForm } from '../../../components/template-form'
import { useRgBackSide } from './hooks'
import img_document from '../../../assets/svg/back-side-rg.svg'

const OPTIONS_DROPZONE = 'image/jpeg, image/png, application/pdf'

export const RGBackSide = () => {
  const { goToNextScreen, setIsLoading, creditPath } = useContext(TemplateContext)
  const [
    onSubmit,
    isButtonDisabled,
    numberOfFiles,
    preview,
    setPreview,
    setFilesNotUpload,
    filesNotUpload,
    setNumberOfFiles,
    numberOfFilesInitial
  ] = useRgBackSide(goToNextScreen, creditPath, setIsLoading)

  return (
    <TemplateForm>
      <Container>
        <Form onSubmit={e => onSubmit(e)}>
          <Title text="Envie a parte de trás do seu RG" />
          <SubTitle text="As informações devem estar legíveis" />
          <UploadBox
            preview={preview}
            setPreview={setPreview}
            setFilesNotUpload={setFilesNotUpload}
            filesNotUpload={filesNotUpload}
            setNumberOfFiles={setNumberOfFiles}
            numberOfFiles={numberOfFiles}
            numberOfFilesInitial={numberOfFilesInitial}
            image={img_document}
            options={OPTIONS_DROPZONE}
          />

          <Button marginHorizontal={0} type="submit" disabled={isButtonDisabled} text="Avançar" persistFloatingMode={false} />
        </Form>
      </Container>
    </TemplateForm>
  )
}
