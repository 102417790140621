import React, { useContext } from 'react'
import { Container, Title } from '@provi/provi-components'
import { TemplateContext } from '../../../components/template/context'
import { useISAContext } from '../../../components/template/ISA/context'
import { TemplateFormIsa } from '../../../components/template-isa'
import { FormFomik } from './form'

import { useCourseReason } from './hooks'

export const CourseReason = () => {
  const { creditPath } = useContext(TemplateContext)
  const { goToNextScreen } = useISAContext()
  const [courseName, partnerName] = useCourseReason(creditPath)
  return (
    <TemplateFormIsa>
      <Container>
        <Title
          text={`Nos conte sobre os motivos de você ter escolhido o curso ${courseName}${
            partnerName ? ` na ${partnerName}` : ``
          }`}
        />
        <FormFomik data={creditPath && creditPath.ReadReasonsToChooseProduct} goToNextScreen={goToNextScreen} />
      </Container>
    </TemplateFormIsa>
  )
}
