import React, { useContext } from 'react'
import { TemplateContext } from '../../../components/template/context'
import { useISAContext } from '../../../components/template/ISA/context'
import { TemplateFormIsa } from '../../../components/template-isa'
import { Container, SubHeaderDesktop, Title } from '@provi/provi-components'
import { FormFormik } from './form'
import { useBirthDate } from './hooks'

export const BirthDate = () => {
  const { setIsShowModal, isMobile, setDataModal, creditPath } = useContext(TemplateContext)
  const { goToNextScreen } = useISAContext()
  useBirthDate(setDataModal, setIsShowModal)
  return (
    <TemplateFormIsa>
      <Container>
        {isMobile ? (
          <Title text="Qual a sua data de nascimento?" />
        ) : (
          <SubHeaderDesktop
            title="Qual a sua data de nascimento?"
            handleModal={() => setIsShowModal(true)}
            isShowButtonHelpModal
          />
        )}
        <FormFormik data={creditPath && creditPath.ReadBirthDate} goToNextScreen={goToNextScreen} />
      </Container>
    </TemplateFormIsa>
  )
}
