import { useState, useEffect } from 'react'
import { showToast } from '@provi/provi-components'
import { uploadImages } from '../../../services/api'

export const usePersonalDocument = (goToNextScreen, setDataModal, creditPath, setIsLoading) => {
  const [filesNotUpload, setFilesNotUpload] = useState([])
  const [preview, setPreview] = useState()
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [numberOfFiles, setNumberOfFiles] = useState(0)
  const [numberOfFilesInitial, setNumberOfFilesInitial] = useState(0)

  useEffect(() => {
    setDataModal({
      title: 'Dica: ',
      texts: [
        'Se o documento estiver plastificado, envie duas fotos, uma da frente e outra do verso. Caso contrário, envie somente uma foto do documento aberto.'
      ]
    })
    if (creditPath && creditPath.ReadPersonalDocument && creditPath.ReadPersonalDocument.length > 0) {
      setIsButtonDisabled(false)
      setPreview(creditPath.ReadPersonalDocument[0])
      setNumberOfFiles(creditPath.ReadPersonalDocument.length)
      setNumberOfFilesInitial(creditPath.ReadPersonalDocument.length)
    }
  }, [setDataModal, creditPath])

  useEffect(() => {
    if (filesNotUpload.length > 0) {
      setIsButtonDisabled(false)
    }
  }, [filesNotUpload])

  const onSubmit = async e => {
    e.preventDefault()
    if (filesNotUpload.length > 0) {
      setIsLoading(true)
      try {
        const urls = await uploadImages(filesNotUpload)
        goToNextScreen(urls)
      } catch (e) {
        showToast()
      }
    } else {
      goToNextScreen([])
    }
  }

  return [
    onSubmit,
    isButtonDisabled,
    numberOfFiles,
    setFilesNotUpload,
    filesNotUpload,
    setNumberOfFiles,
    preview,
    setPreview,
    numberOfFilesInitial
  ]
}
