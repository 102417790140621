import React, { useContext } from 'react'
import { Container, Title } from '@provi/provi-components'
import { TemplateContext } from '../../../components/template/context'
import { TemplateForm } from '../../../components/template-form'
import { FormFomik } from './form'

export const IntendedWayToPay = () => {
  const { goToNextScreen, creditPath } = useContext(TemplateContext)
  return (
    <TemplateForm>
      <Container>
        {creditPath.partnerId === 1344 ? (
          <Title text="Como ficou sabendo sobre a Sanar Empréstimo?" />
        ) : (
          <Title text="O que pretende fazer quando concluir seus estudos?" />
        )}
        <FormFomik data={creditPath && creditPath.ReadAfterGraduationIntent} goToNextScreen={goToNextScreen} />
      </Container>
    </TemplateForm>
  )
}
