import React, { useContext, useMemo } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import Button from '@material-ui/core/Button'
import { TemplateContext } from '../../../components/template/context'
import { TimerDocSign } from '../../../components/timer-docsign'
import { ArrowDocSign } from '../../../assets/svg/arrow-docsign'
import { IconDownload } from '../../../assets/svg/icon-download'
import { IconFingerSigner } from '../../../assets/svg/icon-finger-signer'
import { ArrowMore } from '../../../assets/svg/arrow-more'
import { IconCheck } from '../../../assets/svg/icon-check2'
import { LoadingProvi } from '@provi/provi-components'
import {
  Title,
  DocumentContainer,
  Header,
  CenterHeaderContent,
  SignerBottomSheet,
  WrapperLoading,
  Container,
  HackButtonGlobals,
  OpacityTrick,
  SignerCallToAction,
  SignerBottomSheetContainer,
  LayoutContent,
  HotClickableDragableArea,
  LoadingPosition,
  TextLoading
} from './style'
import { useProvisign } from './hooks'
import { logger } from '../../../utils/logger'
import { PROXY_BASE_URL } from '../../../constants'

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export const ProviSign = () => {
  const { isMobile, goToNextScreen, creditPath, isShowLoading } = useContext(TemplateContext)
  const [
    counter,
    redirectUrl,
    hasPdf,
    signContract,
    signInProgress,
    arrayPages,
    openBottomSheetSigner,
    setOpenBottomSheetSigner,
    fingerSigned,
    onFingerSignToggle,
    documentRef,
    isLoadingIntern,
    onLoadSuccess,
    proviContractUrl,
    loadProgress,
    setLoadProgress,
    showLoadingText
  ] = useProvisign(goToNextScreen, creditPath)

  const renderPages = useMemo(() => {
    return (
      <DocumentContainer>
        <Document
          ref={documentRef}
          scale={1}
          file={proviContractUrl && `${PROXY_BASE_URL}/${proviContractUrl}`}
          error="Ocorreu um erro ao carregar este contrato"
          onLoadProgress={({ loaded, total }) => setLoadProgress((loaded / total) * 100)}
          onLoadError={error => logger(error)}
          onRenderError={error => logger(error)}
          onLoadSuccess={object => {
            onLoadSuccess(object.numPages)
          }}
        >
          {arrayPages &&
            arrayPages.map((item, index) => (
              <Page
                width={isMobile ? window && window.screen.width : 800}
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                error="Ocorreu um erro ao carregar esta página"
                noData="Ocorreu um erro ao carregar esta página"
                loading="Carregando..."
                onLoadError={error => logger(error)}
              />
            ))}
        </Document>
      </DocumentContainer>
    )
  }, [arrayPages, documentRef, onLoadSuccess, proviContractUrl, isMobile, setLoadProgress])

  return (
    <>
      {isLoadingIntern && (hasPdf || redirectUrl) && (
        <LoadingPosition>
          {showLoadingText && <TextLoading>{loadProgress.toFixed(0)}% Estamos gerando seu contrato</TextLoading>}
          <LoadingProvi isLoadingDark={false} />
        </LoadingPosition>
      )}

      <Container>
        <Header>
          <CenterHeaderContent>
            {!signInProgress && (
              <OpacityTrick hide={!hasPdf || openBottomSheetSigner}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setOpenBottomSheetSigner(!openBottomSheetSigner)}
                  size={isMobile ? 'small' : 'medium'}
                >
                  Assinar Contrato
                </Button>
              </OpacityTrick>
            )}

            <OpacityTrick hide={!hasPdf}>
              <HackButtonGlobals>
                <Button
                  variant="outlined"
                  color="secondary"
                  aria-label="Baixar Contrato"
                  style={{ marginLeft: '16px' }}
                  startIcon={!isMobile && <IconDownload />}
                  size={isMobile ? 'small' : 'medium'}
                  onClick={() => {
                    window.open(redirectUrl, '_blank')
                  }}
                >
                  {isMobile ? <IconDownload /> : 'Baixar'}
                </Button>
              </HackButtonGlobals>
            </OpacityTrick>
          </CenterHeaderContent>
        </Header>

        {!hasPdf && !isMobile && (
          <Title>
            <h1>Assine o contrato</h1>
            <ArrowDocSign />
          </Title>
        )}

        {(!redirectUrl || !hasPdf) && (
          <WrapperLoading>
            <TimerDocSign counter={counter} />
          </WrapperLoading>
        )}

        {!isShowLoading && hasPdf && renderPages}

        <SignerBottomSheet isOpen={openBottomSheetSigner}>
          {!signInProgress && (
            <SignerBottomSheetContainer>
              <HotClickableDragableArea
                onClick={() => {
                  setOpenBottomSheetSigner(false)
                  setTimeout(() => onFingerSignToggle(false), 500)
                }}
              >
                <ArrowMore />
              </HotClickableDragableArea>

              <LayoutContent>
                {!fingerSigned && (
                  <SignerCallToAction
                    onClick={() => onFingerSignToggle(!fingerSigned)}
                    checked={fingerSigned}
                    aria-label="Clique para concordar com as condições do contrato"
                  >
                    <IconFingerSigner />
                    Clique para concordar com as condições do contrato
                  </SignerCallToAction>
                )}

                {fingerSigned && (
                  <SignerCallToAction
                    onClick={() => onFingerSignToggle(!fingerSigned)}
                    checked={fingerSigned}
                    aria-label="Termos e condições aceitas"
                  >
                    <IconCheck /> Termos e condições aceitas
                  </SignerCallToAction>
                )}

                <Button variant="contained" color="secondary" disabled={!fingerSigned} onClick={() => signContract()}>
                  Assinar Contrato
                </Button>
              </LayoutContent>
            </SignerBottomSheetContainer>
          )}
        </SignerBottomSheet>
      </Container>
    </>
  )
}
