import React, { useContext } from 'react'
import { Button, LoadingProvi } from '@provi/provi-components'
import { TemplateContext } from '../../../components/template/context'
import { history } from '../../../navigation/history'
import { getSlugPartner } from '../../../utils'

import { Container, Title, Text, Wrapper, ContainerTitle, WrapperButton } from './styles'

export const Intro = () => {
  const { isMobile, setIsLoading, isLoading } = useContext(TemplateContext)

  return (
    <>
      {isLoading && <LoadingProvi isLoadingDark />}
      <Container isMobile={isMobile}>
        <Wrapper>
          <ContainerTitle>
            <Title>Estamos muito felizes em fazer parte da sua mudança de vida!</Title>
          </ContainerTitle>

          <Text>As informações que vamos pedir fazem parte do seu processo seletivo de ISA. </Text>
          <Text>As questões abertas não tem certo ou errado, são só para te conhecer melhor, então responda com carinho.</Text>

          <WrapperButton>
            <Button
              marginHorizontal={0}
              type="submit"
              text="Começar"
              onClick={() => {
                setIsLoading(true)
                history.push(`/isa/cpf/${getSlugPartner()}`)
              }}
              persistFloatingMode={false}
            />
          </WrapperButton>
        </Wrapper>
      </Container>
    </>
  )
}
