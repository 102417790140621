import React, { useContext } from 'react'
import { Container, Title } from '@provi/provi-components'
import { TemplateContext } from '../../../components/template/context'
import { useISAContext } from '../../../components/template/ISA/context'
import { TemplateFormIsa } from '../../../components/template-isa'
import { FormFomik } from './form'
import { useCourseAfter } from './hooks'

export const CourseAfter = () => {
  const { creditPath, setDataModal } = useContext(TemplateContext)
  const { goToNextScreen } = useISAContext()
  useCourseAfter(setDataModal)
  return (
    <TemplateFormIsa>
      <Container>
        <Title text="Nos conte um pouco sobre o que você pretende fazer depois que terminar o curso" />
        <FormFomik data={creditPath && creditPath.ReadAfterGraduationIntent} goToNextScreen={goToNextScreen} />
      </Container>
    </TemplateFormIsa>
  )
}
