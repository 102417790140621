import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Wrapper = styled.div`
  width: 640px;
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`

export const Container = styled.div`
  padding-left: 137px;
  padding-right: 22px;
  padding-bottom: 22px;
  height: 100vh;
  background-color: ${theme.colors.blue900};
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  ${theme.breakpoints.down('md')} {
    padding-left: 22px;
    margin-left: 0;
  }
`

export const SubTitle = styled.p`
  font-family: 'CenturyGothicBold', sans serif;
  font-size: 24px;
  line-height: 30px;
  color: ${theme.colors.white100};
  ${theme.breakpoints.down('md')} {
    font-size: 16px;
    line-height: 24px;
  }
`

export const Text = styled.p`
  line-height: 30px;
  font-size: 16px;
  color: ${theme.colors.white100};
  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    line-height: 18px;
  }
`

export const TextModal = styled.div`
  font-size: 16px;
  line-height: 24px;
  margin: 24px 0;

  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    line-height: 18px;
    margin-top: 20px;
  }
`

export const ContainerButton = styled.div`
  width: 256px;
  text-align: center;
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`

export const ContainerButtonModal = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
