import React from 'react'
import { Button, Input, InputMasks, TextErrorInput, Title } from '@provi/provi-components'
import { Form } from '../../components/form'
import {
  validateBirthDate,
  validateBirthDateMinor18,
  validateFullName,
  isValidCpf,
  validatePhone
} from '../../utils/validators'
import moment from 'moment'

export const FormCPF = props => {
  const {
    handleChange,
    touched,
    InputProps,
    isShowPersonalInfo,
    handleSubmit,
    onChange,
    onChangeBirthDate,
    values,
    errors,
    handleBlur,
    setFieldValue,
    setValidateOnChange,
    formState,
    isSanar
  } = props

  const divHeight = '24px'

  return (
    <Form onSubmit={handleSubmit}>
      <InputMasks
        id="cpf"
        margin="normal"
        inputProps={{ cy: 'input-cpf', 'aria-label': 'cpf' }}
        mask="999.999.999-99"
        placeholder={'Ex: 111.222.333-44'}
        type="tel"
        autoFocus
        value={values.cpf}
        error={!!errors.cpf}
        onBlur={handleBlur('cpf')}
        InputProps={InputProps}
        onChange={e => onChange(e, { setFieldValue, setValidateOnChange })}
      />
      {errors.cpf && !(values.cpf?.length == 11 && errors.cpf == 'Preencha o seu CPF') && touched.cpf && (
        <TextErrorInput text={errors.cpf} cy="error-cpf" />
      )}

      {isShowPersonalInfo && (
        <>
          <div style={{ height: divHeight }} />

          <Title marginVertical={12} text="Nome completo" />
          <Input
            id="fullName"
            cy="input-full-name"
            placeholder="Digite seu nome completo"
            onBlur={handleBlur('fullName')}
            onChange={handleChange('fullName')}
            style={{ textTransform: 'uppercase' }}
            error={!!errors.fullName}
            value={values.fullName}
            margin="normal"
          />
          {errors.fullName && touched.fullName && <TextErrorInput cy="error-full-name" text={errors.fullName} />}

          <div style={{ height: divHeight }} />

          <Title marginVertical={12} text="Data de nascimento" />
          <InputMasks
            id="birthDate"
            mask="99/99/9999"
            cy="input-birth-date"
            inputProps={{ 'aria-label': 'birthDate' }}
            onChange={e => onChangeBirthDate(e, { handleChange, setValidateOnChange, setFieldValue })}
            error={!!errors.birthDate}
            value={values.birthDate}
            placeholder="Ex: 11/03/1992"
            margin="normal"
            type="tel"
          />
          {errors.birthDate && <TextErrorInput text={errors.birthDate} cy="error-birth-date" />}
        </>
      )}

      {isSanar && (
        <>
          <div style={{ height: divHeight }} />
          <Title marginVertical={12} text="Qual o número do seu celular?" />

          <InputMasks
            autoFocus={!values.phone}
            inputProps={{ cy: 'input-phone', 'aria-label': 'name' }}
            mask="(99) 99999-9999"
            margin="normal"
            id="phone"
            type="tel"
            placeholder={'Ex: (11) 95771-2414'}
            onChange={handleChange('phone')}
            onBlur={handleBlur('phone')}
            error={!!errors.phone}
            value={values.phone}
          />
        </>
      )}

      <Button
        marginHorizontal={0}
        marginVertical={24}
        id="button"
        cy="button"
        type="submit"
        disabled={
          (!(values.cpf && values.fullName && values.birthDate) ||
            !!errors.cpf ||
            !!errors.fullName ||
            !!errors.birthDate ||
            (isSanar && !!errors.phone) ||
            !validateBirthDate(values.birthDate) ||
            !validateBirthDateMinor18(values.birthDate) ||
            (isSanar && !validatePhone(values.phone)) ||
            !moment(values.birthDate, 'DD/MM/YYYY', true).isValid() ||
            !validateFullName(values.fullName)) &&
          ((!formState && values.cpf.length < 11) || formState || !isValidCpf(values.cpf))
        }
        text="Avançar"
        persistFloatingMode={false}
      />
    </Form>
  )
}
