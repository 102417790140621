import styled from 'styled-components'

import { theme } from '../../../theme'

import backgroundIsa from '../../../assets/svg/background-isa.svg'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 0 5%;

  width: 100%;

  min-height: 100vh;

  background-color: ${theme.colors.deepblue};

  background-image: url(${backgroundIsa});
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 35% auto;

  ${theme.breakpoints.down('md')} {
    background-image: none;
    padding: 0;
    align-items: center;
    justify-content: flex-start;
  }
`

export const Title = styled.h1`
  font-size: 36px;
  line-height: 46px;
  font-weight: 700;
  color: ${theme.colors.pink900};
  font-family: 'Montserrat', sans-serif !important;

  ${theme.breakpoints.down('md')} {
    font-size: 24px;
    line-height: 34px;
  }
`

export const Subtitle = styled.p`
  font-size: 22px;
  line-height: 32px;
  font-weight: 500;
  margin-top: 60px;
  color: ${theme.colors.white100};
  font-family: 'Montserrat', sans-serif !important;

  ${theme.breakpoints.down('md')} {
    font-size: 16px;
    line-height: 26px;
    margin-top: 30px;
  }
`

export const Wrapper = styled.div`
  transform: translateY(-40%);
  max-width: 600px;

  ${theme.breakpoints.down('md')} {
    width: 90%;

    transform: translateY(20%);
  }
`

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;

  bottom: 0px;
  left: 0;

  ${theme.breakpoints.up('sm')} {
    display: none;
  }
`
