import React, { useContext } from 'react'
import { Container, Selector, Button, TextErrorInput, Input, Title, SubHeaderDesktop, Modal } from '@provi/provi-components'
import { Form } from '../../../components/form'
import { TemplateContext } from '../../../components/template/context'
import { TemplateForm } from '../../../components/template-form'
import { useBank } from './hooks'
import { Timer } from '../../../components/timer'
import {
  Row,
  WrapperInputs,
  Label,
  OptionsList,
  Option,
  OptionText,
  Field,
  LabelSelect,
  TextModal,
  TitleModal,
  CenterButton,
  ButtonCloseModal,
  ContainerTimer,
  ContainerUserBlocked,
  ButtonWhatsApp
} from './style'
import { URL_EXTERNAL } from '../../../constants'

export const Bank = () => {
  const { goToNextScreen, setDataModal, creditPath, isMobile, setIsShowModal } = useContext(TemplateContext)
  const [
    onSubmit,
    setSelect,
    values,
    onSelectOption,
    selectedOption,
    isButtonDisabled,
    onFormChange,
    agencyError,
    accountError,
    bank,
    banks,
    OPTIONS,
    isShowModalBankAccount,
    setIsShowModalBankAccount,
    onSubmitModal,
    digitError,
    bankError,
    isBankDigital,
    modalError,
    setModalError,
    isVerifyingAccount,
    isBlocked
  ] = useBank(goToNextScreen, setDataModal, creditPath, setIsShowModal)
  return isBlocked ? (
    <ContainerUserBlocked>
      <h1>Não conseguimos validar os seus dados bancários</h1>
      <h3>Por esse motivo não conseguimos seguir com a sua solicitação.</h3>
      <h4>Caso precise de ajuda, entre em contato com o nosso suporte.</h4>
      <ButtonWhatsApp onClick={() => window.open(URL_EXTERNAL, '_blank')}>Entrar em contato</ButtonWhatsApp>
    </ContainerUserBlocked>
  ) : isVerifyingAccount ? (
    <ContainerTimer>
      <Timer />
      <h4>Estamos analisando os seus dados bancários...</h4>
    </ContainerTimer>
  ) : (
    <TemplateForm>
      {isShowModalBankAccount && (
        <Modal
          title="Lembre-se que para não ter problemas com o recebimento do dinheiro, a conta bancária cadastrada NÃO pode ser:"
          onDismiss={() => setIsShowModalBankAccount(false)}
          footer={<Button marginHorizontal={0} type="button" text="Entendi" onClick={() => onSubmitModal()} />}
        >
          <TextModal>- Conta no nome de outra pessoa</TextModal>
          <TextModal>- Conta Caixa Fácil</TextModal>
          <TextModal>- Conta BB fácil</TextModal>
          <TextModal>- Conta salário</TextModal>
        </Modal>
      )}

      {modalError && (
        <Modal onDismiss={() => setModalError(false)}>
          <TitleModal>Ops! Alguma coisa deu errado.</TitleModal>
          <TextModal>Ocorreu algum problema na validação dos seus dados bancários.</TextModal>
          <CenterButton>
            <ButtonCloseModal onClick={() => setModalError(false)}>Revisar dados bancários</ButtonCloseModal>
          </CenterButton>
        </Modal>
      )}
      <Container>
        <Form onSubmit={onSubmit}>
          {isMobile ? (
            creditPath.partnerId === 1344 ? (
              <Title text="Preencha os seus dados bancários para receber o seu empréstimo:" />
            ) : (
              <Title text="Preencha os seus dados bancários para receber a mesada:" />
            )
          ) : creditPath.partnerId === 1344 ? (
            <SubHeaderDesktop
              title="Preencha os seus dados bancários para receber o seu empréstimo:"
              handleModal={() => setIsShowModal(true)}
              isShowButtonHelpModal={true}
            />
          ) : (
            <SubHeaderDesktop
              title="Preencha os seus dados bancários para receber a mesada:"
              handleModal={() => setIsShowModal(true)}
              isShowButtonHelpModal={true}
            />
          )}

          <LabelSelect>Selecione o seu banco:</LabelSelect>
          <Selector placeholder="Escolher" options={banks} value={bank} onValueChange={setSelect} />
          {bankError && <TextErrorInput text={bankError} />}
          <Row>
            <WrapperInputs width="70">
              <Label>Agência:</Label>
              <Input
                inputProps={{
                  maxLength: 4
                }}
                type="tel"
                disabled={isBankDigital}
                value={values['agency']}
                placeholder="Digite aqui..."
                onChange={v => onFormChange(v.target.value, 'agency')}
              />
            </WrapperInputs>
            {!isBankDigital && (
              <WrapperInputs width="25">
                <Label>Dígito:</Label>
                <Field
                  inputProps={{
                    maxLength: 1
                  }}
                  type="tel"
                  placeholder="Opcional"
                  onChange={v => onFormChange(v.target.value, 'agencyDigit')}
                  value={values['agencyDigit']}
                />
              </WrapperInputs>
            )}
          </Row>
          {agencyError && <TextErrorInput text={agencyError} />}

          <Row>
            <WrapperInputs width="70">
              <Label>Conta:</Label>
              <Input
                inputProps={{
                  maxLength:
                    selectedOption &&
                    selectedOption.id === 2 &&
                    ((bank && bank.code === '104') || (bank && bank.code === '001'))
                      ? 11
                      : 8
                }}
                type="tel"
                value={values['account']}
                placeholder="Digite aqui..."
                onChange={v => onFormChange(v.target.value, 'account')}
              />
              {accountError && <TextErrorInput text={accountError} />}
            </WrapperInputs>
            <WrapperInputs width="25">
              <Label>Dígito:</Label>
              <Field
                type="tel"
                inputProps={{
                  maxLength: 1
                }}
                value={values['accountDigit']}
                placeholder="0"
                onChange={v => onFormChange(v.target.value, 'accountDigit')}
              />
              {digitError && <TextErrorInput text={digitError} />}
            </WrapperInputs>
          </Row>

          <OptionsList isMobile={isMobile}>
            {OPTIONS.map(option => (
              <Option
                key={option.id}
                isSelected={selectedOption && selectedOption.id === option.id}
                onClick={() => onSelectOption(option)}
              >
                <OptionText isSelected={selectedOption && selectedOption.id === option.id}>{option.label}</OptionText>
              </Option>
            ))}
          </OptionsList>

          <Button marginHorizontal={0} type="submit" disabled={!isButtonDisabled} text="Avançar" persistFloatingMode={false} />
        </Form>
      </Container>
    </TemplateForm>
  )
}
