import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const ContainerHeader = styled.div`
  height: 55px;
  width: 100%;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  flex-direction: column;
  z-index: 10;
`

export const Title = styled.h3`
  font-size: 16px;
  line-height: 18px;
  font-family: 'CenturyGothicBold';
  color: ${theme.colors.white100};
  margin: 0;
  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    line-height: 18px;
  }
`

export const SubTitle = styled.h3`
  font-size: 14px;
  line-height: 16px;
  font-family: 'CenturyGothicBold';
  color: ${theme.colors.pink900};
  margin: 0;
  margin-top: 3px;
  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    line-height: 18px;
  }
`

export const WrapperItens = styled.div`
  height: 52px;
  width: 100%;
  background-color: ${theme.colors.blue900};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
