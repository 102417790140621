import { useState, useEffect, useContext, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { TemplateContext } from '../../../components/template/context'
import { getContract, validateContractSign, fetchCurrentIP, getCreditPathPrevious } from '../../../services/api'
import { validateToken } from '../../../services/auth'

export const useProvisign = goToNextScreen => {
  const { setIsLoading } = useContext(TemplateContext)
  const [contractInfo, setContractInfo] = useState({})
  const [counter, setCounter] = useState(60)
  const [redirectUrl, setRedirectUrl] = useState()
  const [hasPdf, setHasPdf] = useState(false)
  const [signInProgress, setSignInProgress] = useState(false)
  const params = useParams()
  const [arrayPages, setNumberOfPages] = useState([])
  const [openBottomSheetSigner, setOpenBottomSheetSigner] = useState(false)
  const [fingerSigned, onFingerSignToggle] = useState(false)
  const [proviContractURL, setProviContractURL] = useState('')
  const [loadProgress, setLoadProgress] = useState(0)
  const [isLoadingIntern, setIsLoadingIntern] = useState(false)
  const [showLoadingText, setShowLoadingText] = useState(true)

  useEffect(() => {
    setIsLoadingIntern(true)
  }, [])

  useEffect(() => {
    let interval = null
    if (counter > 0 && !hasPdf) {
      interval = setInterval(() => {
        setCounter(counter => counter - 1)
      }, 1000)
    } else if (counter === 0) {
      clearInterval(interval)
      if (!redirectUrl) {
        throw Error('Error contract docsign')
      }
    }
    return () => clearInterval(interval)
    // eslint-disable-next-line
  }, [counter])

  useEffect(() => {
    const intervalContract = setInterval(async () => {
      if (contractInfo.signed) return clearInterval(intervalContract)

      const { data } = await getContract(params.crid)

      if (!data) {
        return
      }

      setContractInfo(data)

      if (data.signed) {
        clearInterval(intervalContract)
        return await goToNextScreen()
      }

      if (data.contractUrl && !redirectUrl) {
        setRedirectUrl(data.contractUrl)
        clearInterval(intervalContract)
        setProviContractURL(data.contractUrl.replace('https://', ''))
        setHasPdf(true)
      }
    }, 3000)

    return () => clearInterval(intervalContract)
    // eslint-disable-next-line
  }, [signInProgress])

  async function signContract() {
    setSignInProgress(true)
    setShowLoadingText(false)
    setIsLoadingIntern(true)

    const auth = await validateToken(localStorage.getItem('token'))
    const ip = await fetchCurrentIP()
    const creditPath = await getCreditPathPrevious(params.crid, 'contractSignature')

    const payload = {
      ContractRecipientId: contractInfo.ContractRecipientId,
      ProviContractId: contractInfo.ProviContractId,
      IP: (ip && ip.data && ip.data.ip) || null,
      device: navigator.userAgent,
      phone: creditPath.ReadPhone,
      email: auth.data.email
    }

    await validateContractSign(payload)
  }

  const onLoadSuccess = pages => {
    const array = Array.from(Array(pages).keys())
    setNumberOfPages(array)
    setIsLoading(false)
    setIsLoadingIntern(false)
  }

  const documentRef = useRef(null)

  return [
    counter,
    redirectUrl,
    hasPdf,
    signContract,
    signInProgress,
    arrayPages,
    openBottomSheetSigner,
    setOpenBottomSheetSigner,
    fingerSigned,
    onFingerSignToggle,
    documentRef,
    isLoadingIntern,
    onLoadSuccess,
    proviContractURL,
    loadProgress,
    setLoadProgress,
    showLoadingText
  ]
}
