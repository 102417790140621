import React from 'react'
import moment from 'moment'

import { theme, Button, Title, SubTitle, SubHeaderDesktop } from '@provi/provi-components'
import { currencyParser } from '../../../utils'
import { IconCheck } from '../../../assets/svg/icon-check'
import { ArrowMore } from '../../../assets/svg/arrow-more'
import { ContainerOption, Option, Grid, Text, ContainerDescont, Search, DownloadMore, TextMore, ValueDiscount } from './style'
import { SmallText } from '../../../components/SmallText'

function CourseSelection({
  isMobile,
  setIsShowModal,
  options,
  textSearch,
  handleChange,
  creditPath,
  cursesSelect,
  SelectedCourse,
  removeCurse,
  optionsSearch,
  selectCourse,
  countCourses,
  incrementNumberOfShowCourses
}) {
  return (
    <>
      {isMobile ? (
        <Title text="Escolha o seu produto" />
      ) : (
        <SubHeaderDesktop title="Escolha o seu produto" handleModal={() => setIsShowModal(true)} isShowButtonHelpModal />
      )}

      {options.length > 3 && (
        <>
          <SubTitle text="Você pode escolher mais de um caso necessário" />
          <Search
            type="text"
            placeholder="Digite o produto desejado"
            value={textSearch}
            onChange={e => handleChange(e.target.value)}
          />
        </>
      )}

      {creditPath && creditPath.ReadProductSelection && creditPath.ReadProductSelection.total !== null && (
        <ContainerDescont>
          <ValueDiscount bold color={theme.colors.blue900}>
            Valor total:{' '}
            {currencyParser(creditPath && creditPath.ReadProductSelection && creditPath.ReadProductSelection.total, 'R$')}
          </ValueDiscount>

          {creditPath && creditPath.ReadProductSelection && creditPath.ReadProductSelection.discount !== 0 && (
            <>
              <ValueDiscount bold color={theme.colors.pink900}>
                Desconto: {currencyParser(creditPath.ReadProductSelection.discount, 'R$')}
              </ValueDiscount>
              <ValueDiscount bold>
                Valor final: {currencyParser(creditPath.ReadProductSelection.finalTotal, 'R$')}
              </ValueDiscount>
            </>
          )}

          <SmallText>*O valor final pode sofrer alteração por conta do IOF.</SmallText>
        </ContainerDescont>
      )}

      <ContainerOption>
        {cursesSelect.map((item, key) => (
          <SelectedCourse item={item} key={key} removeCurse={removeCurse} />
        ))}
      </ContainerOption>

      <ContainerOption>
        {optionsSearch.map((item, key) => (
          <Option key={item.id} isFirst={key === 0} onClick={() => selectCourse(item)}>
            {item.name && (
              <Grid>
                <Text title size="13" bold color={theme.colors.pink900}>
                  {item.name}
                </Text>
              </Grid>
            )}

            {item.startDate && (
              <Grid line="row">
                <IconCheck />
                <Text bold margin>
                  {moment(item.startDate).format('DD/MM/YYYY')}
                </Text>
              </Grid>
            )}

            {item.details &&
              item.details.map(item => (
                <Grid line="row">
                  <IconCheck />
                  <Text bold margin>
                    {item}
                  </Text>
                </Grid>
              ))}

            {item.price && (
              <Grid line="row">
                <IconCheck />
                <Text bold margin>
                  {currencyParser(item.price, 'R$')}
                </Text>
              </Grid>
            )}
          </Option>
        ))}
      </ContainerOption>

      {countCourses < options.length && countCourses !== options.length && (
        <DownloadMore onClick={() => incrementNumberOfShowCourses()}>
          <TextMore>VER MAIS</TextMore>
          <ArrowMore />
        </DownloadMore>
      )}

      <Button
        marginHorizontal={0}
        disabled={cursesSelect.length <= 0}
        text="Avançar"
        type="submit"
        persistFloatingMode={false}
      />
    </>
  )
}

export default CourseSelection
